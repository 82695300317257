import { Alert, Button, Input, Prompt, Tab, Tabs, ToggleContainer } from '@components'
import { HeaderBlock } from '@components/HeaderBlock'
import { ReportType, UserRole } from '@constants'
import { PermissionBoundary, usePermissionBoundary } from '@context'
import { deletePeriod, fetchPeriod, IPeriod, performPeriodAction, updatePeriod } from '@query'
import { formatDate, setAPIErrors } from '@utils'
import { generateReport } from '@utils/generateReport'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FaCheckCircle, FaTrash, FaUnlock } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { FinancialStatementForm } from './FinancialStatementForm'
import { DateBlock, DetailContainer } from './PeriodDetails.styled'
import { PeriodSteps } from './PeriodSteps'

interface Props {
  periodId: number
  businessId: string
  onClose: () => void
}

export const PeriodDetails: React.FC<Props> = ({ periodId, businessId, onClose }) => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const [showDelete, setShowDelete] = useState(false)
  const isEditor = usePermissionBoundary(UserRole.EDITOR)

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { isDirty, isSubmitting, errors }
  } = useForm()

  const { data } = useQuery(
    [businessId, 'periods', periodId],
    () => fetchPeriod({ businessId, periodId }),
    {
      onSuccess: data => {
        reset({
          start_date: data.start_date,
          end_date: data.end_date
        })
      },
      onError: () => onClose()
    }
  )
  const period = data

  const updateMutation = useMutation<IPeriod, unknown, Partial<IPeriod>>(
    data => updatePeriod({ businessId, periodId }, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([businessId, 'periods'])
        await queryClient.invalidateQueries([businessId, 'periods', periodId])
        toast.success(t('general.success'))
      },
      onError: ({ status, data }) => {
        status === 400 && setAPIErrors(data, setError)
      }
    }
  )

  const onSubmit = handleSubmit(data => updateMutation.mutateAsync(data))

  const deleteMutation = useMutation(() => deletePeriod({ businessId, periodId }), {
    onSuccess: () => {
      queryClient.invalidateQueries([businessId, 'periods'])
      setShowDelete(false)
      onClose()
      toast.success(t('general.success'))
    },
    onError: ({ status }) => {
      if (status == 412) {
        toast.error(t('period.deleteFail412Description'))
      }

      setShowDelete(false)
      onClose()
    }
  })

  if (!period) return null

  return (
    <StyledWrapper data-key={`period-${period.id}`}>
      <HeaderBlock
        header={t('period.period')}
        subHeader={
          <DateBlock>
            {formatDate(period.start_date)} - {formatDate(period.end_date)}
          </DateBlock>
        }
        menuItems={
          isEditor && [
            ...(period?.is_locked
              ? [
                  {
                    name: t('period.unlock'),
                    Icon: FaUnlock,
                    onClick: () => {
                      const promise = performPeriodAction({
                        businessId,
                        periodId,
                        action: 'unlock'
                      })
                      toast.promise(promise, {
                        loading: t('general.loading'),
                        success: t('general.success'),
                        error: t('general.error')
                      })
                      promise.then(() => {
                        queryClient.invalidateQueries([businessId, 'periods'])
                      })
                    }
                  },
                  {
                    divider: true
                  }
                ]
              : []),
            {
              name: t('period.downloadLedger'),
              onClick: () => {
                generateReport({
                  business_id: businessId,
                  type: ReportType.LEDGER,
                  columns: [
                    {
                      date_from: period.start_date,
                      date_to: period.end_date
                    }
                  ]
                })
              }
            },
            {
              name: t('period.downloadJournal'),
              onClick: () => {
                generateReport({
                  business_id: businessId,
                  type: ReportType.JOURNAL,
                  columns: [
                    {
                      date_from: period.start_date,
                      date_to: period.end_date
                    }
                  ]
                })
              }
            },
            {
              name: t('period.downloadBalanceSheet'),
              onClick: () => {
                generateReport({
                  business_id: businessId,
                  type: ReportType.BALANCE_SHEET,
                  extend_accounts: true,
                  columns: [
                    {
                      date_at: period.end_date
                    }
                  ]
                })
              }
            },
            {
              name: t('period.downloadIncomeStatement'),
              onClick: () => {
                generateReport({
                  business_id: businessId,
                  type: ReportType.INCOME_STATEMENT,
                  extend_accounts: true,
                  columns: [
                    {
                      date_from: period.start_date,
                      date_to: period.end_date
                    }
                  ]
                })
              }
            },
            {
              divider: true
            },
            {
              name: t('period.delete'),
              Icon: FaTrash,
              onClick: () => setShowDelete(true),
              intent: 'danger'
            }
          ]
        }
      />

      <ContentWrapper>
        <Tabs>
          <Tab key="details" name={t('period.tabGeneral')}>
            <ScrollableTabContent>
              <Alert
                type="info"
                title={t('period.info.notEndedTitle')}
                isVisible={!period.has_ended}
                description={t('period.info.notEndedDescription')}
              />
              <Alert
                type="info"
                title={t('period.info.isLockedTitle')}
                isVisible={period.is_locked}
                description={t('period.info.isLockedDescription')}
              />
              {/*
          <Alert
            type="warning"
            title={t('period.info.notReportedTitle')}
            isVisible={period.has_ended && !period.is_reported}
            description={t('period.info.notReportedDescription')}
          />
          <Alert
            type="success"
            title={t('period.info.isReportedTitle')}
            isVisible={period.is_reported}
            description={t('period.info.isReportedDescription')}
          />
          */}

              <form onSubmit={onSubmit}>
                <fieldset disabled={period.is_locked || !isEditor}>
                  <Input
                    type="date"
                    label={t('period.form.startDate')}
                    info={t('period.form.startDateInfo')}
                    {...register('start_date', { required: true })}
                    errors={errors.start_date}
                  />

                  <Input
                    type="date"
                    label={t('period.form.endDate')}
                    info={t('period.form.endDateInfo')}
                    {...register('end_date', { required: true })}
                    errors={errors.end_date}
                  />

                  {!period.is_locked && isEditor && (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        type="submit"
                        intent="success"
                        disabled={!isDirty}
                        showSpinner={isSubmitting}
                        icon={<FaCheckCircle />}
                      >
                        {t('general.save')}
                      </Button>
                    </div>
                  )}
                </fieldset>
              </form>

              <PermissionBoundary requireRole={UserRole.EDITOR}>
                <DetailContainer>
                  <ToggleContainer
                    title={t('period.makeFinancialStatementTitle')}
                    openByDefault={true}
                  >
                    {/*<StepperInfo>{t('period.makeFinancialStatementDescription')}</StepperInfo>*/}
                    <PeriodSteps period={period} />
                  </ToggleContainer>
                </DetailContainer>
              </PermissionBoundary>
            </ScrollableTabContent>
          </Tab>

          <Tab key="financial-statement" name={t('period.tabFinancialStatement')}>
            <FinancialStatementForm period={period} />
          </Tab>
        </Tabs>
      </ContentWrapper>

      <Prompt
        title={t('period.ensureDeleteTitle')}
        description={t('period.ensureDeleteDescription')}
        isVisible={showDelete}
        onClose={() => setShowDelete(false)}
        buttons={[
          {
            text: t('general.cancel'),
            action: () => setShowDelete(false)
          },
          {
            text: t('period.delete'),
            action: () => deleteMutation.mutateAsync(),
            intent: 'danger'
          }
        ]}
      />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;

  .tabs-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .tabs-tab {
    flex: 1;
    overflow: hidden;
  }
`

const ScrollableTabContent = styled.div`
  height: 100%;
  overflow: auto;
`

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: ${({ theme }) => theme.spacing.md}rem;
  overflow: hidden;
`
