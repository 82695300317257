import { callAll, handleOnEnterPress, isFunction } from '@utils'
import { AnimatePresence } from 'framer-motion'
import React, {
  cloneElement,
  createContext,
  isValidElement,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react'
import { createPortal } from 'react-dom'
import { useHotkeys } from 'react-hotkeys-hook'
import { ModalDialog as StyledModalDialog, ModalOverlay } from './Modal.styled'

interface ModalProps {
  onClose?: () => void
  onAppear?: () => void
  children: ReactNode | ((value: IModalContext) => ReactNode)
  initiallyOpen?: boolean
}

interface IModalContext {
  openModal: () => void
  hideModal: () => void
  isOpen: boolean
}

const ModalContext = createContext<IModalContext | undefined>(undefined)

export const useModalState = (): IModalContext => {
  const store = useContext(ModalContext)
  if (!store) {
    throw new Error('Cannot use modalContext outside of a provider')
  }
  return store
}

export const Modal: React.FC<ModalProps> = ({
  children,
  initiallyOpen = false,
  onClose,
  onAppear
}) => {
  const [isOpen, setIsOpen] = useState(Boolean(initiallyOpen))

  const hideModal = useCallback(() => {
    setIsOpen(false)
    onClose?.()
  }, [setIsOpen, onClose])

  const openModal = useCallback(() => {
    setIsOpen(true)
    onAppear?.()
  }, [setIsOpen, onClose])

  const modalContext = useMemo(
    () => ({ isOpen, openModal, hideModal }),
    [isOpen, openModal, hideModal]
  )

  if (isFunction(children)) {
    return (
      <ModalContext.Provider value={modalContext}>{children(modalContext)}</ModalContext.Provider>
    )
  }
  return <ModalContext.Provider value={modalContext}>{children}</ModalContext.Provider>
}

export const ModalDialog: React.FC<{
  width?: number
  height?: number
  children: React.ReactNode
}> = ({ children, ...rest }) => {
  const rootRef = useRef<HTMLElement>(document.getElementById('modal-root'))
  return createPortal(<ModalComponent {...rest}>{children}</ModalComponent>, rootRef.current)
}

export const ModalComponent: React.FC<{
  width?: number
  height?: number
  children: React.ReactNode
}> = ({ width, height, children }) => {
  const { isOpen, hideModal } = useModalState()
  // NOTE: Mouse up triggers also onClick event. Accidental mouseUp event on modal backdrop
  // might occur when selecting text inside modal, for example. This logic makes sure that
  // onClick events have been started on the backdrop as well.
  const [, setMouseDown] = useState(false)

  useHotkeys('escape', () => hideModal())

  return (
    <AnimatePresence mode="wait">
      {isOpen && (
        <>
          <ModalOverlay
            onMouseDown={() => setMouseDown(true)}
            onTouchStart={() => setMouseDown(true)}
            onClick={() =>
              setMouseDown(value => {
                value && hideModal()
                return false
              })
            }
          >
            <StyledModalDialog
              onMouseDown={e => e.stopPropagation()}
              onClick={e => e.stopPropagation()}
              width={width}
              height={height}
            >
              {children}
            </StyledModalDialog>
          </ModalOverlay>
        </>
      )}
    </AnimatePresence>
  )
}

export const ModalOpenButton: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { openModal } = useModalState()

  if (!isValidElement(children)) throw new Error('Invalid child element')

  return cloneElement<any>(children, {
    onClick: callAll(openModal, children.props.onClick),
    onKeyUp: callAll<React.KeyboardEvent<HTMLElement>>(e => {
      e.preventDefault()
      handleOnEnterPress(e, () => {
        openModal()
      })
    }, children.props.onKeyUp)
  })
}

export const ModalDismissButton: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { hideModal } = useModalState()

  if (!isValidElement(children)) throw new Error('Invalid child element')

  return cloneElement<any>(children, {
    onClick: callAll(hideModal, children.props.onClick),
    onKeyUp: callAll<React.KeyboardEvent<HTMLElement>>(e => {
      e.preventDefault()
      handleOnEnterPress(e, () => {
        hideModal()
      })
    }, children.props.onKeyUp)
  })
}
