import { IBusiness } from '@query'
import React, { useContext } from 'react'

interface Props {
  businessId: string
  businessName?: string
  periodId?: number
  vatPeriodId?: number
  data: Partial<IBusiness>
}

export const BusinessContext = React.createContext<Props>({ businessId: '', data: {} })

export const useBusinessContext = (): Props => {
  return useContext(BusinessContext)
}
