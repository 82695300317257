export const omitEmptyValues = (obj: Record<string, any>): Record<string, any> => {
  for (const propName in obj) {
    if (obj[propName] === '' || obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName]
    }
  }
  return obj
}

export const omitNullValues = (obj: Record<string, any>): Record<string, any> => {
  for (const propName in obj) {
    if (obj[propName] === null) {
      delete obj[propName]
    }
  }
  return obj
}
