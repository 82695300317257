import { ActionBar, Button, UploadFilesButton } from '@components'
import { useBusinessContext, useFeature } from '@context'
import { useQueryParam, useQueryParams } from '@hooks'
import { createFolder, IFile, uploadFile } from '@query'
import { IFilter } from '@root/components/ActionBar/FilterBar/FilterSelection'
import React from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FaCloudUploadAlt, FaFolderPlus } from 'react-icons/fa'
import { useMutation, useQueryClient } from 'react-query'

interface Props {
  onSearch: (value: string) => void
  onFilter: (filters: any) => void
  activeFilters: IFilter[]
}

export const FilesActionBar: React.FC<Props> = ({ onSearch, onFilter, activeFilters }) => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const [folderId] = useQueryParam<number>('folder', value =>
    value ? parseInt(value, 10) : undefined
  )
  const [, setParams] = useQueryParams()
  const { businessId } = useBusinessContext()
  const hasFileAccess = useFeature('feature_unlimited_storage')

  const filterButtons = [
    {
      id: 'not-used',
      name: t('files.filters.notUsed'),
      filter: { is_used: false }
    }
  ]

  const { mutate: createBlankFolder, isLoading: isCreatingFolder } = useMutation(
    () =>
      createFolder({ businessId }, { name: t('files.actionBar.defaultName'), parent: folderId }),
    {
      onSuccess: data => {
        queryClient.invalidateQueries([businessId, 'folders'])
        setParams({
          id: data.id,
          idType: 'folder'
        })
      }
    }
  )

  const { mutateAsync: uploadFileMutation, isLoading: isUploading } = useMutation<
    IFile,
    unknown,
    File
  >(file => uploadFile(file, { businessId, fileName: file.name, folderId }), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([businessId, 'files'])
    },
    onError: ({ status }) => {
      if (status == 426) {
        toast.error(t('error.upgradeRequired'))
      }
    }
  })

  const primaryButtons = [
    <UploadFilesButton
      key="new-file"
      icon={<FaCloudUploadAlt size={16} />}
      uploadFile={uploadFileMutation}
      showSpinner={isUploading}
      disabled={isUploading}
    >
      {t('files.actionBar.newFile')}
    </UploadFilesButton>,

    <Button
      key="new-folder"
      icon={<FaFolderPlus size={16} />}
      showSpinner={isCreatingFolder}
      onClick={() => createBlankFolder()}
    >
      {t('files.actionBar.newFolder')}
    </Button>
  ]

  return (
    <ActionBar
      searchPlaceholder={t('files.actionBar.search')}
      onSearch={search => onSearch(search)}
      onFilter={filters => onFilter(filters)}
      filterButtons={filterButtons}
      activeFilters={activeFilters}
      primaryButtons={hasFileAccess ? primaryButtons : []}
    />
  )
}
