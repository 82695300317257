import styled from 'styled-components'

const SELECT_WIDTH = '2rem !important'

export const VatSelectWrapper = styled.div`
  display: flex;
  position: relative;
`

export const VatTypeSelect = styled.select`
  opacity: 0;
  width: ${SELECT_WIDTH};
  cursor: pointer;
`

export const VatRateSelect = styled.select`
  width: ${SELECT_WIDTH};
  text-align: right;
  cursor: pointer;
`

export const VatIconWrapper = styled.div`
  display: flex;
  position: absolute;
  width: ${SELECT_WIDTH};

  svg {
    fill: ${({ theme }) => theme.colors.metalGray};
  }
`
