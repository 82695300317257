import React from 'react'
import { IAttachmentAnalysisValueType, IFile } from '@query'
import { FeatureWrapper } from '@context'
import { AttachmentAnalysisStatus } from '@constants'
import { Spinner } from 'evergreen-ui'
import { formatCurrency, formatDate } from '@utils'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ToggleContainer } from '@components'
import { AnimatePresence, motion } from 'framer-motion'

interface Props {
  attachment: IFile
}

export const AttachmentAnalysisPreview: React.FC<Props> = ({ attachment }) => {
  const isLoading = attachment.analysis_status === AttachmentAnalysisStatus.IN_PROGRESS
  const analysisResults = attachment.analysis_results
  const { t } = useTranslation()

  const getLabel = (type: IAttachmentAnalysisValueType): string => {
    return t(`files.analysis.labels.${type}`)
  }

  const getValue = (type: IAttachmentAnalysisValueType, value: string): string => {
    const formatters = {
      [IAttachmentAnalysisValueType.TOTAL_AMOUNT]: (value: string) =>
        formatCurrency(parseFloat(value) / 100, null, 'EUR'),
      [IAttachmentAnalysisValueType.INVOICE_DATE]: (value: string) => formatDate(value),
      [IAttachmentAnalysisValueType.INVOICE_DUE_DATE]: (value: string) => formatDate(value),
      [IAttachmentAnalysisValueType.RECEIPT_DATE]: (value: string) => formatDate(value)
    }
    const formatter = formatters[type]
    return formatter ? formatter(value) : value
  }

  return (
    <FeatureWrapper feature="feature_analyze_attachments">
      <ToggleContainer
        title={'🐞 Extracted data'}
        info={'Lorem ipsum'}
        openByDefault={analysisResults.length == 1}
      >
        <ResultsWrapper>
          {isLoading && <Spinner size={24} />}

          {!isLoading &&
            analysisResults.map((result, index) => (
              <ResultWrapper key={`result-${index}`}>
                <ResultTags>
                  <AnimatePresence>
                    {result.values?.map(({ type, value }) => (
                      <AnalysisTag key={`analysis-type-${type}`}>
                        <span className="title">{getLabel(type)}</span>
                        <span className="value">{getValue(type, value)}</span>
                      </AnalysisTag>
                    ))}
                  </AnimatePresence>
                </ResultTags>
              </ResultWrapper>
            ))}
        </ResultsWrapper>
      </ToggleContainer>
      <br />
    </FeatureWrapper>
  )
}

const ResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md}rem;

  .title {
    display: flex;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.neutralBlack};
    gap: ${({ theme }) => theme.spacing.xs}rem;
  }
`

const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xs}rem;

  .page-info {
    font-size: ${({ theme }) => theme.fontSize.xs}rem;
    color: ${({ theme }) => theme.colors.neutralBlack};
    font-weight: bold;
    text-transform: uppercase;
  }
`

const ResultTags = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm}rem;
`

const AnalysisTag = styled(motion.div).attrs({
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 }
})`
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  font-weight: 500;
  background: ${({ theme }) => theme.colors.nocfoPurple}22;
  padding: ${({ theme }) => theme.spacing.xs}rem;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xxxs}rem;

  .title {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.nocfoPurple};
  }

  .value {
    color: ${({ theme }) => theme.colors.nocfoPurple};
  }
`
