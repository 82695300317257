import { removeDuplicates } from '@utils'
import React, { useCallback, useEffect } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

export interface Item {
  id: any
  [key: string]: any
}

export interface IDraggableItem {
  type: string
  allowDrop?: boolean
  allowDrag?: boolean
  item: Item
}

export interface IDraggableItemRenderProps {
  isSelected: boolean
  isDragging: boolean
  isOver: boolean
  isActiveTarget: boolean
  didDrop: boolean
}

interface Props {
  item: IDraggableItem
  size: number
  itemType: string
  selectedItems: IDraggableItem[]
  isSelected: boolean
  onClick?: (item: IDraggableItem, e: React.MouseEvent<HTMLElement>) => void
  onDrop: (target: IDraggableItem, items: IDraggableItem[]) => void
  render: (item: IDraggableItem, props: IDraggableItemRenderProps) => React.ReactNode
}

const itemID = (item: IDraggableItem) => `${item.type}-${item.item.id}`

export const DraggableItem: React.FC<Props> = ({
  item,
  size,
  itemType,
  selectedItems,
  onClick,
  onDrop,
  isSelected,
  render
}) => {
  const [{ isDragging }, dragRef, preview] = useDrag(
    () => ({
      type: itemType,
      item:
        selectedItems.length > 0
          ? removeDuplicates([...selectedItems, item], item => `${item.type}-${item.item.id}`)
          : [item],
      collect: monitor => ({
        isDragging: monitor.isDragging()
      }),
      canDrag: () => item.allowDrag !== false
    }),
    [item, selectedItems]
  )

  const canDrop = useCallback(() => {
    if (isDragging) {
      return false
    }

    if (selectedItems.some(s => itemID(s) === itemID(item))) {
      return false
    }

    return true
  }, [item, selectedItems, isDragging])

  const [{ isActiveTarget, didDrop, isOver }, dropRef] = useDrop(
    () => ({
      accept: item.allowDrop ? [itemType] : [],
      canDrop: canDrop,
      collect: monitor => ({
        isOver: monitor.isOver(),
        isActiveTarget: monitor.canDrop(),
        didDrop: monitor.didDrop()
      }),
      drop: (items: IDraggableItem[]) => {
        onDrop(item, items)
      }
    }),
    [item, selectedItems, isDragging]
  )

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  return (
    <div
      style={{ height: size }}
      ref={el => {
        dragRef(el)
        dropRef(el)
      }}
      onClick={e => onClick(item, e)}
    >
      {render(item, {
        isSelected,
        isDragging,
        isOver: isOver && canDrop(),
        isActiveTarget: isActiveTarget && canDrop(),
        didDrop
      })}
    </div>
  )
}
