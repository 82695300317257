import { network } from '@utils'
import { handleReturn, MutationFunction, PaginatedResponse, QueryFunction } from './root'

export interface IPeriod {
  id: number
  start_date: string
  end_date: string
  is_active: boolean
  has_began: boolean
  has_ended: boolean
  is_reported: boolean
  is_taxed: boolean
  is_locked: boolean
  is_ensured: boolean
  is_depreciations_ensured: boolean
  is_depreciations_recorded: boolean
  is_deferrals_recorded: boolean
}

interface CreatePeriodProps {
  businessId: string
}

export const createPeriod: MutationFunction<CreatePeriodProps, IPeriod> = async (
  { businessId },
  requestData
) => {
  const url = `/v1/business/${businessId}/period/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'POST',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

interface PeriodListProps {
  businessId: string
}

export const fetchPeriods: QueryFunction<PeriodListProps, PaginatedResponse<IPeriod>> = async (
  { businessId },
  params = null
) => {
  const url = `/v1/business/${businessId}/period/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}

interface PeriodInstanceProps {
  businessId: string
  periodId: number
}

export const fetchPeriod: QueryFunction<PeriodInstanceProps, IPeriod> = async (
  { businessId, periodId },
  params = null
) => {
  const url = `/v1/business/${businessId}/period/${periodId}/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}

export const updatePeriod: MutationFunction<PeriodInstanceProps, IPeriod> = async (
  { businessId, periodId },
  requestData
) => {
  const url = `/v1/business/${businessId}/period/${periodId}/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'PATCH',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export const activatePeriod: MutationFunction<PeriodInstanceProps, void> = async ({
  businessId,
  periodId
}) => {
  const url = `/v1/business/${businessId}/period/${periodId}/action/activate/`
  await network.httpClient.request({
    url,
    method: 'POST'
  })
}

export const deletePeriod: MutationFunction<PeriodInstanceProps, void> = async ({
  businessId,
  periodId
}) => {
  const url = `/v1/business/${businessId}/period/${periodId}/`
  await network.httpClient.request({
    url,
    method: 'DELETE'
  })
}

export type PeriodActionType =
  | 'ensure'
  | 'ensure_depreciations'
  | 'record_depreciations'
  | 'skip_record_depreciations'
  | 'deferrals_recorded'
  | 'record_taxes'
  | 'lock'
  | 'unlock'
  | 'report'

interface PeriodActionProps extends PeriodInstanceProps {
  action: PeriodActionType
}

export const performPeriodAction: MutationFunction<PeriodActionProps, void> = async ({
  action,
  businessId,
  periodId
}) => {
  const url = `/v1/business/${businessId}/period/${periodId}/action/${action}/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'POST',
    validateStatus: () => true
  })

  return handleReturn({ status, data })
}

export interface IFinancialStatement {
  show_pma_1_01: boolean
  text_pma_1_01?: string

  show_pma_3_01: boolean
  text_pma_3_01?: string

  show_pma_3_02: boolean
  text_pma_3_02?: string

  show_pma_3_03: boolean
  text_pma_3_03?: string

  show_pma_3_04: boolean
  text_pma_3_04?: string

  show_pma_3_05: boolean
  text_pma_3_05?: string

  show_pma_3_06: boolean
  text_pma_3_06?: string

  show_pma_3_07: boolean
  text_pma_3_07?: string

  show_pma_3_08: boolean
  text_pma_3_08?: string

  show_pma_3_09: boolean
  text_pma_3_09?: string

  show_pma_3_10: boolean
  text_pma_3_10?: string

  show_pma_3_11: boolean
  text_pma_3_11?: string

  show_pma_3_12: boolean
  text_pma_3_12?: string

  show_pma_3_13: boolean
  text_pma_3_13?: string

  show_accounting_and_supporting_list: boolean
  text_accounting_and_supporting_list?: string

  show_changes_in_equity: boolean
  text_changes_in_equity?: string

  show_financial_statement_signature: boolean
  text_financial_statement_signature?: string

  financial_statement_docx?: string
}

interface FinancialStatementProps {
  businessId: string
  periodId: number
}

export const fetchFinancialStatement: QueryFunction<
  FinancialStatementProps,
  IFinancialStatement
> = async ({ businessId, periodId }, params = null) => {
  const url = `/v1/business/${businessId}/period/${periodId}/financial_statement/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })

  return data
}

export const updateFinancialStatement: MutationFunction<
  FinancialStatementProps,
  IFinancialStatement
> = async ({ businessId, periodId }, requestData) => {
  const url = `/v1/business/${businessId}/period/${periodId}/financial_statement/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'PATCH',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}
