import UniversalAPISelector, {
  ItemRenderProps,
  SuggestionRenderProps
} from '@root/components/UniversalAPISelector/UniversalAPISelector'
import { useBusinessContext } from '@root/context'
import {
  ContactType,
  IContact,
  SuggestContactProps,
  createContact,
  fetchContact,
  fetchContacts,
  suggestContact
} from '@root/query'
import { AnimatePresence, motion } from 'framer-motion'
import { Button, InputProps, ModalProps } from '@components'
import styled from 'styled-components'
import { FaCheck, FaMagic, FaPen } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { ContactForm } from '@root/pages/ContactsPage/ContactFormModal/ContactForm'
import { useMutation, useQueryClient } from 'react-query'
import toast from 'react-hot-toast'

const QUERY_KEY = 'ContactUAS'

interface Props extends InputProps {
  onChange: (accountId: number) => void
  clearSelectedItem: () => void
  suggestionQuery?: Pick<SuggestContactProps, 'query'>
  isSuggestionEnabled?: boolean
  fetchOnlyInvoicableContacts: boolean
  noDataMessage: string
  isQuickAddEnabled?: boolean
}

export const ContactSelector: React.FC<Props> = ({
  suggestionQuery,
  isSuggestionEnabled = false,
  fetchOnlyInvoicableContacts,
  noDataMessage,
  isQuickAddEnabled = false,
  ...rest
}) => {
  const { businessId } = useBusinessContext()
  const [t] = useTranslation()
  const formId = 'contact-form'
  const queryClient = useQueryClient()

  const dataToShow = (item: IContact): string => {
    return `${item.name}`
  }

  const renderRow = (
    item,
    {
      isFocused,
      isSelected,
      onKeyDown,
      handleOnItemClick,
      handleOnEditClick,
      style,
      showEditItem
    }: ItemRenderProps<IContact>
  ) => {
    return (
      <ItemWrapper
        className={isFocused && 'keyboard-focused'}
        onKeyDown={onKeyDown}
        onClick={() => handleOnItemClick(item)}
        style={style}
        key={item.id}
      >
        <Item key={`contact-${item.id}`} title={item.name}>
          <ItemColumn>{item.name}</ItemColumn>
          <RightItemsWrapper>
            {showEditItem && (
              <EditButtonWrapper>
                <Button
                  isSecondary={true}
                  icon={<FaPen />}
                  onClick={() => handleOnEditClick(item)}
                ></Button>
              </EditButtonWrapper>
            )}
            {isSelected && (
              <ItemColumn className="check">
                <CheckMarkWrapper>
                  <FaCheck />
                </CheckMarkWrapper>
              </ItemColumn>
            )}
          </RightItemsWrapper>
        </Item>
      </ItemWrapper>
    )
  }

  const createModalProps: Partial<ModalProps> = {
    width: 600,
    height: 800
  }

  const renderCreateForm = (onCreate: (data: IContact) => void, item: IContact) => {
    return <ContactForm contact={item} onCreate={onCreate} />
  }

  const renderSuggestion = (
    suggestion,
    isSuggestionEnabled,
    { handleOnItemClick }: SuggestionRenderProps<IContact>
  ) => {
    return (
      <AnimatePresence>
        {isSuggestionEnabled && suggestion && (
          <StyledContactSuggestionOuter>
            <StyledContactSuggestionInner
              onClick={() => {
                handleOnItemClick(suggestion)
              }}
            >
              <FaMagic />
              <span>{t('document.contactSuggestion', { contactName: suggestion.name })}</span>
            </StyledContactSuggestionInner>
          </StyledContactSuggestionOuter>
        )}
      </AnimatePresence>
    )
  }

  const createMutation = useMutation<IContact, unknown, string>(name =>
    createContact(
      { businessId },
      {
        type: ContactType.UNSET,
        name
      }
    )
  )

  const onQuickCreate = (contactName: string, handleItemClick: (item: IContact) => void) => {
    const mutateOptions = {
      onSuccess: async (newContact: IContact) => {
        toast.success(t('general.success'))
        handleItemClick(newContact)
        queryClient.invalidateQueries([`${QUERY_KEY}-items`, 'items'])
      },
      onLoad: async () => {
        toast.error(t('general.loading'))
      },
      onError: async () => {
        toast.error(t('general.error'))
      }
    }

    createMutation.mutate(contactName, mutateOptions)
  }

  const quickCreate = (
    searchTerm: string,
    isFocused: boolean,
    handleItemClick: (item: IContact) => void
  ): React.ReactNode => {
    return (
      <ItemWrapper
        onClick={() => onQuickCreate(searchTerm, handleItemClick)}
        className={isFocused && 'keyboard-focused'}
      >
        <Item key={'contact-quick-add'} title={searchTerm}>
          <ItemColumn>
            <QuickCreateHelperText>
              {t('contacts.contactSelector.quickCreate', { name: searchTerm })}
            </QuickCreateHelperText>
          </ItemColumn>
        </Item>
      </ItemWrapper>
    )
  }

  return (
    <UniversalAPISelector
      {...rest}
      fetchItem={id => {
        const data = fetchContact({ businessId, contactId: id })
        return data
      }}
      fetchItems={({ page, search }) => {
        const optionsDefault = { page, page_size: 200, search }
        const optionsDetailed = {
          ...optionsDefault,
          ...(fetchOnlyInvoicableContacts && { is_invoicing_enabled: true })
        }
        const data = fetchContacts({ businessId }, optionsDetailed)
        return data
      }}
      renderRow={renderRow}
      noDataMessage={noDataMessage}
      modalHeaderNew={t('contacts.contactSelector.modalHeaderNew')}
      modalHeaderUpdate={t('contacts.contactSelector.modalHeaderUpdate')}
      dropDownHeader={t('contacts.contactSelector.dropdownHeader')}
      queryKey={QUERY_KEY}
      formId={formId}
      getDataToShow={dataToShow}
      renderCreateForm={renderCreateForm}
      renderUpdateForm={renderCreateForm}
      createModalProps={createModalProps}
      updateModalProps={createModalProps}
      renderSuggestion={renderSuggestion}
      isSuggestionEnabled={isSuggestionEnabled}
      fetchSuggestion={() => {
        const suggestion = suggestContact({ businessId, ...suggestionQuery })
        return suggestion
      }}
      renderQuickCreate={isQuickAddEnabled && quickCreate}
      handleQuickCreate={isQuickAddEnabled && onQuickCreate}
    />
  )
}

const Item = styled(motion.div).attrs({
  variants: {
    hidden: {
      y: 5,
      opacity: 0
    },
    show: {
      y: 0,
      opacity: 1
    }
  }
})`
  display: flex;
  width: 100%;
  padding: 0.4rem 0.6rem;
  cursor: pointer;
  height: 35px;
`

const EditButtonWrapper = styled.div`
  display: none;
  align-self: center;
`

const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: ${({ theme }) => theme.spacing.xs}rem;
  &:hover ${EditButtonWrapper} {
    display: block;
  }
  &.keyboard-focused,
  &:hover {
    background: ${({ theme }) => theme.colors.mainBg};
  }
`

const RightItemsWrapper = styled.div`
  margin-left: auto;
  display: flex;
`

const ItemColumn = styled.div`
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.id {
    min-width: 60px;
    font-size: ${({ theme }) => theme.fontSize.sm}rem;
    margin-right: ${({ theme }) => theme.spacing.sm}rem;
    color: ${({ theme }) => theme.colors.metalGray};
    font-variant-numeric: tabular-nums;
    font-family: 'Roboto Mono', monospace;
  }
`
const CheckMarkWrapper = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  margin: ${({ theme }) => theme.spacing.sm}rem;
  svg {
    fill: ${({ theme }) => theme.colors.nocfoGreen};
  }
`

const StyledContactSuggestionInner = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  whileHover: 'hover',
  whileTap: 'tap'
})`
  display: flex;
  justify-self: flex-end;
  color: ${({ theme }) => theme.colors.nocfoPurple};
  gap: ${({ theme }) => theme.spacing.xs}rem;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const StyledContactSuggestionOuter = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  margin-top: ${({ theme }) => theme.spacing.sm}rem;
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: ${({ theme }) => theme.colors.nocfoPurple};
  gap: ${({ theme }) => theme.spacing.xs}rem;
  font-weight: 500;
`

const QuickCreateHelperText = styled.p`
  font-style: italic;
  margin: 0;
`
