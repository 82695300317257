import { Button } from '@components'
import { useBusinessContext } from '@context'
import { IInvoice, sendInvoice, SendInvoiceProps } from '@query'
import { setAPIErrors } from '@utils'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FaPaperPlane } from 'react-icons/fa'
import { useMutation, useQueryClient } from 'react-query'
import styled from 'styled-components'
import SendInvoiceFormBase from './SendInvoiceFormBase'

interface Props {
  invoice: IInvoice
  onSubmit: () => void
  onCancel: () => void
}

export const SendInvoiceForm: React.FC<Props> = ({ invoice, onSubmit: _onSubmit, onCancel }) => {
  const {
    businessId,
    data: { name }
  } = useBusinessContext()
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const [isDisabled, setIsDisabled] = useState<boolean>()

  const changeDisabled = (value: boolean) => {
    setIsDisabled(value)
  }

  const methods = useForm<SendInvoiceProps>({
    defaultValues: {
      data: {
        email_subject: invoice.is_credit_note_for
          ? t('invoicing.send.defaultEmailSubjectCreditNote', {
              businessName: name,
              invoiceNumber: invoice.invoice_number
            })
          : t('invoicing.send.defaultEmailSubject', {
              businessName: name,
              invoiceNumber: invoice.invoice_number
            }),
        email_content: ''
      }
    }
  })

  const sendMutation = useMutation<unknown, unknown, SendInvoiceProps>(
    data => sendInvoice({ businessId, invoiceId: invoice.id }, data),
    {
      onError: ({ status, data }) => {
        if (status === 400) {
          setAPIErrors(data, methods.setError)
        }
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([businessId, 'invoices'])
        _onSubmit()
      }
    }
  )

  const onSubmit = methods.handleSubmit(async data => {
    const promise = sendMutation.mutateAsync(data)
    toast.promise(promise, {
      loading: t('invoicing.send.sendLoading'),
      success: t('invoicing.send.sendSuccess'),
      error: t('invoicing.send.sendError')
    })
    await promise
  })

  return (
    <FormProvider {...methods}>
      <FormWrapper>
        <form id="send-invoice-form" onSubmit={onSubmit}>
          <SendInvoiceFormBase
            emailContentName="data.email_content"
            emailSubjectName="data.email_subject"
            invoiceReceiver={invoice.receiver}
            changeDisabled={changeDisabled}
          ></SendInvoiceFormBase>
        </form>

        <FormFooterWrapper>
          <FormFooterContent>
            <Button type="button" onClick={() => onCancel()}>
              {t('general.cancel')}
            </Button>
            <Button
              form="send-invoice-form"
              intent="success"
              icon={<FaPaperPlane />}
              disabled={isDisabled}
            >
              {t('invoicing.send.sendButton')}
            </Button>
          </FormFooterContent>
        </FormFooterWrapper>
      </FormWrapper>
    </FormProvider>
  )
}

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  form {
    flex: 1;
    overflow-y: auto;
    overflow-x: auto;
    padding-bottom: 1rem;
  }
`

const FormFooterWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing.lg}rem;
  border-top: 1px solid ${({ theme }) => theme.colors.neutralGray};
`

const FormFooterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.md}rem;
`
