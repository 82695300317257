import React from 'react'
import { useMemo } from 'react'
import {
  FieldMapper,
  ReadyStatus,
  ValueError
} from '@containers/CSVImportWizard/base/slides/components/FieldMapper.tsx'
import lodash, { isNil } from 'lodash'
import { Controller, get, Resolver, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Alert, Button } from '@components'
import styled from 'styled-components'
import { FaArrowRight } from 'react-icons/fa'
import { FieldToMap } from '@containers/CSVImportWizard/base/slides/components/types.ts'
import { HandleReturnProps } from '@query'
import toast from 'react-hot-toast'

interface Props {
  fields: FieldToMap<any>[]
  data: Record<string, string | number | boolean | undefined>[]
  onMappingsComplete: (mappings: Record<string, string>[]) => Promise<HandleReturnProps | void>
  onPrevSlide: () => void
}

export const FieldMappingSlide: React.FC<Props> = ({
  fields: fieldsToMap,
  data,
  onMappingsComplete,
  onPrevSlide
}) => {
  const { t } = useTranslation()

  // Find out what keys are available in the original data
  const fieldNames = useMemo(
    () => Object.keys(data?.[0]).map(name => ({ value: name, label: name })),
    [data]
  )

  const valuesValidator = async (values: Record<string, any>) => {
    // Validate CSV values using valueValidators
    const valueErrors = await Promise.all(
      Object.entries(values).map(async ([targetField, sourceField]) => {
        const valueErrors: ValueError[] = []
        for (const [index, value] of data.entries()) {
          const valueValidator = fieldsToMap.find(
            field => field.apiField === targetField
          )?.valueValidator
          if (!valueValidator) continue

          try {
            await valueValidator.validate(value[sourceField as string])
          } catch (result) {
            // First row is the header row and numbering starts from 1,
            // hence index + 2
            valueErrors.push({
              row: index + 2,
              message: result?.message as string
            })
          }
        }
        return {
          [targetField]: valueErrors
        }
      })
    )
    return valueErrors.reduce((acc, valueError) => ({ ...acc, ...valueError }), {})
  }

  const compileValueErrorsMessages = (valueErrors: Record<string, ValueError[]>) => {
    return Object.entries(valueErrors).reduce((acc, [key, errors]) => {
      if (errors.length === 0) return acc
      return {
        ...acc,
        [key]: {
          message: t('components.importWizard.mapper.rowError', {
            count: errors.length,
            row: errors[0].row,
            detail: errors[0].message
          })
        }
      }
    }, {})
  }

  // Customer resolver to validate the selected fields and CSV values
  // against valueValidators
  const resolver: Resolver = async (data, context, options) => {
    // Validate fields using fieldValidators
    const { values, errors: yupErrors } = await yupResolver(
      yup.object().shape(
        fieldsToMap.reduce(
          (acc, { apiField, fieldValidator }) => ({
            ...acc,
            [apiField]: fieldValidator
          }),
          {}
        )
      )
    )(data, context, options)

    const valueErrors = await valuesValidator(data)
    const valueErrorsCompiled = compileValueErrorsMessages(valueErrors)

    return { values, errors: { ...valueErrorsCompiled, ...yupErrors } }
  }

  const {
    watch,
    control,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver,
    defaultValues: async () => {
      const sourceKeys = Object.keys(data?.[0] || {})
      const targetKeys = fieldsToMap.map(field => field.csvField)
      const intersection = sourceKeys.filter(key => targetKeys.includes(key))

      // Automatically create mappings for the fields that
      // are common between the CSV and the target fields
      return intersection?.reduce(
        (acc, key) => ({
          ...acc,
          [fieldsToMap.find(({ csvField }) => csvField === key).apiField]: key
        }),
        {}
      )
    }
  })

  const convertDataKeys = async (data: Record<string, any>, mappings: Record<string, string>) => {
    const entries = await Promise.all(
      Object.entries(data).map(async ([key, value]) => {
        if (isNil(mappings[key])) return null

        const field = fieldsToMap.find(field => field.apiField === mappings[key])
        const valueValidator = field?.valueValidator

        const newValue = valueValidator ? await valueValidator.validate(value) : value
        return [mappings[key], newValue]
      })
    )

    const newData: Record<string, any> = {}
    entries.forEach(entry => {
      if (entry) {
        const [key, value] = entry
        newData[key] = value
      }
    })

    return newData
  }

  const targetToSourceMappings = watch()
  const getSourceToTargetMappings = (mapping: Record<string, string>) =>
    lodash(mapping).omitBy(lodash.isNil).omitBy(lodash.isEmpty).invert().value()

  const onSubmit = handleSubmit(async formValues => {
    const sourceToTargetMappings = getSourceToTargetMappings(formValues)
    const restructuredData = await Promise.all(
      data.map(data => convertDataKeys(data, sourceToTargetMappings))
    )
    await onMappingsComplete(restructuredData).catch(({ status, data }) => {
      if (status === 400) {
        const combinedErrors: [string, string[]][] = data?.data.reduce((acc, obj, index) => {
          Object.entries(obj).forEach(([key, value]) => {
            if (!acc[key]) {
              acc[key] = []
            }
            acc[key].push([index, value])
          })
          return acc
        }, {})

        Object.entries(combinedErrors).forEach(([key, value]) => {
          const [index, error] = value[0]
          setError(key, {
            type: 'custom',
            message: t('components.importWizard.mapper.rowError', {
              row: index + 1,
              count: value.length,
              detail: error
            })
          })
        })
      } else {
        toast.error(t('general.error'))
      }
    })
  })

  console.log(errors)

  const getFieldStatus = (field: FieldToMap<any>): ReadyStatus =>
    get(errors, field.apiField)
      ? 'ERROR'
      : targetToSourceMappings[field.apiField]
      ? 'SUCCESS'
      : 'UNSET'

  return (
    <Form onSubmit={onSubmit}>
      <Alert
        type="error"
        isVisible={Object.keys(errors).length > 0}
        description={t('components.importWizard.mapper.errorAlert')}
      />
      <FormContent>
        {fieldsToMap.map(field => (
          <Controller
            name={field.apiField}
            key={field.apiField}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FieldMapper
                field={field}
                value={value}
                onChange={onChange}
                options={fieldNames}
                disabledOptions={Object.values(targetToSourceMappings)}
                status={getFieldStatus(field)}
                errors={error}
              />
            )}
          />
        ))}
      </FormContent>

      <FormFooter>
        <Button type="button" onClick={() => onPrevSlide()}>
          {t('general.back')}
        </Button>
        <Button
          showSpinner={isSubmitting}
          disabled={isSubmitting}
          type="submit"
          intent="primary"
          iconRight={<FaArrowRight />}
        >
          {t('general.next')}
        </Button>
      </FormFooter>
    </Form>
  )
}

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg}rem;
`

const FormContent = styled.div`
  flex: 1;
  overflow: auto;
`

const FormFooter = styled.div`
  display: flex;
  gap: 1rem;
  background: ${({ theme }) => theme.colors.neutralGray}25;
  justify-content: space-between;
`
