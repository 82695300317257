import { ValidLocale } from '@localisation'
import { updateCurrentUser } from '@query'
import cn from 'classnames'
import { motion } from 'framer-motion'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import styled from 'styled-components'

const SUPPORTED_LANGUAGES = [
  {
    value: ValidLocale.FI,
    labelKey: 'language.finnish'
  },
  {
    value: ValidLocale.EN,
    labelKey: 'language.english'
  }
]

export const LanguagePicker: React.FC = () => {
  const queryClient = useQueryClient()
  const {
    t,
    i18n: { language: currentLanguage }
  } = useTranslation()

  const languageMutation = useMutation<unknown, unknown, ValidLocale>(
    language => updateCurrentUser(undefined, { language: language }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('user')
      }
    }
  )

  return (
    <StyledLanguagePicker>
      {SUPPORTED_LANGUAGES.map(({ value, labelKey }) => (
        <LanguageButton
          key={value}
          className={cn({ selected: value === currentLanguage })}
          onClick={() => languageMutation.mutateAsync(value)}
        >
          {t(labelKey)}
          {value === currentLanguage && <LanguageButtonSelectRing />}
        </LanguageButton>
      ))}
    </StyledLanguagePicker>
  )
}

const StyledLanguagePicker = styled.div`
  padding: ${({ theme }) => theme.spacing.sm}rem ${({ theme }) => theme.spacing.sm}rem;
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  background: ${({ theme }) => theme.colors.neutralGray};
`

const LanguageButton = styled.button`
  position: relative;
  padding: ${({ theme }) => theme.spacing.xs}rem ${({ theme }) => theme.spacing.sm}rem;
  min-width: 2.5rem;
  min-height: 1.8rem;
  outline: none !important;
  border: none;
  background: white;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  border-radius: 1rem;
  color: ${({ theme }) => theme.colors.metalGray};
  transition: 0.2s;

  &.selected {
    color: ${({ theme }) => theme.colors.nocfoBlue};
  }
`

const spring = {
  type: 'spring',
  stiffness: 400,
  damping: 30
}

const LanguageButtonSelectRing = styled(motion.div).attrs({
  layoutId: 'select-ring',
  initial: false,
  transition: spring
})`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 2px solid ${({ theme }) => theme.colors.nocfoBlue};
  border-radius: 1rem;
  z-index: 1000;
`
