import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from '../Button'
import { useTranslation } from 'react-i18next'
import { PricingTableModal } from '../PricingTable'

interface Props {
  text: string
  icon: React.ReactNode
  iconText: string
}

export const InlineTeaserView: React.FC<Props> = ({ text, icon, iconText }) => {
  const [t] = useTranslation()
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <StyledContainer>
        <StyledContentWrapper>
          <StyledHeaderContainer>
            <StyledIconWrapper>{icon}</StyledIconWrapper>

            <StyledIconText>{iconText}</StyledIconText>
          </StyledHeaderContainer>
          <StyledText>{text}</StyledText>
          <StyledStretchedButton
            intent="primary"
            size="sm"
            type="button"
            onClick={() => setShowModal(true)}
          >
            {t('inlineTeaser.startTrial')}
          </StyledStretchedButton>
        </StyledContentWrapper>
      </StyledContainer>
      <PricingTableModal isVisible={showModal} handleOnClose={() => setShowModal(false)} />
    </>
  )
}

const StyledContainer = styled.div`
  display: flex;
  border: 2px solid ${({ theme }) => theme.colors.nocfoBlue};
  border-radius: 1rem;
  padding: ${({ theme }) => theme.spacing.md}rem;
  align-items: center;
  justify-content: flex-start;
`

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${({ theme }) => theme.spacing.md}rem;
`

const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm}rem;
`

const StyledIconWrapper = styled.div`
  & > * {
    width: 1.2rem;
    height: 1.2rem;

    color: ${({ theme }) => theme.colors.nocfoBlue};
  }
`

const StyledIconText = styled.b`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
`

const StyledText = styled.p`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSize.md}rem;
`

const StyledStretchedButton = styled(Button)`
  justify-content: center;
`
