import { Button } from '@root/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaCog } from 'react-icons/fa'

interface Props {
  onClick: () => void
}

export const RecurringInvoiceSettingBadgeButton: React.FC<Props> = ({ onClick }) => {
  const [t] = useTranslation()
  const text = t('invoicing.details.recurrence.badge.setting')
  return (
    <Button intent="default" icon={<FaCog></FaCog>} onClick={() => onClick()}>
      {text}
    </Button>
  )
}
