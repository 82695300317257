import { Button, Input } from '@components'
import { useBusinessContext } from '@context'
import { fetchBusiness, IBusiness, updateBusiness } from '@query'
import { setAPIErrors } from '@utils'
import { Heading, Pane, toaster } from 'evergreen-ui'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'

const InvoiceSettingsGeneral: React.FC = () => {
  const queryClient = useQueryClient()
  const { businessId } = useBusinessContext()
  const [t] = useTranslation()

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { isDirty, errors }
  } = useForm()

  const resetFormValues = (data: Partial<IBusiness>) =>
    reset({
      invoicing_email: data?.invoicing_email,
      invoicing_iban: data?.invoicing_iban,
      invoicing_bic: data?.invoicing_bic,
      invoicing_contact: data?.invoicing_contact,
      invoicing_street: data?.invoicing_street,
      invoicing_city: data?.invoicing_city,
      invoicing_postal_code: data?.invoicing_postal_code,
      invoicing_country: data?.invoicing_country
    })

  useQuery([businessId], () => fetchBusiness({ businessId }), {
    onSuccess: data => {
      if (!isDirty) resetFormValues(data)
    }
  })

  const updateMutation = useMutation<IBusiness, unknown, Partial<IBusiness>>(
    data => updateBusiness({ businessId }, data),
    {
      onSuccess: async data => {
        resetFormValues(data)
        await queryClient.invalidateQueries(['businesses'])
        await queryClient.invalidateQueries([businessId])
        await queryClient.invalidateQueries([businessId, 'features'])
        toaster.success(t('general.changesSaved'))
      },
      onError: ({ status, data }) => {
        status === 400 && setAPIErrors(data, setError)
      }
    }
  )

  return (
    <Pane margin={'1rem'}>
      <form onSubmit={handleSubmit(data => updateMutation.mutate(data))}>
        <Heading marginTop={16} marginBottom={8}>
          {t('settings.invoicing.paymentTitle')}
        </Heading>
        <Input
          label={t('settings.invoicing.iban')}
          errors={errors.invoicing_iban}
          required={true}
          {...register('invoicing_iban', {
            required: { value: true, message: t('validation.required') }
          })}
        />
        <Input
          label={t('settings.invoicing.bic')}
          errors={errors.invoicing_bic}
          required={true}
          {...register('invoicing_bic', {
            required: { value: true, message: t('validation.required') }
          })}
        />

        <Heading marginTop={16} marginBottom={8}>
          {t('settings.invoicing.emailTitle')}
        </Heading>
        <Input
          label={t('general.address.email')}
          errors={errors.invoicing_email}
          required={true}
          info={t('settings.invoicing.emailInfo')}
          {...register('invoicing_email', {
            required: { value: true, message: t('validation.required') }
          })}
        />

        <Heading marginTop={16} marginBottom={8}>
          {t('settings.invoicing.invoicingAddressTitle')}
        </Heading>
        <Input label={t('settings.invoicing.contactPerson')} {...register('invoicing_contact')} />
        <Input
          label={t('general.address.street')}
          required={true}
          {...register('invoicing_street', {
            required: { value: true, message: t('validation.required') }
          })}
        />
        <InputRow>
          <Input
            label={t('general.address.postalCode')}
            required={true}
            {...register('invoicing_postal_code', {
              required: { value: true, message: t('validation.required') }
            })}
          />
          <Input
            label={t('general.address.city')}
            required={true}
            {...register('invoicing_city', {
              required: { value: true, message: t('validation.required') }
            })}
          />
        </InputRow>
        <Input
          label={t('general.address.country')}
          required={true}
          {...register('invoicing_country', {
            required: { value: true, message: t('validation.required') }
          })}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '32px' }}>
          <Button intent="success" disabled={!isDirty} showSpinner={updateMutation.isLoading}>
            {t('general.save')}
          </Button>
        </div>
      </form>
    </Pane>
  )
}

const InputRow = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md}rem;
`
export default InvoiceSettingsGeneral
