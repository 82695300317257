import { InvoiceStatus } from '@constants'
import { useTheme } from '@hooks'
import { TextTooltip } from '@root/components'
import cn from 'classnames'
import { motion } from 'framer-motion'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  FaCheckCircle,
  FaClock,
  FaFeatherAlt,
  FaMinusCircle,
  FaPaperPlane,
  FaQuestionCircle,
  FaStamp
} from 'react-icons/fa'
import styled from 'styled-components'
import { FaHeadset } from 'react-icons/fa6'

interface Props {
  status: InvoiceStatus
  iconOnly?: boolean
  hideIcon?: boolean
  size?: 'md' | 'sm'
}

export const InvoiceStatusBadge: React.FC<Props> = ({
  status,
  hideIcon = false,
  iconOnly = false,
  size = 'md'
}) => {
  const theme = useTheme()
  const [t] = useTranslation()

  interface InvoiceStatusOption {
    text: string
    Icon: React.ElementType
    color: string
  }

  const statusOptions: Record<InvoiceStatus, InvoiceStatusOption> = {
    [InvoiceStatus.DRAFT]: {
      text: t('invoicing.status.draft'),
      Icon: FaFeatherAlt,
      color: theme.colors.nocfoYellow
    },
    [InvoiceStatus.ACCEPTED]: {
      text: t('invoicing.status.accepted'),
      Icon: FaStamp,
      color: theme.colors.nocfoBlue
    },
    [InvoiceStatus.CREDIT_LOSS]: {
      text: t('invoicing.status.creditLoss'),
      Icon: FaMinusCircle,
      color: theme.colors.orange
    },
    [InvoiceStatus.PAID]: {
      text: t('invoicing.status.paid'),
      Icon: FaCheckCircle,
      color: theme.colors.nocfoGreen
    }
  }

  const statusOption = statusOptions[status]
  if (statusOption)
    return iconOnly ? (
      <StyledIconWrapper color={statusOption.color}>
        <statusOption.Icon color={statusOption.color} />
      </StyledIconWrapper>
    ) : (
      <StyledStatus className={cn([size])} color={statusOption.color}>
        {!hideIcon && <statusOption.Icon />}
        <span>{statusOption.text}</span>
      </StyledStatus>
    )
}

export const InvoiceSentBadge: React.FC = () => {
  const theme = useTheme()
  const [t] = useTranslation()

  return (
    <StyledStatus className="md" color={theme.colors.nocfoBlue}>
      <FaPaperPlane />
      <span>{t('invoicing.status.sent')}</span>
    </StyledStatus>
  )
}

export const PurchaseInvoiceStatusBadge: React.FC<{
  isPaid: boolean
  isPastDue: boolean
  size: string
  hideIcon?: boolean
  iconOnly?: boolean
}> = ({ size, isPaid, isPastDue, iconOnly = false, hideIcon = false }) => {
  const theme = useTheme()
  const [t] = useTranslation()
  const color = isPaid
    ? theme.colors.nocfoGreen
    : isPastDue
    ? theme.colors.nocfoRed
    : theme.colors.nocfoYellow

  const Icon = isPaid ? FaCheckCircle : FaClock
  if (iconOnly) return <Icon color={color} />

  return (
    <StyledStatus className={cn([size])} color={color}>
      {!hideIcon && <Icon />}
      <span>{isPaid ? t('invoicing.status.paid') : t('invoicing.status.unpaid')}</span>
    </StyledStatus>
  )
}

export const InvoiceRecurrenceDisabledBadge: React.FC = () => {
  const theme = useTheme()
  const [t] = useTranslation()
  const color = theme.colors.iconGray
  const text = t('invoicing.details.recurrence.badge.text')
  const tip = t('invoicing.details.recurrence.badge.tip')
  const size = 'md'
  return (
    <StyledStatus
      style={{ marginLeft: `${theme.spacing.xxs}rem` }}
      className={cn([size])}
      color={color}
    >
      <span>{text}</span>

      <TextTooltip tooltip={tip}>
        <FaQuestionCircle size={14}></FaQuestionCircle>
      </TextTooltip>
    </StyledStatus>
  )
}

export const DebtCollectionIcon: React.FC = () => {
  const theme = useTheme()

  return (
    <TextTooltip tooltip={'Lasku on perinnässä'}>
      <StyledIconWrapper>
        <FaHeadset color={theme.colors.orange} />
      </StyledIconWrapper>
    </TextTooltip>
  )
}

const StyledStatus = styled(motion.div)<{
  color: string
}>`
  color: ${({ color }) => color};
  background: ${({ color }) => color}22;
  border-radius: 0.4rem;
  font-weight: 500;
  display: flex;
  align-items: center;

  &.md {
    padding: ${({ theme }) => theme.spacing.xxs}rem ${({ theme }) => theme.spacing.xs}rem;
    font-size: ${({ theme }) => theme.fontSize.sm}rem;
    gap: ${({ theme }) => theme.spacing.xs}rem;
  }

  &.sm {
    padding: 0.2rem 0.3rem;
    font-size: ${({ theme }) => theme.fontSize.xs}rem;
    gap: ${({ theme }) => theme.spacing.xxs}rem;
  }
`

const StyledIconWrapper = styled.div<{ color?: string; pulse?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.2rem;
  height: 1.2rem;
  background: ${({ color }) => (color ? `${color}22` : 'none')};
  border-radius: 6px;

  svg {
    width: 0.8rem;
    height: 0.8rem;
  }

  ${({ pulse }) =>
    pulse
      ? `
    animation: pulse 3s linear infinite;
  `
      : null};

  @keyframes pulse {
    0% {
      opacity: 0.8;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 0.8;
    }
  }
`
