import { Alert } from '@components'
import { formatRelativeTime } from '@utils'
import { tryFormatIBAN } from '@utils/iban'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaCheck, FaSyncAlt } from 'react-icons/fa'
import styled from 'styled-components'

interface Props {
  accountId: number
  accountName?: string
  accountIdentifier: string
  lastSyncAt: string
  isMissingInfo: boolean
}

export const BankAccount: React.FC<Props> = ({
  accountId,
  accountName,
  accountIdentifier,
  lastSyncAt,
  isMissingInfo
}) => {
  const [t] = useTranslation()

  return (
    <>
      <AccountComponent key={accountId}>
        <AccountNameRow>
          <AccountName>
            {accountName ?? t('settings.integrations.bank.defaultAccountName')}
            <IBAN>{tryFormatIBAN(accountIdentifier)}</IBAN>
          </AccountName>
        </AccountNameRow>

        {!isMissingInfo && <AccountAlert lastSyncAt={lastSyncAt} />}

        <Alert
          type="warning"
          isVisible={isMissingInfo}
          description={t('settings.integrations.bank.accountEditButtonMissingInfo')}
        />
      </AccountComponent>
    </>
  )
}
interface AccountAlertProps {
  lastSyncAt: string
}

const AccountAlert: React.FC<AccountAlertProps> = ({ lastSyncAt }) => {
  const [t] = useTranslation()

  let alertText = t('settings.integrations.bank.accountActive')
  let alertType = 'success'
  let AlertIcon = FaCheck

  if (lastSyncAt) {
    alertText = t('settings.integrations.bank.accountSynced', {
      syncedAt: formatRelativeTime(lastSyncAt)
    })
    alertType = 'success'
    AlertIcon = FaSyncAlt
  }

  return alertText ? (
    <StyledAccountAlert className={alertType}>
      <AlertIcon /> {alertText}
    </StyledAccountAlert>
  ) : (
    <></>
  )
}

const AccountComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  padding: ${({ theme }) => theme.spacing.md}rem;
  background: white;
  border-radius: 1rem;
`

const IBAN = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  font-weight: 500;
  font-variant-numeric: tabular-nums;
  color: ${({ theme }) => theme.colors.metalGray};
`

const AccountNameRow = styled.div`
  display: flex;
`

const AccountName = styled.div`
  flex: 1;
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  color: ${({ theme }) => theme.colors.neutralBlack};
  font-weight: bold;
`

const StyledAccountAlert = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.3rem;

  &.success {
    color: ${({ theme }) => theme.colors.nocfoGreen};
  }

  &.warning {
    color: ${({ theme }) => theme.colors.nocfoYellow};
  }
`
