import { network } from '@utils'
import { MutationFunction, PaginatedResponse, QueryFunction } from './root'

export interface ISubFolder {
  id: number
  name: string
  parent: number
  for_mobile_uploads: boolean
}

export interface IFolder extends ISubFolder {
  children: ISubFolder[]
  breadcrumbs: ISubFolder[]
  has_content: boolean
  email: string
}

interface FolderListProps {
  businessId: string
}

export const fetchFolders: QueryFunction<FolderListProps, PaginatedResponse<IFolder>> = async (
  { businessId },
  params = null
) => {
  const url = `/v1/business/${businessId}/folders/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}

export const createFolder: MutationFunction<FolderListProps, IFolder> = async (
  { businessId },
  requestData
) => {
  const url = `/v1/business/${businessId}/folders/`
  const { data } = await network.httpClient.request({
    url,
    method: 'POST',
    data: requestData
  })
  return data
}

interface FolderInstanceProps extends FolderListProps {
  folderId: number
}

export const fetchFolder: QueryFunction<FolderInstanceProps, IFolder> = async (
  { businessId, folderId },
  params = null
) => {
  const url = `/v1/business/${businessId}/folders/${folderId}/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}

export const updateFolder: MutationFunction<FolderInstanceProps, IFolder> = async (
  { businessId, folderId },
  requestData
) => {
  const url = `/v1/business/${businessId}/folders/${folderId}/`
  const { data } = await network.httpClient.request({
    url,
    method: 'PATCH',
    data: requestData
  })
  return data
}

export const deleteFolder: MutationFunction<FolderInstanceProps, unknown> = async ({
  businessId,
  folderId
}) => {
  const url = `/v1/business/${businessId}/folders/${folderId}/`
  await network.httpClient.request({
    url,
    method: 'DELETE'
  })
}

interface IMoveItem {
  type: string
  id: number
}

export interface IFolderMoveAction {
  target_folder_id: number
  items: IMoveItem[]
}

export const moveItemsToFolder: MutationFunction<FolderListProps, IFolderMoveAction> = async (
  { businessId },
  requestData
) => {
  const url = `/v1/business/${businessId}/folders/actions/move/`
  const { data } = await network.httpClient.request({
    url,
    method: 'POST',
    data: requestData
  })
  return data
}
