import { Button } from '@components'
import { UserRole } from '@constants'
import { usePermissionBoundary } from '@context'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaPlus } from 'react-icons/fa'
import { ProductFormModal } from './ProductFormModal'
import { ActionBar } from '@root/components/ActionBar/ActionBar'

interface Props {
  onSearch: (value: string) => void
}

export const ProductActionBar: React.FC<Props> = ({ onSearch }) => {
  const [t] = useTranslation()
  const [showCreate, setShowCreate] = useState(false)
  const isEditor = usePermissionBoundary(UserRole.EDITOR)

  const primaryButtons = [
    <Button key="create-button" icon={<FaPlus />} onClick={() => setShowCreate(true)}>
      {t('products.actionBar.addNew')}
    </Button>
  ]

  return (
    <>
      <ActionBar
        searchPlaceholder={t('products.actionBar.search')}
        onSearch={search => onSearch(search)}
        primaryButtons={isEditor ? primaryButtons : []}
      />
      <ProductFormModal isVisible={showCreate} handleOnClose={() => setShowCreate(false)} />
    </>
  )
}
