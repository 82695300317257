import styled from 'styled-components'

export const StyledDebtCollectionBadge = styled.span`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xxs}rem;
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  padding: 2px 4px;
  border-radius: 100px;
  font-weight: bold;
  white-space: nowrap;

  // Default styles
  color: ${({ theme }) => theme.colors.metalGray};
  border: 1px solid ${({ theme }) => theme.colors.metalGray}88;

  &.in-progress {
    color: ${({ theme }) => theme.colors.nocfoRed};
    border: 1px solid ${({ theme }) => theme.colors.nocfoRed};
  }

  &.completed {
    color: ${({ theme }) => theme.colors.nocfoGreen};
    border: 1px solid ${({ theme }) => theme.colors.nocfoGreen};
  }
`
