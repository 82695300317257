import { IImportJob, ImportJobType } from '@query/importJob.ts'
import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { FaFileCsv } from 'react-icons/fa6'
import { FaTimes } from 'react-icons/fa'
import { useRollingNumber } from '@utils/useRollingNumber.ts'
import { Spinner } from 'evergreen-ui'
import { useTranslation } from 'react-i18next'
import { Button } from '@components'
import { useQueryClient } from 'react-query'

const ANIMATION_DURATION = 3

interface ImportJobTrackerProps {
  data: IImportJob
  businessId: string
  onDismiss: (jobId: string) => void
}

export const ImportJobTracker: React.FC<ImportJobTrackerProps> = ({
  data,
  businessId,
  onDismiss
}) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const title = useMemo(() => {
    if (!data) return null
    switch (ImportJobType[data?.type]) {
      case ImportJobType.CONTACTS:
        return t('components.importWizard.tracker.title.contacts')
      case ImportJobType.INVOICES:
        return t('components.importWizard.tracker.title.invoices')
      default:
        throw 'Not implemented'
    }
  }, [data?.type])

  // Invalidate queries always when success count changes
  useEffect(() => {
    if (!data?.type || !data?.import_success_count) return

    switch (ImportJobType[data.type]) {
      case ImportJobType.CONTACTS:
        queryClient.invalidateQueries({
          queryKey: [businessId, 'contacts'],
          exact: false
        })
        return
      case ImportJobType.INVOICES:
        queryClient.invalidateQueries({
          queryKey: [businessId, 'combined_invoices'],
          exact: false
        })
        return
      default:
        throw 'Not implemented'
    }
  }, [data?.import_success_count])

  const isCompleted = data?.finished_at !== null
  const initializing = data?.import_success_count === 0 && data?.import_failed_count === 0
  const currentCount = useRollingNumber(
    (data?.import_success_count || 0) + (data?.import_failed_count || 0),
    ANIMATION_DURATION
  )

  const [successProgress, failedProgress] = useMemo(() => {
    if (!data) return [0, 0]
    return [
      Math.min((data.import_success_count / data.import_total_count) * 100, 100),
      Math.min((data.import_failed_count / data.import_total_count) * 100, 100)
    ]
  }, [data?.import_total_count, data?.import_success_count, data?.import_failed_count])

  const formatDuration = (seconds: number) => {
    if (seconds === null) return ''
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const secs = Math.floor(seconds % 60)

    if (hours > 0) return `${hours}h ${minutes}m ${secs}s`
    if (minutes > 0) return `${minutes}m ${secs}s`
    return `${secs}s`
  }

  return (
    <StyledWrapper>
      <IconWrapper>
        <FaFileCsv />
      </IconWrapper>
      <ContentWrapper>
        <Title>
          <span>{title}</span>{' '}
          <Button isSecondary size="sm" icon={<FaTimes />} onClick={() => onDismiss(data?.id)} />
        </Title>
        <StyledProgressWrapper>
          <StyledProgressBase />
          <StackedProgressWrapper>
            <StyledProgressBar className="success" animate={{ width: `${successProgress}%` }} />
            <StyledProgressBar className="failed" animate={{ width: `${failedProgress}%` }} />
          </StackedProgressWrapper>
        </StyledProgressWrapper>
        <DetailsWrapper>
          {initializing ? (
            <InitWrapper>
              <Spinner size={12} /> <span>{t('components.importWizard.tracker.initializing')}</span>
            </InitWrapper>
          ) : (
            <>
              <div>
                <div>
                  {t('components.importWizard.tracker.importing') + ' '}
                  <motion.span>{currentCount}</motion.span> {`/ ${data?.import_total_count}`}
                </div>
                {data?.import_failed_count > 0 && (
                  <div className="failed">
                    {t('components.importWizard.tracker.failedInfo', {
                      count: data?.import_failed_count
                    })}
                  </div>
                )}
              </div>
              {isCompleted ? (
                <div>{t('general.done')}</div>
              ) : (
                <div style={{ opacity: 0.6 }}>
                  {t('components.importWizard.tracker.timeLeft', {
                    timeLeft: formatDuration(data?.seconds_left)
                  })}
                </div>
              )}
            </>
          )}
        </DetailsWrapper>
      </ContentWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 340px;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md}rem;
  border-radius: 1rem;
  background: ${({ theme }) => theme.colors.neutralWhite};
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  padding: ${({ theme }) => theme.spacing.md}rem;
`

const ICON_SIZE = 48

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  background: ${({ theme }) => theme.colors.nocfoGreen}22;
  border-radius: 100px;

  svg {
    font-size: ${({ theme }) => theme.fontSize.lg}rem;
    fill: ${({ theme }) => theme.colors.nocfoGreen};
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  flex: 1;
`

const StyledProgressWrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xxs}rem;
`

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.metalGray};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledProgressBase = styled.div`
  position: relative;
  width: 100%;
  height: 7px;
  background: ${({ theme }) => theme.colors.neutralGray};
  overflow: hidden;
  border-radius: 100px;
`

const StackedProgressWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 7px;
  display: flex;
  overflow: hidden;
  border-radius: 100px;
`

const StyledProgressBar = styled(motion.div).attrs({
  initial: false,
  transition: { duration: ANIMATION_DURATION, type: 'tween' }
})`
  height: 100%;

  &.success {
    background: ${({ theme }) => theme.colors.nocfoGreen};
  }

  &.failed {
    background: ${({ theme }) => theme.colors.nocfoRed};
  }
`

const DetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.metalGray};
  gap: ${({ theme }) => theme.spacing.sm}rem;

  .failed {
    color: ${({ theme }) => theme.colors.nocfoRed};
  }

  .success {
    color: ${({ theme }) => theme.colors.nocfoGreen};
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xs}rem;
  }
`

const InitWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs}rem;
`
