import { DoubleColumnLayout, DoubleColumnLayoutColumn, TeaserView } from '@components'
import { useFeature } from '@context'
import { useQueryParam, useQueryParams } from '@hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FileBrowser, FileDetailView, FolderDetailView } from './components'

export const FilesPage: React.FC = () => {
  const [t] = useTranslation()
  const [, setQueryParams] = useQueryParams()
  const [openItem] = useQueryParam('id', value => parseInt(value, 10))
  const [openType] = useQueryParam('idType')
  const isUnlimitedStorage = useFeature('feature_unlimited_storage', true)
  const showFreeTrackerWidget = !isUnlimitedStorage

  const onClose = () =>
    setQueryParams({
      id: undefined,
      idType: undefined
    })

  return (
    <DoubleColumnLayout
      header={t('files.mainHeader')}
      isRightVisible={Boolean(openItem)}
      onRightClose={() => onClose()}
    >
      <DoubleColumnLayoutColumn>
        <FileBrowser
          upperWidget={
            showFreeTrackerWidget ? (
              <TeaserView
                header={t('teaser.files.header')}
                features={[
                  t('teaser.feature.5'),
                  t('teaser.feature.6'),
                  t('teaser.feature.8'),
                  t('teaser.feature.3')
                ]}
              />
            ) : null
          }
        />
      </DoubleColumnLayoutColumn>

      <DoubleColumnLayoutColumn innerKey={openItem} isRight={true}>
        {openType === 'file' && (
          <FileDetailView key={openItem} fileId={openItem} onClose={() => onClose()} />
        )}

        {openType === 'folder' && (
          <FolderDetailView key={openItem} folderId={openItem} onClose={() => onClose()} />
        )}
      </DoubleColumnLayoutColumn>
    </DoubleColumnLayout>
  )
}
