export const removeDuplicates = <T>(items: T[], lookup: (T) => string): T[] => {
  const seenIds = {}
  return items.reduce((unique, item) => {
    const id = lookup(item)

    if (id in seenIds) {
      return unique
    } else {
      seenIds[id] = true
      return [...unique, item]
    }
  }, [])
}
