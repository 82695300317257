import { theme } from './theme'

export const breakpoint = {
  xxs: `(max-width: ${theme.breakpoint.xs})`,
  xs: `(min-width: ${theme.breakpoint.xs})`,
  sm: `(min-width: ${theme.breakpoint.sm})`,
  md: `(min-width: ${theme.breakpoint.md})`,
  lg: `(min-width: ${theme.breakpoint.lg})`,
  mobile: `(min-width: ${theme.breakpoint.xs}) and (max-width: ${theme.breakpoint.md})`
}

export const breakpointNumberValues = {
  xs: 320,
  sm: 375,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1600
}
