import { useCallback, useEffect, useState } from 'react'

const getLocalStorageValue = (key, initialValue) => {
  try {
    const item = window.localStorage.getItem(key)
    return JSON.parse(item) || initialValue
  } catch (error) {
    console.log(error)
    return initialValue
  }
}

const setLocalStorageValue = (key, value) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value))
  } catch (error) {
    console.log(error)
  }
}

export const useLocalStorage = <T,>(
  key: string,
  initialValue: T
): [T, (value: T | ((prevValue: T) => T)) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => getLocalStorageValue(key, initialValue))

  const setValue = useCallback(
    (value: T | ((prevValue: T) => T)) => {
      setStoredValue(prevValue => {
        const valueToStore =
          typeof value === 'function' ? (value as (prevValue: T) => T)(prevValue) : value
        setLocalStorageValue(key, valueToStore)
        return valueToStore
      })
    },
    [key]
  )

  useEffect(() => {
    setStoredValue(getLocalStorageValue(key, initialValue))
  }, [key, JSON.stringify(initialValue)])

  return [storedValue, setValue]
}
