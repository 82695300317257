import { getVATPageUrl } from '@constants'
import { useBusinessContext } from '@context'
import { IVatPeriod } from '@query'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { DateProgressItem } from '../../components/DateProgressItem'

interface Props {
  vatPeriod: IVatPeriod
  isSelected: boolean
  isFirstOfType: boolean
  isLastOfType: boolean
  height: number
}

export const VatPeriodListItem: React.FC<Props> = ({
  vatPeriod,
  isSelected,
  isFirstOfType,
  isLastOfType,
  height
}) => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()

  let status = 'default'
  if (vatPeriod.has_due_passed && !vatPeriod.is_reported) {
    status = 'error'
  }
  if (vatPeriod.has_ended && !vatPeriod.is_reported) {
    status = 'warning'
  }

  const itemUrl = useMemo(
    () =>
      getVATPageUrl(businessId, {
        id: vatPeriod.id
      }),
    [vatPeriod.id]
  )

  return (
    <StyledItemWrapper
      itemHeight={height}
      isFirstOfType={isFirstOfType}
      isLastOfType={isLastOfType}
      isSelected={isSelected}
    >
      <Link to={itemUrl} style={{ display: 'block', height, textDecoration: 'none' }}>
        <DateProgressItem
          dateFrom={new Date(vatPeriod.start_date)}
          dateTo={new Date(vatPeriod.end_date)}
          titleFrom={t('vatPage.periodStart')}
          titleTo={t('vatPage.periodEnd')}
          status={status}
        />
      </Link>
    </StyledItemWrapper>
  )
}

const StyledItemWrapper = styled.div<{
  isSelected: boolean
  itemHeight: number
  isFirstOfType: boolean
  isLastOfType: boolean
}>`
  cursor: pointer;

  background: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.defaultHover : theme.colors.neutralWhite};
  height: ${({ itemHeight }) => itemHeight}px;
  border-top-left-radius: ${({ isFirstOfType }) => (isFirstOfType ? 1 : 0)}rem;
  border-top-right-radius: ${({ isFirstOfType }) => (isFirstOfType ? 1 : 0)}rem;
  border-bottom-right-radius: ${({ isLastOfType }) => (isLastOfType ? 1 : 0)}rem;
  border-bottom-left-radius: ${({ isLastOfType }) => (isLastOfType ? 1 : 0)}rem;

  &:hover {
    background: ${({ theme }) => theme.colors.defaultHover};
  }
`
