import { motion } from 'framer-motion'
import styled from 'styled-components'

export const DanknessContainer = styled.div`
  background: ${({ theme }) => theme.colors.menuBg};
  min-height: 100%;
  width: 100%;
  height: 100%;
`

export const StyledColumnLayoutWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  max-height: 100%;
  width: 100%;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.mainBg};
  margin: auto;
  overflow: hidden;
  border-radius: 1rem;
`

export const StyledColumnLayoutColumnWrapper = styled.div`
  flex: 1;
  flex-basis: 50%;
  display: flex;
  position: relative;
`

export const StyledColumnLayoutColumn = styled.div<{ fullWidth: boolean }>`
  ${({ fullWidth }) => (fullWidth ? `flex-basis: 100%;` : `flex-basis: 50%;`)}

  padding: ${({ theme }) => theme.spacing.xs}rem;

  @media only screen and (min-width: 800px) {
    padding: ${({ theme }) => theme.spacing.lg}rem;
  }

  padding-top: 0 !important;
`

export const StyledColumnLayoutColumnContentWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

export const StyledColumnLayoutColumnContent = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: block;
  border-radius: 1rem;
  overflow: hidden;
`
