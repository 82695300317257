import loaderImg from '@assets/nocfo-loader.svg'
import { motion } from 'framer-motion'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const RequisitionLoader: React.FC = () => {
  const [t] = useTranslation()

  return (
    <LoaderWrapper>
      <LoaderIcon src={loaderImg} />
      <h4>{t('settings.integrations.bank.integrationLoader')}</h4>
    </LoaderWrapper>
  )
}

const LoaderWrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 }
})`
  padding: ${({ theme }) => theme.spacing.lg}rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.lg}rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.metalGray};
`

const LoaderIcon = styled(motion.img).attrs({
  initial: { scale: 0.9, opacity: 0 },
  animate: {
    scale: [1.0, 0.9, 1.0],
    opacity: 1,
    transition: { repeat: Infinity, duration: 2 }
  }
})`
  width: 5rem;
  height: 5rem;
  background: ${({ theme }) => theme.colors.nocfoBlue};
  border-radius: 100px;
`
