import { network } from '@utils'
import { handleReturn, MutationFunction, PaginatedResponse, QueryFunction } from './root'

export interface IInvitation {
  id: number
  email: string
  role: string
}

interface InvitationListProps {
  businessId: string
}

export const fetchInvitations: QueryFunction<
  InvitationListProps,
  PaginatedResponse<IInvitation>
> = async ({ businessId }, params = null) => {
  const url = `/v1/business/${businessId}/invitations/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}

interface InvitationInstanceProps {
  businessId: string
  invitationId: number
}

export const deleteInvitation: MutationFunction<InvitationInstanceProps, void> = async (
  { businessId, invitationId },
  params = null
) => {
  const url = `/v1/business/${businessId}/invitations/${invitationId}/`
  await network.httpClient.request({
    url,
    method: 'DELETE',
    params
  })
}

export const createInvitation: MutationFunction<InvitationListProps, IInvitation> = async (
  { businessId },
  requestData
) => {
  const url = `/v1/business/${businessId}/invitations/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'POST',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}
