import { Alert, Button } from '@components'
import { useBusinessContext } from '@context'
import { useDebouncedValue } from '@hooks'
import { fetchHeaderPath, showAccount, validateAccountNumber } from '@query'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { Control, useWatch } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Trans, useTranslation } from 'react-i18next'
import { FaEye } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'

interface Props {
  control: Control
  onClose: () => void
  currentAccountId?: number
}

export const AccountPathVisualizer: React.FC<Props> = ({ control, currentAccountId, onClose }) => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const { businessId } = useBusinessContext()
  const number = useWatch({ control, name: 'number' })
  const debouncedNumber = useDebouncedValue(number, 400)
  const isEnabled = !!debouncedNumber
  const history = useHistory()

  const { data } = useQuery(
    [businessId, 'header_path', debouncedNumber],
    () => fetchHeaderPath({ businessId, number: debouncedNumber }),
    {
      enabled: isEnabled,
      keepPreviousData: true
    }
  )

  const { data: validationData } = useQuery(
    [businessId, 'account_number_validation', debouncedNumber],
    () => validateAccountNumber({ businessId, number: debouncedNumber }),
    {
      enabled: isEnabled,
      keepPreviousData: true
    }
  )

  const showMutation = useMutation(
    () => showAccount({ businessId, accountId: validationData?.data.id }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([businessId, 'accounts'])
        await queryClient.invalidateQueries([businessId, 'account_number_validation'])
        history.push(`/${businessId}/accounts?id=${validationData?.data.id}`)
        onClose()
      }
    }
  )

  const numberAlreadyExists = validationData?.exists
  const lastHeader = data?.headers[data.headers.length - 1]
  const doesExist = validationData?.exists
  const accountData = validationData?.data

  const isAlreadyShown = accountData?.is_shown
  const isCurrentAccount = accountData?.id === currentAccountId

  const getAlertContent = () => {
    if (doesExist && !isCurrentAccount) {
      if (isAlreadyShown) {
        return (
          <StyledAccountPathVisualizer>
            <Trans
              i18nKey="accounts.accountNumberInUse"
              values={{ accountName: validationData?.data.name }}
            >
              <AnimatedTitle>
                <Link to={`/${businessId}/accounts?id=${validationData?.data.id}`} />
              </AnimatedTitle>
            </Trans>
          </StyledAccountPathVisualizer>
        )
      } else {
        return (
          <StyledAccountPathVisualizer>
            <Trans
              i18nKey="accounts.accountNumberInUseHidden1"
              values={{ accountName: validationData?.data.name }}
            >
              <AnimatedTitle>
                <Link to={`/${businessId}/accounts?id=${validationData?.data.id}`} />
              </AnimatedTitle>
            </Trans>
            <br />
            <br />
            <Trans i18nKey="accounts.accountNumberInUseHidden2" />
            <ButtonContainer>
              <Button
                icon={<FaEye />}
                type="button"
                intent="warning"
                onClick={() => {
                  toast.promise(showMutation.mutateAsync(), {
                    loading: t('accounts.unhideInProgress'),
                    success: t('accounts.unhideSuccess'),
                    error: t('general.error')
                  })
                }}
              >
                {t('accounts.unhideAccount')}
              </Button>
            </ButtonContainer>
          </StyledAccountPathVisualizer>
        )
      }
    }

    return (
      <StyledAccountPathVisualizer>
        {lastHeader ? (
          <span>{t('accounts.accountBelongsToCategory')} </span>
        ) : (
          <span>{t('accounts.selectAccountNumber')}</span>
        )}
        <AnimatePresence mode="wait">
          <AnimatedTitle key={lastHeader}>{lastHeader}</AnimatedTitle>
        </AnimatePresence>
      </StyledAccountPathVisualizer>
    )
  }

  return (
    <Alert
      type={numberAlreadyExists && !isCurrentAccount ? 'warning' : 'info'}
      isVisible={true}
      description={getAlertContent()}
    />
  )
}

const StyledAccountPathVisualizer = styled.div``

const AnimatedTitle = styled(motion.span).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  font-weight: bold;

  & > * {
    color: ${({ theme }) => theme.colors.neutralBlack} !important;
  }
`

const ButtonContainer = styled.div`
  margin-top: 0.4rem;
  width: 100%;
`
