import { AnimatedContentLoader } from '@components'
import { AnalyticsFrequency, AnalyticsRange } from '@constants'
import { useTheme } from '@hooks'
import { capitalize, ChartData, formatEurosWithOpts, useChartData } from '@utils'
import React, { useCallback, useEffect, useState } from 'react'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { Label, LabelDot, LabelTitle, TooltipContent, TooltipTitle } from './Tooltip.styled'
import dayjs from 'dayjs'

interface ChartProps {
  height?: number | string
  width?: number | string
  range?: AnalyticsRange
}

interface AxisConfiguration {
  unit: string
  multiplier: number
}

const FORMATTING_THRESHOLD = 10000

const getUnitAndMultiplier = (data: ChartData[]): AxisConfiguration => {
  const highest = Math.abs(Math.max(0, ...data.map(d => Math.max(d.expenses, d.sales))))

  if (highest >= FORMATTING_THRESHOLD) {
    return { multiplier: 1000, unit: 'K' }
  }
  return { multiplier: 1, unit: '' }
}

export const MainChart: React.FC<ChartProps> = ({
  height = 400,
  width = '99%' /*HOX: 99% tarkoituksella, jotta rechart osaa hanskata resizen ruutukoon muutosten mukana */
}) => {
  const theme = useTheme()

  const { data, freq, labelMap, isLoading } = useChartData()

  useEffect(() => {
    const configuration = getUnitAndMultiplier(data)
    setYAxisConfiguration(configuration)
  }, [data])

  const [yAxisConfiguration, setYAxisConfiguration] = useState<AxisConfiguration>(
    getUnitAndMultiplier(data)
  )

  const formatDateTick = useCallback(
    (tick: string) => {
      switch (freq) {
        case AnalyticsFrequency.M:
          return dayjs(tick).format('M/YY')
        default:
          return dayjs(tick).format('DD.M.')
      }
    },
    [freq]
  )

  return (
    <AnimatedContentLoader isLoading={isLoading || data?.length === 0}>
      <ResponsiveContainer width={width} height={height}>
        <ComposedChart
          stackOffset="sign"
          data={data}
          margin={{ top: 26, bottom: 10 }}
          barCategoryGap={'15%'}
        >
          <Legend
            wrapperStyle={{ left: 0, top: 0 }}
            verticalAlign="top"
            align="center"
            formatter={legend => capitalize(labelMap[legend])}
            height={36}
          />
          <CartesianGrid vertical={false} stroke={theme.colors.neutralGray} />
          <YAxis
            tickLine={false}
            axisLine={false}
            tick={{ stroke: theme.colors.neutralGray, strokeWidth: 0.2 }}
            tickFormatter={tick =>
              Math.floor(tick / yAxisConfiguration.multiplier).toLocaleString()
            }
            unit={yAxisConfiguration.unit}
          />
          <XAxis
            tickLine={false}
            axisLine={false}
            dataKey="label"
            tick={{ stroke: theme.colors.neutralGray, strokeWidth: 0.2 }}
            tickFormatter={tick => formatDateTick(tick)}
          />
          <Tooltip content={<CustomTooltip labelMap={labelMap} />} />
          <Bar
            legendType="circle"
            dataKey="expenses"
            fill={theme.colors.orange}
            radius={[7, 7, 7, 7]}
            maxBarSize={70}
            spacing={5}
            unit="€"
            stackId="asd"
          />
          <Bar
            legendType="circle"
            dataKey="sales"
            fill={theme.colors.blue}
            radius={[7, 7, 7, 7]}
            maxBarSize={70}
            spacing={5}
            unit="€"
            stackId="asd"
          />
          <ReferenceLine y={0} strokeWidth={1} stroke={theme.colors.neutralGray} />
          <Line
            dot={null}
            type="monotone"
            strokeDasharray="6 8"
            strokeLinecap="round"
            dataKey="profit"
            stroke={theme.colors.purple}
            strokeWidth="3"
            legendType="line"
            unit="€"
            connectNulls
          />
        </ComposedChart>
      </ResponsiveContainer>
    </AnimatedContentLoader>
  )
}

const CustomTooltip: React.FC<any> = ({ labelMap, label, payload = [] }) => {
  return (
    <TooltipContent>
      <TooltipTitle>{dayjs(label).format('LL')}</TooltipTitle>
      {payload.map(pl => (
        <Label key={pl.dataKey}>
          <LabelDot color={pl.color} />
          <LabelTitle>{capitalize(labelMap[pl.name])}</LabelTitle>
          {formatEurosWithOpts(pl.value, {
            inCents: false,
            avoidDecimals: false,
            withEuroSymbol: true
          })}
        </Label>
      ))}
    </TooltipContent>
  )
}
