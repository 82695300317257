import { getInvoicingPageUrl } from '@constants'
import { useBusinessContext } from '@context'
import { useTheme } from '@hooks'
import { ICombinedInvoice } from '@query'
import { formatDate } from '@utils'
import React from 'react'
import { FaEnvelope, FaShare } from 'react-icons/fa'
import {
  CombinedStatusIcon,
  getCombinedInvoiceStatus,
  getCombinedPurchaseInvoiceStatus
} from './combinedStatus'
import { GeneralListItem } from './GeneralListItem'
import { RepetitionStatusIcon } from './RepetitionStatus'
import { DebtCollectionIcon } from '@pages/InvoicingPage/InvoiceStatusBadge.tsx'

interface InvoiceListItemProps {
  combinedInvoice: ICombinedInvoice
  isSelected: boolean
  isFirst: boolean
  isLast: boolean
}

export const InvoiceListItem: React.FC<InvoiceListItemProps> = ({
  combinedInvoice,
  isFirst,
  isLast
}) => {
  const theme = useTheme()
  const { businessId } = useBusinessContext()

  if (combinedInvoice.type === 'sales') {
    const invoice = combinedInvoice.item

    return (
      <GeneralListItem
        to={getInvoicingPageUrl(businessId, { id: invoice.id, type: combinedInvoice.type })}
        icon={<FaShare />}
        iconColorHex={theme.colors.blue}
        isFirst={isFirst}
        isLast={isLast}
        date={formatDate(invoice.invoicing_date)}
        statusDot={[
          ...(invoice?.is_recurrence_active
            ? [<RepetitionStatusIcon key={`status-icon-repetition-${invoice.id}`} />]
            : []),
          ...(invoice?.is_registered_to_kravia
            ? [<DebtCollectionIcon key={`status-icon-debt-collection-${invoice.id}`} />]
            : []),
          <CombinedStatusIcon
            key={`status-icon-${invoice.id}`}
            {...getCombinedInvoiceStatus(invoice)}
          />
        ]}
        contact={invoice.receiver_info?.name}
        amount={invoice.total_amount + invoice.total_vat_amount}
      />
    )
  }

  if (combinedInvoice.type === 'purchase') {
    const invoice = combinedInvoice.item

    return (
      <GeneralListItem
        to={getInvoicingPageUrl(businessId, { id: invoice.id, type: combinedInvoice.type })}
        icon={<FaEnvelope />}
        iconColorHex={theme.colors.yellow}
        isFirst={isFirst}
        isLast={isLast}
        statusDot={
          <CombinedStatusIcon
            key={`status-icon-${invoice.id}`}
            {...getCombinedPurchaseInvoiceStatus(invoice)}
          />
        }
        date={formatDate(invoice.invoicing_date)}
        contact={invoice.sender_name}
        amount={-invoice.amount}
      />
    )
  }

  return null
}
