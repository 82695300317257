import { AnimatedContentLoader, Badge, BusinessLogo, Button, SearchInput } from '@components'
import { Header } from '@containers/Header'
import { fetchBusinesses, IBusiness } from '@query'
import { useScreen } from '@utils'
import { AnimatePresence } from 'framer-motion'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaFlask, FaPlus } from 'react-icons/fa'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { Notifications } from '../../containers/Notifications'
import {
  AnimatedCard,
  BusinessCard as StyledBusinessCard,
  BusinessCardID,
  BusinessCardTitle,
  BusinessesHeader,
  BusinessesHeaderWrapper,
  BusinessesWrapper,
  BusinessListContainer,
  StyledBusinessesPage
} from './BusinessesPage.styled'
import { WelcomeView } from './components/WelcomeView'

export const BusinessesPage: React.FC = () => {
  const [t] = useTranslation()
  const [search, setSearch] = useState('')
  const { isMobile } = useScreen()

  const { data, isLoading } = useQuery(['businesses'], () =>
    fetchBusinesses(undefined, { page_size: 1000 })
  )

  const allBusinesses = data?.results
  const businesses = search
    ? allBusinesses.filter(business => {
        return (
          business.business_id.toLowerCase().includes(search.toLowerCase()) ||
          business.name.toLowerCase().includes(search.toLowerCase())
        )
      })
    : allBusinesses

  const isEmpty = businesses?.length === 0
  const showWelcome = isEmpty && !search

  return (
    <>
      <StyledBusinessesPage>
        <Header label={t('businessesPage.yourBusinesses')} />
        <BusinessesWrapper>
          <BusinessesHeaderWrapper>
            <BusinessesHeader>
              <SearchInput
                autoFocus={!isMobile}
                placeholder={t('businessesPage.searchPlaceholder')}
                onSearch={value => setSearch(value)}
                delay={100}
              />
            </BusinessesHeader>

            {!isMobile && (
              <Link to="/demo">
                <Button icon={<FaFlask />}>{t('businessesPage.newDemoBusiness')}</Button>
              </Link>
            )}
            <Link to="/onboarding">
              <Button type="button" id="add-business-button" intent="primary" icon={<FaPlus />}>
                {t('businessesPage.newBusiness')}
              </Button>
            </Link>
          </BusinessesHeaderWrapper>

          <AnimatedContentLoader
            isLoading={isLoading}
            isEmpty={isEmpty}
            isEmptyContent={showWelcome ? <WelcomeView /> : null}
            isEmptyDescription={showWelcome ? null : t('businessesPage.emptySearch')}
          >
            <BusinessListContainer key="container">
              <AnimatePresence>
                {businesses?.map(business => (
                  <AnimatedCard key={`business-card-${business.id}`}>
                    <BusinessCard business={business} />
                  </AnimatedCard>
                ))}
              </AnimatePresence>
            </BusinessListContainer>
          </AnimatedContentLoader>

          <Notifications />
        </BusinessesWrapper>
      </StyledBusinessesPage>
    </>
  )
}

const BusinessCard: React.FC<{ business: IBusiness }> = ({ business }) => (
  <StyledBusinessCard to={{ pathname: `/${business.business_id}` }} key={`business-${business.id}`}>
    <BusinessLogo src={business.logo} name={business.name} />
    <br />
    <BusinessCardTitle>{business.name}</BusinessCardTitle>
    {business.form == 'DEMO' ? (
      <span style={{ marginTop: 8 }}>
        <Badge>DEMO</Badge>
      </span>
    ) : (
      <BusinessCardID>{business.business_id}</BusinessCardID>
    )}
  </StyledBusinessCard>
)
