import { Button, Modal, ModalContainer, ModalDialog, ModalOpenButton } from '@components'
import { IFile } from '@query'
import { useBusinessContext } from '@root/context'
import { downloadFileToBeSaved } from '@root/utils/downloadFileToBeSaved'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaCloudDownloadAlt } from 'react-icons/fa'
import styled from 'styled-components'

interface Props {
  title: string
  attachment: IFile
  children: React.ReactNode
}

export const InvoicePreviewModal: React.FC<Props> = ({ children, title, attachment }) => {
  const { t } = useTranslation()
  const { businessId } = useBusinessContext()

  return (
    <Modal>
      {({ hideModal }) => (
        <>
          <ModalOpenButton>{children}</ModalOpenButton>
          <ModalDialog width={1000} height={1000}>
            <ModalContainer
              header={title}
              onClose={() => hideModal()}
              footer={
                <StyledFooter>
                  <a
                    style={{ textDecoration: 'none' }}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() =>
                      downloadFileToBeSaved(
                        businessId,
                        attachment.id,
                        attachment?.name,
                        t('general.downloadError')
                      )
                    }
                  >
                    <Button type="button" icon={<FaCloudDownloadAlt />}>
                      {t('files.fileDetails.download')}
                    </Button>
                  </a>
                  <Button onClick={() => hideModal()}>{t('general.close')}</Button>
                </StyledFooter>
              }
            >
              <EmbedWrapper>
                <StyledEmbed src={attachment.file} type={attachment.type} />
              </EmbedWrapper>
            </ModalContainer>
          </ModalDialog>
        </>
      )}
    </Modal>
  )
}

const EmbedWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 1rem;
`

const StyledFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const StyledEmbed = styled.embed`
  width: 100%;
  height: 100%;
`
