import styled from 'styled-components'

export const TooltipContent = styled.div`
  background: ${({ theme }) => theme.colors.neutralWhite};
  border-radius: 1rem;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  min-width: 12rem;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.sm}rem;
  gap: ${({ theme }) => theme.spacing.xs}rem;
`

export const TooltipTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  line-height: ${({ theme }) => theme.fontSize.md}rem;
  color: ${({ theme }) => theme.colors.neutralBlack};
  font-weight: 500;
`

export const Separator = styled.div.attrs({ ['aria-hidden']: true })`
  border-bottom: 1px;
  border-bottom-style: solid;
  color: ${({ theme }) => theme.colors.neutralWhite};
  margin: ${({ theme }) => theme.spacing.sm}rem 0;
  flex: 1;
`

export const Label = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  line-height: ${({ theme }) => theme.fontSize.sm}rem;
  color: ${({ theme }) => theme.colors.neutralBlack};

  & > strong {
    font-size: ${({ theme }) => theme.fontSize.md}rem;
    line-height: ${({ theme }) => theme.fontSize.md}rem;
    font-weight: 500;
  }
`

export const LabelTitle = styled.span`
  flex: 1;
`

export const LabelDot = styled.div<{ color: string }>`
  display: block;
  height: 0.8rem;
  width: 0.8rem;
  background: ${({ color }) => color};
  border-radius: 0.2rem;
  border: 1px solid white;
`

export const Italic = styled.span`
  margin-bottom: ${({ theme }) => theme.spacing.xs}rem;
  color: ${({ theme }) => theme.colors.neutralWhite};
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  line-height: ${({ theme }) => theme.fontSize.xs}rem;
  font-style: italic;
`
