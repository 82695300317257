import { motion } from 'framer-motion'
import { FaMagic } from 'react-icons/fa'
import styled from 'styled-components'

export const DropdownWrapper = styled.div`
  position: relative;
`

export const Dropdown = styled(motion.div).attrs({
  layout: true,
  variants: {
    hidden: { y: 10, opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.1
      }
    }
  },
  initial: 'hidden',
  animate: 'show',
  exit: 'hidden'
})`
  position: absolute;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  background: white;
  padding: 0.8rem;
  z-index: 999999;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
`

export const Header = styled.div`
  display: flex;
  font-weight: 500;
  margin: 0.6rem;
  margin-bottom: 0;
`

export const HeaderIcon = styled(FaMagic)`
  align-self: center;
  margin-left: 0.4rem;
  fill: ${({ theme }) => theme.colors.iconGrayDark};
`

export const HeaderDetail = styled.div`
  font-size: 0.8rem;
  margin: 0.6rem;
  margin-top: 0;
  color: ${({ theme }) => theme.colors.metalGray};
`

export const Item = styled(motion.div).attrs({
  variants: {
    hidden: {
      y: 5,
      opacity: 0
    },
    show: {
      y: 0,
      opacity: 1
    }
  }
})`
  display: flex;
  padding: 0.4rem 0.6rem;
  border-radius: 0.5rem;
  cursor: pointer;

  &.keyboard-focused,
  &:hover {
    background: ${({ theme }) => theme.colors.mainBg};
  }
`

export const ItemColumn = styled.div`
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.description {
    flex: 1;
  }

  &.number {
    min-width: 80px;
    font-size: 0.8rem;
    margin-right: 0.8rem;
    color: ${({ theme }) => theme.colors.metalGray};
  }

  &.balance {
    margin-left: 0.8rem;
    text-align: right;
    font-family: 'Roboto Mono', monospace;
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.metalGray};
  }
`
