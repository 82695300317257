import { network } from '@utils'
import { QueryFunction } from './root'

interface BusinessPageVisitProps {
  businessId: string
}

export const trackBusinessPageVisit: QueryFunction<BusinessPageVisitProps, void> = async ({
  businessId
}) => {
  const url = `/v1/visit/business/${businessId}/`
  await network.httpClient.request({
    url,
    method: 'GET'
  })
}
