import { BlueprintType } from '@constants'
import { IBlueprint, IImportData } from '@query/documents'
import { PaginatedResponse, QueryFunction } from '@query/root'
import { network } from '@utils'

export interface IAccountingSuggestionUIRow {
  account_name: string
  account_number: number
  vat_rate: number
  vat_code: number
  is_debet: boolean
  amount: number
}

export interface IAccountingSuggestion {
  id: number
  date: string
  amount: number
  description: string
  contact?: number
  contact_name: string
  attachment_ids: number[]
  blueprint_type: BlueprintType
  blueprint: IBlueprint
  ui_rows: IAccountingSuggestionUIRow[]
  import_data: IImportData
}

interface SuggestionProps {
  businessId: string
  documentId?: number
}

export const fetchAccountingSuggestions: QueryFunction<
  SuggestionProps,
  PaginatedResponse<IAccountingSuggestion>
> = async ({ businessId, documentId }) => {
  const { data } = await network.httpClient.request({
    url: `/v1/business/${businessId}/accounting_suggestions/`,
    method: 'GET',
    params: { document: documentId }
  })
  return data
}

interface AcceptSuggestionProps {
  businessId: string
  suggestionId: number
  modifiedData?: Partial<IAccountingSuggestion>
}

export const acceptAccountingSuggestion: QueryFunction<AcceptSuggestionProps, void> = async ({
  businessId,
  suggestionId,
  modifiedData
}) => {
  await network.httpClient.request({
    url: `/v1/business/${businessId}/accounting_suggestions/${suggestionId}/accept/`,
    method: 'POST',
    data: modifiedData
  })
}

interface RejectSuggestionProps {
  businessId: string
  suggestionId: number
}

export const rejectAccountingSuggestion: QueryFunction<RejectSuggestionProps, void> = async ({
  businessId,
  suggestionId
}) => {
  await network.httpClient.request({
    url: `/v1/business/${businessId}/accounting_suggestions/${suggestionId}/reject/`,
    method: 'POST'
  })
}
