import { Prompt, StepBlock, ToolBar } from '@components'
import { useBusinessContext } from '@context'
import { useDebugMode } from '@hooks'
import { IPeriod, performPeriodAction, PeriodActionType } from '@query'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FaCheckCircle, FaLock, FaMask, FaTimes } from 'react-icons/fa'
import ReactMarkdown from 'react-markdown'
import { useMutation, useQueryClient } from 'react-query'
import { Paragraph } from './PeriodSteps.styled'
import { PeriodValidate } from './PeriodValidate'

interface Props {
  period: IPeriod
}

export const PeriodSteps: React.FC<Props> = ({ period }) => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const queryClient = useQueryClient()
  const [debugModeOn] = useDebugMode()
  const [showLock, setShowLock] = useState(false)

  const actionMutation = useMutation<void, unknown, PeriodActionType>(
    async action => {
      performPeriodAction({ action, businessId, periodId: period.id })
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([businessId, 'periods'])
        await queryClient.invalidateQueries([businessId, 'periods', period.id])
        await queryClient.invalidateQueries([businessId, 'documents'])
        toast.success(t('general.success'))
      },
      onError: () => {
        toast.error(t('error.genericRetry'))
      }
    }
  )

  if (debugModeOn) {
    return (
      <>
        <StepBlock
          title={t('period.steps.wait.title')}
          isBlocked={false}
          isCompleted={period.has_ended}
          descCurrent={<Paragraph>{t('period.steps.wait.current')}</Paragraph>}
          descComplete={<Paragraph className="muted">{t('period.steps.wait.complete')}</Paragraph>}
        />

        <StepBlock
          title={t('period.steps.ensure.title')}
          isBlocked={!period.has_ended}
          isCompleted={period.is_ensured}
          descCurrent={
            <>
              <Paragraph>{t('period.steps.ensure.current1')}</Paragraph>
              <Paragraph>{t('period.steps.ensure.current2')}</Paragraph>
              <PeriodValidate period={period} />
              <Paragraph>{t('period.steps.ensure.current3')}</Paragraph>
              <ToolBar
                align="left"
                items={[
                  {
                    text: t('period.steps.ensure.continueButton'),
                    icon: FaCheckCircle,
                    primary: true,
                    disabled: period.is_ensured,
                    action: () => actionMutation.mutate('ensure')
                  }
                ]}
              />
            </>
          }
          descComplete={
            <Paragraph className="muted">{t('period.steps.ensure.complete')}</Paragraph>
          }
        />

        <StepBlock
          title={t('period.steps.depreciations.title')}
          isBlocked={!period.is_ensured}
          isCompleted={period.is_depreciations_ensured}
          descCurrent={
            <>
              <Paragraph>{t('period.steps.depreciations.current1')}</Paragraph>
              <Paragraph>{t('period.steps.depreciations.current2')}</Paragraph>
              {period.is_depreciations_recorded ? (
                <ToolBar
                  align="left"
                  items={[
                    {
                      text: t('period.steps.depreciations.continueButton'),
                      icon: FaCheckCircle,
                      primary: true,
                      disabled: period.is_depreciations_ensured,
                      action: () => actionMutation.mutate('ensure_depreciations')
                    }
                  ]}
                />
              ) : (
                <>
                  <Paragraph>{t('period.steps.depreciations.current3')}</Paragraph>
                  <ToolBar
                    align="left"
                    items={[
                      {
                        text: t('period.steps.depreciations.createDepreciations'),
                        icon: FaCheckCircle,
                        primary: true,
                        disabled: period.is_depreciations_recorded,
                        action: () => actionMutation.mutate('record_depreciations')
                      },
                      {
                        text: t('period.steps.depreciations.skipDepreciations'),
                        icon: FaTimes,
                        primary: false,
                        disabled: period.is_depreciations_recorded,
                        action: () => actionMutation.mutate('skip_record_depreciations')
                      }
                    ]}
                  />
                </>
              )}
            </>
          }
          descComplete={
            <Paragraph className="muted">{t('period.steps.depreciations.complete')}</Paragraph>
          }
        />

        <StepBlock
          title={t('period.steps.deferrals.title')}
          isBlocked={!period.is_depreciations_ensured}
          isCompleted={period.is_deferrals_recorded}
          descCurrent={
            <>
              <Paragraph>{t('period.steps.deferrals.current1')}</Paragraph>
              <Paragraph>{t('period.steps.deferrals.current2')}</Paragraph>
              <Paragraph>{t('period.steps.deferrals.current3')}</Paragraph>

              <ToolBar
                align="left"
                items={[
                  {
                    text: t('period.steps.deferrals.continueButton'),
                    icon: FaCheckCircle,
                    primary: true,
                    disabled: period.is_deferrals_recorded,
                    action: () => actionMutation.mutate('deferrals_recorded')
                  }
                ]}
              />
            </>
          }
          descComplete={
            <Paragraph className="muted">{t('period.steps.deferrals.complete')}</Paragraph>
          }
        />

        <StepBlock
          title={t('period.steps.taxes.title')}
          isBlocked={!period.is_deferrals_recorded}
          isCompleted={period.is_taxed}
          descCurrent={
            <>
              <Paragraph>{t('period.steps.taxes.current')}</Paragraph>
              <ToolBar
                align="left"
                items={[
                  {
                    text: t('period.steps.taxes.continueButton'),
                    icon: FaMask,
                    primary: true,
                    disabled: period.is_taxed,
                    action: () => actionMutation.mutate('record_taxes')
                  }
                ]}
              />
            </>
          }
          descComplete={<Paragraph className="muted">{t('period.steps.taxes.complete')}</Paragraph>}
        />

        <StepBlock
          title={t('period.steps.lock.title')}
          isBlocked={!period.is_taxed}
          isCompleted={period.is_locked}
          descCurrent={
            <>
              <Paragraph>{t('period.steps.lock.current1')}</Paragraph>
              <Paragraph>{t('period.steps.lock.current2')}</Paragraph>
              <ToolBar
                align="left"
                items={[
                  {
                    text: t('period.steps.lock.continueButton'),
                    icon: FaLock,
                    primary: true,
                    disabled: period.is_locked,
                    action: () => actionMutation.mutate('lock')
                  }
                ]}
              />
            </>
          }
          descComplete={<Paragraph color="muted">{t('period.steps.lock.complete')}</Paragraph>}
        />

        <StepBlock
          title={t('period.steps.report.title')}
          isBlocked={!period.is_locked}
          isCompleted={period.is_reported}
          descCurrent={
            <>
              <Paragraph>{t('period.steps.report.current')}</Paragraph>
              <ToolBar
                align="left"
                items={[
                  {
                    text: t('period.steps.report.continueButton'),
                    icon: FaCheckCircle,
                    primary: true,
                    disabled: period.is_reported,
                    action: () => actionMutation.mutate('report')
                  }
                ]}
              />
            </>
          }
          descComplete={<Paragraph color="muted">{t('period.steps.report.complete')}</Paragraph>}
          isLast={true}
        />
      </>
    )
  } else {
    return (
      <>
        <StepBlock
          title={t('period.steps.wait.title')}
          isBlocked={false}
          isCompleted={period.has_ended}
          descCurrent={<Paragraph>{t('period.steps.wait.current')}</Paragraph>}
          descComplete={<Paragraph className="muted">{t('period.steps.wait.complete')}</Paragraph>}
        />
        <StepBlock
          title={t('period.steps.lock.title')}
          isBlocked={!period.has_ended}
          isCompleted={period.is_locked}
          isLast={true}
          descCurrent={
            <>
              <Paragraph>{t('period.steps.lock.currentTemp')}</Paragraph>
              <ToolBar
                align="left"
                items={[
                  {
                    text: t('period.steps.lock.continueButton'),
                    icon: FaLock,
                    primary: true,
                    disabled: period.is_locked,
                    action: () => setShowLock(true)
                  }
                ]}
              />
            </>
          }
          descComplete={<Paragraph color="muted">{t('period.steps.lock.complete')}</Paragraph>}
        />

        <Prompt
          title={t('period.steps.lock.ensureTitle')}
          description={<ReactMarkdown>{t('period.steps.lock.ensureDescription')}</ReactMarkdown>}
          isVisible={showLock}
          onClose={() => setShowLock(false)}
          buttons={[
            {
              text: t('general.cancel'),
              action: () => setShowLock(false)
            },
            {
              text: t('period.steps.lock.ensureButton'),
              action: async () => {
                await actionMutation.mutateAsync('lock')
                setShowLock(false)
              },
              icon: <FaLock />,
              intent: 'primary'
            }
          ]}
        />
      </>
    )
  }
}
