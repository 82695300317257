import React from 'react'
import styled from 'styled-components'

const isImage = (file: File) => file.type?.split('/')[0] === 'image'

export const ToastFilePreview: React.FC<{ file: File }> = ({ file }) => (
  <FileWrapper>
    <Column style={{ flex: 1 }}>
      <FileName>{file.name}</FileName>
      <FileType>{file.type}</FileType>
    </Column>
    {isImage(file) && (
      <Column>
        <ImagePreview src={URL.createObjectURL(file)} />
      </Column>
    )}
  </FileWrapper>
)

const FileWrapper = styled.div`
  min-width: 250px;
  display: flex;

  & > * {
    align-self: center;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const FileName = styled.div`
  width: 200px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const FileType = styled.div`
  color: ${({ theme }) => theme.colors.neutralWhite};
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  opacity: 0.75;
`

const ImagePreview = styled.div<{ src: string }>`
  margin-left: ${({ theme }) => theme.spacing.sm}rem;
  width: 32px;
  height: 32px;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 5px;
`
