import { useTheme } from '@hooks'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { FaCheckCircle, FaDotCircle } from 'react-icons/fa'
import styled from 'styled-components'

interface Goal {
  content: string | React.ReactElement
  isCompleted: boolean
}

interface Props {
  header?: string
  goals: Goal[]
}

export const GoalStepper: React.FC<Props> = ({ header, goals }) => {
  return (
    <StyledGoalStepper>
      <AnimatePresence>
        {header && <GoalStepperHeader>{header}</GoalStepperHeader>}
        {goals.map((goal, index) => (
          <GoalStep key={`goal-${index}`} goal={goal} />
        ))}
      </AnimatePresence>
    </StyledGoalStepper>
  )
}

interface GoalStepProps {
  goal: Goal
}

const GoalStep: React.FC<GoalStepProps> = ({ goal: { content, isCompleted } }) => {
  const theme = useTheme()
  const icon = isCompleted ? (
    <FaCheckCircle color={theme.colors.nocfoGreen} />
  ) : (
    <FaDotCircle color={theme.colors.iconGrayDark} style={{ opacity: '0.2' }} />
  )

  return (
    <StyledStep>
      <AnimatePresence mode="wait">
        <AnimatedIconWrapper key={`value-${isCompleted}`}>{icon}</AnimatedIconWrapper>
      </AnimatePresence>
      <span style={{ textDecoration: isCompleted ? 'line-through' : null }}>{content}</span>
    </StyledStep>
  )
}

const AnimatedIconWrapper = styled(motion.div).attrs({
  initial: { opacity: 0, scale: 1.5, rotate: 90 },
  animate: { opacity: 1, scale: 1.0, rotate: 0 },
  exit: { opacity: 0, scale: 0.9, rotate: 0 }
})``

const StyledStep = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md}rem;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;

  svg {
    width: 1.4rem;
    height: 1.4rem;
  }
`

const GoalStepperHeader = styled.h1`
  color: ${({ theme }) => theme.colors.neutralBlack};
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  margin: 0;
`

const StyledGoalStepper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  padding: ${({ theme }) => theme.spacing.md}rem;
`
