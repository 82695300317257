import loginImg from '@assets/undraw/undraw_hello_re_3evm.svg'
import { BasicPage } from '@components'
import { Login } from '@containers/Login'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export const SignupPage: React.FC = () => {
  const [t] = useTranslation()

  return (
    <BasicPage imageUrl={loginImg}>
      <h2>{t('signup.title')}</h2>
      <p>{t('signup.description')}</p>
      <div style={{ flex: 1 }} />
      <Login isRegister={true} />

      <div style={{ padding: '1rem', textAlign: 'center' }}>
        <Trans i18nKey="signup.gotAccount">
          <Link
            to={{
              pathname: '/',
              search: window.location.search
            }}
          />
        </Trans>
      </div>
    </BasicPage>
  )
}
