import { NotificationIntent } from '@constants'
import { useTheme } from '@hooks'
import { INotification2 } from '@query'
import React, { useMemo } from 'react'
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaInfoCircle
} from 'react-icons/fa'

export const useNotificationColor = (notification: INotification2 | undefined): string => {
  const theme = useTheme()
  const colors = useMemo(
    () => ({
      [NotificationIntent.INFO]: theme.colors.nocfoBlue,
      [NotificationIntent.SUCCESS]: theme.colors.nocfoGreen,
      [NotificationIntent.WARNING]: theme.colors.nocfoYellow,
      [NotificationIntent.DANGER]: theme.colors.nocfoRed
    }),
    []
  )

  return colors[notification?.intent] || theme.colors.metalGray
}

export const useNotificationIcon = (notification: INotification2 | undefined): React.ReactNode => {
  const color = useNotificationColor(notification)
  const icons = useMemo(
    () => ({
      [NotificationIntent.INFO]: <FaInfoCircle color={color} />,
      [NotificationIntent.SUCCESS]: <FaCheckCircle color={color} />,
      [NotificationIntent.WARNING]: <FaExclamationTriangle color={color} />,
      [NotificationIntent.DANGER]: <FaExclamationCircle color={color} />
    }),
    [color]
  )
  return icons[notification?.intent] || <FaInfoCircle color={color} />
}
