import { Button, ModalV2 } from '@components'
import { IProduct } from '@query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ProductForm } from './ProductForm'

interface Props {
  product?: IProduct
  isVisible: boolean
  handleOnClose: () => void
}

export const ProductFormModal: React.FC<Props> = ({ product, isVisible, handleOnClose }) => {
  const [t] = useTranslation()
  const header = product?.id ? t('products.form.modalTitleEdit') : t('products.form.modalTitleNew')

  return (
    <ModalV2
      header={header}
      isVisible={isVisible}
      handleOnClose={handleOnClose}
      width={600}
      height={900}
      footer={
        <>
          <Button onClick={() => handleOnClose()}>{t('general.cancel')}</Button>
          <Button type="submit" intent="success" form="product-form">
            {t('general.save')}
          </Button>
        </>
      }
    >
      <ProductForm product={product} onSubmit={() => handleOnClose()} />
    </ModalV2>
  )
}
