import { NocfoPlan, NocfoPlanSource } from '@constants'
import React, { useContext } from 'react'
import { BusinessContext } from './BusinessContext'

const SubscriptionContext = React.createContext<{ plan?: NocfoPlan; source?: NocfoPlanSource }>({
  plan: undefined,
  source: undefined
})

export const SubscriptionContent: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data } = useContext(BusinessContext)

  return (
    <SubscriptionContext.Provider
      value={{ plan: data?.subscription_plan, source: data?.subscription_source }}
    >
      {children}
    </SubscriptionContext.Provider>
  )
}

interface PlanContentProps {
  required: string[]
  requiredSources?: NocfoPlanSource[]
  children: React.ReactNode
}

export const PlanContent: React.FC<PlanContentProps> = ({
  required,
  requiredSources,
  children
}) => {
  const { plan, source } = useContext(SubscriptionContext)
  const sufficientPlan = required.indexOf(plan) != -1
  const sufficientSource =
    requiredSources?.length > 0 ? requiredSources?.indexOf(source) != -1 : true
  return sufficientPlan && sufficientSource ? <>{children}</> : null
}

export const PaidContent: React.FC<{ children: React.ReactNode; sources?: NocfoPlanSource[] }> = ({
  sources,
  children
}) => {
  const requiredPlans = [NocfoPlan.PLUS, NocfoPlan.PRO, NocfoPlan.ENTERPRISE]
  return (
    <PlanContent required={requiredPlans} requiredSources={sources}>
      {children}
    </PlanContent>
  )
}

export const FreeContent: React.FC<{ children: React.ReactNode; sources?: NocfoPlanSource[] }> = ({
  sources,
  children
}) => {
  return (
    <PlanContent required={[NocfoPlan.FREE]} requiredSources={sources}>
      {children}
    </PlanContent>
  )
}
