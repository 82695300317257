import { omitEmptyValues } from '@utils'
import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

type KeyPairs = { [key: string]: string | number | undefined | null }
type SetParams = (KeyPairs) => void
type GetUrlWithParams = (KeyPairs) => string

export const useQueryParams = (): [KeyPairs, SetParams, GetUrlWithParams] => {
  const query = useLocation().search
  const history = useHistory()

  const params = useMemo(() => {
    const queryPairs = query.slice(1).split('&').filter(Boolean)
    const params = {}

    queryPairs.map(part => {
      const [key, value] = part.split('=')
      params[key] = decodeURIComponent(value)
    })

    return params
  }, [query])

  const getUrlWithParams: GetUrlWithParams = useCallback(
    values => {
      const newParams = omitEmptyValues({ ...params, ...values })
      const search = new URLSearchParams(newParams).toString()
      const hash = location.hash
      return `${location.pathname}?${search}${hash ? `#${hash}` : ''}`
    },
    [params]
  )

  const setParams: SetParams = values => {
    const url = getUrlWithParams(values)
    history.push(url)
  }

  return [params, setParams, getUrlWithParams]
}

type SetParam = (name: string) => void

export const useQueryParam = <T>(
  paramKey: string,
  formatter?: (value: any) => T
): [any, SetParam] => {
  const [params, setParams] = useQueryParams()

  const param = useMemo(
    () => (formatter ? formatter(params[paramKey]) : params[paramKey]),
    [params[paramKey]]
  )

  const setParam = (value: string | number | undefined) => setParams({ [paramKey]: value })

  return [param, setParam]
}
