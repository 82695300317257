import { TextTooltip } from '@components'
import { Control, isFunction } from '@utils'
import React, { HTMLAttributes, ReactNode } from 'react'
import { FaQuestionCircle } from 'react-icons/fa'
import styled from 'styled-components'

interface Props<T> extends HTMLAttributes<HTMLDivElement> {
  widget: (props: WidgetChildProps<T>) => ReactNode
  controls?: Control<T>[]
  initialControlValue?: (() => T) | T
  widgetTitle?: string
  tooltip?: string
  highlight?: boolean
}

interface WidgetChildProps<AnalyticsRange> {
  controls: Control<AnalyticsRange>[]
}

export function Widget<AnalyticsRange>({
  widget,
  widgetTitle,
  controls,
  tooltip,
  highlight = false,
  ...elementProps
}: Props<AnalyticsRange>): React.ReactElement {
  if (!isFunction(widget)) {
    throw new Error('Widget can only be of type function')
  }

  return (
    <WidgetContainer {...elementProps} highlight={highlight}>
      <TitleRow tooltip={tooltip} title={widgetTitle ?? ''} controls={controls} />
      {widget({ controls })}
    </WidgetContainer>
  )
}

interface TitleRowProps<T> extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  controls?: Control<T>[]
  currentValue?: T
  onControlChange?: (newVal: T) => void
  tooltip?: string
}

function TitleRow<AnalyticsRange>({
  title,
  tooltip,
  ...elementProps
}: TitleRowProps<AnalyticsRange>): React.ReactElement {
  return (
    <Row {...elementProps}>
      <RowTitle className="title">{title}</RowTitle>
      <Separator />
      <>
        {tooltip ? (
          <TextTooltip tooltip={tooltip}>
            <FaQuestionCircle />
          </TextTooltip>
        ) : null}
      </>
    </Row>
  )
}

const WidgetContainer = styled.div<{ highlight: boolean }>`
  flex: 1 1 400px;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 1rem;
  overflow: visible;
  box-sizing: border-box;

  border: ${({ theme, highlight }) => (highlight ? `3px solid ${theme.colors.nocfoBlue}` : 'none')};

  .title {
    font-weight: ${({ highlight }) => (highlight ? 'bold' : '500')};
    color: ${({ theme, highlight }) =>
      highlight ? theme.colors.nocfoBlue : theme.colors.iconGrayDark};
  }
`

const Separator = styled.div.attrs({ ['aria-hidden']: true })`
  flex: 1;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing.md}rem;
  min-height: 3.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralGray};
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;
`

const RowTitle = styled.p`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  line-height: ${({ theme }) => theme.fontSize.md}rem;
  color: ${({ theme }) => theme.colors.iconGrayDark};
  font-weight: 500;
`
