import { network } from '@utils'
import { handleReturn, MutationFunction, PaginatedResponse, QueryFunction } from './root'
import { VatRateLabel } from '@constants'

export interface IAutoCompleteAccount {
  id: number
  type: string
  number: string
  padded_number: string
  name: string
  description: string
  default_vat_code: number
  default_vat_rate: number
  default_vat_rate_label: string
}

interface AutoCompleteAccountProps {
  businessId: string
}

export const fetchAutoCompleteAccounts: QueryFunction<
  AutoCompleteAccountProps,
  PaginatedResponse<IAutoCompleteAccount>
> = async ({ businessId }, params = null) => {
  const url = `/v1/business/${businessId}/account_auto_complete/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params: { ...params, page_size: 10000 }
  })
  return data
}

export interface IAccount {
  id: number
  name: string
  description: string
  number: number
  header_path: string[]
  padded_number: number
  default_vat_code: number
  default_vat_rate: number
  default_vat_rate_label: VatRateLabel
  type: string
  opening_balance: number
  is_used: boolean
  is_shown: boolean
  balance: number
}

interface AccountListProps {
  businessId: string
}

export const fetchAccounts: QueryFunction<AccountListProps, PaginatedResponse<IAccount>> = async (
  { businessId },
  params = null
) => {
  const url = `/v1/business/${businessId}/account/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}

interface AccountInstanceProps {
  businessId: string
  accountId: number
}

export const fetchAccount: QueryFunction<AccountInstanceProps, IAccount> = async (
  { businessId, accountId },
  params = null
) => {
  const url = `/v1/business/${businessId}/account/${accountId}/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}

export const createAccount: MutationFunction<AccountListProps, IAccount> = async (
  { businessId },
  requestData
) => {
  const url = `/v1/business/${businessId}/account/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'POST',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export const updateAccount: MutationFunction<AccountInstanceProps, IAccount> = async (
  { businessId, accountId },
  requestData
) => {
  const url = `/v1/business/${businessId}/account/${accountId}/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'PATCH',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export const deleteAccount: MutationFunction<AccountInstanceProps, void> = async ({
  businessId,
  accountId
}) => {
  const url = `/v1/business/${businessId}/account/${accountId}/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'DELETE',
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

interface ValidateAccountNumberProps {
  businessId: string
  number: number
}

interface IAccountNumberValidation {
  exists: boolean
  data: IAccount
}

export const validateAccountNumber: QueryFunction<
  ValidateAccountNumberProps,
  IAccountNumberValidation
> = async ({ businessId, number }) => {
  const url = `/v1/business/${businessId}/account/validate_number/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params: { number }
  })
  return data
}

export const showAccount: MutationFunction<AccountInstanceProps, void> = async ({
  businessId,
  accountId
}) => {
  const url = `/v1/business/${businessId}/account/${accountId}/show/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'POST',
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export const hideAccount: MutationFunction<AccountInstanceProps, void> = async ({
  businessId,
  accountId
}) => {
  const url = `/v1/business/${businessId}/account/${accountId}/hide/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'POST',
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}
