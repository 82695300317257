import { NotificationIntent, NotificationType } from '@constants'
import { useCurrentUser } from '@query'
import { network } from '@utils'
import { useQuery } from 'react-query'
import { handleReturn, MutationFunction, PaginatedResponse, QueryFunction } from './root'

interface ISimpleBusiness {
  id: number
  business_id: string
  name: string
}

export interface INotification2 {
  id: number
  type: NotificationType
  seen: boolean
  intent: NotificationIntent
  timestamp: string

  business?: ISimpleBusiness

  custom_message_title?: string
  custom_message_content?: string
  custom_message_link?: string

  related_object_id?: number
  related_object_ct?: string
}

interface IInvitationData {
  invitation_id: number
  business: number
  business_name: string
}

export interface INotification {
  action: string
  data: IInvitationData
}

interface NotificationListProps {
  businessId?: string
}

export const fetchNotifications: QueryFunction<
  NotificationListProps,
  PaginatedResponse<INotification2>
> = async ({ businessId }, params) => {
  const url = businessId
    ? `/v1/notification/in_app/notifications/business/${businessId}/`
    : `/v1/notification/in_app/notifications/`

  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}

interface INotificationUnseenCounts {
  count_user_related: number
  count_business_related: number
  counts_by_business: { [businessId: number]: number }
}

export const fetchNotificationUnseenCounts: QueryFunction<
  void,
  INotificationUnseenCounts
> = async () => {
  const url = `/v1/notification/in_app/unseen_counts/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET'
  })
  return data
}

interface NotificationInstanceProps {
  notificationId: number
}

export const updateNotification: MutationFunction<
  NotificationInstanceProps,
  INotification2
> = async ({ notificationId }, requestData) => {
  const url = `/v1/notification/in_app/notifications/${notificationId}/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'PATCH',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export const useUnseenNotificationCount = (businessId?: number): number => {
  const { data: user } = useCurrentUser()
  const { data: counts } = useQuery(
    ['notifications', user?.id, 'unseen_counts'],
    () => fetchNotificationUnseenCounts(),
    {
      enabled: !!user?.id
    }
  )

  return businessId
    ? (counts?.count_user_related || 0) + (counts?.counts_by_business?.[businessId] || 0)
    : (counts?.count_user_related || 0) + (counts?.count_business_related || 0)
}

export const markAllAsSeen: MutationFunction<NotificationListProps, void> = async ({
  businessId
}) => {
  const url = businessId
    ? `/v1/notification/in_app/mark_as_seen/business/${businessId}/`
    : `/v1/notification/in_app/mark_as_seen/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'POST',
    data: {},
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}
