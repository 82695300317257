import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaCheckCircle, FaHourglassHalf, FaTimes } from 'react-icons/fa'
import styled from 'styled-components'

interface Props {
  isLoading: boolean
  isSuccess: boolean
}

export const AllDoneSlide: React.FC<Props> = ({ isLoading, isSuccess }) => {
  const { t } = useTranslation()

  return (
    <StyledWrapper>
      <AnimatePresence mode="wait">
        {isLoading ? (
          <AnimatedHourglass key="loader">
            <FaHourglassHalf />
          </AnimatedHourglass>
        ) : isSuccess ? (
          <AnimatedCheckCircle key="check">
            <FaCheckCircle className="success-icon" />
          </AnimatedCheckCircle>
        ) : (
          <AnimatedCheckCircle key="check">
            <FaTimes className="error-icon" />
          </AnimatedCheckCircle>
        )}
      </AnimatePresence>
      {isLoading ? (
        <h2>{t('onboarding.creatingBusiness')}</h2>
      ) : isSuccess ? (
        <>
          <h2>{t('general.allDone')}</h2>
        </>
      ) : (
        <h2>{t('general.error')}</h2>
      )}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.md}rem;
  gap: ${({ theme }) => theme.spacing.xxl}rem;
  text-align: center;

  svg {
    width: 25vw;
    max-width: 80px;
    height: 25vw;
    max-height: 80px;
  }
`

const AnimatedHourglass = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: {
    opacity: [1, 1],
    rotate: [-5, 10, 0, 10, -10, 0, -5],
    transition: {
      duration: 2,
      repeat: Infinity
    }
  },
  exit: {
    opacity: 0
  }
})`
  svg {
    fill: ${({ theme }) => theme.colors.iconGray};
  }
`

const AnimatedCheckCircle = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  .success-icon {
    fill: ${({ theme }) => theme.colors.nocfoGreen};
  }

  .error-icon {
    fill: ${({ theme }) => theme.colors.nocfoRed};
  }
`
