import { motion } from 'framer-motion'
import styled from 'styled-components'

export const SecondaryModalContent = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  padding: ${({ theme }) => theme.spacing.lg}rem;
  background: ${({ theme }) => theme.colors.neutralWhite};
`

export const ModalColumn = styled(motion.div)`
  z-index: 50;
  top: 0;
  left: 0;
  position: absolute;
  border-radius: 1rem;
  overflow: hidden;

  & > div {
    border-radius: 1rem;
  }
`

export const DoubleModalWrapper = styled(motion.div).attrs({
  variants: {
    init: { scale: 1.1, y: 0 },
    show: { scale: 1, y: 0 },
    exit: { scale: 0.95, y: 0 }
  },
  initial: 'init',
  animate: 'show',
  exit: 'exit'
})`
  position: relative;
  max-width: 100vw;
`

export const ModalOverlay = styled(motion.div).attrs({
  variants: {
    initial: { opacity: 0 },
    isOpen: { opacity: 1 },
    exit: { opacity: 0, transition: { duration: 0.2 } }
  },
  initial: 'initial',
  animate: 'isOpen',
  exit: 'exit'
})`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9000;
  overflow-y: auto;
  background: ${({ theme }) => theme.colors.backdrop};
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 376px) {
    overflow: hidden;
  }
`
