import React, { useState } from 'react'
import { Alert, Button } from '@components'
import styled from 'styled-components'
import { VerificationResponse } from '@containers/CSVImportWizard'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { FaFileImport } from 'react-icons/fa6'
import { FieldToMap } from '@containers/CSVImportWizard/base'
import { ExampleCard } from '@containers/CSVImportWizard/base/ExampleCardBase.tsx'
import { useMutation } from 'react-query'
import { useFloating, autoUpdate } from '@floating-ui/react'
import { offset } from '@floating-ui/react-dom-interactions'
import { useHotkeys } from 'react-hotkeys-hook'

interface Props {
  data: Record<string, any>[]
  fields: FieldToMap<any>[]
  verificationResponse: VerificationResponse
  onPrevSlide: () => void
  onComplete: () => Promise<void>
}

export const ExamplesSlide: React.FC<Props> = ({
  data,
  fields,
  verificationResponse,
  onPrevSlide,
  onComplete
}) => {
  const { t } = useTranslation()
  const [exampleIndex, setExampleIndex] = useState(0)
  const exampleData = data[exampleIndex]

  const { isLoading, mutate } = useMutation(() => onComplete())
  const { floatingStyles, refs } = useFloating({
    whileElementsMounted: autoUpdate,
    placement: 'bottom',
    strategy: 'fixed',
    middleware: [offset(({ rects }) => -rects.floating.height - 16)]
  })

  useHotkeys('left', () => setExampleIndex(v => Math.max(v - 1, 0)), [exampleIndex])
  useHotkeys('right', () => setExampleIndex(v => Math.min(v + 1, (data?.length || 1) - 1)), [
    exampleIndex
  ])

  return (
    <SlideWrapper>
      <Alert
        type="success"
        description={t('components.importWizard.review.importInfo', {
          toBeCreated: verificationResponse.toBeCreated,
          toBeUpdated: verificationResponse.toBeUpdated
        })}
        marginBottom={0}
      />
      <ExampleWrapper ref={refs.setReference}>
        <ExampleCard
          fields={fields.map(({ name, apiField, renderExample }) => ({
            label: name,
            value: renderExample
              ? renderExample(exampleData[apiField as any])
              : exampleData[apiField as any]
          }))}
        />
        <BrowserContainer ref={refs.setFloating} style={floatingStyles}>
          <Button
            disabled={exampleIndex <= 0}
            type="button"
            onClick={() => setExampleIndex(v => v - 1)}
            icon={<FaArrowLeft />}
            isSecondary
          >
            {t('general.previous')}
          </Button>

          <Pager>{`${exampleIndex + 1} / ${data.length}`}</Pager>

          <Button
            disabled={exampleIndex >= data.length - 1}
            type="button"
            onClick={() => setExampleIndex(v => v + 1)}
            iconRight={<FaArrowRight />}
            isSecondary
          >
            {t('general.next')}
          </Button>
        </BrowserContainer>
      </ExampleWrapper>

      <Footer>
        <Button onClick={() => onPrevSlide()}>{t('general.back')}</Button>

        <Button
          showSpinner={isLoading}
          disabled={isLoading}
          icon={<FaFileImport />}
          intent="success"
          onClick={() => mutate()}
        >
          {t('components.importWizard.review.startImport')}
        </Button>
      </Footer>
    </SlideWrapper>
  )
}

const SlideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg}rem;
  height: 100%;
`

const ExampleWrapper = styled.div`
  position: relative;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`

const Pager = styled.div`
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.neutralGray};
  padding: ${({ theme }) => theme.spacing.xxs}rem ${({ theme }) => theme.spacing.sm}rem;
  border-radius: 1rem;
  color: ${({ theme }) => theme.colors.metalGray};
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
`

const BrowserContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md}rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.neutralWhite};
  border-radius: 1rem;
  border: 2px solid ${({ theme }) => theme.colors.neutralWhite};
`

const Footer = styled.div`
  display: flex;
  gap: 1rem;
  background: ${({ theme }) => theme.colors.neutralGray}25;
  justify-content: space-between;
`
