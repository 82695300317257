import imgSrc from '@assets/undraw/undraw_warning_re_eoyh.svg'
import { BasicPage, Button } from '@components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const ErrorPage: React.FC = () => {
  const [t] = useTranslation()

  return (
    <BasicPage title={t('errorPage.mainTitle')} imageUrl={imgSrc}>
      <h2>{t('errorPage.title')}</h2>
      <p>{t('errorPage.description1')}</p>
      <p>{t('errorPage.description2')}</p>

      <ButtonWrapper>
        <a href={t('link.contact')}>
          <Button intent="default">{t('errorPage.contactUs')}</Button>
        </a>
        <a href="/">
          <Button intent="primary">{t('errorPage.refresh')}</Button>
        </a>
      </ButtonWrapper>
    </BasicPage>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.sm}rem;
`
