import { Avatar } from 'evergreen-ui'
import React from 'react'
import styled from 'styled-components'

interface Props {
  size?: number
  name: string
  src?: string
  borderRadius?: string | number
}

const LOGO_SIZE = 128

export const BusinessLogo: React.FC<Props> = ({
  size = LOGO_SIZE,
  name,
  src,
  borderRadius = '1rem'
}) =>
  src ? (
    <StyledLogo backgroundSrc={src} size={size} borderRadius={0} />
  ) : (
    <Avatar name={name} size={size} shape="square" borderRadius={borderRadius} />
  )

const StyledLogo = styled.div<{
  backgroundSrc: string
  size: number
  borderRadius: string | number
}>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background-image: url(${({ backgroundSrc }) => backgroundSrc});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-origin: content-box;
`
