import { handleReturn, QueryFunction } from '@query/root.ts'
import { network } from '@utils'
import { MutationFunction } from 'react-query'

export interface ISalaxySessionResponse {
  access_token: string
  is_enabled: boolean
  is_verified: boolean
}

interface SalaxyTokenProps {
  businessId: string
}

export const fetchSalaxySession: QueryFunction<SalaxyTokenProps, ISalaxySessionResponse> = async ({
  businessId
}) => {
  const url = `/v1/integrations/salary/${businessId}/session/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'GET',
    validateStatus: () => true
  })
  return handleReturn({ data, status })
}

export const enableSalaxy: MutationFunction<void, SalaxyTokenProps> = async ({ businessId }) => {
  const url = `/v1/integrations/salary/${businessId}/enable/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'POST',
    validateStatus: () => true
  })
  return handleReturn({ data, status })
}

export const disableSalaxy: MutationFunction<void, SalaxyTokenProps> = async ({ businessId }) => {
  const url = `/v1/integrations/salary/${businessId}/disable/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'POST',
    validateStatus: () => true
  })
  return handleReturn({ data, status })
}

export interface ISalaxyVerifyResponse {
  is_verified: boolean
}

export const verifySalaxy: MutationFunction<ISalaxyVerifyResponse, SalaxyTokenProps> = async ({
  businessId
}) => {
  const url = `/v1/integrations/salary/${businessId}/verify/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'POST',
    validateStatus: () => true
  })
  return handleReturn({ data, status })
}
