import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { FaRotate } from 'react-icons/fa6'
import { TextTooltip } from '@root/components'

export const RepetitionStatusIcon: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const color = theme.colors.metalGray
  const icon = <FaRotate />

  return (
    <TextTooltip closeDelay={200} tooltip={t('invoicing.repetitionStatus.repetitive')}>
      {<IconWrapper color={color}>{icon}</IconWrapper>}
    </TextTooltip>
  )
}

const IconWrapper = styled.div`
  & > * {
    opacity: 0.7;
    color: ${props => props.color};
  }
`
