import { useOnClickOutside, useVirtualList } from '@hooks'
import { removeDuplicates } from '@utils'
import { motion } from 'framer-motion'
import React, { useCallback, useEffect, useState } from 'react'
import { useDragLayer } from 'react-dnd'
import { DraggableItem, IDraggableItem, IDraggableItemRenderProps } from './DraggableItem'
import { DragLayer } from './DragLayer'

interface Props {
  items: IDraggableItem[]
  itemType: string
  itemSize: number
  render: (item: IDraggableItem, props: IDraggableItemRenderProps, index: number) => React.ReactNode
  renderDrag: (item: IDraggableItem[]) => React.ReactNode
  onDrop?: (target: IDraggableItem, items: IDraggableItem[]) => void
  onDelete?: (ids: number[]) => Promise<unknown>
  onScrollBottom?: () => void
}

const itemID = (item: IDraggableItem) => `${item.type}-${item.item.id}`

export const DraggableVirtualList: React.FC<Props> = ({
  items,
  itemType,
  itemSize,
  render,
  onDrop,
  renderDrag,
  onScrollBottom
}) => {
  const [dragForce, setDragForce] = useState<number>(0)
  const dragAnimationRef = React.useRef<number>()
  const [lastSelected, setLastSelected] = useState<IDraggableItem>(undefined)
  const [selectedItems, setSelectedItems] = useState<IDraggableItem[]>([])

  const inSelectedItems = useCallback(
    (items: IDraggableItem[], item: IDraggableItem) => items.some(s => itemID(s) === itemID(item)),
    []
  )

  const clearItems = () => {
    setSelectedItems([])
  }

  const toggleItem = item =>
    setSelectedItems(selected =>
      inSelectedItems(selected, item)
        ? selected.filter(s => itemID(s) !== itemID(item))
        : [...selected, item]
    )

  const shiftSelectItems = useCallback(
    item => {
      if (lastSelected) {
        const itemFrom = itemID(lastSelected)
        const itemTo = itemID(item)

        const itemFromIndex = items.findIndex(i => itemFrom === itemID(i))
        const itemToIndex = items.findIndex(i => itemTo === itemID(i))
        const index1 = Math.min(itemFromIndex, itemToIndex)
        const index2 = Math.max(itemFromIndex, itemToIndex)
        const shiftSelected = items.slice(index1, index2).filter(item => item.allowDrag !== false)

        setSelectedItems(selected =>
          removeDuplicates([...selected, ...shiftSelected, item], itemID)
        )
      } else {
        toggleItem(item)
      }
    },
    [items, lastSelected]
  )

  const { scrollRef, renderItems } = useVirtualList({
    items: items,
    itemSize,
    onScrollBottom
  })
  useOnClickOutside(scrollRef, () => clearItems())

  // DRAG SCROLL

  useDragLayer(monitor => {
    const dragging = monitor.isDragging()
    const dragItemType = monitor.getItemType()

    if (dragItemType === itemType) {
      const clientOffset = monitor.getClientOffset()
      const containerOffset = scrollRef.current?.getBoundingClientRect()

      if (clientOffset && containerOffset) {
        const cursorY = clientOffset.y
        const { top: upperBound, bottom: lowerBound } = containerOffset

        if (cursorY < upperBound) {
          setDragForce((cursorY - upperBound) / 3)
        } else if (cursorY > lowerBound) {
          setDragForce((cursorY - lowerBound) / 3)
        } else {
          setDragForce(0)
        }
      }
    }

    // Make sure dragForce is back to 0 when drag ends
    if (!dragging) {
      setDragForce(0)
    }
  })

  const animateScroll = force => {
    if (scrollRef.current) scrollRef.current.scrollTop += dragForce
    dragAnimationRef.current = requestAnimationFrame(() => animateScroll(force))
  }

  useEffect(() => {
    if (dragForce) {
      dragAnimationRef.current = requestAnimationFrame(() => animateScroll(dragForce))
      return () => cancelAnimationFrame(dragAnimationRef.current)
    }
  }, [dragForce])

  return (
    <>
      <div ref={scrollRef} style={{ height: '100%', overflow: 'auto' }}>
        {renderItems((item, style, index) => {
          return (
            <motion.div key={itemID(item)} style={style} layout="position">
              <DraggableItem
                item={item}
                itemType={itemType}
                selectedItems={selectedItems}
                isSelected={inSelectedItems(selectedItems, item)}
                size={itemSize}
                onDrop={(target, items) => {
                  onDrop(target, items)
                  clearItems()
                }}
                onClick={(item, e) => {
                  if (e.metaKey) {
                    toggleItem(item)
                  } else if (e.shiftKey) {
                    shiftSelectItems(item)
                  } else {
                    clearItems()
                  }
                  setLastSelected(item)
                }}
                render={(item, props) => render(item, props, index)}
              />
            </motion.div>
          )
        })}
      </div>
      <DragLayer
        key="drag-layer"
        acceptedType={itemType}
        render={(items: IDraggableItem[]) => renderDrag(items)}
      />
    </>
  )
}
