import styled from 'styled-components'

export const StyledModelContainer = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const StyledModelContent = styled.div`
  flex: 1;
  overflow: auto;
`

export const StyledModelFooter = styled.div`
  display: flex;
  padding-top: ${({ theme }) => theme.spacing.md}rem;
`

export const StyledWaitingSpinnerWrapper = styled.div`
  height: 100%;
  flex: 1;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xxl}rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.xxl}rem;
  color: ${({ theme }) => theme.colors.neutralBlack};

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 80%;

    p {
      color: ${({ theme }) => theme.colors.metalGray};
    }
  }

  & > .success-img {
    width: 50%;
  }
`

export const PulseLoader = () => (
  <StyledLoader>
    <div />
    <div />
  </StyledLoader>
)

export const StyledLoader = styled.div`
  color: #1c4c5b;
  position: relative;
  width: 100px;
  height: 100px;

  div {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    &:nth-child(2) {
      animation-delay: -0.5s;
    }
  }

  @keyframes lds-ripple {
    0% {
      border: 6px solid ${({ theme }) => theme.colors.nocfoBlue};
      width: 10%;
      height: 10%;
      opacity: 0;
    }
    4.9% {
      border: 6px solid ${({ theme }) => theme.colors.nocfoBlue};
      width: 10%;
      height: 10%;
      opacity: 0;
    }
    5% {
      border: 6px solid ${({ theme }) => theme.colors.nocfoBlue};
      width: 10%;
      height: 10%;
      opacity: 1;
    }
    100% {
      border: 2px solid ${({ theme }) => theme.colors.nocfoBlue};
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
`
