import { INotification2 } from '@query'
import React from 'react'
import styled from 'styled-components'
import { useNotificationIcon } from './useNotificationColor'

interface Props {
  notification: INotification2
}

export const NotificationIcon: React.FC<Props> = ({ notification }) => {
  const icon = useNotificationIcon(notification)
  return <IconWrapper>{icon}</IconWrapper>
}

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 1.2rem;
  }
`
