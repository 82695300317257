import { useScreen } from '@utils'
import { ArrowLeftIcon, Heading, IconButton, Pane } from 'evergreen-ui'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

interface Props {
  heading?: string
  isShown: boolean
  handleClose: () => void
  unsavedChanges?: boolean
  size?: 'sm' | 'md' | 'lg' | 'xl'
  footerContent?: ({ close }: { close: () => void }) => React.FC | React.ReactNode
  children: React.ReactNode
}

export const SideSheet: React.FC<Props> = ({
  children,
  isShown,
  heading,
  handleClose,
  size = 'md',
  footerContent
}) => {
  const screen = useScreen()

  let width
  if (size === 'sm') width = screen.xs ? '100vw' : screen.breakPoints.xs * 0.8
  if (size === 'md') width = screen.xs ? '100vw' : screen.breakPoints.md * 0.8
  if (size === 'lg') width = screen.xs ? '100vw' : screen.breakPoints.lg * 0.8
  if (size === 'xl') width = screen.xs ? '100vw' : screen.breakPoints.xl * 0.8

  return (
    <AnimatePresence>
      {isShown && (
        <>
          <StyledBackDrop onClick={() => handleClose()} />
          <StyledSideSheet style={{ width }}>
            <Pane flex="1" display="flex" height="100%" flexDirection="column">
              <Pane padding={8} background="white" borderBottom display="flex">
                <IconButton
                  width={48}
                  icon={ArrowLeftIcon}
                  height={48}
                  appearance="minimal"
                  onClick={() => handleClose()}
                />
                <Heading size={500} flex="1" alignSelf="center" textAlign="center">
                  {heading}
                </Heading>
                <Pane width={48}></Pane>
              </Pane>

              <Pane flex="1" background="white" overflowY="auto">
                {children}
              </Pane>

              {footerContent && (
                <Pane padding={16} background="white" minHeight={64} borderTop display="flex">
                  {footerContent({ close: handleClose }) as React.ReactNode}
                </Pane>
              )}
            </Pane>
          </StyledSideSheet>
        </>
      )}
    </AnimatePresence>
  )
}

const StyledBackDrop = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.colors.backdrop};
  z-index: 100;
`

const StyledSideSheet = styled(motion.div).attrs({
  initial: { x: '100%', opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { x: '100%', opacity: 0 },
  transition: { type: 'tween' }
})`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  max-width: 100vw;
  background: white;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 101;
`
