import { NocfoTheme } from './styled'

export const theme: NocfoTheme = {
  colors: {
    neutralBlack: '#262C30',
    neutralGray: '#F0F2F5',
    neutralWhite: '#FFFFFF',
    iconGray: '#73808C',
    iconGrayDark: '#3B4A57',
    mutedYellow: '#F7CC7A',
    nocfoBlue: '#1b98f5',
    nocfoGreen: '#39d273',
    nocfoRed: '#f50d05',
    nocfoYellow: '#ffa600',
    nocfoPurple: '#c000ec',
    mainBg: '#F0F2F5',
    menuBg: '#252e38',
    metalGray: '#425a70',
    backdrop: '#435a6f66',
    defaultHover: '#f6f7f9',
    blue: '#003f5c',
    orange: '#ff6361',
    purple: '#58508d',
    pink: '#bc5090',
    yellow: '#ffa600'
  },
  typography: {
    fontFamily: {
      montserrat: 'Montserrat, sans-serif'
    }
  },
  spacing: {
    none: 0,
    xxxs: 0.125,
    xxs: 0.25,
    xs: 0.5,
    sm: 0.75,
    md: 1,
    lg: 1.5,
    xl: 2,
    xxl: 3
  },
  fontSize: {
    xxs: 0.5,
    xs: 0.75,
    sm: 0.875,
    md: 1,
    lg: 1.25,
    xl: 1.5,
    xxl: 2
  },
  iconSize: {
    xxs: 0.8,
    xs: 1,
    sm: 1.5,
    md: 2,
    lg: 2.5
  },
  breakpoint: {
    xs: '20em',
    sm: '23.4375em',
    md: '48em',
    lg: '64em',
    xl: '80em',
    xxl: '100em'
  },
  shadow: {
    md: '0px 2px 10px 0px rgba(0, 0, 0, 0.1)'
  }
}

export default theme
