import { DangerouslyRenderHTML } from '@components'
import { useBusinessContext } from '@context'
import { IPeriod } from '@query'
import { theme } from '@styles'
import { appendDocumentLinks, network } from '@utils'
import { Popover, Spinner } from 'evergreen-ui'
import { AnimatePresence } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCheck, FaExclamationTriangle, FaInfoCircle } from 'react-icons/fa'
import {
  AccountingValidationRow,
  AnimatedIconWrapper,
  DescriptionWrapper,
  MutedTextRow,
  PopoverContentWrapper,
  PopoverHeader,
  ValidationWrapper
} from './PeriodValidate.styled'

const CheckMarkRow: React.FC<{
  validationEndpoint: string
  errorDescription: string
  validDescription: string
}> = ({ validationEndpoint, errorDescription, validDescription }) => {
  const [t] = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const [errors, setErrors] = useState([])
  const { businessId } = useBusinessContext()

  useEffect(() => {
    setIsLoading(true)
    network.httpClient.get(validationEndpoint).then(({ data }) => {
      setErrors(data)
      setIsLoading(false)
    })
  }, [])

  const isValid = errors.length === 0
  const variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
  }

  const ValidIcon: React.FC = () => <FaCheck className="ok" color={theme.colors.nocfoGreen} />
  const InvalidIcon: React.FC = () => (
    <FaExclamationTriangle className="warn" color={theme.colors.nocfoYellow} />
  )

  const InvalidPopover: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Popover
      trigger="hover"
      content={
        <PopoverContentWrapper>
          <PopoverHeader>{t('period.validation.errorsTitle')}</PopoverHeader>
          {errors.slice(0, 10).map((str: string) => (
            <AccountingValidationRow key={str}>
              <FaExclamationTriangle
                color={theme.colors.nocfoYellow}
                size={10}
                style={{ alignSelf: 'center', marginRight: 4 }}
              />
              <DescriptionWrapper>
                <DangerouslyRenderHTML>
                  {appendDocumentLinks(str, businessId)}
                </DangerouslyRenderHTML>
              </DescriptionWrapper>
            </AccountingValidationRow>
          ))}
          {errors.slice(10).length > 0 && (
            <MutedTextRow>
              {t('period.validation.otherErrors', { count: errors.slice(5).length })}
            </MutedTextRow>
          )}
        </PopoverContentWrapper>
      }
    >
      <div style={{ cursor: 'pointer' }}>{children}</div>
    </Popover>
  )

  return (
    <AccountingValidationRow>
      <AnimatePresence initial={false} mode="wait">
        <AnimatedIconWrapper
          key={[isLoading, ...errors].join('-')}
          variants={variants}
          animate="animate"
          exit="exit"
          initial="initial"
        >
          {isLoading ? <Spinner /> : isValid ? <ValidIcon /> : <InvalidIcon />}
        </AnimatedIconWrapper>
      </AnimatePresence>

      {isValid ? (
        <DescriptionWrapper>{validDescription}</DescriptionWrapper>
      ) : (
        <InvalidPopover>
          <DescriptionWrapper>
            {errorDescription}
            <FaInfoCircle />
          </DescriptionWrapper>
        </InvalidPopover>
      )}
    </AccountingValidationRow>
  )
}

interface Props {
  period: IPeriod
}

export const PeriodValidate: React.FC<Props> = ({ period }) => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const periodUrl = `/v1/business/${businessId}/period/${period.id}/`

  return (
    <ValidationWrapper>
      <CheckMarkRow
        validationEndpoint={periodUrl + 'validate/documents/'}
        validDescription={t('period.validation.validDocuments')}
        errorDescription={t('period.validation.invalidDocuments')}
      />
      <CheckMarkRow
        validationEndpoint={periodUrl + 'validate/document_attachments/'}
        validDescription={t('period.validation.validAttachments')}
        errorDescription={t('period.validation.invalidAttachments')}
      />
      <CheckMarkRow
        validationEndpoint={periodUrl + 'validate/vat_periods/'}
        validDescription={t('period.validation.validVAT')}
        errorDescription={t('period.validation.invalidVAT')}
      />
    </ValidationWrapper>
  )
}
