import { ItemRenderProps } from '@components/UniversalAPISelector'
import { useBusinessContext } from '@root/context'
import { fetchProduct, fetchProducts, IProduct } from '@root/query'
import { motion } from 'framer-motion'
import { Button, InputProps, ModalProps } from '@components'
import styled from 'styled-components'
import { FaCheck, FaPen } from 'react-icons/fa'

import { useTranslation } from 'react-i18next'
import { ProductForm } from '@pages/ProductPage/ProductForm.tsx'
import UniversalAPISelector from '@root/components/UniversalAPISelector/UniversalAPISelector'

interface Props extends InputProps {
  onChange: (productId: number) => void
  clearSelectedItem: () => void
}

export const ProductSelector: React.FC<Props> = ({ idForQuery, ...rest }) => {
  const { businessId } = useBusinessContext()
  const [t] = useTranslation()
  const formId = 'product-form'

  const dataToShow = (item: IProduct): string => {
    return `${item.name}`
  }

  const renderRow = (
    item,
    {
      isFocused,
      isSelected,
      onKeyDown,
      handleOnItemClick,
      handleOnEditClick,
      style,
      showEditItem
    }: ItemRenderProps<IProduct>
  ) => {
    return (
      <ItemWrapper
        className={isFocused && 'keyboard-focused'}
        onKeyDown={onKeyDown}
        onClick={() => handleOnItemClick(item)}
        style={style}
        key={item.id}
      >
        <Item key={`product-${item.id}`} title={item.name}>
          <ItemColumn>
            <div className="code">{item?.code || '-'}</div>
            <div className="name">{item.name}</div>
          </ItemColumn>
          <RightItemsWrapper>
            {showEditItem && (
              <EditButtonWrapper>
                <Button
                  isSecondary={true}
                  icon={<FaPen />}
                  onClick={() => handleOnEditClick(item)}
                ></Button>
              </EditButtonWrapper>
            )}
            {isSelected && (
              <ItemColumn className="check">
                <CheckMarkWrapper>
                  <FaCheck />
                </CheckMarkWrapper>
              </ItemColumn>
            )}
          </RightItemsWrapper>
        </Item>
      </ItemWrapper>
    )
  }

  const createModalProps: Partial<ModalProps> = {
    width: 600,
    height: 800
  }

  const renderCreateForm = (onCreate: (data: IProduct) => void, item: IProduct) => {
    return <ProductForm product={item} onCreate={onCreate} />
  }

  return (
    <UniversalAPISelector
      {...rest}
      fetchItem={id => fetchProduct({ businessId, productId: id })}
      fetchItems={({ page, search }) =>
        fetchProducts({ businessId }, { page, page_size: 200, search })
      }
      renderRow={renderRow}
      placeholder={t('invoicing.form.rows.selectProduct')}
      noDataMessage={t('invoicing.form.rows.noSearchResults')}
      modalHeaderNew={t('products.form.modalTitleNew')}
      modalHeaderUpdate={t('products.form.modalTitleEdit')}
      dropDownHeader={t('invoicing.form.rows.product')}
      label={t('invoicing.form.rows.product')}
      queryKey={`ProductUAS-${idForQuery}`}
      formId={formId}
      getDataToShow={dataToShow}
      renderCreateForm={renderCreateForm}
      renderUpdateForm={renderCreateForm}
      createModalProps={createModalProps}
      updateModalProps={createModalProps}
    />
  )
}

const Item = styled(motion.div).attrs({
  variants: {
    hidden: {
      y: 5,
      opacity: 0
    },
    show: {
      y: 0,
      opacity: 1
    }
  }
})`
  display: flex;
  width: 100%;
  padding: 0.4rem 0.6rem;

  cursor: pointer;
  height: 35px;
`

const EditButtonWrapper = styled.div`
  display: none;
  align-self: center;
`

const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: ${({ theme }) => theme.spacing.xs}rem;

  &:hover ${EditButtonWrapper} {
    display: block;
  }
  &.keyboard-focused,
  &:hover {
    background: ${({ theme }) => theme.colors.mainBg};
  }
`

const RightItemsWrapper = styled.div`
  margin-left: auto;
  display: flex;
`

const ItemColumn = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .name {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .code {
    font-size: ${({ theme }) => theme.fontSize.sm}rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.metalGray};
    font-variant-numeric: tabular-nums;
    min-width: 2rem;
    overflow: hidden;
  }

  &.id {
    min-width: 60px;
    font-size: ${({ theme }) => theme.fontSize.sm}rem;
    margin-right: ${({ theme }) => theme.spacing.sm}rem;
    color: ${({ theme }) => theme.colors.metalGray};
    font-variant-numeric: tabular-nums;
    font-family: 'Roboto Mono', monospace;
  }
`
const CheckMarkWrapper = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  margin: ${({ theme }) => theme.spacing.sm}rem;

  svg {
    fill: ${({ theme }) => theme.colors.nocfoGreen};
  }
`
