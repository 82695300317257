import { IFileMinimal } from '@root/query'
import { getFilesPageUrl } from '@root/constants'
import { ContentPill } from './ContentPill'
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa'
import { theme } from '@root/styles'
import { useTranslation } from 'react-i18next'
import { IAuditTrailObjectBase, IChangeDocument } from '@root/query/auditTrail'
import { useBusinessContext } from '@root/context'
import styled from 'styled-components'

interface IAttachmentBlockProps {
  auditRecord: IAuditTrailObjectBase<IChangeDocument>
}

export const AttachmentBlock: React.FC<IAttachmentBlockProps> = ({ auditRecord }) => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()

  const isAdded = auditRecord.changes?.[0]?.added.length !== 0

  const FileIcon = isAdded ? (
    <FaPlusCircle style={{ color: theme.colors.nocfoGreen }} />
  ) : (
    <FaMinusCircle style={{ color: theme.colors.nocfoRed }} />
  )

  const files = isAdded ? auditRecord.changes?.[0]?.added : auditRecord.changes?.[0]?.removed

  return (
    <AttachmentsContainer>
      {files.map((file: IFileMinimal, index: number) => {
        return file === null ? (
          <ContentPill
            key={`${index}-no-file-found`}
            Icon={FileIcon}
            text={t('components.auditTrail.noFileFound')}
            info={t('components.auditTrail.noFileFoundInfo')}
          />
        ) : (
          <ContentPill
            Icon={FileIcon}
            text={file.name}
            url={getFilesPageUrl(businessId, {
              id: file.id,
              idType: 'file',
              folder: file?.folder_id
            })}
            key={`content-pill-${index}`}
          />
        )
      })}
    </AttachmentsContainer>
  )
}

const AttachmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xxs}rem;
`
