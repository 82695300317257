import { useCurrentUser } from '@query'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const CANNY_APP_ID = '645b80d172468408fbd17c41'

export const useCannyIdentify = (): void => {
  const { data: currentUser } = useCurrentUser()

  useEffect(() => {
    if (!currentUser) return
    const _window = window as any
    _window.Canny?.('identify', {
      appID: CANNY_APP_ID,
      user: {
        email: `nocfo-user-${currentUser.id}@canny.nocfo.io`,
        name: `Anonymous`,
        id: currentUser.id
      }
    })
  }, [currentUser?.id])
}

export const CannyLink: React.FC<{ children: string }> = ({ children }) => {
  const { t } = useTranslation()
  useCannyIdentify()

  return <a href={t('link.canny')}>{children}</a>
}
