import { CSVImportWizard, FieldToMap } from '@containers/CSVImportWizard/base'
import { IInvoice, IInvoiceRow } from '@query'
import * as yup from 'yup'
import { createImportJob, createImportJobDryRun, ImportJobType } from '@query/importJob.ts'
import { useBusinessContext } from '@context'
import { Badge } from '@components'
import { VAT_CODE_OPTIONS } from '@constants'
import { useTranslation } from 'react-i18next'
import { formatDate, normalizeDateString } from '@utils'
import dayjs from 'dayjs'

type PrefixedInvoiceRows = {
  [K in keyof IInvoiceRow as `invoice_row_${string & K}`]: IInvoiceRow[K]
}

interface ICSVInvoice extends Omit<IInvoice, 'rows'>, PrefixedInvoiceRows {
  group_id?: string
  receiver_customer_id?: string
  auto_send: boolean
  auto_accept: boolean
}

interface Props {
  onComplete: () => void
}

const _transformDecimal = (_: any, v: string) => (v ? parseFloat(v.replace(',', '.')) : undefined)

export const InvoiceImportWizard: React.FC<Props> = ({ onComplete }) => {
  const { t } = useTranslation()
  const { businessId } = useBusinessContext()

  const fields: FieldToMap<keyof ICSVInvoice>[] = [
    {
      name: t('invoicing.form.groupId'),
      info: t('invoicing.form.groupIdInfo'),
      csvField: 'Group ID',
      apiField: 'group_id',
      fieldValidator: yup.string().optional(),
      valueValidator: yup.string().optional()
    },
    {
      name: t('invoicing.form.customerId'),
      info: t('invoicing.form.customerIdInfo'),
      required: true,
      csvField: 'Customer ID',
      apiField: 'receiver_customer_id',
      fieldValidator: yup.string().required(),
      valueValidator: yup.string().required()
    },
    {
      name: t('invoicing.form.deliveryMethod'),
      info: t('invoicing.form.deliveryMethodInfo'),
      csvField: 'Delivery Method',
      apiField: 'delivery_method',
      fieldValidator: yup.string().optional(),
      valueValidator: yup.string().optional(),
      renderExample: value => {
        return t(`invoicing.deliveryMethod.${value}`)
      }
    },
    {
      name: t('invoicing.form.invoiceDate'),
      info: t('invoicing.form.invoiceDateInfo'),
      csvField: 'Invoicing Date',
      apiField: 'invoicing_date',
      fieldValidator: yup.string().required(),
      valueValidator: yup
        .string()
        .required()
        .transform(value => {
          const [formatted] = normalizeDateString(value)
          return formatted
        })
        .test('is-valid-date', t('validation.invalid_date'), value =>
          dayjs(value, 'YYYY-MM-DD').isValid()
        ),
      renderExample: value => formatDate(value)
    },
    {
      name: t('invoicing.form.paymentCondition'),
      info: t('invoicing.form.paymentConditionInfo'),
      csvField: 'Payment Condition Days',
      apiField: 'payment_condition_days',
      fieldValidator: yup.string().required(),
      valueValidator: yup.number().integer().required()
    },
    {
      name: t('invoicing.form.penaltyInterest'),
      info: t('invoicing.form.penaltyInterestInfo'),
      csvField: 'Penalty Interest',
      apiField: 'penalty_interest',
      fieldValidator: yup.string().optional(),
      valueValidator: yup.number().transform(_transformDecimal).optional()
    },
    {
      name: t('invoicing.form.reference'),
      info: t('invoicing.form.referenceInfo'),
      csvField: 'Reference',
      apiField: 'reference',
      fieldValidator: yup.string().optional(),
      valueValidator: yup.string().trim().optional()
    },
    {
      name: t('invoicing.form.description'),
      info: t('invoicing.form.descriptionInfo'),
      csvField: 'Description',
      apiField: 'description',
      fieldValidator: yup.string().optional(),
      valueValidator: yup.string().optional()
    },
    {
      name: t('products.form.code'),
      info: t('products.form.codeInfo'),
      csvField: 'Product Code',
      apiField: 'invoice_row_code',
      fieldValidator: yup.string().optional(),
      valueValidator: yup.string().optional()
    },
    {
      name: t('invoicing.form.rows.product'),
      csvField: 'Product Name',
      apiField: 'invoice_row_name',
      fieldValidator: yup.string().required(),
      valueValidator: yup.string().required()
    },
    {
      name: t('invoicing.form.rows.unit'),
      csvField: 'Product Unit',
      apiField: 'invoice_row_unit',
      fieldValidator: yup.string().required(),
      valueValidator: yup.string().required()
    },
    {
      name: t('invoicing.form.rows.unitPrice'),
      csvField: 'Product Unit Price',
      apiField: 'invoice_row_amount',
      fieldValidator: yup.string().required(),
      valueValidator: yup.string().required()
    },
    {
      name: t('invoicing.form.rows.quantityWithoutUnit'),
      csvField: 'Product Quantity',
      apiField: 'invoice_row_product_count',
      fieldValidator: yup.string().required(),
      valueValidator: yup.string().required()
    },
    {
      name: t('products.form.vatCode'),
      csvField: 'Product VAT Code',
      apiField: 'invoice_row_vat_code',
      fieldValidator: yup.string().required(),
      valueValidator: yup.string().required(),
      renderExample: value => (
        <Badge>
          {t(VAT_CODE_OPTIONS.find(option => option.value === parseInt(value, 10))?.label)}
        </Badge>
      )
    },
    {
      name: t('products.form.vatRate'),
      csvField: 'Product VAT Rate',
      apiField: 'invoice_row_vat_rate',
      fieldValidator: yup.string().required(),
      valueValidator: yup.number().transform(_transformDecimal).required(),
      renderExample: value => `${value} %`
    },
    {
      name: t('invoicing.form.rows.rowDescription'),
      csvField: 'Row Description',
      apiField: 'invoice_row_description',
      fieldValidator: yup.string().optional(),
      valueValidator: yup.string().optional()
    },
    {
      name: t('invoicing.form.autoAccept'),
      csvField: 'Automatic Acceptance',
      apiField: 'auto_accept',
      fieldValidator: yup.string().optional(),
      valueValidator: yup.boolean().optional(),
      renderExample: value =>
        value ? (
          <Badge type="success">{t('general.yes')}</Badge>
        ) : (
          <Badge type="warning">{t('general.no')}</Badge>
        )
    },
    {
      name: t('invoicing.form.autoSend'),
      csvField: 'Automatic Sending',
      apiField: 'auto_send',
      fieldValidator: yup.string().optional(),
      valueValidator: yup.boolean().optional(),
      renderExample: value =>
        value ? (
          <Badge type="success">{t('general.yes')}</Badge>
        ) : (
          <Badge type="warning">{t('general.no')}</Badge>
        )
    }
  ]

  return (
    <CSVImportWizard
      fields={fields}
      onVerifyData={async data => {
        const { to_be_created: toBeCreated, to_be_updated: toBeUpdated } =
          await createImportJobDryRun({ businessId }, { type: ImportJobType.INVOICES, data })
        return { toBeCreated, toBeUpdated }
      }}
      onImportData={async data => {
        const result = await createImportJob({ businessId }, { type: ImportJobType.INVOICES, data })
        onComplete()
        return result
      }}
    />
  )
}
