export const isValidIBAN = (iban: string): boolean => {
  const ibanRegex = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/
  return ibanRegex.test(iban)
}

export const formatIBAN = (iban: string): string =>
  iban
    .replace(/\s/g, '')
    .match(/.{1,4}/g)
    .join(' ')

export const tryFormatIBAN = (iban: string): string => {
  if (isValidIBAN(iban)) {
    return formatIBAN(iban)
  }
  return iban
}
