import { TextTooltip } from '@components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaMagic } from 'react-icons/fa'
import styled from 'styled-components'

export const DraftBadge: React.FC = () => {
  const { t } = useTranslation()
  return (
    <TextTooltip tooltip={t('document.draftBadge.tooltip')} placement="bottom-start">
      <StyledBadge>
        <FaMagic />
        <span>{t('document.draftBadge.text')}</span>
      </StyledBadge>
    </TextTooltip>
  )
}

const StyledBadge = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xxs}rem;
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.nocfoPurple};
  background: ${({ theme }) => theme.colors.nocfoPurple}22;
  padding: 3px ${({ theme }) => theme.spacing.xs}rem;
  border-radius: 0.4rem;

  svg {
    width: ${({ theme }) => theme.fontSize.xs}rem;
    height: ${({ theme }) => theme.fontSize.xs}rem;
  }
`
