import { useBusinessContext } from '@context'
import { fetchHolviAuthorizeUrl } from '@query/holvi'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { Button } from '@components'
import imgSrc from '@assets/Holvi_Symbol.png'
import { getBankIntegrationPageURL } from '@constants'
import { getAbsoluteUrl } from '@utils/getAbsoluteUrl'

export const HolviAuthorizeButton: React.FC = () => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()

  const { data, isLoading } = useQuery([businessId, 'holvi', 'authorize'], () =>
    fetchHolviAuthorizeUrl({
      businessId,
      redirectUrl: getAbsoluteUrl(getBankIntegrationPageURL(businessId, 'holvi-finalize'))
    })
  )

  return (
    <a href={data?.url}>
      <Button showSpinner={isLoading} icon={<img src={imgSrc} />} style={{ background: '#bbe5ee' }}>
        {t('components.holviAuthorizeButton.authorize')}
      </Button>
    </a>
  )
}
