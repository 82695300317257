import { Button, HeaderBlock, MonetaryAmount, Prompt } from '@components'
import { UserRole, VAT_CODES_IGNORE_VAT_IN_SALES, VAT_CODE_OPTIONS } from '@constants'
import { PermissionBoundary, useBusinessContext } from '@context'
import { collectFromPages, deleteProduct, fetchProduct, IProduct } from '@query'
import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaPen, FaTrash } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { ProductFormModal } from './ProductFormModal'

interface Props {
  productId: number
  onClose: () => void
}

export const ProductDetails: React.FC<Props> = ({ productId, onClose }) => {
  const queryClient = useQueryClient()
  const { businessId } = useBusinessContext()
  const [t] = useTranslation()
  const [showDelete, setShowDelete] = useState(false)
  const [showEdit, setShowEdit] = useState(false)

  const { data } = useQuery(
    [businessId, 'products', productId],
    () => fetchProduct({ businessId, productId }),
    {
      enabled: !showDelete,
      initialData: () => {
        const data = queryClient.getQueryData([businessId, 'products'], { exact: false })
        if (data) {
          const products = collectFromPages<IProduct>(data as any)
          return products.find(c => c.id === productId)
        }
      }
    }
  )

  const { mutateAsync: deleteMutation, isLoading: isDeleting } = useMutation(
    () => deleteProduct({ businessId, productId }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([businessId, 'products'])
        onClose()
        setShowDelete(false)
      }
    }
  )

  const vatOption = VAT_CODE_OPTIONS.find(option => option.value === data?.vat_code)
  const hideVatPercentage = VAT_CODES_IGNORE_VAT_IN_SALES.indexOf(data?.vat_code) !== -1

  return (
    <StyledProductDetails>
      <HeaderBlock
        header={t('products.form.header')}
        subHeader={data?.name}
        menuItems={[
          {
            Icon: FaTrash,
            name: t('products.delete'),
            intent: 'danger',
            onClick: () => setShowDelete(true)
          }
        ]}
      />

      <ScrollableContent>
        <DetailRow>
          <StyledHeader>{t('products.form.code')}</StyledHeader>
          <StyledValue>{data?.code || '-'}</StyledValue>
        </DetailRow>

        <DetailRow>
          <StyledHeader>{t('products.form.name')}</StyledHeader>
          <StyledValue>{data?.name}</StyledValue>
        </DetailRow>

        <DetailRow>
          <StyledHeader>{t('products.form.amountExclVat')}</StyledHeader>
          <StyledValue>
            <MonetaryAmount
              value={data?.vat_exclusive_amount}
              precision={{
                maximumFractionDigits: 3,
                minimumFractionDigits: 2
              }}
            />
          </StyledValue>
        </DetailRow>

        <DetailRow>
          <StyledHeader>{t('products.form.vatAmount')}</StyledHeader>
          <StyledValue>
            <MonetaryAmount
              value={data?.vat_amount}
              precision={{
                maximumFractionDigits: 3,
                minimumFractionDigits: 2
              }}
            />
          </StyledValue>
        </DetailRow>

        <DetailRow>
          <StyledHeader>{t('products.form.totalAmount')}</StyledHeader>
          <StyledValue>
            <MonetaryAmount
              value={data?.vat_inclusive_amount}
              precision={{
                maximumFractionDigits: 3,
                minimumFractionDigits: 2
              }}
            />
          </StyledValue>
        </DetailRow>

        <DetailRow>
          <StyledHeader>{t('products.form.vatCode')}</StyledHeader>
          <StyledValue>{t(vatOption?.label)}</StyledValue>
        </DetailRow>

        {!hideVatPercentage && (
          <DetailRow>
            <StyledHeader>{t('products.form.vatRate')}</StyledHeader>
            <StyledValue>{data?.vat_rate}%</StyledValue>
          </DetailRow>
        )}
      </ScrollableContent>

      <StyledFooter>
        <PermissionBoundary requireRole={UserRole.EDITOR}>
          {data && (
            <Button icon={<FaPen />} onClick={() => setShowEdit(true)}>
              {t('general.edit')}
            </Button>
          )}
        </PermissionBoundary>
      </StyledFooter>

      <ProductFormModal
        isVisible={showEdit}
        handleOnClose={() => setShowEdit(false)}
        product={data}
      />

      <Prompt
        isVisible={showDelete}
        title={t('products.deleteTitle')}
        description={t('products.deleteDescription')}
        buttons={[
          {
            text: t('general.delete'),
            icon: <FaTrash />,
            intent: 'danger',
            action: () => deleteMutation(),
            showSpinner: isDeleting,
            disabled: isDeleting
          }
        ]}
        onClose={() => setShowDelete(false)}
      />
    </StyledProductDetails>
  )
}

const StyledProductDetails = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.neutralWhite};
  display: flex;
  flex-direction: column;
`

const DetailRow = styled(motion.div).attrs({ layout: 'position' })`
  flex: 1;
  margin-bottom: ${({ theme }) => theme.spacing.sm}rem;
`

const StyledHeader = styled.p`
  color: ${({ theme }) => theme.colors.metalGray};
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  margin-bottom: ${({ theme }) => theme.spacing.xxs}rem;
  align-self: flex-start;
`

const StyledValue = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  color: ${({ theme }) => theme.colors.neutralBlack};
  margin: 0;
  display: flex;
  align-items: center;

  svg {
    width: ${({ theme }) => theme.iconSize.xs}rem;
    height: ${({ theme }) => theme.iconSize.xs}rem;
    margin: 0;
    padding: 0;
  }
`

const ScrollableContent = styled.div`
  flex: 1;
  overflow: auto;
  padding: ${({ theme }) => theme.spacing.md}rem;
`

const StyledFooter = styled.div`
  padding: ${({ theme }) => theme.spacing.md}rem;
`
