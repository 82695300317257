import { FileThumbnail } from '@components'
import React from 'react'
import styled from 'styled-components'

interface Props {
  filename: string
  filetype: string
  thumb?: string
  blurhash?: string
  size?: number
}

export const FileIcon: React.FC<Props> = ({ filename, filetype, thumb, size = 40, blurhash }) => {
  return (
    <FileIconWrapper size={size}>
      <FileThumbnail filetype={filetype} thumb={thumb} size={size} blurhash={blurhash} />
      <FileDetails>
        <FileName>{filename}</FileName>
      </FileDetails>
    </FileIconWrapper>
  )
}

const FileIconWrapper = styled.div<{ size: number }>`
  display: flex;
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing.sm}rem;

  & > * {
    align-self: center;
  }
`

const FileName = styled.div`
  margin-left: ${({ theme }) => theme.spacing.sm}rem;
  overflow: hidden;
  white-space: pre-line;
  word-break: break-word;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.neutralBlack};
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
`

const FileDetails = styled.div`
  flex: 1;
  overflow: hidden;
`
