import { ReportType } from '@constants'
import { theme } from '@styles'
import { openLinkInTab } from '@utils'
import toast from 'react-hot-toast'
import { Trans } from 'react-i18next'
import { network } from './network'

type GenerateReportArgs = {
  business_id: number | string
  type: ReportType
  columns: ReportColumn[]
  extend_accounts?: boolean
}

type ReportColumnDateAt = {
  name?: string
  date_at: string
}

type ReportColumnDateRange = {
  name?: string
  date_from: string
  date_to: string
}

export type ReportColumn = ReportColumnDateRange | ReportColumnDateAt

export const generateReport = (data: GenerateReportArgs): Promise<void> => {
  const reportUrl = `/v1/report/${data.business_id}/download`

  const promise = network.httpClient
    .post(
      reportUrl,
      data,
      { timeout: 120000 } // Two mins
    )
    .then(({ data }) => {
      openLinkInTab(data.report_url)
    })

  toast.promise(
    promise,
    {
      loading: <Trans i18nKey="reports.toastLoading" />,
      success: <Trans i18nKey="reports.toastSuccess" />,
      error: <Trans i18nKey="reports.toastError" />
    },
    {
      style: {
        borderRadius: '1rem',
        background: theme.colors.menuBg,
        color: theme.colors.neutralWhite
      }
    }
  )

  return promise
}
