import { IAccount } from '@query'
import { useMemo } from 'react'

interface IHeader {
  path: string[]
  accounts: IAccount[]
}

const generateAccountStruct = (accounts: IAccount[]): IHeader[] => {
  const headers: IHeader[] = []

  let oldPath = ''
  accounts.forEach(account => {
    const newPath = JSON.stringify(account.header_path)
    if (newPath !== oldPath) {
      headers.push({
        path: account.header_path,
        accounts: []
      })
    }
    headers[headers.length - 1].accounts.push(account)
    oldPath = newPath
  })

  return headers
}

export const useAccountStruct = (accounts: IAccount[]): IHeader[] => {
  return useMemo(() => generateAccountStruct(accounts), [JSON.stringify(accounts)])
}
