import { useLocalStorage } from '@hooks'
import { useCallback, useEffect } from 'react'
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form'

interface PropsIn<T> {
  cacheKey: string
  getValues: UseFormGetValues<T>
  setValue: UseFormSetValue<T>
  enabled?: boolean
}

interface PropsOut {
  resetCache: () => void
}

export const useCacheFormValues = <T>({
  cacheKey,
  getValues,
  setValue,
  enabled = true
}: PropsIn<T>): PropsOut => {
  const [cachedValue, setCachedValue] = useLocalStorage<T>(cacheKey, getValues())

  const onReMount = useCallback(() => {
    if (cachedValue) {
      Object.entries(cachedValue).forEach(([key, value]) => {
        setValue(key as any, value as any)
      })
      console.log(`FORM ${cacheKey} RESUMED VALUES: `, cachedValue)
    }
  }, [cachedValue])

  const onUnMount = useCallback(() => {
    const values = getValues()
    setCachedValue(values)
    console.log(`FORM ${cacheKey} CACHED VALUES: `, values)
  }, [])

  useEffect(() => {
    if (enabled === true) onReMount()
    return () => {
      if (enabled === true) onUnMount()
    }
  }, [cacheKey])

  const resetCache = useCallback(() => {
    setCachedValue(null)
    console.log(`FORM ${cacheKey} CACHE CLEARED`)
  }, [cacheKey])

  return {
    resetCache
  }
}
