import { network } from '@utils'
import { QueryFunction } from '../root.ts'

export interface IBasicBusinessInfoFromPrh {
  business_name: string
  business_id: string
}

export interface IPrhBusinessResults {
  results: Array<IBasicBusinessInfoFromPrh>
}

export interface IPrhSearchResults {
  business_name: string
  business_id: string
  address?: {
    street?: string
    post_code?: string
    city?: string
    country?: string
  }
}

interface IPhrNameSearchProps {
  businessName: string
}

export const fetchPrhNameSearch: QueryFunction<IPhrNameSearchProps, IPrhBusinessResults> = async ({
  businessName
}) => {
  const url = `v1/integrations/prh/search/name/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params: { business_name: businessName }
  })
  return data
}

interface IPrhSearchProps {
  businessId: string
}

export const fetchPrhSearch: QueryFunction<IPrhSearchProps, IPrhSearchResults> = async ({
  businessId
}) => {
  const url = `v1/integrations/prh/search/id/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params: { business_id: businessId }
  })
  return data
}
