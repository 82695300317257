import { motion } from 'framer-motion'
import styled from 'styled-components'

export const CSATWrapper = styled(motion.div).attrs({ layout: true })`
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  display: flex;
`

export const CSATHeader = styled(motion.h1).attrs({ layout: 'position' })`
  margin: 0;
  font-size: ${({ theme }) => theme.spacing.md}rem;
`

export const CSATButtonWrapper = styled(motion.div).attrs({ layout: 'position' })`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-self: center;
`

export const CSATFooter = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  layout: true
})`
  text-align: center;
  color: ${({ theme }) => theme.colors.metalGray};
  position: relative;

  textarea {
    padding: ${({ theme }) => theme.spacing.md}rem;
    background: transparent;
    border: none;
    width: 100%;
    max-height: 200px;
  }
`

export const CSATButton = styled(motion.button)`
  outline: none !important;
  border: none;
  background: transparent;
  text-align: center;
  align-content: center;
  padding: 0;
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.md}rem;

  & > * {
    align-self: center;
  }

  &:selected {
    background: red;
  }
`

export const CSATButtonIcon = styled(motion.div).attrs({
  layout: 'position'
})`
  font-size: ${({ theme }) => theme.spacing.lg}rem;
`

export const CSATSuccess = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 }
})`
  padding: ${({ theme }) => theme.spacing.md}rem;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  background: #ffffffaa;

  & > * {
    align-self: center;
  }
`

export const CSATSuccessText = styled.div`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  margin-left: ${({ theme }) => theme.spacing.md}rem;
`

export const CSATSuccessAgain = styled.p`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.nocfoBlue};

  &:hover {
    text-decoration: underline;
  }
`
