import { useOnClickOutside } from '@hooks'
import { callAll, isFunction } from '@utils'
import cn from 'classnames'
import { AnimatePresence } from 'framer-motion'
import React, {
  cloneElement,
  createContext,
  isValidElement,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react'
import { ItemRow, MenuContainer, MenuItem, MenuWrapper } from './PopupMenu.styled'

interface PopupMenuContext {
  visible: boolean
  toggleVisibility: React.Dispatch<React.SetStateAction<boolean>>
}

interface PopupMenuProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {
  initialVisibility?: boolean
  children: React.ReactNode | ((ctx: PopupMenuContext) => React.ReactNode)
}

interface PopupMenuItemProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: React.ReactNode
  title: string
  onClick?: () => void
  leftContent?: React.ReactNode | (() => React.ReactNode)
  disabled?: boolean
}

const PopupMenuCtx = createContext<PopupMenuContext>(undefined)

export const usePopupMenu = (): PopupMenuContext => {
  const store = useContext(PopupMenuCtx)
  if (!store) throw new Error('Cannot use popup menu context outside of provider')
  return store
}

export const PopupMenuContainer: React.FC<PopupMenuProps> = ({
  initialVisibility = false,
  children,
  ...rest
}) => {
  const [visible, toggleVisibility] = useState(initialVisibility)
  const menuRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(menuRef, () => toggleVisibility(false))
  const contextValue = useMemo(() => ({ visible, toggleVisibility }), [visible, toggleVisibility])

  if (isFunction(children)) {
    return (
      <MenuWrapper ref={menuRef} {...rest}>
        <PopupMenuCtx.Provider value={contextValue}>{children(contextValue)}</PopupMenuCtx.Provider>
      </MenuWrapper>
    )
  }
  return (
    <MenuWrapper ref={menuRef} role="menu" {...rest}>
      <PopupMenuCtx.Provider value={contextValue}>{children}</PopupMenuCtx.Provider>
    </MenuWrapper>
  )
}

export const PopupMenuButton: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { toggleVisibility, visible } = usePopupMenu()
  if (!isValidElement(children)) {
    throw new Error('PopupMenuButton requires a valid child element')
  }

  return cloneElement<any>(children, {
    ['aria-haspopup']: 'menu',
    onClick: callAll(() => {
      toggleVisibility(!visible)
    }, children.props.onClick)
  })
}

export const PopupMenu: React.FC<{ menuGrow?: 'right' | 'left'; children: React.ReactNode }> = ({
  children,
  menuGrow = 'right'
}) => {
  const { visible } = usePopupMenu()

  return (
    <AnimatePresence>
      {visible && (
        <MenuContainer grow={menuGrow} initial="hidden" animate="open" exit="hidden">
          {children}
        </MenuContainer>
      )}
    </AnimatePresence>
  )
}

export const PopupMenuItem: React.FC<PopupMenuItemProps> = ({
  title,
  icon = null,
  leftContent = null,
  disabled,
  children,
  ...rest
}) => {
  const { visible } = usePopupMenu()

  return (
    <MenuItem
      role="menuitem"
      className={cn({ disabled })}
      tabIndex={visible ? 0 : -1}
      data-test="menu-item-dropdown-button"
      {...(rest as any)}
    >
      <ItemRow>
        <span className="icon">{icon}</span>
        <span style={{ flexGrow: 1 }}>{title}</span>
        {isFunction(leftContent) ? leftContent() : leftContent}
      </ItemRow>
      {children}
    </MenuItem>
  )
}
