import styled from 'styled-components'

export const StyledDateRangeSelect = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing.xs}rem;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  border-radius: 1rem;
  align-items: center;

  transition: 0.2s;
  cursor: pointer;

  color: ${({ theme }) => theme.colors.neutralGray}99;
  border: 2px solid ${({ theme }) => theme.colors.neutralGray}22;

  &.isActive {
    color: ${({ theme }) => theme.colors.neutralGray};
    border: 2px solid ${({ theme }) => theme.colors.nocfoBlue};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.neutralGray};
    border: 2px solid ${({ theme }) => theme.colors.nocfoBlue};
    background: #eeeeee10;
  }
`

export const StyledFilterLabel = styled.div`
  flex: 1;

  span {
    font-weight: initial;
    font-size: ${({ theme }) => theme.fontSize.xs}rem;
  }
`

export const StyledDropdown = styled.div``

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  border-radius: 100rem;

  &.isActive {
    background: ${({ theme }) => theme.colors.nocfoBlue}44;

    svg {
      color: ${({ theme }) => theme.colors.nocfoBlue};
    }
  }
`

export const StyledClearIconWrapper = styled.div`
  padding: 0.4rem;
`
