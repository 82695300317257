import { useScreen } from '@utils'
import { ArrowLeftIcon, Heading, IconButton, Pane, Position, SideSheet } from 'evergreen-ui'
import React from 'react'

interface BasicSideSheetProps {
  heading?: string
  isShown: boolean
  onClose?: () => void
  size?: 'sm' | 'md' | 'lg' | 'xl'
  position?: Position
  footerContent?: ({ close }: { close: () => void }) => React.FC | React.ReactNode
  children: React.ReactNode
}

export const BasicSideSheet: React.FC<BasicSideSheetProps> = ({
  children,
  isShown,
  heading,
  onClose,
  size = 'md',
  position = Position.RIGHT,
  footerContent
}) => {
  const screen = useScreen()

  let width
  if (size === 'sm') width = screen.sm ? '100vw' : screen.breakPoints.xs * 0.6
  if (size === 'md') width = screen.sm ? '100vw' : screen.breakPoints.md * 0.6
  if (size === 'lg') width = screen.sm ? '100vw' : screen.breakPoints.lg * 0.6
  if (size === 'xl') width = screen.sm ? '100vw' : screen.breakPoints.xl * 0.6

  return (
    <SideSheet
      isShown={isShown}
      onCloseComplete={onClose}
      width={width}
      position={position as any}
      shouldCloseOnOverlayClick={true}
      preventBodyScrolling={true}
    >
      {({ close }: any) => {
        return (
          <Pane flex="1" display="flex" height="100%" flexDirection="column">
            <Pane padding={8} background="white" borderBottom display="flex">
              <IconButton
                type="button"
                width={48}
                icon={ArrowLeftIcon}
                height={48}
                appearance="minimal"
                onClick={close}
              />
              <Heading size={500} flex="1" alignSelf="center" textAlign="center">
                {heading}
              </Heading>
              <Pane width={48}></Pane>
            </Pane>
            <Pane flex="1" background="tint1" display="flex">
              {children}
            </Pane>

            {footerContent && (
              <Pane padding={16} background="white" minHeight={64} borderTop display="flex">
                {footerContent({ close }) as any}
              </Pane>
            )}
          </Pane>
        )
      }}
    </SideSheet>
  )
}
