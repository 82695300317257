import { useEffect, useState } from 'react'

interface PropsIn<T, I> {
  value: T
  formatter: (value: T) => I
  validator: (value: T) => boolean
}

export const useValidatedValue = <T, I>({ value, formatter, validator }: PropsIn<T, I>): I => {
  /* Set only valid values to state. Invalid values are
   * ignored so that last valid value is always in state.
   */
  const [state, setState] = useState<I>()
  useEffect(() => setState(prevState => (validator(value) ? formatter(value) : prevState)), [value])
  return state
}
