import { BusinessContext } from '@context'
import { fetchFeatures, IFeatures } from '@query'
import React, { useContext } from 'react'
import { useQuery } from 'react-query'

const FeatureContext = React.createContext<Partial<IFeatures>>({
  vat_obliged: false,
  has_history: false,
  is_ny: false
})

export const FeatureContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { businessId } = useContext(BusinessContext)
  const { data } = useQuery([businessId, 'features'], () => fetchFeatures({ businessId }))

  return <FeatureContext.Provider value={data || {}}>{children}</FeatureContext.Provider>
}

interface FeatureWrapperProps {
  feature: keyof IFeatures
  reverse?: boolean
  alternativeContent?: () => React.ReactNode
  children: React.ReactNode
}

export const useFeature = (key: keyof IFeatures, default_value = undefined): boolean => {
  const context = useContext(FeatureContext)
  return context ? context[key] : default_value
}

export const FeatureWrapper: React.FC<FeatureWrapperProps> = ({
  feature,
  reverse = false,
  children,
  alternativeContent = () => null
}) => {
  let is_enabled = useFeature(feature)
  if (is_enabled === undefined) return null
  if (reverse) is_enabled = !is_enabled
  return is_enabled ? <>{children}</> : alternativeContent()
}

export const useFeatureContext = (): Partial<IFeatures> => {
  return useContext(FeatureContext)
}
