import { Button } from '@components/Button/Button.tsx'
import { theme } from '@root/styles'
import { motion } from 'framer-motion'
import styled from 'styled-components'

export const StyledContainer = styled(motion.div).attrs({
  variants: {
    hide: {
      opacity: 0,
      scale: 0.8,
      y: -25,
      x: 0,
      transition: {
        duration: 0.4,
        staggerChildren: 0.0,
        staggerDirection: -1,
        type: 'spring'
      }
    },
    show: {
      opacity: 1,
      scale: 1.0,
      y: 0,
      x: 0,
      transition: {
        duration: 0.4,
        staggerChildren: 0.0,
        staggerDirection: 1,
        type: 'spring'
      }
    }
  },
  initial: 'hide',
  animate: 'show',
  exit: 'hide'
})`
  background-color: ${({ theme }) => theme.colors.neutralWhite};
  padding: ${({ theme }) => theme.spacing.md}rem;
  border-radius: 1rem;

  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  overflow-x: hidden;
`

export const StyledHeader = styled.h4`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.metalGray};
  margin-bottom: 0;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: row;

  gap: ${({ theme }) => theme.spacing.sm}rem;

  margin-top: auto;

  padding-top: ${({ theme }) => theme.spacing.xs}rem;
  align-self: flex-end;
`

export const FilterBadge = styled(motion.button).attrs({
  variants: {
    active: {
      color: theme.colors.neutralWhite,
      background: theme.colors.nocfoBlue
    },
    default: {
      color: theme.colors.metalGray,
      background: theme.colors.neutralGray
    }
  }
})`
  all: unset;
  position: relative;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.xxs}rem ${({ theme }) => theme.spacing.xs}rem;
  border-radius: 0.5rem;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  font-weight: 500;
  user-select: none;
  white-space: nowrap;
`

export const FilterBadgeContainer = styled.div`
  flex: 1;
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spacing.sm}rem;
`

export const FilterSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.sm}rem;
`

export const FilterBadgeText = styled.div``

export const ActiveFilters = styled.div`
  background: ${({ theme }) => theme.colors.nocfoYellow}30;
  color: ${({ theme }) => theme.colors.nocfoYellow};

  border-radius: 1rem;

  padding-top: ${({ theme }) => theme.spacing.xxxs}rem;
  padding-bottom: ${({ theme }) => theme.spacing.xxxs}rem;
  padding-right: ${({ theme }) => theme.spacing.sm}rem;
  padding-left: ${({ theme }) => theme.spacing.sm}rem;
`

export const FilterButton = styled(Button)<{ active: boolean }>`
  color: ${({ theme, active }) => (active ? theme.colors.nocfoYellow : theme.colors.metalGray)};
  padding: ${({ theme }) => theme.spacing.xs}rem ${({ theme }) => theme.spacing.md}rem;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
