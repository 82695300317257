import styled from 'styled-components'

import { theme } from '@root/styles'
import { motion } from 'framer-motion'
import { FaMask, FaCalculator } from 'react-icons/fa'
import { FaClockRotateLeft, FaRotate } from 'react-icons/fa6'
import { useBusinessContext } from '@root/context'
import { DashboardAlertTypes, IDashboardAlert, DashboardAlertIntents } from '@root/query'
import { getInvoicingPageUrl, getSettingsPageUrl, getVATPageUrl } from '@root/constants'
import { Link } from 'react-router-dom'

const variantToColor = {
  [DashboardAlertIntents.ERROR]: theme.colors.nocfoRed,
  [DashboardAlertIntents.SUGGESTION]: theme.colors.nocfoPurple,
  [DashboardAlertIntents.INFO]: theme.colors.nocfoBlue,
  [DashboardAlertIntents.WARNING]: theme.colors.nocfoYellow,
  [DashboardAlertIntents.SUCCESS]: theme.colors.nocfoGreen
}

const typeToIcon = {
  [DashboardAlertTypes.BANK_INTEGRATION_CREATION]: <FaRotate />,
  [DashboardAlertTypes.BANK_INTEGRATION_RENEWAL]: <FaClockRotateLeft />,
  [DashboardAlertTypes.VAT_PERIOD_REPORTING_PENDING]: <FaMask />,
  [DashboardAlertTypes.UNPAID_PURCHASE_INVOICES]: <FaCalculator />,
  [DashboardAlertTypes.UNPAID_SALES_INVOICES]: <FaCalculator />
}

export interface IAlertCard {
  alert: IDashboardAlert
}

const AlertCard: React.FC<IAlertCard> = ({
  alert: { title, message, intent, type, link, metadata }
}) => {
  const color = variantToColor[intent] || theme.colors.nocfoBlue
  const icon = typeToIcon[type] || <FaMask />
  const { businessId } = useBusinessContext()

  const getLinkURL = () => {
    if (link !== undefined && link !== null) {
      return link
    }

    let linkToRedirect = undefined

    switch (type) {
      case DashboardAlertTypes.BANK_INTEGRATION_CREATION:
        linkToRedirect = getSettingsPageUrl(businessId, { page: 'integrations' })
        break
      case DashboardAlertTypes.VAT_PERIOD_REPORTING_PENDING:
        linkToRedirect = getVATPageUrl(businessId, { id: metadata?.vat_period_id })
        break
      case DashboardAlertTypes.BANK_INTEGRATION_RENEWAL:
        linkToRedirect = getSettingsPageUrl(businessId, { page: 'integrations' })
        break
      case DashboardAlertTypes.UNPAID_SALES_INVOICES:
        linkToRedirect = getInvoicingPageUrl(businessId)
        break
      case DashboardAlertTypes.UNPAID_PURCHASE_INVOICES:
        linkToRedirect = getInvoicingPageUrl(businessId)
        break
      default:
        break
    }

    if (linkToRedirect !== undefined) return linkToRedirect

    return `/${businessId}`
  }

  return (
    <Link to={getLinkURL()} style={{ textDecoration: 'none' }}>
      <CardOuterWrapper tabIndex={-1}>
        <CardInnerWrapper color={color}>
          <HeaderWrapper>
            <IconWrapper color={color}>{icon}</IconWrapper>
            <Header color={color}>{title}</Header>
          </HeaderWrapper>
          <Content color={color}>{message}</Content>
        </CardInnerWrapper>
      </CardOuterWrapper>
    </Link>
  )
}

export default AlertCard

function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min
}

const CardOuterWrapper = styled(motion.div).attrs({
  variants: {
    hide: {
      opacity: 0,
      y: -50,
      height: 0,
      transition: {
        duration: 0.3,
        staggerChildren: 0.06,
        staggerDirection: -1,
        type: 'tween'
      }
    },
    show: {
      opacity: 1,
      y: 0,
      height: 'auto',
      transition: {
        duration: 0.3,
        staggerChildren: 0.06,
        staggerDirection: 1,
        type: 'tween'
      }
    },
    whileHover: {
      scale: 0.95,
      transition: { type: 'spring', stiffness: 400, damping: 14, duration: 1 }
    },
    whileTap: {
      scale: 0.98,
      transition: {
        type: 'spring',
        stiffness: 400,
        damping: 14,
        duration: 1
      }
    },
    shake: {
      rotate: [0, 3, -3, 3, -3, 2, -2, 1, -1, 0],
      transition: {
        type: 'spring',
        stiffness: 150,
        delay: getRandomNumber(30, 33),
        duration: 2,
        repeat: 100,
        repeatDelay: getRandomNumber(30, 33),
        repeatType: 'loop'
      }
    }
  },
  initial: 'hide',
  animate: ['show', 'shake'],
  exit: 'hide',
  whileHover: 'whileHover',
  whileTap: 'whileTap',
  shake: 'shake'
})`
  background-color: ${({ theme }) => theme.colors.neutralWhite};
  width: fit-content;
  border-radius: 1rem;
`

const CardInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 17rem;
  padding: ${({ theme }) => theme.spacing.md}rem;

  gap: ${({ theme }) => theme.spacing.xs}rem;

  border-radius: 1rem;

  min-width: 300px;
  min-height: 160px;

  background-color: ${props => props.color}44;

  @media only screen and (max-width: 768px) {
    min-width: 250px;
    min-height: 150px;
  }
`

const IconWrapper = styled.div`
  & > * {
    width: ${({ theme }) => theme.iconSize.sm}rem;
    height: ${({ theme }) => theme.iconSize.sm}rem;
    color: ${props => props.color};
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  gap: 1rem;
  min-height: 37px;
`

const Header = styled.h4`
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  margin: 0;
  color: ${props => props.color};
  font-weight: 600;
`

const Content = styled.p`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  color: ${({ theme }) => theme.colors.blue};
`
