import { motion } from 'framer-motion'
import React, { useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

interface Props {
  particle: React.ReactElement[] | string[]
  count?: number
  children: React.ReactNode
}

interface Particle {
  x: number[]
  y: number[]
  scale: number[]
  rotate: number[]
  particle: string
}

const PARTICLE_SIZE = 24 // px
const ANIMATION_DURATION = 1000 // ms

const randomChoice = choices => {
  const index = Math.floor(Math.random() * choices.length)
  return choices[index]
}

export const Particles: React.FC<Props> = ({ particle, count = 20, children }) => {
  const anchorRef = useRef()
  const rootRef = useRef<HTMLElement>(document.getElementById('particle-root'))
  const [particles, setParticles] = useState<Particle[]>([])

  const appendParticles = () => {
    const createNewParticle = () => {
      if (!anchorRef.current) return

      // Calculate particle position relative to window
      const { top, left, width, height } = (anchorRef as any).current.getBoundingClientRect()
      const x = Math.round(left + width / 2 - PARTICLE_SIZE / 2)
      const y = Math.round(top + height / 2 - PARTICLE_SIZE / 2 - window.innerHeight)
      const angle = Math.random() * Math.PI * 2

      // Create new particle with random animation props
      const key = Date.now()
      const newParticle = {
        x: [x, x + Math.sin(angle) * Math.random() * 120],
        y: [y, y + Math.cos(angle) * Math.random() * 120],
        scale: [1.4, 0.5],
        rotate: [Math.random() * 360 - 180, Math.random() * 360 - 180],
        particle: randomChoice(particle)
      }

      // Append particle into particles
      setParticles(particles => ({ ...particles, [key]: newParticle }))

      // Clear particle when animation is finished
      setTimeout(() => {
        setParticles(particles => {
          const newParticles = { ...particles }
          delete newParticles[key]
          return newParticles
        })
      }, ANIMATION_DURATION)
    }

    createNewParticle()
    const interval = setInterval(() => createNewParticle(), ANIMATION_DURATION / count / 2)
    setTimeout(() => clearInterval(interval), ANIMATION_DURATION / 2)
  }

  return (
    <>
      <div ref={anchorRef} onClick={() => appendParticles()}>
        {children}
      </div>
      {createPortal(
        Object.entries(particles).map(([key, p]) => (
          <Particle
            key={`particle-${key}`}
            animate={{
              x: p.x,
              y: p.y,
              rotate: p.rotate,
              scale: p.scale,
              opacity: [0, 1, 1, 0]
            }}
            style={{ originX: 0.5, originY: 0.5 }}
            transition={{ duration: ANIMATION_DURATION / 1000, type: 'tween' }}
          >
            {p.particle}
          </Particle>
        )),
        rootRef.current
      )}
    </>
  )
}

export const Particle = styled(motion.span)`
  position: fixed;
  z-index: 10000;
  user-select: none;
  pointer-events: none;
  width: ${PARTICLE_SIZE}px;
  height: ${PARTICLE_SIZE}px;

  font-size: ${PARTICLE_SIZE}px;
  text-align: center;
  line-height: ${PARTICLE_SIZE}px;
`
