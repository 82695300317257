import { motion } from 'framer-motion'
import styled from 'styled-components'

export const ValidationWrapper = styled.div`
  padding: 0.6rem;
  background: ${({ theme }) => theme.colors.neutralGray};
  border-radius: 1rem;
  margin-bottom: 8px;
`

export const AccountingValidationRow = styled.div`
  display: flex;
`

export const AnimatedIconWrapper = styled(motion.div)`
  padding: 4px;
  align-self: center;
  margin-right: 4px;
  & > svg,
  & > div {
    width: 14px;
    height: 14px;
    align-self: center;
  }
`

export const DescriptionWrapper = styled.div`
  padding: 4px;
  align-self: center;
  display: flex;
  & > svg {
    width: 12px;
    height: 12px;
    align-self: center;
    margin: 4px;
  }
`

export const PopoverContentWrapper = styled.div`
  padding: 1rem;
`

export const PopoverHeader = styled.h5`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.metalGray};
  margin: 0;
`

export const MutedTextRow = styled.div`
  color: ${({ theme }) => theme.colors.metalGray};
`
