import imgSrc from '@assets/undraw/undraw_forgot_password_re_hxwm.svg'
import { Alert, BasicPage, Button, Input } from '@components'
import { useQueryParam } from '@hooks'
import {
  IResetPassword,
  IResetPasswordRequest,
  nocfoResetPassword,
  nocfoResetPasswordRequest
} from '@query'
import { setAPIErrors } from '@utils'
import React from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

interface IPasswordResetRequestForm {
  email: string
}

export const NocfoResetPasswordPage: React.FC = () => {
  const [token] = useQueryParam<string>('token')

  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<IPasswordResetRequestForm>()

  const requestResetMutation = useMutation<unknown, unknown, IResetPasswordRequest>(
    data => nocfoResetPasswordRequest(null, data),
    {
      onSuccess: () => {
        toast.success(t('general.success'))
      },
      onError: ({ status, data }) => {
        if (status === 400) {
          setAPIErrors(data, setError)
        } else {
          toast.success(t('general.error'))
        }
      }
    }
  )

  const onSubmit = handleSubmit(async data => {
    await requestResetMutation.mutateAsync({ email: data.email })
  })

  if (token) {
    return <ChangePasswordFormPage />
  }

  return (
    <BasicPage title={t('nocfoLogin.resetPasswordTitle')} imageUrl={imgSrc}>
      <Alert
        type="success"
        isVisible={requestResetMutation.isSuccess}
        title={t('nocfoLogin.resetPasswordLinkSendTitle')}
        description={t('nocfoLogin.resetPasswordLinkSendDescription')}
      />
      <form onSubmit={onSubmit}>
        <fieldset disabled={requestResetMutation.isSuccess}>
          <p>{t('nocfoLogin.resetPasswordDescription')}</p>
          <br />
          <Input
            label={t('nocfoLogin.email')}
            errors={errors.email}
            {...register('email', {
              required: { value: true, message: t('validation.required') }
            })}
          />

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={requestResetMutation.isLoading || requestResetMutation.isSuccess}
              showSpinner={requestResetMutation.isLoading}
              intent="primary"
            >
              {t('nocfoLogin.resetPasswordButton')}
            </Button>
          </div>
        </fieldset>
      </form>
    </BasicPage>
  )
}

interface IPasswordResetForm {
  email: string
  token: string
  new_password: string
  new_password_repeat: string
}

const ChangePasswordFormPage: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const [token] = useQueryParam<string>('token')
  const [email] = useQueryParam<string>('email')

  const updatePasswordMutation = useMutation<unknown, unknown, IResetPassword>(
    data => nocfoResetPassword(null, data),
    {
      onSuccess: () => {
        toast.success(t('general.success'))
        history.push('/nocfo-signin')
      },
      onError: () => {
        toast.success(t('general.error'))
      }
    }
  )

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<IPasswordResetForm>()

  const onSubmit = handleSubmit(async data => {
    if (data?.new_password !== data?.new_password_repeat) {
      setError('new_password_repeat', { message: t('validation.passwordsDoNotMatch') })
      return
    }

    await updatePasswordMutation.mutateAsync({
      email: data.email,
      token: data.token,
      new_password: data.new_password
    })
  })

  return (
    <BasicPage title={t('nocfoLogin.resetPasswordTitle')} imageUrl={imgSrc}>
      <form onSubmit={onSubmit}>
        <p>{t('nocfoLogin.newPasswordDescription')}</p>
        <input type="hidden" {...register('email')} value={email} />
        <input type="hidden" {...register('token')} value={token} />

        <Input
          label={t('nocfoLogin.newPassword')}
          errors={errors.new_password}
          type="password"
          {...register('new_password', {
            required: { value: true, message: t('validation.required') }
          })}
        />

        <Input
          label={t('nocfoLogin.newPasswordRepeat')}
          errors={errors.new_password_repeat}
          type="password"
          {...register('new_password_repeat', {
            required: { value: true, message: t('validation.required') }
          })}
        />

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            disabled={updatePasswordMutation.isLoading}
            showSpinner={updatePasswordMutation.isLoading}
            intent="primary"
          >
            {t('nocfoLogin.newPasswordButton')}
          </Button>
        </div>
      </form>
    </BasicPage>
  )
}
