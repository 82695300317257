import React, { cloneElement, ReactElement, useRef, useState } from 'react'
import {
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  safePolygon,
  useListNavigation,
  flip,
  offset
} from '@floating-ui/react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { ButtonProps } from '@components'
import { callAll } from '@utils'

type ButtonElement = ReactElement<ButtonProps>

interface Props {
  children: ButtonElement
  items: ButtonElement[]
}

export const DropdownMenu: React.FC<Props> = ({ children, items }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [isVisible, setVisible] = useState(false)

  const { context, refs, floatingStyles } = useFloating({
    open: isVisible,
    onOpenChange: setVisible,
    placement: 'bottom-end',
    middleware: [offset(8), flip()]
  })
  const listRef = useRef<(HTMLButtonElement | null)[]>([])

  const dismiss = useDismiss(context, { outsidePress: true })
  const click = useClick(context)
  const hover = useHover(context, { handleClose: safePolygon() })
  const focus = useFocus(context)

  const listNavigation = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    focus,
    hover,
    dismiss,
    listNavigation
  ])

  return (
    <>
      {cloneElement(children, {
        ref: refs.setReference,
        ...getReferenceProps()
      })}
      <FloatingPortal>
        <AnimatePresence mode="wait">
          {isVisible && (
            <StyledDropdownMenu
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              {items.map((Child, index) => (
                <AnimatedListItem key={`list-item-${index}`}>
                  {cloneElement(Child, {
                    onClick: callAll(Child.props.onClick, () => setVisible(false)),
                    tabIndex: activeIndex === index ? 0 : -1,
                    isSecondary: Child.props.isSecondary ?? true,
                    ref: (el: HTMLButtonElement) => {
                      listRef.current[index] = el
                    }
                  })}
                </AnimatedListItem>
              ))}
            </StyledDropdownMenu>
          )}
        </AnimatePresence>
      </FloatingPortal>
    </>
  )
}

const StyledDropdownMenu = styled(motion.div).attrs({
  variants: {
    open: {
      opacity: 1
    },
    closed: {
      opacity: 0
    }
  },
  initial: 'closed',
  animate: 'open',
  exit: 'closed'
})`
  display: flex;
  flex-direction: column;
  gap: 1px;
  border-radius: 1rem;
  position: absolute;
  min-width: 200px;
  box-shadow: ${({ theme }) => theme.shadow.md};
  background: ${({ theme }) => theme.colors.neutralGray};
`

const AnimatedListItem = styled.div`
  background: ${({ theme }) => theme.colors.neutralWhite};
  border-radius: 1rem;

  button {
    width: 100%;
  }

  &:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    button {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &:not(:last-child) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    button {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`
