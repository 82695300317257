import {
  AnimatedContentLoader,
  DoubleColumnLayout,
  DoubleColumnLayoutColumn,
  SingleColumnLayout,
  UploadDropzone
} from '@components'
import { useBusinessContext, useDateRangeContext } from '@context'
import { useLocalStorage, useQueryParam } from '@hooks'
import { collectFromPages, convertToJSON, fetchDocuments, IDocument } from '@query'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInfiniteQuery } from 'react-query'
import styled from 'styled-components'
import { DocumentActionBar } from './DocumentActionBar'
import { DocumentDetails } from './DocumentDetails'
import { DocumentImportTable } from './DocumentImport/DocumentImportTable'
import { DocumentList } from './DocumentList'
import { DocumentPageOnboarding } from './DocumentPageOnboarding'
import { IFilter } from '@root/components/ActionBar/FilterBar/FilterSelection'
import { fileToBase64 } from '@utils/fileToBase64.ts'

interface DocumentPageProps {
  location: string
}

type JSONObject = { [key: string]: any }

export const DocumentPage: React.FC<DocumentPageProps> = () => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const [search, setSearch] = useState('')

  const [filters, setFilter] = useLocalStorage<IFilter[]>(`document-filters-${businessId}`, [])
  const [documentId, setDocumentId] = useQueryParam('id', value => parseInt(value, 10))
  const [sourceJSON, setSourceJSON] = useState<JSONObject[]>()

  const { isActive, selectedRange } = useDateRangeContext()

  const handleSourceUpload = async (file: File) => {
    const contents = await fileToBase64(file)
    const fileBase = contents.split(',')[1]
    const fileJSON = await convertToJSON({
      format: 'csv',
      base64: fileBase
    })

    setSourceJSON(fileJSON)
  }

  const combinedFilters = useMemo(
    () => ({
      search,
      ...filters?.reduce(
        (prev, current) => ({
          ...prev,
          ...current
        }),
        {}
      ),
      ...(isActive
        ? {
            date_from: selectedRange?.date_from,
            date_to: selectedRange?.date_to
          }
        : {})
    }),
    [search, filters, selectedRange]
  )

  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery(
    [businessId, 'documents', combinedFilters],
    ({ pageParam }) =>
      fetchDocuments(
        { businessId },
        {
          page: pageParam || 1,
          ...combinedFilters
        }
      ),
    {
      getNextPageParam: lastPage => lastPage.next,
      getPreviousPageParam: lastPage => lastPage.prev,
      keepPreviousData: true
    }
  )
  const documents = collectFromPages<IDocument>(data)

  const onClose = () => setDocumentId(undefined)
  const isEmpty = documents.length === 0 && !isLoading

  const noDatefilters = !isActive
  const hasNoDocuments = isEmpty && !filters && !search && noDatefilters

  if (sourceJSON) {
    return (
      <SingleColumnLayout header={t('document.documentImportTitle')}>
        <DocumentImportTable data={sourceJSON} onClose={() => setSourceJSON(undefined)} />
      </SingleColumnLayout>
    )
  }

  return (
    <DoubleColumnLayout
      header={t('document.mainTitle')}
      isRightVisible={Boolean(documentId)}
      onRightClose={onClose}
    >
      <DoubleColumnLayoutColumn innerKey="column-left">
        <UploadDropzone fileTypes={['.csv']} uploadFile={handleSourceUpload}>
          <DocumentPageWrapper>
            <DocumentActionBar
              documents={documents}
              onSearch={search => setSearch(search)}
              onFilter={filters => setFilter(filters)}
              uploadFile={handleSourceUpload}
              filters={filters}
            />
            <DocumentListWrapper>
              <AnimatedContentLoader
                isLoading={isLoading}
                isEmpty={isEmpty}
                isEmptyContent={hasNoDocuments && <DocumentPageOnboarding />}
                isEmptyDescription={t('document.listEmptySearch')}
              >
                <DocumentList
                  documents={documents}
                  onScrollBottom={async () => {
                    if (hasNextPage) {
                      await fetchNextPage()
                    }
                  }}
                />
              </AnimatedContentLoader>
            </DocumentListWrapper>
          </DocumentPageWrapper>
        </UploadDropzone>
      </DoubleColumnLayoutColumn>

      <DoubleColumnLayoutColumn innerKey={`document-${documentId}`} isRight={true}>
        {documentId && <DocumentDetails documentId={documentId} onClose={onClose} />}
      </DoubleColumnLayoutColumn>
    </DoubleColumnLayout>
  )
}

const DocumentPageWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const DocumentListWrapper = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
`
