import { VatCode, VAT_CODE_OPTIONS } from '@constants'
import React, { useEffect, useMemo, useState } from 'react'
import { Control, Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import {
  FaBan,
  FaDollyFlatbed,
  FaEuroSign,
  FaGlobe,
  FaHammer,
  FaHome,
  FaMinusCircle
} from 'react-icons/fa'
import { InputWrapper } from '../Input'
import { VatIconWrapper, VatRateSelect, VatSelectWrapper, VatTypeSelect } from './VatSelect.styled'

interface Props {
  id: string
  include: 'all' | 'sales' | 'purchase'
  vatCodeName: string
  vatRateName: string
  isInvalid: boolean
  control: Control<any>
  options: { value: number; label: string }[]
}

const VatCodesForPurchase = [
  VatCode.TAX_FREE,
  VatCode.DOMESTIC_PURCHASE_TAX,
  VatCode.EU_PURCHASE_OF_GOODS,
  VatCode.EU_PURCHASE_OF_SERVICES,
  VatCode.PURCHASE_OF_GOODS_OUTSIDE_EU,
  VatCode.PURCHASE_OF_SERVICES_OUTSIDE_EU,
  VatCode.PURCHASE_OF_CONSTRUCTION_SERVICES
]

const VatCodesForSales = [
  VatCode.TAX_FREE,
  VatCode.DOMESTIC_SALES_TAX,
  VatCode.DOMESTIC_ZERO_TAX_RATE,
  VatCode.EU_SALE_OF_GOODS,
  VatCode.EU_SALE_OF_SERVICES,
  VatCode.SALE_OF_CONSTRUCTION_SERVICES
]

export const VatCodeIcons = {
  [VatCode.TAX_FREE]: FaBan,
  [VatCode.DOMESTIC_SALES_TAX]: FaHome,
  [VatCode.DOMESTIC_PURCHASE_TAX]: FaHome,
  [VatCode.DOMESTIC_ZERO_TAX_RATE]: FaMinusCircle,
  [VatCode.EU_SALE_OF_GOODS]: FaEuroSign,
  [VatCode.EU_SALE_OF_SERVICES]: FaEuroSign,
  [VatCode.EU_PURCHASE_OF_GOODS]: FaEuroSign,
  [VatCode.EU_PURCHASE_OF_SERVICES]: FaEuroSign,
  [VatCode.PURCHASE_OF_GOODS_OUTSIDE_EU]: FaDollyFlatbed,
  [VatCode.PURCHASE_OF_SERVICES_OUTSIDE_EU]: FaGlobe,
  [VatCode.SALE_OF_CONSTRUCTION_SERVICES]: FaHammer,
  [VatCode.PURCHASE_OF_CONSTRUCTION_SERVICES]: FaHammer
}

export const VatSelect: React.FC<Props> = ({
  id,
  control,
  options,
  vatCodeName,
  vatRateName,
  include,
  isInvalid
}) => {
  const [t] = useTranslation()
  const [isFocused, setFocused] = useState(false)
  const { watch, getValues, setValue } = useFormContext()

  const vatCodeOptions = useMemo(
    () =>
      VAT_CODE_OPTIONS.filter(option => {
        if (include === 'sales') return VatCodesForSales.indexOf(option.value) !== -1
        if (include === 'purchase') return VatCodesForPurchase.indexOf(option.value) !== -1
        return true
      }),
    [include]
  )

  const [vatCode, vatRate] = watch([vatCodeName, vatRateName])
  const hideVatRate = [VatCode.TAX_FREE, VatCode.DOMESTIC_ZERO_TAX_RATE].includes(
    parseInt(vatCode || VatCode.TAX_FREE, 10)
  )

  // CASE: Options are changed dynamically
  // > Make sure selected value exists in the new options
  // > If not, select the first option automatically
  useEffect(() => {
    const oldVatRate = get(getValues(), vatRateName)
    const newVatRate = options.find(option => option.value === oldVatRate)?.value
    if (oldVatRate !== newVatRate) {
      setValue(vatRateName, newVatRate ?? options?.[0]?.value)
    }
  }, [JSON.stringify(options)])

  // Needed since if toggled from VAT code that needs a VAT rate to VAT code that doesn't need it,
  // the old value would still be used
  useEffect(() => {
    setValue(vatRateName, hideVatRate ? 0 : vatRate !== undefined ? vatRate : options?.[0]?.value)
  }, [hideVatRate])

  return (
    <VatSelectWrapper>
      <InputWrapper
        inputId={id}
        label={t('components.vatSelect.vat')}
        isFocused={isFocused}
        isInvalid={isInvalid}
      >
        <Controller
          control={control as any}
          name={vatCodeName}
          render={({ field: { ref, value, onChange } }) => {
            const Icon = VatCodeIcons[value] || FaBan
            const selected = VAT_CODE_OPTIONS.find(vatCodeOption => vatCodeOption.value == value)
            return (
              <>
                <VatIconWrapper key={value}>
                  {value ? <Icon size={16} /> : <FaBan />}
                </VatIconWrapper>

                <VatTypeSelect
                  data-test="vat-select"
                  ref={ref}
                  title={t(selected?.label)}
                  style={{ opacity: 0 }}
                  value={value}
                  onFocus={() => {
                    setFocused(true)
                  }}
                  onBlur={() => {
                    setFocused(false)
                  }}
                  onChange={ev => onChange?.(parseInt(ev.target.value, 10))}
                >
                  {vatCodeOptions.map(option => (
                    <option key={option.value} value={option.value}>
                      {t(option.label)}
                    </option>
                  ))}
                </VatTypeSelect>
              </>
            )
          }}
        />
        <Controller
          control={control as any}
          name={vatRateName}
          render={({ field: { ref, value, onChange } }) => {
            return (
              <VatRateSelect
                data-test="vat-amount"
                ref={ref}
                value={hideVatRate ? 0 : value}
                onChange={ev => onChange?.(parseFloat(ev.target.value))}
                className="percentage"
                onFocus={() => {
                  setFocused(true)
                }}
                onBlur={() => {
                  setFocused(false)
                }}
                disabled={hideVatRate}
              >
                {options.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </VatRateSelect>
            )
          }}
        />
      </InputWrapper>
    </VatSelectWrapper>
  )
}
