import { AnimatePresence, motion } from 'framer-motion'
import React, { useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { FaTimes } from 'react-icons/fa'
import styled from 'styled-components'

interface Props {
  title: string
  initiallyOpen?: boolean
  children: (PropsOut) => React.ReactNode
}

export const CornerDialog: React.FC<Props> = ({ title, initiallyOpen = true, children }) => {
  const [isVisible, setVisible] = useState(initiallyOpen)
  const rootRef = useRef<HTMLElement>(document.getElementById('modal-root'))

  return createPortal(
    <AnimatePresence>
      {isVisible && (
        <StyledCornerDialog>
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
            <FaTimes onClick={() => setVisible(false)} />
          </DialogHeader>
          <DialogContent>{children({ close: () => setVisible(false) })}</DialogContent>
        </StyledCornerDialog>
      )}
    </AnimatePresence>,
    rootRef.current
  )
}

const StyledCornerDialog = styled(motion.div).attrs({
  initial: { opacity: 0, y: 10, x: '-50%', pointerEvents: 'none' },
  animate: { opacity: 1, y: 0, x: '-50%', transition: { delay: 2 }, pointerEvents: 'all' },
  exit: { opacity: 0, y: 10, x: '-50%', pointerEvents: 'none' }
})`
  position: fixed;
  bottom: 1rem;
  left: 50%;
  background: white;
  border-radius: 1rem;
  background: ${({ theme }) => theme.colors.neutralWhite};
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
`

const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;

  & > * {
    align-self: center;
  }

  svg {
    margin: ${({ theme }) => theme.spacing.md}rem;
    margin-bottom: 0;
    cursor: pointer;
  }
`

const DialogTitle = styled.h1`
  margin: 0;
  font-size: ${({ theme }) => theme.spacing.md}rem;
  margin: ${({ theme }) => theme.spacing.md}rem;
  margin-bottom: 0;
`

const DialogContent = styled.div``
