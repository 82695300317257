import { BusinessLogo } from '@components'
import {
  autoUpdate,
  flip,
  offset,
  size,
  useDismiss,
  useFloating
} from '@floating-ui/react-dom-interactions'
import { fetchBusinesses } from '@query'
import { callAll } from '@utils'
import { AnimatePresence, motion } from 'framer-motion'
import React, { cloneElement, JSXElementConstructor, ReactElement, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface BusinessMenuProps {
  children: ReactElement<any, string | JSXElementConstructor<any>>
}

export const BusinessMenu: React.FC<BusinessMenuProps> = ({ children }) => {
  const [t] = useTranslation()
  const rootRef = useRef<HTMLElement>(document.getElementById('dropdown-root'))
  const [isOpen, setOpen] = useState(false)

  const { data } = useQuery(['businesses'], () => fetchBusinesses(undefined, { page_size: 1000 }), {
    refetchOnWindowFocus: false
  })
  const businesses = data?.results

  const { context, x, y, reference, floating, strategy } = useFloating({
    open: isOpen,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    middleware: [
      offset(10),
      flip(),
      size({
        apply: ({ availableWidth, availableHeight, elements }) => {
          Object.assign(elements.floating.style, {
            width: `${Math.min(800, availableWidth - 48)}px`,
            maxHeight: `${Math.min(1000, availableHeight - 48)}px`
          })
        }
      })
    ]
  })

  useDismiss(context)

  return (
    <>
      {cloneElement(children, {
        ref: reference,
        onClick: callAll(() => setOpen(!isOpen), children.props.onClick)
      })}
      {createPortal(
        <AnimatePresence>
          {isOpen && (
            <StyledBusinessesDropdown
              ref={floating}
              style={{
                top: y || 0,
                left: x || 0,
                position: strategy
              }}
            >
              <StyledMenuHeader to="/">{t('businessesPage.yourBusinesses')}:</StyledMenuHeader>

              <DropdownCardsWrapper>
                {businesses?.map(business => (
                  <StyledBusinessCard key={`business-${business.id}`}>
                    <StyledBusinessCardLink to={`/${business.business_id}`}>
                      <BusinessLogo src={business.logo} name={business.name} size={64} />
                      <StyledBusienssCardName>{business.name}</StyledBusienssCardName>
                    </StyledBusinessCardLink>
                  </StyledBusinessCard>
                ))}
              </DropdownCardsWrapper>
            </StyledBusinessesDropdown>
          )}
        </AnimatePresence>,
        rootRef.current
      )}
    </>
  )
}

const StyledBusinessesDropdown = styled(motion.div).attrs({
  variants: {
    hide: {
      opacity: 0,
      scale: 0.8,
      y: -50,
      x: -50,
      transition: {
        duration: 0.4,
        staggerChildren: 0.0,
        staggerDirection: -1,
        type: 'spring'
      }
    },
    show: {
      opacity: 1,
      scale: 1.0,
      y: 0,
      x: 0,
      transition: {
        duration: 0.4,
        staggerChildren: 0.0,
        staggerDirection: 1,
        type: 'spring'
      }
    }
  },
  initial: 'hide',
  animate: 'show',
  exit: 'hide'
})`
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 100000;
  border-radius: 1rem;
  width: 600px;
  overflow: auto;
  padding: ${({ theme }) => theme.spacing.md}rem;
  box-sizing: content-box;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
`

const DropdownCardsWrapper = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing.md}rem;
  grid-template-columns: 1fr 1fr;

  @media only screen and (min-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media only screen and (min-width: 800px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

const StyledBusinessCard = styled(motion.a).attrs({
  variants: {
    hide: {
      opacity: 0,
      y: 0,
      transition: {
        duration: 0.2
      }
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.2
      }
    }
  }
})`
  width: auto;
`

const StyledMenuHeader = styled(Link)`
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;
  color: ${({ theme }) => theme.colors.neutralBlack};
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  font-weight: 500;
`

const StyledBusinessCardLink = styled(Link)`
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.xl}rem ${({ theme }) => theme.spacing.md}rem;
  border-radius: 1rem;

  &:hover {
    background: ${({ theme }) => theme.colors.mainBg};
    text-decoration: none;
  }
`

const StyledBusienssCardName = styled.div`
  margin-top: ${({ theme }) => theme.spacing.md}rem;
  color: ${({ theme }) => theme.colors.neutralBlack};
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  text-align: center;
`
