import { Input } from '@components'
import { useDateRangeContext } from '@context'
import { FloatingFocusManager } from '@floating-ui/react-dom-interactions'
import { IDashboardFilter } from '@query'
import cn from 'classnames'
import dayjs from 'dayjs'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useCallback, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { get, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface DateRangeMenuProps {
  context: any
  isVisible: boolean
  x?: number
  y?: number
  strategy?: any
}

export const DateRangeDropdown = React.forwardRef<HTMLElement, DateRangeMenuProps>(
  ({ isVisible, context, x = 0, y = 0, strategy }, ref) => {
    const { selectedRange: selected, options, setRange } = useDateRangeContext()
    const {
      watch,
      register,
      setValue,
      formState: { errors }
    } = useForm<Omit<IDashboardFilter, 'key'>>({
      mode: 'onChange',
      defaultValues: {
        date_from: selected?.date_from,
        date_to: selected?.date_to
      }
    })
    const [t] = useTranslation()
    const rootRef = useRef<HTMLElement>(document.getElementById('dropdown-root'))
    const isCustom = selected?.key === 'custom'

    useEffect(() => {
      setValue('date_to', selected.date_to)
      setValue('date_from', selected.date_from)
    }, [isCustom])

    const setCustomRange = useCallback(
      (newRange: Partial<Omit<IDashboardFilter, 'key'>>) => {
        setRange({ ...selected, ...newRange, key: 'custom' })
      },
      [selected]
    )

    const dateValidation = useCallback(date => {
      if (!dayjs(date, 'YYYY-MM-DD').isValid()) {
        return t('validation.invalidDate')
      }

      return true
    }, [])

    const isDateRangeValid = useCallback((dates: Omit<IDashboardFilter, 'key'>) => {
      const dateFrom = dayjs(dates.date_from, 'YYYY-MM-DD')
      const dateTo = dayjs(dates.date_to, 'YYYY-MM-DD')
      return dateTo.isBefore(dateFrom)
    }, [])

    const formValues = watch()

    return createPortal(
      <AnimatePresence>
        {isVisible && (
          <FloatingFocusManager context={context}>
            <StyledMenu
              ref={ref as any}
              className="date-range-options"
              style={{
                top: y || 0,
                left: x || 0,
                position: strategy,
                width: 'max-content'
              }}
            >
              {options.map(option => (
                <StyledOption
                  key={option.key}
                  className={cn({ isSelected: selected?.key === option.key })}
                  data-value={option.key}
                  onClick={() => setRange(option)}
                >
                  {t(`components.dateRangeFilter.${option.key}`)}
                </StyledOption>
              ))}

              <AnimatePresence>
                {isCustom && (
                  <>
                    <CustomDatesRow>
                      <Input
                        label={t(`components.dateRangeFilter.startDate`)}
                        type="date"
                        errors={errors.date_from}
                        {...register('date_from', {
                          onChange: e => {
                            setCustomRange({ date_from: e.currentTarget.value })
                          },
                          validate: dateValidation
                        })}
                      />
                      <Input
                        label={t(`components.dateRangeFilter.endDate`)}
                        type="date"
                        errors={get(errors, 'date_to')}
                        {...register('date_to', {
                          onChange: e => {
                            setCustomRange({ date_to: e.currentTarget.value })
                          },
                          validate: dateValidation
                        })}
                      />
                    </CustomDatesRow>
                    {!!isDateRangeValid(formValues) && (
                      <StyledErrorText>
                        {t('validation.invalidDateRangeLong', {
                          dateFrom: formValues.date_from,
                          dateTo: formValues.date_to
                        })}
                      </StyledErrorText>
                    )}
                  </>
                )}
              </AnimatePresence>
            </StyledMenu>
          </FloatingFocusManager>
        )}
      </AnimatePresence>,
      rootRef.current
    )
  }
)

const StyledMenu = styled(motion.div).attrs({
  variants: {
    hide: {
      opacity: 0,
      scale: 0.98,
      y: 10,
      transition: {
        duration: 0.2,
        staggerChildren: 0.06,
        staggerDirection: -1,
        type: 'tween'
      }
    },
    show: {
      opacity: 1,
      scale: 1.0,
      y: 0,
      transition: {
        duration: 0.15,
        staggerChildren: 0.06,
        staggerDirection: 1,
        type: 'tween'
      }
    }
  },
  initial: 'hide',
  animate: 'show',
  exit: 'hide'
})`
  display: flex;
  flex-wrap: wrap;
  max-width: min(100vw, 600px);
  background: ${({ theme }) => theme.colors.neutralWhite};
  padding: ${({ theme }) => theme.spacing.sm}rem;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  z-index: 10000;
  outline: none !important;
`

const StyledOption = styled(motion.div).attrs({
  variants: {
    hide: {
      opacity: 0,
      y: 20,
      transition: {
        duration: 0.05
      }
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.05
      }
    }
  }
})`
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.metalGray};
  white-space: nowrap;
  background: ${({ theme }) => theme.colors.neutralGray};
  border-radius: 0.6rem;
  padding: ${({ theme }) => theme.spacing.xs}rem ${({ theme }) => theme.spacing.sm}rem;
  transition: 0.2s;
  cursor: pointer;
  text-align: center;

  &.isSelected {
    background: ${({ theme }) => theme.colors.nocfoBlue};
    color: white;
  }
`

const CustomDatesRow = styled(motion.div).attrs({
  initial: { opacity: 0, y: -10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -10 }
})`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  padding-top: ${({ theme }) => theme.spacing.xs}rem;
  border-top: 1px solid ${({ theme }) => theme.colors.neutralGray};

  .input-wrapper {
    flex: 1;
    margin: 0;
  }
`

const StyledErrorText = styled.span`
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  color: ${({ theme }) => theme.colors.nocfoRed};
  padding: ${({ theme }) => theme.spacing.xxs}rem;
  padding-top: 0;
`
