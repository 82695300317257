import svgSrc from '@assets/undraw/undraw_mailbox_re_dvds.svg'
import { AnimatedContentLoader, CopyValue } from '@components'
import { useBusinessContext } from '@context'
import { fetchBusiness } from '@query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import styled from 'styled-components'

export const EInvoicingDetails: React.FC = () => {
  const { t } = useTranslation()
  const { businessId } = useBusinessContext()

  const { data, isLoading } = useQuery([businessId], () => fetchBusiness({ businessId }))

  return (
    <AnimatedContentLoader isLoading={isLoading}>
      <EinvoiceAddressContainer>
        <EinvoiceImg />
        <EinvoiceAddressContent>
          <p>{t('settings.einvoicing.detailsDescription')}</p>

          <h4>{t('settings.einvoicing.einvoicingAddress')}:</h4>
          <CopyValue value={data?.einvoicing_address} />

          <br />
          <h4>{t('settings.einvoicing.einvoicingOperator')}:</h4>
          <CopyValue value={data?.einvoicing_operator} />
        </EinvoiceAddressContent>
      </EinvoiceAddressContainer>
    </AnimatedContentLoader>
  )
}

const EinvoiceAddressContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.xl}rem;
  padding: ${({ theme }) => theme.spacing.xl}rem;
  border: 3px solid ${({ theme }) => theme.colors.neutralGray};
  border-radius: 1rem;
  color: ${({ theme }) => theme.colors.neutralBlack};
  margin: ${({ theme }) => theme.spacing.md}rem 0;
`

const EinvoiceAddressContent = styled.div`
  flex: 1;
`

const EinvoiceImg = styled.img.attrs({ src: svgSrc })`
  flex: 1;
  max-width: 160px;
  min-width: 140px;
`
