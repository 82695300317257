import { Button, FileThumbnail } from '@components'
import { getFilesPageUrl, UserRole } from '@constants'
import { PermissionBoundary, useBusinessContext } from '@context'
import { IFile, ISubFolder } from '@query'
import cn from 'classnames'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FaEllipsisH, FaFolder, FaFolderOpen } from 'react-icons/fa'
import { FaInbox } from 'react-icons/fa6'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { formatDate } from '@root/utils'

const ICON_SIZE = 24

interface Props {
  size: number
  folder: ISubFolder
  isOver: boolean
  isDragging: boolean
  isSelected: boolean
  isActiveTarget: boolean
  isFirst?: boolean
  isLast?: boolean
}

export const Folder: React.FC<Props> = ({
  folder,
  size,
  isDragging,
  isActiveTarget,
  isOver,
  isSelected,
  isFirst,
  isLast
}) => {
  const history = useHistory()
  const { businessId } = useBusinessContext()
  const { t } = useTranslation()

  const folderName = useMemo(() => {
    if (folder.for_mobile_uploads) return t('files.browser.mobileUploads')
    return folder.name
  }, [folder])

  const folderClosedIcon = useMemo(() => {
    if (folder.for_mobile_uploads) return <FaInbox size={ICON_SIZE} />
    return <FaFolder size={ICON_SIZE} />
  }, [folder])

  const folderOpenIcon = useMemo(() => {
    return <FaFolderOpen size={ICON_SIZE} />
  }, [folder])

  return (
    <Draggable
      className={cn({
        'file-browser-item': true,
        'is-dragged': isDragging,
        'is-over': isOver,
        'is-selected': isSelected,
        'is-first': isFirst,
        'is-last': isLast
      })}
    >
      <StyledItem
        to={getFilesPageUrl(businessId, { folder: folder.id, id: null, idType: null })}
        style={{ height: size }}
      >
        {isActiveTarget ? folderOpenIcon : folderClosedIcon}

        <ItemName>{folderName}</ItemName>

        <PermissionBoundary requireRole={UserRole.EDITOR}>
          <Button
            isSecondary={true}
            onClick={e => {
              history.push(
                getFilesPageUrl(businessId, {
                  id: folder.id,
                  idType: 'folder',
                  folder: folder.parent
                })
              )
              e.stopPropagation()
              e.preventDefault()
            }}
          >
            <FaEllipsisH size={16} />
          </Button>
        </PermissionBoundary>
      </StyledItem>
    </Draggable>
  )
}

interface FileItemProps {
  size: number
  file: IFile
  isDragging: boolean
  didDrop: boolean
  isSelected: boolean
  isFirst?: boolean
  isLast?: boolean
}

export const FileItem: React.FC<FileItemProps> = ({
  file,
  size,
  isDragging,
  didDrop,
  isSelected,
  isFirst,
  isLast
}) => {
  const { businessId } = useBusinessContext()

  return (
    <Draggable
      data-test="file-item"
      className={cn({
        'file-browser-item': true,
        'did-drop': didDrop,
        'is-selected': isSelected,
        'is-dragged': isDragging,
        'is-first': isFirst,
        'is-last': isLast
      })}
    >
      <StyledItem
        to={getFilesPageUrl(businessId, { id: file.id, folder: file.folder_id, idType: 'file' })}
        style={{ height: size }}
      >
        <FileThumbnail
          thumb={file.file}
          blurhash={file.blurhash}
          filetype={file.type}
          size={ICON_SIZE}
        />
        <ItemMetaDataWrapper>
          <ItemName>{file.name}</ItemName>
          <ItemUploadDate>{formatDate(file.created_at)}</ItemUploadDate>
        </ItemMetaDataWrapper>
      </StyledItem>
    </Draggable>
  )
}

const Draggable = styled.div`
  border-radius: 0;
  overflow: hidden;
  opacity: 1;
  transition: 0.4s;
  background: white;

  &.is-dragged {
    opacity: 0.6;
    pointer-events: none;
  }

  &.did-drop {
    opacity: 0 !important;
  }

  &.is-over {
    cursor: copy;

    svg {
      transform: scale(1.2);
    }
  }

  &.is-selected {
    opacity: 0.6;
  }

  &.is-first {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  &.is-last {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
`

const StyledItem = styled(Link).attrs({
  onClick: e => {
    if (e.shiftKey || e.metaKey) {
      e.preventDefault()
    }
  }
})`
  display: flex;
  padding: ${({ theme }) => theme.spacing.xs}rem;
  padding-left: ${({ theme }) => theme.spacing.sm}rem;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  color: ${({ theme }) => theme.colors.neutralBlack};
  align-items: center;
  text-decoration: none !important;
  user-drag: none;

  &.isDragging {
    pointer-events: none;
  }

  svg {
    fill: ${({ theme }) => theme.colors.metalGray};
  }
`

const ItemName = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.colors.neutralBlack};
`

const ItemMetaDataWrapper = styled.div`
  flex: 1;
`

const ItemUploadDate = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  color: ${({ theme }) => theme.colors.metalGray};
`
