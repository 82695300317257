import { fetchInvoice, IInvoice } from '@root/query'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import svgImg from '@assets/undraw/undraw_not_found_re_bh2e.svg'
import { formatDate } from '@root/utils'
import { INVOICE_REPETITION_MONTHS, InvoiceRepetitionDays, InvoiceStatus } from '@root/constants'
import { ImageMessageBox, TextTooltip } from '@root/components'
import { FaQuestionCircle } from 'react-icons/fa'
import { theme } from '@root/styles'
import { RecurringInvoiceSettingBadgeButton } from './InvoiceStatusBadgeButton'
import InvoiceRecurrenceEditSettings from './InvoiceRecurrenceEditSettings'
import { useQuery } from 'react-query'
import { useBusinessContext } from '@root/context'

interface Props {
  invoice: IInvoice
}

const InvoiceRecurrenceInfo: React.FC<Props> = ({ invoice }) => {
  const [t, i18n] = useTranslation()
  const [showRecurenceEdit, setShowRecurenceEdit] = useState(false)
  const { businessId } = useBusinessContext()

  const { data: parentInvoice } = useQuery(
    [businessId, 'invoices', invoice.recurrence_parent_id],
    () => fetchInvoice({ businessId, invoiceId: invoice.recurrence_parent_id }),
    {
      enabled: !!invoice?.recurrence_parent_id
    }
  )

  const invoiceToUse = parentInvoice ?? invoice

  const dates = invoiceToUse?.next_recurrences

  const showThreshHold = 3
  const amountOfToShow = dates.length < showThreshHold ? dates.length : showThreshHold

  const getMatchingTranslatedMonth = (month: number): string => {
    const result = INVOICE_REPETITION_MONTHS.find(element => element.value === month)?.labelKey
    if (i18n.language === 'en') {
      return t(`${result}`)
    }
    return t(`${result}`).toLowerCase()
  }

  const createInvoiceRepetitionElement = (invoice: IInvoice): React.ReactElement => {
    const dayNumber = invoice?.recurrence_rule?.rule.by_month_day[0]
    const monthNumber = invoice?.recurrence_rule?.rule.by_month[0]
    if (invoice?.recurrence_rule?.rule?.by_month.length === 0) {
      return dayNumber === InvoiceRepetitionDays.DAY_LAST ? (
        <Trans>{t('invoicing.details.recurrence.monthlyLastDay')}</Trans>
      ) : (
        <Trans>
          {t('invoicing.details.recurrence.monthlyNormalDay', { count: dayNumber, ordinal: true })}
        </Trans>
      )
    } else if (invoice?.recurrence_rule?.rule?.by_month_day.length > 0) {
      return dayNumber === InvoiceRepetitionDays.DAY_LAST ? (
        <Trans>
          {t('invoicing.details.recurrence.yearlyLastDay', {
            month: getMatchingTranslatedMonth(monthNumber)
          })}
        </Trans>
      ) : (
        <Trans>
          {t('invoicing.details.recurrence.yearlyNormalDay', {
            month: getMatchingTranslatedMonth(monthNumber),
            count: dayNumber,
            ordinal: true
          })}
        </Trans>
      )
    }
    return null
  }

  const getTheNextInvoicesToBeSent = (amountOfToShow: number, invoice: IInvoice): Array<string> => {
    const results = []
    invoice?.next_recurrences.slice(0, amountOfToShow).map((date, index) => {
      if (index === amountOfToShow - 1) {
        results.push(`${formatDate(date)}.`)
        return
      }
      results.push(`${formatDate(date)}, `)
    })
    return results
  }

  const createInvoiceEndingDate = (invoice: IInvoice): string => {
    if (!invoice?.recurrence_end) {
      return `${t('invoicing.details.recurrence.invoiceDoesntEnd')}`
    }
    const date = formatDate(invoice?.recurrence_end)
    return `${t('invoicing.details.recurrence.invoiceEnds')} ${date}.`
  }

  const createNextInvoiceString = (invoice: IInvoice): string => {
    if (invoice?.next_recurrences.length === 0) {
      return `${t('invoicing.details.recurrence.guidanceTextForLastInvoice')}`
    }

    if (invoice.status === InvoiceStatus.DRAFT) {
      return `${t('invoicing.details.recurrence.guidanceDraftTextForNextInvoice')} ${formatDate(
        invoice?.next_recurrences[0]
      )}.`
    }
    return `${t('invoicing.details.recurrence.guidanceTextForNextInvoice')} ${formatDate(
      invoice?.next_recurrences[0]
    )}.`
  }

  const createToolTipMessage = (amountOfToShow: number, invoice: IInvoice) => {
    if (amountOfToShow === 0) {
      return t('invoicing.details.recurrence.guidanceTextForLastInvoice')
    }

    let toolTip = `${t('invoicing.details.recurrence.nextInvoicesToolTip', { amountOfToShow })} `
    const formattedDates = getTheNextInvoicesToBeSent(amountOfToShow, invoice)
    formattedDates.forEach(date => {
      toolTip += date
    })

    return toolTip
  }

  const hasRecurrenceEnded = invoiceToUse?.is_recurrence_end_exceeded

  return (
    <ImageMessageBox imgSrc={svgImg} childrenStyle={{ flex: '1 0 55%' }}>
      <RightWrapper>
        <HeaderRow>
          <Header>{t('invoicing.details.recurrence.recurringInvoice')}</Header>
          <TextTooltip
            style={{ marginLeft: `${theme.spacing.sm}rem`, marginBottom: `${theme.spacing.sm}rem` }}
            tooltip={createToolTipMessage(amountOfToShow, invoiceToUse)}
          >
            <FaQuestionCircle size={14}></FaQuestionCircle>
          </TextTooltip>
        </HeaderRow>

        {hasRecurrenceEnded ? (
          <p>
            {t('invoicing.details.recurrence.hasEnded', {
              recurrenceEndDate: formatDate(invoiceToUse.recurrence_end)
            })}
          </p>
        ) : (
          <>
            <p>
              {createInvoiceRepetitionElement(invoiceToUse)} {createInvoiceEndingDate(invoiceToUse)}
            </p>
            <NextInvoiceInfo>{createNextInvoiceString(invoiceToUse)}</NextInvoiceInfo>
            <div style={{ marginTop: `${theme.spacing.md}rem` }}>
              <RecurringInvoiceSettingBadgeButton onClick={() => setShowRecurenceEdit(true)} />
            </div>
          </>
        )}
      </RightWrapper>

      <InvoiceRecurrenceEditSettings
        invoice={invoice}
        handleOnClose={() => setShowRecurenceEdit(false)}
        showPrompt={showRecurenceEdit}
      />
    </ImageMessageBox>
  )
}

export default InvoiceRecurrenceInfo

const RightWrapper = styled.div`
  p {
    margin-bottom: ${({ theme }) => theme.spacing.xs}rem;
  }
`

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Header = styled.h4`
  margin-bottom: ${({ theme }) => theme.spacing.sm}rem;
`

const NextInvoiceInfo = styled.p``
