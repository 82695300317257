import { Badge, MonetaryAmount } from '@components'
import { VAT_CODE_OPTIONS } from '@constants'
import { IEntry } from '@query'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface Props {
  entry: IEntry
}

export const EntryRow: React.FC<Props> = ({ entry }) => {
  const [t] = useTranslation()
  const [isOpen, setOpen] = useState(false)

  const vatTypeLabel = useMemo(() => {
    return VAT_CODE_OPTIONS.find(item => item.value === entry.vat_code)?.label
  }, [entry.vat_code])

  return (
    <StyledEntryRow onClick={() => setOpen(!isOpen)} data-test="entry-row">
      <EntryHeader>
        <AccountNameWrapper>
          <AccountName>{entry.account_name}</AccountName>
        </AccountNameWrapper>
        <HeaderDetails>
          <AmountColumn data-test="entry-row-monetary-value">
            <MonetaryAmount value={entry.amount} />
          </AmountColumn>
          <BadgeColumn>
            {entry.is_debet ? (
              <Badge type="success">{t('document.entrySection.debet')}</Badge>
            ) : (
              <Badge type="warning">{t('document.entrySection.credit')}</Badge>
            )}
          </BadgeColumn>
        </HeaderDetails>
      </EntryHeader>

      <AnimatePresence>
        {isOpen && (
          <EntryDetails>
            <EntryDetailColumn>
              <EntryColumnName>{t('document.entrySection.accountNumber')}</EntryColumnName>
              <EntryDetailValue data-test="entry-row-details-account-number">
                {entry.account_number}
              </EntryDetailValue>
            </EntryDetailColumn>

            <EntryDetailColumn>
              <EntryColumnName>{t('document.entrySection.vatCode')}</EntryColumnName>
              <EntryDetailValue data-test="entry-row-details-account-vat-type">
                {t(vatTypeLabel) || '-'}
              </EntryDetailValue>
              <EntryColumnName>{t('document.entrySection.vatRate')}</EntryColumnName>
              <EntryDetailValue data-test="entry-row-details-vat-rate">
                {entry.vat_rate ? `${entry.vat_rate} %` : '-'}
              </EntryDetailValue>
            </EntryDetailColumn>
          </EntryDetails>
        )}
      </AnimatePresence>
    </StyledEntryRow>
  )
}

const StyledEntryRow = styled(motion.div).attrs({
  layout: true,
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  margin-top: ${({ theme }) => theme.spacing.xs}rem;
`

const EntryHeader = styled(motion.div).attrs({ layout: 'position' })`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
`

const HeaderDetails = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing.sm}rem;
`

const AccountNameWrapper = styled.span`
  flex: 2;
  color: ${({ theme }) => theme.colors.neutralBlack};
`

const AccountName = styled.div`
  display: flex;
  transition: 0.1s;
  overflow: hidden;
  max-width: 350px;
  min-width: 250px;
`

const AmountColumn = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  text-align: right;
  color: ${({ theme }) => theme.colors.neutralBlack};
  margin: 0;
  font-weight: 500;
`

const BadgeColumn = styled.div`
  text-align: right;
`

const EntryColumnName = styled.div`
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  color: ${({ theme }) => theme.colors.metalGray};
`
const EntryDetails = styled(motion.div).attrs({
  layout: true,
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  margin: ${({ theme }) => theme.spacing.md}rem 0;
  background: ${({ theme }) => theme.colors.neutralGray};
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  flex-wrap: wrap;
`

const EntryDetailColumn = styled.div`
  flex: 1;
`

const EntryDetailValue = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md}rem;

  &:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.spacing.sm}rem;
  }
`
