import { AlertType, TextTooltip } from '@components'
import { InvoiceStatus } from '@constants'
import { useTheme } from '@hooks'
import { IInvoice, IPurchaseInvoice } from '@query'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FaCheckCircle,
  FaDotCircle,
  FaExclamationCircle,
  FaFeather,
  FaMinusCircle
} from 'react-icons/fa'

export enum CombinedStatus {
  DRAFT = 'DRAFT',
  EMPTY = 'EMPTY',
  CREDIT_LOSS = 'CREDIT_LOSS',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS'
}

interface CombinedStatusProps {
  status: CombinedStatus
}

const WarningIcon: React.FC = () => {
  const theme = useTheme()
  return <FaExclamationCircle style={{ fill: theme.colors.nocfoYellow }} />
}

export const DueDateStatusIcon: React.FC = () => {
  const { t } = useTranslation()
  const status = CombinedStatus.WARNING
  const icon = <WarningIcon />
  return <TextTooltip tooltip={t(`invoicing.combinedStatus.${status}`)}>{icon}</TextTooltip>
}

export const getCombinedInvoiceStatus = (invoice: IInvoice): CombinedStatusProps => {
  if (invoice.status === InvoiceStatus.DRAFT) {
    return {
      status: CombinedStatus.DRAFT
    }
  }

  if (invoice.status === InvoiceStatus.CREDIT_LOSS) {
    return {
      status: CombinedStatus.CREDIT_LOSS
    }
  }

  if (invoice.status === InvoiceStatus.PAID) {
    return {
      status: CombinedStatus.SUCCESS
    }
  }

  if (invoice.is_past_due) {
    return {
      status: CombinedStatus.WARNING
    }
  }

  return {
    status: CombinedStatus.EMPTY
  }
}

export const getCombinedPurchaseInvoiceStatus = (
  invoice: IPurchaseInvoice
): CombinedStatusProps => {
  if (invoice.is_paid) {
    return {
      status: CombinedStatus.SUCCESS
    }
  }

  return {
    status: CombinedStatus.EMPTY
  }
}

export const CombinedStatusIcon: React.FC<CombinedStatusProps> = ({ status }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const icon = useMemo(() => {
    if (status === CombinedStatus.DRAFT) {
      return <FaFeather style={{ fill: theme.colors.nocfoYellow }} />
    }

    if (status === CombinedStatus.SUCCESS) {
      return <FaCheckCircle style={{ fill: theme.colors.nocfoGreen }} />
    }

    if (status === CombinedStatus.WARNING) {
      return <WarningIcon />
    }

    if (status === CombinedStatus.CREDIT_LOSS) {
      return <FaMinusCircle style={{ fill: theme.colors.orange }} />
    }

    return <FaDotCircle style={{ fill: theme.colors.neutralGray }} />
  }, [status])

  if (status === CombinedStatus.EMPTY) return icon
  return <TextTooltip tooltip={t(`invoicing.combinedStatus.${status}`)}>{icon}</TextTooltip>
}

export const getCombinedInvoiceStatusAlertType = (status: CombinedStatus): AlertType =>
  ({
    [CombinedStatus.CREDIT_LOSS]: 'warning',
    [CombinedStatus.WARNING]: 'warning',
    [CombinedStatus.DRAFT]: 'warning',
    [CombinedStatus.EMPTY]: undefined,
    [CombinedStatus.SUCCESS]: 'success'
  }[status] as AlertType)
