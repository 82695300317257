import styled from 'styled-components'

const ACTIVE_DRAG_STYLE = ({ theme, isDragActive }) =>
  isDragActive
    ? `
  background: ${theme.colors.nocfoBlue}33;
  padding: ${({ theme }) => theme.spacing.md}rem;
  border-radius: 1rem;
  border: 3px dashed ${({ theme }) => theme.colors.nocfoBlue};
      `
    : `
      `

export const StyledEmptyAttachmentSelector = styled.div<{ isDragActive: boolean }>`
  padding: ${({ theme }) => theme.spacing.md}rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.metalGray};

  .add-more {
    font-size: ${({ theme }) => theme.fontSize.sm}rem;
  }

  ${ACTIVE_DRAG_STYLE}
`

export const StyledAttachmentSelector = styled.div<{ isDragActive: boolean }>`
  .add-more {
    color: ${({ theme }) => theme.colors.metalGray};
    font-size: ${({ theme }) => theme.fontSize.sm}rem;
    margin-top: ${({ theme }) => theme.spacing.xxs}rem;
    margin-bottom: ${({ theme }) => theme.spacing.xs}rem;
  }

  ${ACTIVE_DRAG_STYLE}
`
