import { AnimatePresence, motion } from 'framer-motion'
import React, { useMemo } from 'react'

interface AnimatedListProps {
  items?: Array<any>
  footer?: React.FC | React.ReactElement
  whileHover?: any
  whileTap?: any
}

const listVariants = {
  open: {
    opacity: 1,
    transition: { staggerChildren: 0.02, delayChildren: 0.05 }
  },
  closed: {
    opacity: 0,
    transition: { staggerChildren: 0.02, staggerDirection: -1 }
  }
}

export const AnimatedList: React.FC<AnimatedListProps> = ({
  items,
  footer,
  whileHover,
  whileTap
}) => {
  const uniqueKey = useMemo(() => Math.random().toString(36).substring(7), [])

  return (
    <>
      <AnimatePresence mode="popLayout" initial={false}>
        {items?.map((child: any) => {
          return (
            <motion.div
              layout="position"
              layoutId={`${uniqueKey}-${child.key}`}
              key={child.key}
              variants={listVariants}
              whileHover={whileHover}
              whileTap={whileTap}
            >
              {child}
            </motion.div>
          )
        })}
      </AnimatePresence>
      {footer && (
        <motion.div layout="position" key={`${uniqueKey}-animated-list-footer`}>
          <>{footer}</>
        </motion.div>
      )}
    </>
  )
}
