import { BlueprintType } from '@constants'
import { IBlueprint, IDocument, IImportData } from '@query'

const changeBlueprintValues = (
  blueprint: IBlueprint,
  importData: IImportData = null
): IBlueprint => {
  if (!importData) return blueprint

  const shouldNotClearValues =
    [
      ...(blueprint?.debet_entries || []),
      ...(blueprint?.credit_entries || []),
      ...(blueprint?.expense_entries || [])
    ].some(
      entry =>
        entry.account_id === importData.payment_account_id && entry.amount === importData.amount
    ) ||
    blueprint?.debet_entries?.reduce((acc, entry) => acc + (entry.amount || 0), 0) ===
      importData.amount ||
    blueprint?.credit_entries?.reduce((acc, entry) => acc + (entry.amount || 0), 0) -
      blueprint?.expense_entries?.reduce((acc, entry) => acc + (entry.amount || 0), 0) ===
      importData.amount

  // First, clear all values
  // =======================

  blueprint.debet_entries = blueprint?.debet_entries?.map(entry => ({
    ...entry,
    amount: shouldNotClearValues ? entry.amount : undefined
  }))
  blueprint.credit_entries = blueprint?.credit_entries?.map(entry => ({
    ...entry,
    amount: shouldNotClearValues ? entry.amount : undefined
  }))
  blueprint.expense_entries = blueprint?.expense_entries?.map(entry => ({
    ...entry,
    amount: shouldNotClearValues ? entry.amount : undefined
  }))

  // CASE: Has credit entries and no expense entries
  // > Check and change credit entries
  if (
    // Has same payment account id
    blueprint?.debet_account_id &&
    !blueprint?.credit_account_id &&
    blueprint?.credit_entries?.length == 1 &&
    (!blueprint?.expense_entries || blueprint?.expense_entries?.length === 0)
  ) {
    return {
      debet_account_id: blueprint?.debet_account_id,
      credit_entries: blueprint.credit_entries.map(entry => ({
        ...entry,
        amount: Math.abs(importData.amount)
      }))
    }
  }

  // CASE: Match with credit account id
  // > Check and change debet entries
  if (
    blueprint?.credit_account_id &&
    !blueprint?.debet_account_id &&
    blueprint?.debet_entries?.length == 1
  ) {
    return {
      credit_account_id: blueprint?.credit_account_id,
      debet_entries: blueprint.debet_entries.map(entry => ({
        ...entry,
        amount: Math.abs(importData.amount)
      }))
    }
  }

  // CASE: One credit and one debet entry
  // > Change amounts for both entries
  if (
    !blueprint?.debet_account_id &&
    !blueprint?.credit_account_id &&
    blueprint?.credit_entries?.length == 1 &&
    blueprint?.debet_entries?.length == 1 &&
    // Payment account is in credit or debet entries
    (blueprint?.credit_entries.some(entry => entry.account_id === importData.payment_account_id) ||
      blueprint?.debet_entries.some(entry => entry.account_id === importData.payment_account_id))
  ) {
    return {
      ...blueprint,
      credit_entries: blueprint.credit_entries.map(entry => ({
        ...entry,
        amount: Math.abs(importData.amount)
      })),
      debet_entries: blueprint.debet_entries.map(entry => ({
        ...entry,
        amount: Math.abs(importData.amount)
      }))
    }
  }

  if (
    blueprint?.credit_entries?.length >= 1 &&
    blueprint?.debet_entries?.length >= 1 &&
    // Payment account is in credit or debet entries
    (blueprint?.credit_entries.some(entry => entry.account_id === importData.payment_account_id) ||
      blueprint?.debet_entries.some(entry => entry.account_id === importData.payment_account_id))
  )
    return {
      credit_entries: blueprint.credit_entries.map(entry => ({
        ...entry,
        amount:
          entry.account_id === importData.payment_account_id
            ? Math.abs(importData.amount)
            : shouldNotClearValues
            ? entry.amount
            : undefined
      })),
      debet_entries: blueprint.debet_entries.map(entry => ({
        ...entry,
        amount:
          entry.account_id === importData.payment_account_id
            ? Math.abs(importData.amount)
            : shouldNotClearValues
            ? entry.amount
            : undefined
      }))
    }

  // Return unmodified blueprint
  return blueprint
}

export const getDocumentCopyValues = (
  document: IDocument,
  importData: IImportData = null
): Partial<IDocument> => ({
  description: document.description,
  contact_id: document.contact_id,
  blueprint_type: document.blueprint_type || BlueprintType.PURCHASE,
  blueprint: changeBlueprintValues(document.blueprint, importData)
})
