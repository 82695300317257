import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

interface Props {
  percent: number
  progressColor: string
  isReversed?: boolean
  progressBackgroundColor?: string
  initialAnimation?: boolean
}

export const Progress: React.FC<Props> = ({
  percent = 0,
  isReversed = false,
  progressColor,
  progressBackgroundColor = undefined,
  initialAnimation = false
}) => {
  return (
    <ProgressWrapper backgroundColor={progressBackgroundColor}>
      <ProgressBar
        initial={initialAnimation}
        isReversed={isReversed}
        animate={{
          width: `${Math.min(percent, 100)}%`,
          backgroundColor: progressColor
        }}
        transition={{ duration: 1 }}
      />
    </ProgressWrapper>
  )
}

const ProgressWrapper = styled.div<{ backgroundColor?: string }>`
  position: relative;
  width: 100%;
  height: 7px;
  background: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.neutralGray};
  border-radius: 100px;
  overflow: hidden;
`

const ProgressBar = styled(motion.div)<{ isReversed: boolean }>`
  position: absolute;
  ${({ isReversed }) => (isReversed ? 'right: 0;' : 'left: 0;')}
  top: 0;
  height: 100%;
  border-radius: 100px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
`
