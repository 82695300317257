import { Button } from '@components'
import { acceptInvitation, rejectInvitation, useCurrentUser } from '@query'
import { Card, Heading, Pane, Text, toaster } from 'evergreen-ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { FaCheck, FaTimes } from 'react-icons/fa'

export const Notifications: React.FC = () => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const { data: user, isLoading } = useCurrentUser()
  const notifications = user?.notifications || []

  interface NotificationProps {
    emoji?: string
    title?: string
    description?: string | React.ReactElement
    onAccept?: () => void
    onReject?: () => void
    onClick?: () => void
  }

  const options = {
    onSettled: async () => {
      await queryClient.invalidateQueries('user')
      await queryClient.invalidateQueries(['businesses'])

      toaster.notify(t('general.success'), {
        description: t('invitations.invitationAccepted'),
        duration: 5
      })
    }
  }

  const acceptMutation = useMutation<unknown, unknown, number>(
    invitationId => acceptInvitation({ invitationId }),
    options
  )
  const rejectMutation = useMutation<unknown, unknown, number>(
    invitationId => rejectInvitation({ invitationId }),
    options
  )

  const Notification: React.FC<NotificationProps> = ({
    emoji,
    title,
    description,
    onAccept,
    onReject
  }) => {
    return (
      <Card
        padding={24}
        borderRadius="1rem"
        background="white"
        border="3px solid #1b98f5"
        width="100%"
        marginBottom="1rem"
      >
        <Pane display="flex">
          {emoji && (
            <Pane alignSelf="center" marginRight={24} fontSize={32}>
              {emoji}
            </Pane>
          )}
          <Pane alignSelf="center" flex="1">
            {title && <Heading size={400}>{title}</Heading>}
            {description && <Text size={400}>{description}</Text>}
          </Pane>
        </Pane>

        {(onAccept || onReject) && (
          <Pane display="flex" justifyContent="flex-end" marginTop={24}>
            {onReject && (
              <Button
                icon={<FaTimes />}
                onClick={() => onReject()}
                style={{ marginRight: '0.6rem' }}
              >
                {t('invitations.reject')}
              </Button>
            )}
            {onAccept && (
              <Button icon={<FaCheck />} intent="primary" onClick={() => onAccept()}>
                {t('invitations.accept')}
              </Button>
            )}
          </Pane>
        )}
      </Card>
    )
  }

  const renderNotification = (notification: any) => {
    switch (notification.action) {
      case 'invitation':
        return (
          <Notification
            emoji="👋"
            title={`${notification.data.business_name}`}
            description={t('invitations.invitationMessage', {
              businessName: notification.data.business_name
            })}
            onAccept={() => {
              acceptMutation.mutate(notification.data.invitation_id)
            }}
            onReject={() => {
              rejectMutation.mutate(notification.data.invitation_id)
            }}
          />
        )

      default:
        return null
    }
  }

  return (
    <Pane maxWidth="100vw">
      {!isLoading &&
        notifications.length > 0 &&
        notifications.map((notification: any, index: number) => (
          <Pane key={`notification-${index}`}>{renderNotification(notification)}</Pane>
        ))}
    </Pane>
  )
}
