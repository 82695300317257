import { Button } from '@components'
import { AnimatePresence } from 'framer-motion'
import React, { ReactNode, useRef } from 'react'
import { createPortal } from 'react-dom'
import {
  Backdrop,
  PromptClose,
  PromptContent,
  PromptFooter,
  PromptHeader,
  PromptModal,
  PromptTitle,
  PromptWrapper
} from './Prompt.styled'

type ActionButtonProps = {
  text?: string
  icon?: React.ReactElement
  intent?: 'success' | 'primary' | 'default' | 'warning' | 'danger'
  action?: (any) => void
  disabled?: boolean
  showSpinner?: boolean
  link?: string
}

interface Props {
  title: string
  description: string | ReactNode
  buttons: ActionButtonProps[]
  isVisible: boolean
  onClose: () => void
}

export const Prompt: React.FC<Props> = ({ title, description, isVisible, buttons, onClose }) => {
  const rootRef = useRef<HTMLElement>(document.getElementById('modal-root'))

  const LinkWrapper = React.memo<{ button: React.ReactElement; link?: string }>(
    ({ button, link }) => (link ? <a href={link}>{button}</a> : button)
  )

  return createPortal(
    <AnimatePresence>
      {isVisible && (
        <Backdrop onClick={() => onClose()}>
          <PromptWrapper>
            <PromptModal onClick={e => e.stopPropagation()}>
              <PromptHeader>
                <PromptTitle>{title}</PromptTitle>
                <PromptClose role="button" onClick={() => onClose()} />
              </PromptHeader>
              <PromptContent>{description}</PromptContent>
              <PromptFooter>
                {buttons?.map(
                  (
                    {
                      text,
                      icon,
                      intent,
                      action,
                      disabled = false,
                      showSpinner = false,
                      link = undefined
                    },
                    index
                  ) => (
                    <LinkWrapper
                      key={`button-${index}`}
                      link={link}
                      button={
                        <Button
                          type="button"
                          intent={intent}
                          icon={icon}
                          onClick={action}
                          disabled={disabled}
                          showSpinner={showSpinner}
                        >
                          {text}
                        </Button>
                      }
                    />
                  )
                )}
              </PromptFooter>
            </PromptModal>
          </PromptWrapper>
        </Backdrop>
      )}
    </AnimatePresence>,
    rootRef.current
  )
}
