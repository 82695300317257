import {
  Alert,
  BusinessLogo,
  Button,
  HeaderBlock,
  Input,
  Prompt,
  Select,
  ToggleContainer,
  UploadFilesButton
} from '@components'
import { VAT_PERIOD_OPTIONS } from '@constants'
import { useBusinessContext } from '@context'
import {
  deleteBusiness,
  deleteLogo,
  fetchBusiness,
  IBusiness,
  updateBusiness,
  uploadLogo
} from '@query'
import { setAPIErrors } from '@utils'
import { Pane, Switch, Text, toaster } from 'evergreen-ui'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FaTrashAlt } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'

export const BusinessSettings: React.FC = () => {
  const queryClient = useQueryClient()
  const { businessId } = useBusinessContext()
  const [showDelete, setShowDelete] = useState(false)
  const [t] = useTranslation()

  const { data } = useQuery([businessId], () => fetchBusiness({ businessId }), {
    refetchOnMount: false,
    onSuccess: data => {
      reset(getFormData(data))
    }
  })

  const getFormData = (data: Partial<IBusiness>) => ({
    business_id: data?.business_id,
    name: data?.name,
    default_vat_period: data?.default_vat_period,
    has_history_before_nocfo: data?.has_history_before_nocfo,
    contact_phone: data?.contact_phone,
    business_street: data?.business_street,
    business_postal_code: data?.business_postal_code,
    business_city: data?.business_city,
    business_country: data?.business_country
  })

  const {
    register,
    handleSubmit,
    setError,
    reset,
    control,
    formState: { isDirty, isSubmitting, errors }
  } = useForm({ defaultValues: getFormData(data) })

  const updateMutation = useMutation<IBusiness, unknown, Partial<IBusiness>>(
    data => updateBusiness({ businessId }, data),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(['businesses']),
          queryClient.invalidateQueries([businessId]),
          queryClient.invalidateQueries([businessId, 'features'])
        ])
        toaster.success(t('general.changesSaved'))
      },
      onError: ({ status, data }) => {
        status === 400 && setAPIErrors(data, setError)
      }
    }
  )

  const deleteMutation = useMutation(() => deleteBusiness({ businessId }), {
    onSuccess: () => {
      window.location.href = '/'
    }
  })

  const deleteLogoMutation = useMutation(() => deleteLogo({ businessId }), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([businessId])
      await queryClient.invalidateQueries(['businesses'])
    }
  })

  return (
    <StyledWrapper>
      <HeaderBlock header={t('settings.sections.general')} subHeader="" />

      <Pane margin={'1rem'}>
        <Pane marginBottom="2rem">
          <BusinessLogo name={data?.name} src={data?.logo} />
          <br />
          <br />

          <Pane display="flex" gap="0.4rem">
            <UploadFilesButton
              fileTypes={['.png', '.jpg', '.jpeg']}
              uploadFile={file =>
                uploadLogo({ businessId }, file).then(() => {
                  queryClient.invalidateQueries(['businesses'])
                  queryClient.invalidateQueries([businessId])
                })
              }
            >
              {t('settings.uploadLogo')}
            </UploadFilesButton>

            {data?.logo && (
              <Button
                intent="danger"
                isSecondary
                icon={<FaTrashAlt />}
                onClick={() => deleteLogoMutation.mutate()}
                showSpinner={deleteLogoMutation.isLoading}
              >
                {t('settings.deleteLogo')}
              </Button>
            )}
          </Pane>
        </Pane>

        <form onSubmit={handleSubmit(data => updateMutation.mutate(data))}>
          <Pane>
            <StyledHeader>{t('settings.titleInfo')}</StyledHeader>

            <fieldset disabled={true}>
              <Input label={t('general.businessId')} {...register('business_id')} />
            </fieldset>
            <Input
              label={t('general.name')}
              required={true}
              errors={errors.name}
              {...register('name')}
            />

            <Select
              label={t('settings.vatPeriod')}
              {...register('default_vat_period', { valueAsNumber: true })}
            >
              {VAT_PERIOD_OPTIONS.map(({ labelKey, value }) => (
                <option key={value} value={value}>
                  {t(labelKey)}
                </option>
              ))}
            </Select>

            <StyledHeader>{t('settings.businessAddress')}</StyledHeader>
            <Alert
              type="warning"
              isVisible={!data?.has_business_address}
              description={t('settings.businessAddressAlert')}
            />
            <Input
              label={t('general.address.street')}
              errors={errors.business_street}
              required={true}
              {...register('business_street')}
            />
            <Input
              label={t('general.address.postalCode')}
              errors={errors.business_postal_code}
              required={true}
              {...register('business_postal_code')}
            />
            <Input
              label={t('general.address.city')}
              errors={errors.business_city}
              required={true}
              {...register('business_city')}
            />
            <Input
              label={t('general.address.country')}
              errors={errors.business_country}
              required={true}
              {...register('business_country')}
            />

            <StyledHeader>{t('settings.openingBalance')}</StyledHeader>
            <Controller
              name="has_history_before_nocfo"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Pane marginTop={16} marginBottom={16} padding={8}>
                    <label htmlFor="has_history_before_nocfo">
                      <Text>{t('settings.showOpeningBalance')}</Text>
                    </label>
                    <Switch
                      id="has_history_before_nocfo"
                      height={24}
                      onChange={e => onChange(e.currentTarget.checked)}
                      checked={value}
                    />
                  </Pane>
                )
              }}
            />
          </Pane>

          <Pane marginBottom={16}>
            <StyledHeader>{t('settings.titleContact')}</StyledHeader>
            <fieldset disabled={true}>
              <Input label={t('settings.contactPerson')} value={data?.owner_name} />
              <Input label={t('settings.contactEmail')} value={data?.owner_email} />
            </fieldset>
            <Input
              label={t('settings.contactPhone')}
              required={false}
              errors={errors.contact_phone}
              {...register('contact_phone')}
            />
          </Pane>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '32px' }}>
            <Button intent="success" disabled={!isDirty} showSpinner={isSubmitting}>
              {t('general.save')}
            </Button>
          </div>
        </form>

        <ToggleContainer title={t('settings.titleDangerzone')}>
          <StyledHeader>{t('settings.titleDeleteBusiness')}</StyledHeader>
          <Pane display="flex" justifyContent="flex-end">
            <Button onClick={() => setShowDelete(true)} intent="danger">
              {t('settings.deleteBusiness')}
            </Button>
          </Pane>
        </ToggleContainer>
      </Pane>

      <Prompt
        title={t('settings.deletePromptTitle')}
        description={t('settings.deletePromptContent')}
        isVisible={showDelete}
        onClose={() => setShowDelete(false)}
        buttons={[
          {
            text: t('general.cancel'),
            action: () => setShowDelete(false)
          },
          {
            text: t('settings.deleteBusiness'),
            intent: 'danger',
            action: () => deleteMutation.mutate()
          }
        ]}
      />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  background: white;
  height: 100%;
  overflow: auto;
`
const StyledHeader = styled.h1`
  color: ${({ theme }) => theme.colors.neutralBlack};
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  font-weight: bold;
  margin-top: ${({ theme }) => theme.spacing.lg}rem;
  margin-bottom: ${({ theme }) => theme.spacing.sm}rem;
`
