import dayjs from 'dayjs'
import { useMemo } from 'react'

interface Props<T> {
  items: T[]
  itemDateKey: string
  itemDateFormat?: string
  dateFormat?: string
}

interface IDateBadge {
  id: string
  label: string
}

interface BadgeElement {
  type: 'badge'
  item: IDateBadge
}

interface OriginalElement<T> {
  type: 'item'
  item: T
  extra: {
    isFirstOfType: boolean
    isLastOfType: boolean
  }
}

type BadgesAndElements<T> = (BadgeElement | OriginalElement<T>)[]

const _findByKey = (json: Record<string, any>, keys: string[]) => {
  if (!json) return undefined
  const result = json[keys.shift()]
  if (keys.length === 0) return result
  return _findByKey(result, keys)
}

export const useDateBadgedList = <T>({
  items,
  itemDateKey,
  itemDateFormat,
  dateFormat = 'MMMM YYYY'
}: Props<T>): BadgesAndElements<T> =>
  useMemo(() => {
    const itemsAndBadges = []

    items.forEach((thisItem, index) => {
      const lastItem = items[index - 1]
      const nextItem = items[index + 1]

      const lastDateString = _findByKey(lastItem, itemDateKey.split('.'))
      const thisDateString = _findByKey(thisItem, itemDateKey.split('.'))
      const nextDateString = _findByKey(nextItem, itemDateKey.split('.'))

      const thisDate = dayjs(thisDateString, itemDateFormat).format(dateFormat)
      const lastDate = lastItem && dayjs(lastDateString, itemDateFormat).format(dateFormat)
      const nextDate = nextItem && dayjs(nextDateString, itemDateFormat).format(dateFormat)

      const isFirstOfType = thisDate !== lastDate
      const isLastOfType = thisDate !== nextDate

      if (isFirstOfType) {
        itemsAndBadges.push({
          type: 'badge',
          item: {
            id: thisDate,
            label: thisDate
          }
        })
      }

      itemsAndBadges.push({
        type: 'item',
        item: thisItem,
        extra: {
          isFirstOfType,
          isLastOfType
        }
      })
    })

    return itemsAndBadges
  }, [items])
