import { Switch } from 'evergreen-ui'
import React, { useState } from 'react'
import styled from 'styled-components'

interface Props {
  value: any
  updateData: (any) => Promise<any>
  row: any
  name: string
}

export const EditableToggle: React.FC<Props> = ({ value, name, row: { original }, updateData }) => {
  const [isLoading, setLoading] = useState(false)

  const onToggle = () => {
    setLoading(true)
    return updateData({ accountId: original.id, data: { [name]: !value } }).finally(() =>
      setLoading(false)
    )
  }

  const handleKeyUp = e => {
    if (e.key === 'Enter') {
      onToggle()
    }
  }

  return (
    <SwitchWrapper>
      <Switch checked={value} disabled={isLoading} onChange={onToggle} onKeyUp={handleKeyUp} />
    </SwitchWrapper>
  )
}

const SwitchWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.sm}rem ${({ theme }) => theme.spacing.sm}rem;
  height: 18px;
`
