import HolviImgSrc from '@assets/Holvi_Wordmark.png'
import { Button, FlipCard } from '@components'
import {
  SlideContent,
  SlideFooter,
  SlideTitle,
  Spacer
} from '@pages/BankIntegrationOnboardingPage/slides/slide.styled'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaArrowRight } from 'react-icons/fa'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'

interface Props {
  onDone: () => void
  goBack: () => void
}

export const PartnerSlide: React.FC<Props> = ({ onDone, goBack }) => {
  const { t } = useTranslation()
  return (
    <>
      <SlideContent>
        <SlideTitle>{t('bankOnboarding.partnerSlide.title')}</SlideTitle>

        <Partners>
          <FlipCard
            enableFlipping={false}
            maxPerspectiveRotation={5}
            renderFront={() => (
              <PartnerCard className="holvi" href={t('bankOnboarding.partnerSlide.holvi.link')}>
                <PartnerLogo src={HolviImgSrc} />
                <PartnerCardDescription>
                  <ReactMarkdown>
                    {t('bankOnboarding.partnerSlide.holvi.description')}
                  </ReactMarkdown>
                </PartnerCardDescription>
                <div>
                  <Button intent="primary" iconRight={<FaArrowRight />}>
                    {t('bankOnboarding.partnerSlide.holvi.button')}
                  </Button>
                </div>
              </PartnerCard>
            )}
          />
        </Partners>
      </SlideContent>
      <SlideFooter>
        <Button onClick={() => goBack()}>{t('general.back')}</Button>
        <Spacer />
        <Button onClick={() => onDone()} iconRight={<FaArrowRight />} intent="default">
          {t('general.skip')}
        </Button>
      </SlideFooter>
    </>
  )
}

const Partners = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.md}rem;
  gap: ${({ theme }) => theme.spacing.md}rem;
  margin-top: 2rem;
`

const PartnerCard = styled.a.attrs({
  target: '_blank',
  rel: 'noreferrer'
})`
  border-radius: 1rem;
  padding: ${({ theme }) => theme.spacing.lg}rem;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md}rem;
  color: ${({ theme }) => theme.colors.neutralBlack};
  font-weight: 500;

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.neutralBlack};
  }

  &.holvi {
    background: #bee6f066;
  }

  &.revolut {
    background: ${({ theme }) => theme.colors.neutralBlack}11;
  }
`

const PartnerLogo = styled.img`
  display: block;
  max-width: 120px;
  width: auto;
  height: auto;
`

const PartnerCardDescription = styled.div`
  *:last-child {
    margin-bottom: 0;
  }
`
