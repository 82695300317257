import { BasicPage, Button, Checkbox, Alert } from '@components'
import imgSrc from '@assets/undraw/undraw_transfer_money_re_6o1h.svg'
import React, { useState } from 'react'
import { FaArrowLeft, FaCheck } from 'react-icons/fa'
import styled from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { enableSalaxy } from '@query/salaxy.ts'
import { useMutation, useQueryClient } from 'react-query'
import { useBusinessContext } from '@context'
import { toast } from 'react-hot-toast'
import { getDashboardPageUrl } from '@constants'
import { useHistory } from 'react-router-dom'

export const SalariesOnboard: React.FC = () => {
  const { businessId } = useBusinessContext()
  const [salaxyErrorUri, setSalaxyErrorUri] = useState<string>()
  const [hasConsent, setConsent] = useState(false)
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const history = useHistory()

  const { mutate, isLoading } = useMutation(() => enableSalaxy({ businessId }), {
    onMutate: () => {
      setSalaxyErrorUri(undefined)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([businessId])
      toast.success(t('general.success'))
    },
    onError: ({ data, status }) => {
      // CASE: Already Palkkaus.fi customer
      if (status == 400) {
        setSalaxyErrorUri(data?.error_uri)
      } else {
        toast.error(t('general.error'))
      }
    }
  })

  return (
    <BasicPage title={t('salaries.onboard.title')} imageUrl={imgSrc}>
      <TextContent>
        <p>{t('salaries.onboard.description')}</p>
        <Alert
          type="warning"
          isVisible={!!salaxyErrorUri}
          title={t('salaries.onboard.alreadySalaxyCustomerTitle')}
          description={
            <>
              <p>{t('salaries.onboard.alreadySalaxyCustomerDescription')}</p>
              <a href={salaxyErrorUri} target="_blank" rel="noreferrer">
                <Button intent="warning">
                  {t('salaries.onboard.alreadySalaxyCustomerButton')}
                </Button>
              </a>
            </>
          }
        />
        <p>
          <Checkbox
            id="consent-checkbox"
            checked={hasConsent}
            onChange={e => setConsent(e.target.checked)}
            label={
              <Trans i18nKey={'salaries.onboard.tosCheckbox'}>
                <a target="_blank" rel="noreferrer" href={t('link.pricing')} />
              </Trans>
            }
          />
        </p>
      </TextContent>

      <ButtonContainer>
        <Button
          type="button"
          icon={<FaArrowLeft />}
          disabled={isLoading}
          onClick={() => history.push(getDashboardPageUrl(businessId))}
        >
          {t('general.back')}
        </Button>
        <Button
          type="button"
          intent="success"
          icon={<FaCheck />}
          disabled={!hasConsent || isLoading}
          onClick={() => mutate()}
          showSpinner={isLoading}
        >
          {'Aktivoi palkanmaksu'}
        </Button>
      </ButtonContainer>
    </BasicPage>
  )
}

const TextContent = styled.div`
  flex: 1;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  margin-top: ${({ theme }) => theme.spacing.md}rem;
`
