import { Button, HeaderBlock, TeaserView } from '@components'
import { UserRole } from '@constants'
import { FeatureWrapper, PermissionBoundary, useBusinessContext } from '@context'
import { fetchPeople } from '@query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaPlus } from 'react-icons/fa'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { InvitationModal } from './InvitationModal'
import { InvitationTable } from './InvitationTable'
import { UserItem } from './UserItem'

export const PeopleSettings: React.FC = () => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const { data } = useQuery([businessId, 'people'], () =>
    fetchPeople({ businessId }, { page_size: 1000 })
  )
  const userships = data?.results || []

  return (
    <StyledWrapper>
      <HeaderBlock header={t('settings.sections.people')} subHeader="" />

      <FeatureWrapper reverse feature="feature_unlimited_users">
        <ContentWrapper>
          <TeaserView
            header={t('teaser.people.header')}
            subHeader={t('teaser.people.subHeader')}
            features={[
              t('teaser.feature.8'),
              t('teaser.feature.9'),
              t('teaser.feature.2'),
              t('teaser.feature.5')
            ]}
          />
        </ContentWrapper>
      </FeatureWrapper>

      <FeatureWrapper feature="feature_unlimited_users">
        <ContentWrapper>
          {userships.map(usership => (
            <UserItem key={`usership-${usership.id}`} usership={usership} />
          ))}
        </ContentWrapper>

        <PermissionBoundary requireRole={UserRole.ADMIN}>
          <ContentWrapper>
            <InvitationSection>
              <h2>{t('settings.people.invitationTitle')}</h2>
              <InvitationTable />

              <ButtonWrapper>
                <InvitationModal>
                  <Button isSecondary={true} icon={<FaPlus />}>
                    {t('settings.people.sendInvitation')}
                  </Button>
                </InvitationModal>
              </ButtonWrapper>
            </InvitationSection>
          </ContentWrapper>
        </PermissionBoundary>
      </FeatureWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  background: white;
  height: 100%;
  overflow: auto;
`

const ContentWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing.md}rem;
  margin-top: 0;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: ${({ theme }) => theme.fontSize.md}rem;
  }
`

const InvitationSection = styled.div`
  padding: ${({ theme }) => theme.spacing.md}rem;
  background: ${({ theme }) => theme.colors.neutralGray};
  border-radius: 1rem;
`

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing.sm}rem;
  justify-content: flex-end;
`
