import { AnimatePresence, motion } from 'framer-motion'
import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

interface Props {
  anchorRef: RefObject<any>
  children: React.ReactNode
}

interface DropdownProps {
  x?: number
  y?: number
  width?: number
}

const randomString = () => (Math.random() + 1).toString(36).substring(7)

export const Dropdown: React.FC<Props> = ({ anchorRef, children }) => {
  const [props, setPosition] = useState<DropdownProps>({ x: 0, y: 0, width: 0 })
  const rootRef = useRef<HTMLElement>(document.getElementById('dropdown-root'))
  const uniqueKey = useMemo(() => randomString(), [])

  const updatePosition = () => {
    if (!anchorRef.current) return

    const { top, left, width, height } = anchorRef.current.getBoundingClientRect()
    setPosition({
      x: Math.round(left),
      y: Math.round(top + height - window.innerHeight),
      width: Math.round(width)
    })
  }

  useEffect(() => {
    updatePosition()
    const interval = setInterval(() => updatePosition(), 500)
    return () => clearInterval(interval)
  }, [])

  const isHidden = !props.x || !props.y || !props.width

  return createPortal(
    <AnimatePresence>
      {!isHidden && (
        <StyledDropdown
          key={`dropdown-${uniqueKey}`}
          initial={{ x: props.x, y: props.y + 10, width: props.width, opacity: 0 }}
          animate={{ x: props.x, y: props.y, width: props.width, opacity: 1 }}
          exit={{ x: props.x, y: props.y, width: props.width, opacity: 0 }}
        >
          {children}
        </StyledDropdown>
      )}
    </AnimatePresence>,
    rootRef.current
  )
}

const StyledDropdown = styled(motion.div)`
  position: fixed;
  z-index: 10000;
  min-width: 300px;
  background: white;
  border-radius: 1rem;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  padding: ${({ theme }) => theme.spacing.sm}rem;
`
