import { ACCOUNT_TYPES } from '@constants'
import { useBusinessContext, useFeatureContext } from '@context'
import { IAccount } from '@query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaExpandAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useFlexLayout, useTable } from 'react-table'
import styled from 'styled-components'
import { EditableCell } from './EditableCell'
import { EditableToggle } from './EditableToggle'
import { EditableVATCell } from './EditableVATCell'
import { IUpdateTabularAccountProps } from '@pages/AccountsPage/sections'

interface Props {
  accounts: IAccount[]
  headerHeight: number
  rowHeight: number
  updateData: (data: IUpdateTabularAccountProps) => Promise<any>
}

export const TabularAccountTable: React.FC<Props> = ({
  accounts,
  updateData,
  rowHeight,
  headerHeight
}) => {
  const [t] = useTranslation()

  const { has_history } = useFeatureContext()
  const { businessId } = useBusinessContext()

  const columns = React.useMemo(
    () =>
      [
        {
          Header: t('accounts.tabular.cols.toggleShow'),
          accessor: 'is_shown',
          minWidth: 70,
          maxWidth: 70,
          Cell: ({ value, row }) => (
            <EditableToggle name="is_shown" value={value} row={row} updateData={updateData} />
          )
        },
        {
          Header: t('accounts.tabular.cols.number'),
          accessor: 'number',
          minWidth: 100,
          maxWidth: 120,
          Cell: ({ value, row }) => (
            <EditableCell
              name="number"
              label={t('accounts.number')}
              info={t('accounts.numberInfo')}
              isNumber={true}
              value={value}
              row={row}
              formatter={value => <code>{value}</code>}
              updateData={updateData}
            />
          )
        },
        {
          Header: t('accounts.tabular.cols.name'),
          accessor: 'name',
          minWidth: 300,
          maxWidth: 600,
          Cell: ({ value, row }) => (
            <EditableCell
              name="name"
              label={t('accounts.name')}
              info={t('accounts.nameInfo')}
              value={value}
              row={row}
              updateData={updateData}
            />
          )
        },
        {
          Header: t('accounts.tabular.cols.type'),
          accessor: 'type',
          minWidth: 100,
          maxWidth: 120,
          Cell: ({ value, row }) => (
            <EditableCell
              info={t('accounts.accountTypeInfo')}
              label={t('accounts.accountType')}
              name="type"
              value={value}
              row={row}
              updateData={updateData}
              options={ACCOUNT_TYPES.map(({ value, labelKey }) => ({ value, label: t(labelKey) }))}
            />
          )
        },
        {
          id: 'vat_setting',
          Header: t('accounts.tabular.cols.vat'),
          accessor: original => (
            <EditableVATCell
              account={original}
              vatCodeValue={original.default_vat_code}
              vatRateLabelValue={original.default_vat_rate_label}
              updateData={updateData}
            />
          ),
          minWidth: 100,
          maxWidth: 120
        },
        {
          Header: t('accounts.tabular.cols.openingBalance'),
          accessor: 'opening_balance',
          minWidth: 150,
          maxWidth: 150,
          isVisible: has_history,
          Cell: ({ value, row }) => (
            <EditableCell
              info={t('accounts.initialBalanceInfo')}
              label={t('accounts.initialBalance')}
              name="opening_balance"
              isNumber={true}
              value={value}
              row={row}
              updateData={updateData}
            />
          )
        },
        {
          id: 'menu',
          Header: '',
          Cell: ({ row: { original } }) => (
            <MenuIconWrapper style={{ height: rowHeight }}>
              <Link to={`/${businessId}/accounts?editMode=tabular&id=${original.id}`}>
                <FaExpandAlt className="menu-icon" />
              </Link>
            </MenuIconWrapper>
          ),
          minWidth: 40,
          maxWidth: 40
        }
      ].filter(c => c.isVisible !== false),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      maxWidth: 200,
      minWidth: 100
    }),
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: accounts,
      defaultColumn,
      updateData
    } as any,
    useFlexLayout
  )

  return (
    <StyledTableWrapper>
      <div {...getTableProps()} className="table">
        <div>
          {headerGroups.map((headerGroup, i) => (
            <div
              key={i}
              {...headerGroup.getHeaderGroupProps({
                style: { borderBottom: '1px solid #eee', height: headerHeight }
              })}
              className="tr"
            >
              {headerGroup.headers.map((column, i) => (
                <StyledHeader key={i} {...column.getHeaderProps()} className="th">
                  {column.render('Header')}
                </StyledHeader>
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <StyledRow
                key={i}
                {...row.getRowProps({
                  style: { borderBottom: '1px solid #eee', height: rowHeight }
                })}
                className="tr"
              >
                {row.cells.map((cell, i) => {
                  return (
                    <StyledCell key={i} {...cell.getCellProps()} className="td">
                      {cell.render('Cell')}
                    </StyledCell>
                  )
                })}
              </StyledRow>
            )
          })}
        </div>
      </div>
    </StyledTableWrapper>
  )
}

const StyledTableWrapper = styled.div`
  padding: 0rem;

  .table {
    background: white;
    border-radius: 1rem;
    width: 100%;
  }
`

const StyledHeader = styled.div`
  white-space: nowrap;
  text-align: left;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.metalGray};
  padding: ${({ theme }) => theme.spacing.xs}rem ${({ theme }) => theme.spacing.sm}rem;
`

const StyledCell = styled.div`
  white-space: nowrap;
  text-align: left;
  color: ${({ theme }) => theme.colors.neutralBlack};
`

const StyledRow = styled.div`
  &:hover {
    .menu-icon {
      opacity: 1;
    }
  }
`

const MenuIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .menu-icon {
    opacity: 0;
    fill: ${({ theme }) => theme.colors.iconGray};
  }
`
