import { Badge } from '@components'
import { VAT_CODE_OPTIONS } from '@constants'
import { FeatureWrapper, useBusinessContext } from '@context'
import { IAccount } from '@query'
import { useScreen } from '@utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface Props {
  account: IAccount
  isActive: boolean
}

export const AccountListItem: React.FC<Props> = ({ account, isActive }) => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const { isDesktop } = useScreen()

  const vatOption = VAT_CODE_OPTIONS.find(option => option.value === account.default_vat_code)

  return (
    <StyledAccount selected={isActive} to={`/${businessId}/accounts?id=${account.id}`}>
      <AccountDetails>
        <AccountNumber>{account.number}</AccountNumber>

        <AccountBadges>
          <FeatureWrapper feature="vat_obliged">
            {isDesktop && account.default_vat_code !== 3 && (
              <Badge type="success">{t(vatOption.label)}</Badge>
            )}
            {account.default_vat_code !== 3 && (
              <Badge type="info">
                {t('accounts.overview.vatValue', { vatRate: account.default_vat_rate })}
              </Badge>
            )}
          </FeatureWrapper>
        </AccountBadges>
      </AccountDetails>
      <AccountDetails>
        <AccountHeader>{account.name}</AccountHeader>
      </AccountDetails>
    </StyledAccount>
  )
}

const StyledAccount = styled(Link)<{ selected: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  padding: ${({ theme }) => theme.spacing.md}rem;
  border-radius: 0;
  border-bottom: 0.5px solid #f6f7f9;

  background: ${({ theme, selected }) =>
    selected ? theme.colors.defaultHover : theme.colors.neutralWhite};

  &:hover {
    text-decoration: none;
    background: ${({ theme }) => theme.colors.defaultHover};
  }
`

const AccountDetails = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  white-space: nowrap;

  &:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.spacing.xxs}rem;
  }
`

const AccountNumber = styled.code`
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  color: ${({ theme }) => theme.colors.metalGray};
  width: 3rem;
  min-width: 3rem;
  font-weight: bold;
`

const AccountHeader = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.neutralBlack};
`

const AccountBadges = styled.div`
  & > * {
    &:not(:first-of-type) {
      margin-left: ${({ theme }) => theme.spacing.xs}rem;
    }
  }
`
