import { ActionFunction, handleReturn, QueryFunction } from '@root/query'
import { network } from '@utils'
import { KraviaActivityStatus, KraviaInvoiceStatus } from '@constants'

export interface IKYCData {
  kyc_answered_at?: string
  type_of_business_description?: string
  does_foreign_invoicing?: boolean
  does_foreign_invoicing_description?: string
  has_pep_connections?: boolean
  has_pep_connections_description?: string
  is_under_financial_sanctions?: boolean
  is_under_financial_sanctions_description?: string
}

export interface IKraviaOnboardInfo {
  kravia_enabled: boolean
  kravia_verified: boolean
}

interface KraviaOnboardBusinessProps {
  businessId: string
}

export const kraviaOnboardBusiness: ActionFunction<
  KraviaOnboardBusinessProps,
  IKYCData,
  IKraviaOnboardInfo
> = async ({ businessId }, requestData) => {
  const url = `/v1/integrations/business/${businessId}/kravia/onboard/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'POST',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export const kraviaInviteCurrentUser: ActionFunction<
  KraviaOnboardBusinessProps,
  void,
  void
> = async ({ businessId }) => {
  const url = `/v1/integrations/business/${businessId}/kravia/invite/`
  const { status } = await network.httpClient.request({
    url,
    method: 'POST',
    validateStatus: () => true
  })
  return handleReturn({ status })
}

export const kraviaOnboardStatus: QueryFunction<
  KraviaOnboardBusinessProps,
  IKraviaOnboardInfo
> = async ({ businessId }) => {
  const url = `/v1/integrations/business/${businessId}/kravia/onboard/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'GET',
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

interface KraviaInvoiceInstanceProps {
  businessId: string
  invoiceId: number
}

export const kraviaRegisterInvoice: ActionFunction<
  KraviaInvoiceInstanceProps,
  void,
  void
> = async ({ businessId, invoiceId }) => {
  const url = `/v1/integrations/business/${businessId}/kravia/invoice/${invoiceId}/register/`
  const { status } = await network.httpClient.request({
    url,
    method: 'POST',
    validateStatus: () => true
  })
  return handleReturn({ status })
}

export interface KraviaRegisterPaymentProps {
  payment_date: string
  amount: number
}

export const kraviaRegisterInvoicePayment: ActionFunction<
  KraviaInvoiceInstanceProps,
  KraviaRegisterPaymentProps,
  void
> = async ({ businessId, invoiceId }, requestData) => {
  const url = `/v1/integrations/business/${businessId}/kravia/invoice/${invoiceId}/register/`
  const { status } = await network.httpClient.request({
    url,
    method: 'POST',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ status })
}

export const kraviaWithdrawInvoice: ActionFunction<
  KraviaInvoiceInstanceProps,
  void,
  void
> = async ({ businessId, invoiceId }) => {
  const url = `/v1/integrations/business/${businessId}/kravia/invoice/${invoiceId}/action/withdraw/`
  const { status } = await network.httpClient.request({
    url,
    method: 'POST',
    validateStatus: () => true
  })
  return handleReturn({ status })
}

export const kraviaSetInvoiceAsPaid: ActionFunction<
  KraviaInvoiceInstanceProps,
  void,
  void
> = async ({ businessId, invoiceId }) => {
  const url = `/v1/integrations/business/${businessId}/kravia/invoice/${invoiceId}/action/mark_as_paid/`
  const { status } = await network.httpClient.request({
    url,
    method: 'POST',
    validateStatus: () => true
  })
  return handleReturn({ status })
}

export const kraviaSetInvoiceAsCreditLoss: ActionFunction<
  KraviaInvoiceInstanceProps,
  void,
  void
> = async ({ businessId, invoiceId }) => {
  const url = `/v1/integrations/business/${businessId}/kravia/invoice/${invoiceId}/action/mark_as_credit_loss/`
  const { status } = await network.httpClient.request({
    url,
    method: 'POST',
    validateStatus: () => true
  })
  return handleReturn({ status })
}

export interface IKraviaTranslated {
  fi: string
  en: string
  sv: string
}

export interface IKraviaInvoiceStatusInfo {
  status: KraviaInvoiceStatus
  status_detail: IKraviaTranslated
  original_amount: number
  remaining_amount: number
}

export interface IKraviaInvoiceHistory {
  created_at: string
  approved_at: string
  completed_at: string
  status: KraviaActivityStatus
  activity_type: string
  activity_type_detail: IKraviaTranslated
}

export interface IKraviaPayment {
  date: string
  amount: number
}

export interface IKraviaInvoiceDetails {
  invoice: IKraviaInvoiceStatusInfo
  invoice_url: string
  payments: IKraviaPayment[]
  history: IKraviaInvoiceHistory[]
}

export const fetchKraviaInvoiceDetails: QueryFunction<
  KraviaInvoiceInstanceProps,
  IKraviaInvoiceDetails
> = async ({ businessId, invoiceId }) => {
  const url = `/v1/integrations/business/${businessId}/kravia/invoice/${invoiceId}/details/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'GET',
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}
