import {
  AuditTrailChangeType,
  AuditTrailFields,
  AuditTrailItemType,
  IAuditTrailComment,
  IAuditTrailObjectBase,
  IAuditTrailWrapper,
  IChangeDocument
} from '@root/query/auditTrail'
import { theme } from '@root/styles'
import { formatDate, formatRelativeTime } from '@root/utils'
import dayjs from 'dayjs'
import { Trans, useTranslation } from 'react-i18next'
import { FaFlag, FaLock, FaLockOpen, FaQuestionCircle } from 'react-icons/fa'
import UserDetails from './UserDetails'
import styled from 'styled-components'
import { ReactElement, ReactNode, useMemo } from 'react'
import { IDocument, useCurrentUser } from '@root/query'
import { TextTooltip } from '@root/components/InfoPopup'

interface IHeaderBlock {
  auditRecord: IAuditTrailWrapper
  document: IDocument
}

const formatDateWithPastReference = (date: Date | dayjs.Dayjs | string | undefined): string => {
  const formattedDate = formatDate(date)
  const now = dayjs()
  const day = dayjs(date)

  if (day.diff(dayjs(now), 'day') >= -2) {
    return formatRelativeTime(day)
  }
  return formattedDate
}

export const HeaderBlock: React.FC<IHeaderBlock> = ({ auditRecord, document }) => {
  const isAuditAction = auditRecord.type === AuditTrailItemType.ACTIVITY
  const isAuditComment = auditRecord.type === AuditTrailItemType.COMMENT
  const user = useCurrentUser()
  const isNew = useMemo(
    () =>
      auditRecord.type === AuditTrailItemType.ACTIVITY
        ? dayjs(auditRecord.item.change_time).isAfter(document.audit_trail_last_seen_at) &&
          auditRecord.item.changed_by.id !== user?.data.id
        : isAuditComment &&
          dayjs(auditRecord.item.created_at).isAfter(document.audit_trail_last_seen_at) &&
          auditRecord.item.updated_by.id !== user?.data.id,
    []
  )

  const getHeaderTranslationForAction = (
    auditRecord: IAuditTrailObjectBase<IChangeDocument> | null
  ): ReactNode => {
    if (auditRecord === null) {
      return null
    }

    const dropDownComponent = (
      <UserDetails user={auditRecord?.changed_by} changeReason={auditRecord.change_reason} />
    )
    if (auditRecord.changes[0].field === AuditTrailFields.ATTACHMENTS) {
      const isAdded = auditRecord.changes[0].added?.length !== 0
      return (
        <Trans
          i18nKey={
            isAdded
              ? 'components.auditTrail.addedAttachment'
              : 'components.auditTrail.removedAttachment'
          }
          count={
            isAdded ? auditRecord.changes[0].added?.length : auditRecord.changes[0].removed?.length
          }
          components={{
            dropDown: dropDownComponent
          }}
        />
      )
    }
    if (auditRecord.changes[0].field === AuditTrailFields.IS_LOCKED) {
      return (
        <Trans
          i18nKey={
            auditRecord.changes?.[0].new_values
              ? 'components.auditTrail.lock'
              : 'components.auditTrail.unLock'
          }
          components={{
            dropDown: dropDownComponent
          }}
        />
      )
    }
    if (auditRecord.changes[0].field === AuditTrailFields.IS_FLAGGED) {
      return (
        <Trans
          i18nKey={
            auditRecord.changes?.[0].new_values
              ? 'components.auditTrail.flag'
              : 'components.auditTrail.unFlag'
          }
          components={{
            dropDown: dropDownComponent
          }}
        />
      )
    }
    if (auditRecord.type === AuditTrailChangeType.UPDATE) {
      return (
        <Trans
          i18nKey={'components.auditTrail.edit'}
          components={{
            dropDown: dropDownComponent
          }}
        />
      )
    }
    if (auditRecord.type === AuditTrailChangeType.CREATE) {
      return (
        <Trans
          i18nKey={'components.auditTrail.create'}
          components={{
            dropDown: dropDownComponent
          }}
        />
      )
    }
    return null
  }

  const getIcon = (auditRecord: IAuditTrailObjectBase<IChangeDocument> | null): ReactElement => {
    if (auditRecord === null) {
      return null
    }
    if (auditRecord.changes[0].field === AuditTrailFields.IS_FLAGGED) {
      return auditRecord.changes?.[0].new_values ? (
        <FaFlag style={{ color: theme.colors.nocfoRed }} />
      ) : (
        <FaFlag style={{ color: theme.colors.metalGray }} />
      )
    }
    if (auditRecord.changes[0].field === AuditTrailFields.IS_LOCKED) {
      return auditRecord.changes?.[0].new_values ? (
        <FaLock style={{ color: theme.colors.metalGray }} />
      ) : (
        <FaLockOpen style={{ color: theme.colors.metalGray }} />
      )
    }
    return null
  }

  const getHeaderTranslationForComment = (auditComment: IAuditTrailComment): ReactNode => {
    const dropDownComponent = <UserDetails user={auditComment?.created_by} />

    return (
      <Trans
        i18nKey={'components.auditTrail.comment'}
        components={{
          dropDown: dropDownComponent
        }}
      />
    )
  }

  const icon = getIcon(isAuditAction ? auditRecord.item : null)

  const translatedBlock = isAuditAction
    ? getHeaderTranslationForAction(auditRecord.item)
    : isAuditComment && getHeaderTranslationForComment(auditRecord.item)

  return (
    <StepTitle>
      {translatedBlock}
      {icon}
      <StepTitleDate>
        {formatDateWithPastReference(
          isAuditAction
            ? auditRecord?.item.change_time
            : isAuditComment && auditRecord.item.created_at
        )}
        {isNew && <NewTag />}
      </StepTitleDate>
    </StepTitle>
  )
}

interface IDiscontinuationHeaderBlock {
  date: string
}

export const DiscontinuationHeaderBlock: React.FC<IDiscontinuationHeaderBlock> = ({ date }) => {
  const [t] = useTranslation()

  return (
    <StepTitle style={{ alignItems: 'center' }}>
      {t('components.auditTrail.discontinuation')}
      <TextTooltip
        tooltip={t('components.auditTrail.discontinuationInfo', { historyBegin: formatDate(date) })}
      >
        <FaQuestionCircle
          style={{
            color: theme.colors.metalGray,
            marginLeft: `${theme.spacing.xxs}rem`,
            marginBottom: `${theme.spacing.xxxs}rem`
          }}
        />
      </TextTooltip>

      <StepTitleDate>{formatDateWithPastReference(date)}</StepTitleDate>
    </StepTitle>
  )
}

const StepTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.xxs}rem;
  margin-bottom: ${({ theme }) => theme.spacing.xs}rem;
  color: ${({ theme }) => theme.colors.metalGray};
  font-size: ${({ theme }) => theme.fontSize.sm}rem;

  & > svg {
    width: ${({ theme }) => theme.spacing.sm}rem;
    min-width: ${({ theme }) => theme.spacing.sm}rem;
    height: ${({ theme }) => theme.spacing.sm}rem;
    min-height: ${({ theme }) => theme.spacing.sm}rem;
    align-self: center;
    margin-left: ${({ theme }) => theme.spacing.xxs}rem;
  }
`

const StepTitleDate = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.xxs}rem;
  align-items: center;
  margin-left: auto;
`

const NewTag: React.FC = () => {
  const [t] = useTranslation()
  return <NewTagWrapper>{t('components.auditTrail.new')}</NewTagWrapper>
}

const NewTagWrapper = styled.span`
  display: flex;
  align-items: center;
  text-transform: uppercase;

  border-radius: 1rem;
  color: ${({ theme }) => theme.colors.neutralWhite};

  background: ${({ theme }) => theme.colors.nocfoBlue};
  font-size: ${({ theme }) => theme.fontSize.xxs}rem;
  font-weight: bold;

  padding-left: ${({ theme }) => theme.spacing.xs}rem;
  padding-right: ${({ theme }) => theme.spacing.xs}rem;
  padding-top: ${({ theme }) => theme.spacing.xxxs}rem;
  padding-bottom: ${({ theme }) => theme.spacing.xxxs}rem;
  line-height: 1.5;
`
