import { useCurrentUser } from '@root/query'
import { Avatar } from '../Avatar'
import { Container, StyledForm } from './UserCommentInput.styled'
import InputSend from '../Inputs/InputSend'
import { Controller, get, useFormContext } from 'react-hook-form'
import { FaArrowCircleUp } from 'react-icons/fa'
import { Button } from '../Button/Button.tsx'
import { theme } from '@root/styles'
import { UseMutationResult } from 'react-query'
import { onCtrlEnter } from '@root/utils/onCtrlEnter'

const AVATAR_SIZE = 42

interface Props {
  createMutation: UseMutationResult<unknown, unknown, unknown>
}

const UserCommentInput: React.FC<Props> = ({ createMutation }) => {
  const { data: user } = useCurrentUser()

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useFormContext()

  const button = (
    <Button
      icon={
        <FaArrowCircleUp
          style={{ color: `${theme.colors.nocfoBlue}`, height: '24px', width: '24px' }}
        ></FaArrowCircleUp>
      }
      style={{ padding: '0' }}
      isSecondary={true}
      type="submit"
      disabled={createMutation?.isLoading}
    ></Button>
  )

  const transformText = (text: string): string => {
    return text.replace(/@\[(.*?)\]\((.*?)\)/g, (_, __, id) => {
      return `(userId:${id})`
    })
  }

  const onSubmit = (data: any) => {
    createMutation.mutate({ ...data, text: transformText(data.text) })
  }

  return (
    <>
      <Container>
        <Avatar src={user?.avatar_url} role="button" size={AVATAR_SIZE} />
        <StyledForm
          onSubmit={handleSubmit(onSubmit)}
          id={'document-comment'}
          onKeyDown={e => onCtrlEnter(e, handleSubmit(onSubmit))}
        >
          <Controller
            control={control}
            name="text"
            render={({ field: { onBlur, onChange, ref, name, value } }) => (
              <InputSend
                onBlur={onBlur}
                onChangeController={onChange}
                ref={ref}
                actionElement={button}
                name={name}
                value={value}
                error={get(errors, 'text')}
              />
            )}
          ></Controller>
        </StyledForm>
      </Container>
    </>
  )
}

export default UserCommentInput
