import imgSrc from '@assets/undraw/undraw_printing_invoices_-5-r4r.svg'
import { AnimatedContentLoader, Button, GoalStepper } from '@components'
import { getContactPageUrl, getProductPageUrl, getSettingsPageUrl } from '@constants'
import { FeatureWrapper, useBusinessContext } from '@context'
import { fetchBusiness, updateBusiness } from '@query'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FaForward } from 'react-icons/fa'
import { useMutation, useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const InvoicingOnboarding: React.FC = () => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()

  const { data, isLoading, refetch } = useQuery([businessId], () => fetchBusiness({ businessId }), {
    refetchOnMount: true
  })

  const { mutateAsync: skipOnboarding, isLoading: isSkipping } = useMutation(
    () =>
      updateBusiness(
        { businessId },
        {
          onboarding_updated_invoicing_settings: true,
          onboarding_created_invoicing_contact: true,
          onboarding_created_invoicing_product: true
        }
      ),
    {
      onSuccess: () => refetch()
    }
  )

  return (
    <Wrapper>
      <img src={imgSrc} />
      <AnimatedContentLoader isLoading={isLoading}>
        <>
          <GoalStepper
            header={t('invoicing.steps.header')}
            goals={[
              {
                content: (
                  <Trans i18nKey="invoicing.steps.addInvoicingSettings">
                    <Link to={getSettingsPageUrl(businessId, { page: 'invoicing' })} />
                  </Trans>
                ),
                isCompleted: data?.onboarding_updated_invoicing_settings
              },
              {
                content: (
                  <Trans i18nKey="invoicing.steps.addOneContact">
                    <Link to={getContactPageUrl(businessId)} />
                  </Trans>
                ),
                isCompleted: data?.onboarding_created_invoicing_contact
              },
              {
                content: (
                  <Trans i18nKey="invoicing.steps.addOneProduct">
                    <Link to={getProductPageUrl(businessId)} />
                  </Trans>
                ),
                isCompleted: data?.onboarding_created_invoicing_product
              }
            ]}
          />
          <FeatureWrapper feature="feature_show_received_einvoices">
            <AdditionalContent>
              <p>{t('invoicing.steps.skipDescription')}</p>
              <Button
                icon={<FaForward />}
                onClick={() => skipOnboarding()}
                disabled={isSkipping}
                showSpinner={isSkipping}
              >
                {t('invoicing.steps.skipButton')}
              </Button>
            </AdditionalContent>
          </FeatureWrapper>
        </>
      </AnimatedContentLoader>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-width: 600px;
  background: white;
  margin: ${({ theme }) => theme.spacing.md}rem;
  border-radius: 1rem;
  box-shadow: 0px 2px 4px 0px ${({ theme }) => theme.colors.neutralBlack}44;

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-width: 400px;
    padding: ${({ theme }) => theme.spacing.xxl}rem ${({ theme }) => theme.spacing.md}rem;
  }
`

const AdditionalContent = styled.div`
  margin: ${({ theme }) => theme.spacing.md}rem;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  color: ${({ theme }) => theme.colors.neutralBlack};
`
