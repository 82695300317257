import { IKraviaInvoiceHistory } from '@query'
import { TextTooltip } from '@components'
import { FaCheckCircle, FaQuestionCircle } from 'react-icons/fa'
import { StyledDebtCollectionBadge } from './DebtCollectionStatusBadge.styled.tsx'
import { KraviaActivityStatus } from '@constants'
import { useTranslation } from 'react-i18next'

interface Props {
  item: IKraviaInvoiceHistory
}

export const DebtCollectionStatusBadge: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation()

  switch (item?.status) {
    case KraviaActivityStatus.WAITING_FOR_APPROVAL:
      return (
        <StyledDebtCollectionBadge className="waiting-for-action">
          {t('invoicing.debtCollection.kraviaStatus.waitingForApproval')}
          <TextTooltip tooltip={t('invoicing.debtCollection.kraviaStatus.waitingForApprovalInfo')}>
            <FaQuestionCircle />
          </TextTooltip>
        </StyledDebtCollectionBadge>
      )
    case KraviaActivityStatus.SCHEDULED:
      return (
        <StyledDebtCollectionBadge>
          {t('invoicing.debtCollection.kraviaStatus.scheduled')}
        </StyledDebtCollectionBadge>
      )
    case KraviaActivityStatus.STARTED:
      return (
        <StyledDebtCollectionBadge>
          {t('invoicing.debtCollection.kraviaStatus.started')}
        </StyledDebtCollectionBadge>
      )
    case KraviaActivityStatus.CANCELED:
      return (
        <StyledDebtCollectionBadge>
          {t('invoicing.debtCollection.kraviaStatus.canceled')}
        </StyledDebtCollectionBadge>
      )
    case KraviaActivityStatus.COMPLETED:
      return (
        <StyledDebtCollectionBadge className="completed">
          <FaCheckCircle />
          {t('invoicing.debtCollection.kraviaStatus.completed')}
        </StyledDebtCollectionBadge>
      )
    default:
      return null
  }
}
