import { Button } from '@components'
import toast from 'react-hot-toast'
import { Trans } from 'react-i18next'
import { FaExternalLinkAlt } from 'react-icons/fa'
import styled from 'styled-components'

export const openLinkInTab = (link: string): void => {
  const newWin = window.open(link, '_blank')

  // CASE: Popup blocked :(
  if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
    toast(
      t => (
        <StyledPopupBlockedWrapper>
          <Trans i18nKey="reports.popupBlocked" />

          <a href={link} target="_blank" rel="noreferrer">
            <Button
              intent="primary"
              isSecondary
              icon={<FaExternalLinkAlt />}
              type="button"
              onClick={() => toast.dismiss(t.id)}
            >
              <Trans i18nKey="reports.popupBlockedBtn" />
            </Button>
          </a>
        </StyledPopupBlockedWrapper>
      ),
      {
        duration: 6000
      }
    )
  }

  // CASE: Autodownload worked
  else {
    newWin.focus()
  }
}

const StyledPopupBlockedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md}rem;

  a {
    display: flex;
  }

  button {
    flex: 1;
    width: 100%;
  }
`
