import imgSrc from '@assets/undraw/undraw_hello_re_3evm.svg'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const WelcomeView: React.FC = () => {
  const [t] = useTranslation()

  return (
    <WelcomeWrapper>
      <WelcomeImage src={imgSrc} />
      <WelcomeTitle>{t('landingPage.welcome.title')}</WelcomeTitle>
      <WelcomeText>{t('landingPage.welcome.description')}</WelcomeText>
      <WelcomeInfo>
        <Trans i18nKey="landingPage.welcome.info">
          <a href={t('link.addCompany')} rel="noreferrer" target="_blank" />
        </Trans>
      </WelcomeInfo>
    </WelcomeWrapper>
  )
}

const WelcomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: ${({ theme }) => theme.spacing.xxl}rem;
  border-radius: 1rem;
  color: ${({ theme }) => theme.colors.neutralBlack};
  overflow: hidden;

  & > * {
    align-self: center;
  }
`

const WelcomeImage = styled.img`
  width: 40%;
  min-width: 300px;
  margin-bottom: ${({ theme }) => theme.spacing.xl}rem;
`

const WelcomeTitle = styled.h2``

const WelcomeText = styled.p`
  color: ${({ theme }) => theme.colors.metalGray};
  max-width: 500px;
  text-align: center;
`
const WelcomeInfo = styled.h5`
  padding-top: 2rem;
  color: ${({ theme }) => theme.colors.metalGray};
  max-width: 500px;
  text-align: center;
`
