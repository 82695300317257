import { autoUpdate, offset, size } from '@floating-ui/react-dom'
import { useDismiss, useFloating, useInteractions } from '@floating-ui/react-dom-interactions'
import { Button } from '@components/Button/Button.tsx'
import { AnimatePresence } from 'framer-motion'
import { ReactNode, useRef, useState } from 'react'
import { FaFilter, FaTimes } from 'react-icons/fa'
import {
  ActiveFilters,
  FilterBadge,
  FilterBadgeContainer,
  FilterBadgeText,
  FilterButton,
  FilterSearchContainer,
  Footer,
  HeaderContainer,
  StyledContainer,
  StyledHeader
} from './FilterSelection.styled'
import { FaEraser } from 'react-icons/fa6'
import { FloatingPortal } from '@floating-ui/react'
import { useTranslation } from 'react-i18next'
import { deepEqual } from '@root/utils'

export interface IFilterButton {
  id: string
  name: string
  filter: any
}

export interface IFilter extends Record<any, any> {
  [key: string]: any
}

interface Props {
  filterButtonOptions?: IFilterButton[]
  filterSearchOptions?: ReactNode[]
  filters: Array<IFilter>
  onFilter: (any) => void
}

const FilterSelection: React.FC<Props> = ({
  filterButtonOptions,
  onFilter,
  filterSearchOptions,
  filters
}) => {
  const [t] = useTranslation()
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)
  const rootRef = useRef<HTMLElement>(document.getElementById('dropdown-root'))
  const numberOfActiveFilters = filters.length
  const isFilterActive = numberOfActiveFilters !== 0

  /* FLOATING */
  const { context, x, y, reference, floating, strategy } = useFloating({
    open: isDropDownOpen,
    onOpenChange: setIsDropDownOpen,
    whileElementsMounted(referenceEl, floatingEl, update) {
      const cleanup = autoUpdate(referenceEl, floatingEl, update, {
        layoutShift: false // Prevents micro movement when focus is lost on parent button
      })
      return cleanup
    },
    placement: 'bottom-start',
    middleware: [
      offset({
        mainAxis: 12
      }),
      size({
        apply: ({ availableWidth, elements }) => {
          Object.assign(elements.floating.style, {
            width: `${Math.min(400, availableWidth)}px`
          })
        },
        padding: 24
      })
    ]
  })
  const dismiss = useDismiss(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss])

  /* FILTER LOGIC */
  const toggleFilter = (filter: IFilter): void => {
    if (filters.some(value => deepEqual(value, filter))) {
      const newState = filters.filter(value => !deepEqual(value, filter))
      onFilter(newState)
    } else {
      const newState = [...filters, filter]
      onFilter(newState)
    }
  }

  const isActive = (filter: IFilter) => {
    return filters.some(value => deepEqual(value, filter)) ? 'active' : 'default'
  }

  const clearSelectedFilters = () => {
    onFilter([])
  }

  return (
    <>
      <FilterButton
        icon={<FaFilter />}
        onClick={() => setIsDropDownOpen(!isDropDownOpen)}
        active={isFilterActive}
        ref={reference}
        {...getReferenceProps()}
      >
        {isFilterActive && <ActiveFilters>{numberOfActiveFilters}</ActiveFilters>}
      </FilterButton>

      <FloatingPortal root={rootRef}>
        <AnimatePresence>
          {isDropDownOpen && (
            <StyledContainer
              ref={floating}
              style={{
                top: y || 0,
                left: x || 0,
                position: strategy
              }}
              {...getFloatingProps()}
            >
              <HeaderContainer>
                <StyledHeader>{t('components.actionBar.filterHeader')}</StyledHeader>
                <Button
                  icon={<FaTimes />}
                  onClick={() => setIsDropDownOpen(false)}
                  isSecondary={true}
                ></Button>
              </HeaderContainer>

              <FilterBadgeContainer>
                {filterButtonOptions?.map(({ id, name, filter }) => (
                  <FilterBadge
                    key={`filter-${id}`}
                    onClick={() => toggleFilter(filter)}
                    initial={false}
                    animate={isActive(filter)}
                  >
                    <FilterBadgeText>{name}</FilterBadgeText>
                  </FilterBadge>
                ))}
              </FilterBadgeContainer>

              <FilterSearchContainer>
                {filterSearchOptions?.map(search => search)}
              </FilterSearchContainer>

              <Footer>
                <Button
                  icon={<FaEraser />}
                  onClick={() => clearSelectedFilters()}
                  disabled={filters.length === 0}
                >
                  {t('components.actionBar.clearFilters')}
                </Button>
              </Footer>
            </StyledContainer>
          )}
        </AnimatePresence>
      </FloatingPortal>
    </>
  )
}

export default FilterSelection
