import { useQueryClient } from 'react-query'
import { useBusinessContext } from '@context'
import { IKraviaOnboardInfo } from '@query'
import React, { useEffect } from 'react'
import { toast } from 'react-hot-toast'
import { PulseLoader, StyledWaitingSpinnerWrapper } from '../Onboarding.styled.tsx'
import { useTranslation } from 'react-i18next'

const SIGNATURE_CHECK_INTERVAL = 5000

export const SignatureSlide: React.FC = () => {
  const queryClient = useQueryClient()
  const { businessId } = useBusinessContext()
  const { t } = useTranslation()

  const checkSignatureStatus = async () => {
    const data: IKraviaOnboardInfo = await queryClient.fetchQuery([
      businessId,
      'kravia_onboard_status'
    ])

    if (data?.kravia_enabled && data?.kravia_verified) {
      toast.success(t('invoicing.debtCollection.onboard.signatureSuccess'), {
        duration: 8000
      })
    }
  }

  useEffect(() => {
    const interval = setInterval(() => checkSignatureStatus(), SIGNATURE_CHECK_INTERVAL)
    return () => clearInterval(interval)
  }, [])

  return (
    <StyledWaitingSpinnerWrapper>
      <PulseLoader />
      <div>
        <h4>{t('invoicing.debtCollection.onboard.signatureTitle')}</h4>
        <p>{t('invoicing.debtCollection.onboard.signatureDescription')}</p>
      </div>
    </StyledWaitingSpinnerWrapper>
  )
}
