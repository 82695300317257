import { DoubleColumnLayout, DoubleColumnLayoutColumn } from '@components'
import { UserRole } from '@constants'
import { PermissionBoundary, useBusinessContext } from '@context'
import { useQueryParam } from '@hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PeriodActionBar } from './PeriodActionBar'
import { PeriodDetails } from './PeriodDetails'
import { PeriodList } from './PeriodList'

export const PeriodPage: React.FC = () => {
  const { businessId } = useBusinessContext()
  const [_periodId, setPeriodId] = useQueryParam('id')
  const periodId = parseInt(_periodId, 10)
  const [t] = useTranslation()

  const clearQuery = () => {
    setPeriodId(undefined)
  }

  return (
    <DoubleColumnLayout
      header={t('period.mainTitle')}
      isRightVisible={Boolean(periodId)}
      onRightClose={() => clearQuery()}
    >
      <DoubleColumnLayoutColumn>
        <PageContentWrapper>
          <PermissionBoundary requireRole={UserRole.EDITOR}>
            <PeriodActionBar />
          </PermissionBoundary>

          <PeriodListWrapper>
            <PeriodList />
          </PeriodListWrapper>
        </PageContentWrapper>
      </DoubleColumnLayoutColumn>

      <DoubleColumnLayoutColumn innerKey={periodId} isRight={true}>
        {periodId && (
          <PeriodDetails
            key={periodId}
            businessId={businessId}
            periodId={periodId}
            onClose={clearQuery}
          />
        )}
      </DoubleColumnLayoutColumn>
    </DoubleColumnLayout>
  )
}

const PageContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const PeriodListWrapper = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
`
