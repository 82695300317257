import { ActionFunction, handleReturn, MutationFunction, QueryFunction } from '@query/root.ts'
import { network } from '@utils'

export enum ImportJobType {
  CONTACTS = 'CONTACTS',
  PRODUCTS = 'PRODUCTS',
  INVOICES = 'INVOICES'
}

export interface IImportJob {
  id: string
  type: ImportJobType
  actor_id: number
  created_at: string
  finished_at: string
  seconds_left?: number
  import_total_count: number
  import_success_count: number
  import_failed_count: number
  data: any
}

interface ImportJobProps {
  businessId: string
}

export interface IDryRunResponse {
  to_be_created: number
  to_be_updated: number
}

export const createImportJobDryRun: ActionFunction<
  ImportJobProps,
  Partial<IImportJob>,
  IDryRunResponse
> = async ({ businessId }, requestData) => {
  const url = `/v1/business/${businessId}/import_job/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'POST',
    data: { ...requestData, dry_run: true },
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export const createImportJob: MutationFunction<ImportJobProps, IImportJob> = async (
  { businessId },
  requestData
) => {
  const url = `/v1/business/${businessId}/import_job/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'POST',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

interface ImportJobInstanceProps extends ImportJobProps {
  importJobId: string
}

export const fetchImportJob: QueryFunction<ImportJobInstanceProps, IImportJob> = async (
  { businessId, importJobId },
  params = null
) => {
  const url = `/v1/business/${businessId}/import_job/${importJobId}/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'GET',
    params,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}
