import { network } from '@utils'
import { handleReturn, MutationFunction } from './root'

export interface ISignUpData {
  email: string
  password: string
}

export const nocfoAccountSignUp: MutationFunction<void, ISignUpData> = async (_, signupData) => {
  const url = `/auth/nocfo-account/signup/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'POST',
    data: signupData,
    validateStatus: () => true
  })
  return handleReturn({ data, status })
}

export interface ISignInData {
  email: string
  password: string
}

export const nocfoAccountSignIn: MutationFunction<void, ISignInData> = async (_, signupData) => {
  const url = `/auth/nocfo-account/signin/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'POST',
    data: signupData,
    validateStatus: () => true
  })
  return handleReturn({ data, status })
}

export interface ISignInOTPData {
  email: string
  otp: string
}

export interface ISignInOTPResult {
  token: string
}

export const nocfoAccountSignInOTP: MutationFunction<void, ISignInOTPData> = async (
  _,
  signupData
) => {
  const url = `/auth/nocfo-account/signin-otp/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'POST',
    data: signupData,
    validateStatus: () => true
  })
  return handleReturn({ data, status })
}

export interface IResetPasswordRequest {
  email: string
}

export const nocfoResetPasswordRequest: MutationFunction<void, IResetPasswordRequest> = async (
  _,
  changePasswordData
) => {
  const url = `/auth/nocfo-account/reset-password-request/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'POST',
    data: changePasswordData,
    validateStatus: () => true
  })
  return handleReturn({ data, status })
}

export interface IResetPassword {
  email: string
  token: string
  new_password: string
}

export const nocfoResetPassword: MutationFunction<void, IResetPassword> = async (
  _,
  changePasswordData
) => {
  const url = `/auth/nocfo-account/reset-password/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'POST',
    data: changePasswordData,
    validateStatus: () => true
  })
  return handleReturn({ data, status })
}
