import { DoubleColumnLayout, DoubleColumnLayoutColumn } from '@components'
import { getVATPageUrl, UserRole } from '@constants'
import { PermissionBoundary, useBusinessContext } from '@context'
import { useQueryParam } from '@hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { VatActionBar } from './VatActionBar'
import { VatPeriodDetails } from './VatPeriodDetails'
import { VatPeriodList } from './VatPeriodList'

export const VatPage: React.FC = () => {
  const [t] = useTranslation()
  const history = useHistory()
  const { businessId } = useBusinessContext()
  const [selectedId] = useQueryParam<number>('id', value => parseInt(value, 10))

  const onClose = () => {
    const url = getVATPageUrl(businessId)
    history.push(url)
  }

  return (
    <DoubleColumnLayout
      header={t('vatPage.mainTitle')}
      isRightVisible={Boolean(selectedId)}
      onRightClose={() => onClose()}
    >
      <DoubleColumnLayoutColumn>
        <PageContentWrapper>
          <PermissionBoundary requireRole={UserRole.EDITOR}>
            <VatActionBar />
          </PermissionBoundary>

          <VatPeriodListWrapper>
            <VatPeriodList />
          </VatPeriodListWrapper>
        </PageContentWrapper>
      </DoubleColumnLayoutColumn>

      <DoubleColumnLayoutColumn innerKey={selectedId} isRight={true}>
        {selectedId && (
          <VatPeriodDetails key={selectedId} vatPeriodId={selectedId} onClose={onClose} />
        )}
      </DoubleColumnLayoutColumn>
    </DoubleColumnLayout>
  )
}

const PageContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const VatPeriodListWrapper = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
`
