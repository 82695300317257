import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'

const DEBUG_VAR_NAME = 'nocfo-debug-mode'

enum DebugState {
  ON = 'on'
}

const getCurrentState = () => window.localStorage.getItem(DEBUG_VAR_NAME) === DebugState.ON

export const useDebugMode = (): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [isOn, setIsOn] = useState(getCurrentState())

  useEffect(() => {
    const listener = () => setIsOn(getCurrentState())
    window.addEventListener('storage', listener)
    return () => window.removeEventListener('storage', listener)
  }, [])

  const setDebugMode = (on: boolean): void => {
    if (on) {
      toast.success('Debug mode ON', { icon: '🐛' })
      window.localStorage.setItem(DEBUG_VAR_NAME, DebugState.ON)
      window.dispatchEvent(new Event('storage'))
    } else {
      toast.success('Debug mode OFF', { icon: '🐛' })
      window.localStorage.removeItem(DEBUG_VAR_NAME)
      window.dispatchEvent(new Event('storage'))
    }
  }

  return [isOn, setDebugMode]
}
