import { network } from '@utils'
import { handleReturn, MutationFunction, PaginatedResponse, QueryFunction } from './root'
import { InvoiceDeliveryMethod } from '@root/constants'

export enum ContactType {
  UNSET = 'UNSET',
  PERSON = 'PERSON',
  BUSINESS = 'BUSINESS'
}

export interface IContact {
  id: number
  type: ContactType
  customer_id?: string
  name: string
  name_aliases: string[]
  contact_business_id: string
  notes: string
  is_invoicing_enabled: boolean
  invoicing_email?: string
  invoicing_einvoice_address?: string
  invoicing_einvoice_operator?: string
  invoicing_tax_code?: string
  invoicing_street?: string
  invoicing_city?: string
  invoicing_postal_code?: string
  invoicing_country?: string
  invoicing_language?: string
  can_be_invoiced: boolean
  can_be_invoiced_via_email: boolean
  can_be_invoiced_via_einvoice: boolean
}

interface ContactListProps {
  businessId: string
}

export const fetchContacts: QueryFunction<ContactListProps, PaginatedResponse<IContact>> = async (
  { businessId },
  params = null
) => {
  const url = `/v1/business/${businessId}/contacts/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}

export const createContact: MutationFunction<ContactListProps, IContact> = async (
  { businessId },
  requestData
) => {
  const url = `/v1/business/${businessId}/contacts/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'POST',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

interface ContactInstanceProps extends ContactListProps {
  contactId: number
}

export const fetchContact: QueryFunction<ContactInstanceProps, IContact> = async ({
  businessId,
  contactId
}) => {
  const url = `/v1/business/${businessId}/contacts/${contactId}/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET'
  })
  return data
}

export interface SuggestContactProps extends ContactListProps {
  query: string
}

export const suggestContact: QueryFunction<SuggestContactProps, IContact | undefined> = async ({
  businessId,
  query
}) => {
  const url = `/v1/business/${businessId}/contacts/suggest/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params: { query }
  })
  return data
}

export const updateContact: MutationFunction<ContactInstanceProps, IContact> = async (
  { businessId, contactId },
  requestData
) => {
  const url = `/v1/business/${businessId}/contacts/${contactId}/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'PATCH',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export const deleteContact: MutationFunction<ContactInstanceProps, void> = async ({
  businessId,
  contactId
}) => {
  const url = `/v1/business/${businessId}/contacts/${contactId}/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'DELETE',
    validateStatus: () => true
  })

  return handleReturn({ status, data })
}

export interface ContactDeliveryMethod {
  method: InvoiceDeliveryMethod
  errors: Array<string>
}

interface DeliveryMethodProps {
  businessId: string
  contactId: number
}

export const fetchContactDeliveryMethods: QueryFunction<
  DeliveryMethodProps,
  Array<ContactDeliveryMethod>
> = async ({ businessId, contactId }) => {
  const url = `/v1/business/${businessId}/contacts/delivery_methods/${contactId}`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET'
  })
  return data
}
