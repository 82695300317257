import { HeaderBlock } from '@components'
import { PermissionBoundary, useBusinessContext } from '@context'
import { disableEinvoicing } from '@query'
import { Pane, toaster } from 'evergreen-ui'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaTrash } from 'react-icons/fa'
import { useMutation, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { ActivateEInvoicing } from './ActivateEInvoicing'
import { EInvoicingDetails } from './EInvoicingDetails'
import { UserRole } from '@root/constants'

export const EInvoicingSettings: React.FC = () => {
  const queryClient = useQueryClient()
  const { businessId, data: business } = useBusinessContext()
  const [t] = useTranslation()

  const { mutate: disableEinvoicingMutation, isLoading: isDisabling } = useMutation(
    () => disableEinvoicing({ businessId }),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(['businesses']),
          queryClient.invalidateQueries([businessId])
        ])
        toaster.success(t('general.changesSaved'))
      }
    }
  )

  return (
    <PermissionBoundary requireRole={UserRole.ADMIN}>
      <StyledWrapper>
        <HeaderBlock
          header={t('settings.einvoicing.header')}
          subHeader=""
          menuItems={
            business?.einvoicing_enabled
              ? [
                  {
                    Icon: FaTrash,
                    name: t('settings.einvoicing.disable'),
                    onClick: () => disableEinvoicingMutation(),
                    intent: 'danger',
                    disabled: isDisabling
                  }
                ]
              : null
          }
        />

        <Pane margin={'1rem'}>
          {business.einvoicing_enabled ? <EInvoicingDetails /> : <ActivateEInvoicing />}
        </Pane>
      </StyledWrapper>
    </PermissionBoundary>
  )
}

const StyledWrapper = styled.div`
  background: white;
  height: 100%;
  overflow: auto;
`
