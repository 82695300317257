import React, { useMemo, useReducer } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'
import { UploadCSVSlide } from '@containers/CSVImportWizard/base/slides/UploadCSVSlide.tsx'
import { FieldMappingSlide } from '@containers/CSVImportWizard/base/slides/FieldMappingSlide.tsx'
import { convertToJSON } from '@query'
import { fileToBase64 } from '@utils/fileToBase64.ts'
import { isEmpty } from 'lodash'
import { ExamplesSlide } from '@containers/CSVImportWizard/base/slides/ExamplesSlide.tsx'
import { FieldToMap } from '@containers/CSVImportWizard/base/slides/components/types.ts'
import { IImportJob } from '@query/importJob.ts'
import { useJobContext } from '@containers/CSVImportWizard/base/ImportJobContext.tsx'
import { useMutation } from 'react-query'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

export interface VerificationResponse {
  toBeCreated: number
  toBeUpdated: number
}

interface Props {
  fields: FieldToMap<any>[]
  onVerifyData?: (data: any) => Promise<VerificationResponse> // Call API with transformed data to verify it
  onImportData?: (data: any) => Promise<IImportJob>
}

interface ContainerState {
  originalData: Record<string, any>[]
  dataToImport: Record<string, any>[]
  verificationResponse: VerificationResponse
}

enum ImportActionType {
  SET_ORIGINAL_DATA = 'SET_ORIGINAL_DATA',
  SET_DATA_TO_IMPORT = 'SET_DATA_TO_IMPORT',
  SET_VERIFICATION = 'SET_VERIFICATION',
  RESET_DATA_TO_IMPORT = 'RESET_DATA_TO_IMPORT',
  RESET_VERIFICATION = 'RESET_VERIFICATION',
  RESET = 'RESET'
}

interface ImportActionCommon {
  type: Exclude<ImportActionType, ImportActionType.SET_VERIFICATION>
  data?: Record<string, any>[]
}

interface ImportActionSetVerification {
  type: ImportActionType.SET_VERIFICATION
  data: VerificationResponse
}

type ImportAction = ImportActionCommon | ImportActionSetVerification

const reducer = (state: ContainerState, action: ImportAction): ContainerState => {
  switch (action.type) {
    case ImportActionType.SET_ORIGINAL_DATA:
      return { originalData: action.data, dataToImport: null, verificationResponse: null }
    case ImportActionType.SET_DATA_TO_IMPORT:
      return { ...state, dataToImport: action.data, verificationResponse: null }
    case ImportActionType.SET_VERIFICATION:
      return { ...state, verificationResponse: action.data }
    case ImportActionType.RESET_DATA_TO_IMPORT:
      return { ...state, dataToImport: null, verificationResponse: null }
    case ImportActionType.RESET_VERIFICATION:
      return { ...state, verificationResponse: null }
    case ImportActionType.RESET:
      return { originalData: null, dataToImport: null, verificationResponse: null }
    default:
      throw 'Action not implemented'
  }
}

export const CSVImportWizard: React.FC<Props> = ({ fields, onVerifyData, onImportData }) => {
  const { track } = useJobContext()
  const { t } = useTranslation()

  const [{ originalData, dataToImport, verificationResponse }, dispatch] = useReducer(reducer, {
    originalData: null,
    dataToImport: null,
    verificationResponse: null
  })

  const { mutateAsync } = useMutation<IImportJob, unknown, Record<string, any>[]>(onImportData, {
    onSuccess: job => {
      track(job.id)
    },
    onError: () => {
      toast.error(t('general.error'))
    }
  })

  const slideIndex = useMemo(() => {
    if (!isEmpty(dataToImport)) return 2
    if (!isEmpty(originalData)) return 1
    return 0
  }, [originalData, dataToImport])

  return (
    <SlideWrapper>
      <AnimatePresence mode="wait">
        {slideIndex === 0 && (
          <AnimatedSlide key="upload-csv-slide">
            <UploadCSVSlide
              onFileUpload={async (file: File) => {
                const contents = await fileToBase64(file)
                const fileBase = contents.split(',')[1]
                const fileJSON = await convertToJSON({
                  format: 'csv',
                  base64: fileBase
                })

                dispatch({ type: ImportActionType.SET_ORIGINAL_DATA, data: fileJSON })
              }}
            />
          </AnimatedSlide>
        )}
        {slideIndex === 1 && (
          <AnimatedSlide key="field-mapping-slide">
            <FieldMappingSlide
              fields={fields}
              data={originalData}
              onPrevSlide={() => {
                dispatch({ type: ImportActionType.RESET })
              }}
              onMappingsComplete={mapping => {
                dispatch({ type: ImportActionType.RESET_VERIFICATION })
                return onVerifyData(mapping).then(verification => {
                  dispatch({ type: ImportActionType.SET_DATA_TO_IMPORT, data: mapping })
                  dispatch({ type: ImportActionType.SET_VERIFICATION, data: verification })
                })
              }}
            />
          </AnimatedSlide>
        )}
        {slideIndex === 2 && (
          <AnimatedSlide key="field-mapping-slide">
            <ExamplesSlide
              data={dataToImport}
              fields={fields}
              verificationResponse={verificationResponse}
              onPrevSlide={() => {
                dispatch({ type: ImportActionType.RESET_DATA_TO_IMPORT })
              }}
              onComplete={async () => {
                await mutateAsync(dataToImport)
              }}
            />
          </AnimatedSlide>
        )}
      </AnimatePresence>
    </SlideWrapper>
  )
}

const SlideWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.sm}rem;
`

const AnimatedSlide = styled(motion.div).attrs({
  initial: { opacity: 0, y: -20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 },
  transition: {
    duration: 0.4
  }
})`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`
