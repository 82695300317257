import { useBusinessContext } from '@context'
import { fetchAutoCompleteAccounts, IAutoCompleteAccount } from '@query'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

const BANK_ACCOUNT_TYPES = new Set(['ASS_PAY'])
const PURCHASE_PAYMENT_ACC_TYPES = new Set(['ASS_PAY', 'LIA_DUE'])
const SALES_PAYMENT_ACC_TYPES = new Set(['ASS_PAY', 'ASS_DUE'])

const PURCHASE_ACC_TYPES = new Set(['EXP', 'EXP_NO', 'EXP_50'])
const SALES_ACC_TYPES = new Set(['REV', 'REV_SAL', 'REV_NO'])

export interface AccountSelectOption {
  label: string
  value: any
}

interface AssistantAccountReturnProps {
  purchasePaymentAccountOptions: IAutoCompleteAccount[]
  purchaseAccountOptions: IAutoCompleteAccount[]
  salesPaymentAccountOptions: IAutoCompleteAccount[]
  salesAccountOptions: IAutoCompleteAccount[]
  allAccountOptions: IAutoCompleteAccount[]
  bankAccountOptions: IAutoCompleteAccount[]
  balanceSheetAccountOptions: IAutoCompleteAccount[]
  refetch: () => Promise<unknown>
}

export const useAssistantAccounts = (): AssistantAccountReturnProps => {
  const { businessId } = useBusinessContext()
  const { data, refetch } = useQuery([businessId, 'auto_complete_accounts'], () =>
    fetchAutoCompleteAccounts({ businessId })
  )
  const accounts = data?.results || []

  return useMemo(() => {
    const purchasePaymentAccountOptions: IAutoCompleteAccount[] = []
    const salesPaymentAccountOptions: IAutoCompleteAccount[] = []
    const purchaseAccountOptions: IAutoCompleteAccount[] = []
    const salesAccountOptions: IAutoCompleteAccount[] = []
    const allAccountOptions: IAutoCompleteAccount[] = []
    const bankAccountOptions: IAutoCompleteAccount[] = []
    const balanceSheetAccountOptions: IAutoCompleteAccount[] = []

    accounts?.forEach((account: IAutoCompleteAccount) => {
      allAccountOptions.push(account)

      if (PURCHASE_PAYMENT_ACC_TYPES.has(account.type)) {
        purchasePaymentAccountOptions.push(account)
      }

      if (SALES_PAYMENT_ACC_TYPES.has(account.type)) {
        salesPaymentAccountOptions.push(account)
      }

      if (PURCHASE_ACC_TYPES.has(account.type)) {
        purchaseAccountOptions.push(account)
      }

      if (SALES_ACC_TYPES.has(account.type)) {
        salesAccountOptions.push(account)
      }

      if (BANK_ACCOUNT_TYPES.has(account.type)) {
        bankAccountOptions.push(account)
      }

      if (account.number.toString().startsWith('1') || account.number.toString().startsWith('2')) {
        balanceSheetAccountOptions.push(account)
      }
    })

    return {
      purchasePaymentAccountOptions,
      purchaseAccountOptions,
      salesPaymentAccountOptions,
      salesAccountOptions,
      allAccountOptions,
      bankAccountOptions,
      balanceSheetAccountOptions,
      refetch
    }
  }, [accounts, refetch])
}
