import svgImg from '@assets/undraw/undraw_mailbox_re_dvds.svg'
import {
  Alert,
  AnimatedContentLoader,
  Button,
  CopyValue,
  HeaderBlock,
  Input,
  Tab,
  Tabs
} from '@components'
import { useBusinessContext } from '@context'
import { deleteFolder, fetchFolder, IFolder, updateFolder } from '@query'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FaInfoCircle, FaTrash } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'

interface Props {
  folderId: number
  onClose: () => void
}

export const FolderDetailView: React.FC<Props> = ({ folderId, onClose }) => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const queryClient = useQueryClient()

  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty }
  } = useForm<Partial<IFolder>>()

  const {
    mutateAsync: deleteMutation,
    isLoading: isDeleting,
    isSuccess: isDeleteSuccess
  } = useMutation(() => deleteFolder({ businessId, folderId }), {
    onSuccess: async () => {
      onClose()
      await queryClient.invalidateQueries([businessId, 'folders'])
    }
  })

  const { data, isLoading } = useQuery(
    [businessId, 'folders', folderId],
    () => fetchFolder({ businessId, folderId }),
    {
      onSuccess: data => reset(data),
      enabled: !(isDeleteSuccess || isDeleting)
    }
  )

  const { mutate, isLoading: isUpdating } = useMutation<unknown, unknown, Partial<IFolder>>(
    data => updateFolder({ businessId, folderId }, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([businessId, 'folders'])
      }
    }
  )

  const onSubmit = handleSubmit(data => mutate(data))

  return (
    <StyledWrapper>
      <HeaderBlock header={t('files.folderDetails.header')} subHeader={<></>} />

      <ScrollableContent>
        <AnimatedContentLoader isLoading={isLoading}>
          <Tabs>
            <Tab
              key="folder"
              name={t('files.folderDetails.generalSectionHeader')}
              icon={<FaInfoCircle />}
            >
              <form onSubmit={onSubmit} style={{ marginBottom: 20 }}>
                {data?.for_mobile_uploads ? (
                  <Alert
                    type="info"
                    description={t('files.fileDetails.mobileUploadsDescription')}
                  />
                ) : (
                  <Input
                    {...register('name', {
                      required: { value: true, message: t('validation.required') }
                    })}
                    label={t('files.folderDetails.nameInputLabel')}
                    autoFocus
                  />
                )}

                <Button
                  disabled={!isDirty}
                  intent="success"
                  showSpinner={isUpdating}
                  style={{ marginLeft: 'auto' }}
                >
                  {t('general.save')}
                </Button>

                <br />

                <h3>{t('files.emailImport.importSectionHeader')}</h3>
                <EmailReceivingContainer>
                  <EmailImg src={svgImg} />

                  <EmailReceivingContent>
                    <h4>{t('files.emailImport.importHeader')}</h4>
                    <p>{t('files.emailImport.importMessage')}</p>
                    <CopyValue value={data?.email} />
                  </EmailReceivingContent>
                </EmailReceivingContainer>
              </form>
            </Tab>
            <Tab
              key="delete"
              name={t('files.folderDetails.deleteSectionHeader')}
              icon={<FaTrash />}
            >
              <Alert
                type="warning"
                isVisible={data?.has_content}
                title={t('files.folderDetails.deleteDeniedTitle')}
                description={t('files.folderDetails.deleteDeniedDescription')}
              />

              <Alert
                type="info"
                isVisible={!data?.has_content}
                description={t('files.folderDetails.deleteOkDescription')}
              />

              <Button
                intent="danger"
                icon={<FaTrash />}
                showSpinner={isDeleting}
                style={{ marginLeft: 'auto' }}
                disabled={data?.has_content || isDeleting}
                onClick={() => deleteMutation()}
              >
                {t('general.delete')}
              </Button>
            </Tab>
          </Tabs>
        </AnimatedContentLoader>
      </ScrollableContent>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
`
const ScrollableContent = styled.div`
  flex: 1;
  overflow: auto;
  padding: ${({ theme }) => theme.spacing.md}rem;
`

const EmailReceivingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.xl}rem;
  padding: ${({ theme }) => theme.spacing.xl}rem;
  border: 3px solid ${({ theme }) => theme.colors.neutralGray};
  border-radius: 1rem;
  color: ${({ theme }) => theme.colors.neutralBlack};
  margin: ${({ theme }) => theme.spacing.md}rem 0;

  .switch-wrapper > * {
    margin-bottom: 0;
  }
`

const EmailReceivingContent = styled.div`
  flex: 1;
`

const EmailImg = styled.img`
  flex: 1;
  max-width: 160px;
  min-width: 140px;
`
