import { Alert, AnimatedContentLoader, PricingTable } from '@components'
import { FreeContent, PaidContent, SubscriptionContent, useBusinessContext } from '@context'
import { fetchCustomerPortalSession } from '@query'
import { motion } from 'framer-motion'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCcStripe, FaExternalLinkAlt } from 'react-icons/fa'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { NOCFO_PLAN_OPTIONS, NocfoPlanSource } from '@constants'
import imgSrc from '@assets/undraw/undraw_upgrade_re_gano.svg'
import ReactMarkdown from 'react-markdown'

export const PlanSettings: React.FC = () => {
  const [t] = useTranslation()

  const {
    businessId,
    data: {
      stripe_subscription_id: stripeSubscriptionId,
      subscription_plan: subscriptionPlan,
      subscription_source: subscriptionPlanSource
    }
  } = useBusinessContext()
  const showPricingTable = !stripeSubscriptionId
  const currentPlanName = t(
    NOCFO_PLAN_OPTIONS.find(({ value }) => value === subscriptionPlan)?.labelKey
  )

  const {
    data: portalData,
    isLoading,
    isSuccess
  } = useQuery(
    [businessId, 'customer_portal_session'],
    () => fetchCustomerPortalSession({ businessId }),
    {
      refetchInterval: 30000, // once in 30 secs
      enabled: subscriptionPlanSource === NocfoPlanSource.STRIPE && !!stripeSubscriptionId
    }
  )

  return (
    <StyledWrapper>
      <h2>{t('settings.billing.mainTitle')}</h2>

      <SubscriptionContent>
        <PaidContent sources={[NocfoPlanSource.MANUAL]}>
          <InfoBanner>
            <InfoBannerImageWrapper>
              <img src={imgSrc} alt="Manual plan" />
            </InfoBannerImageWrapper>
            <InfoBannerContent>
              <h1>
                {t('settings.billing.manualSubscriptionTitle', {
                  planName: currentPlanName
                })}
              </h1>
              <p>
                <ReactMarkdown>
                  {t('settings.billing.manualSubscriptionDescription', {
                    planName: currentPlanName
                  })}
                </ReactMarkdown>
              </p>
            </InfoBannerContent>
          </InfoBanner>
        </PaidContent>

        <FreeContent sources={[NocfoPlanSource.STRIPE]}>
          <FreeTrialInfo />
          {showPricingTable && <PricingTable />}
        </FreeContent>

        {/* Do not use PaidContent wrapper here as user must be able to access
        Stripe even if plan is not paid and unactive. */}
        <AnimatedContentLoader isLoading={isLoading}>
          {isSuccess && (
            <StripeLink href={portalData.url}>
              <FaCcStripe className="stripe-icon" />
              <span className="link-text">{t('settings.billing.manageSubscription')}</span>
              <FaExternalLinkAlt className="arrow-icon" />
            </StripeLink>
          )}
        </AnimatedContentLoader>
      </SubscriptionContent>
    </StyledWrapper>
  )
}

const FreeTrialInfo: React.FC = () => {
  const { t } = useTranslation()
  const {
    data: { trial_days_left }
  } = useBusinessContext()

  const alertType = useMemo(() => {
    if (trial_days_left < 5) {
      return 'warning'
    }

    return 'success'
  }, [trial_days_left])

  return (
    <Alert
      type={alertType}
      isVisible={true}
      title={t('settings.billing.freeTrialTitle', { days: trial_days_left })}
      description={t('settings.billing.freeTrialDescription')}
    />
  )
}

const StyledWrapper = styled.div`
  background: white;
  height: 100%;
  overflow: auto;
  padding: ${({ theme }) => theme.spacing.md}rem;

  h2 {
    margin-bottom: ${({ theme }) => theme.spacing.md}rem;
  }
`

const StripeLink = styled(motion.a).attrs({ initial: { opacity: 0 }, animate: { opacity: 1 } })`
  width: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutralBlack};
  text-decoration: none !important;
  font-weight: 500;

  .stripe-icon {
    fill: #665bff;
    width: 50px;
    height: 50px;
    margin-right: ${({ theme }) => theme.spacing.md}rem;
  }

  .link-text {
    flex: 1;
    font-size: ${({ theme }) => theme.fontSize.lg}rem;
  }

  .arrow-icon {
    width: ${({ theme }) => theme.iconSize.sm}rem;
    height: ${({ theme }) => theme.iconSize.sm}rem;
  }
`

const InfoBanner = styled.div`
  width: 100%;
  border-radius: 1rem;
  background: ${({ theme }) => theme.colors.neutralGray};
  padding: ${({ theme }) => theme.spacing.xxl}rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.xxl}rem;
`

const InfoBannerImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  img {
    max-width: 300px;
  }
`

const InfoBannerContent = styled.div`
  flex: 100;
  min-width: 50%;
  color: ${({ theme }) => theme.colors.neutralBlack};

  h1 {
    color: ${({ theme }) => theme.colors.nocfoBlue};
    font-size: ${({ theme }) => theme.fontSize.lg}rem;
  }
`
