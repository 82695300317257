import {
  AnimatedContentLoader,
  Button,
  FileThumbnail,
  FilterBar,
  SearchInput,
  UploadDropzone,
  UploadFilesButton
} from '@components'
import { useVirtualList } from '@hooks'
import { collectFromPages, fetchFiles, IFile, uploadFile } from '@query'
import { formatDate } from '@utils'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCheckCircle, FaCircle, FaCloudUploadAlt } from 'react-icons/fa'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { useAllowUpload } from '@hooks/useAllowUpload.tsx'

interface FileSelectorProps {
  businessId: string
  isDisabled?: boolean
  initialSelections?: number[]
  onClose: (ids: number[]) => void
}

export const FileSelector: React.FC<FileSelectorProps> = ({
  businessId,
  isDisabled = false,
  initialSelections = [],
  onClose
}) => {
  const [t] = useTranslation()
  const [selectedFiles, setSelectedFiles] = useState<number[]>(initialSelections)
  const [search, setSearch] = useState('')
  const queryClient = useQueryClient()
  const [filters, setFilters] = useState<Record<string, any>>(null)
  const allowUpload = useAllowUpload()

  useEffect(() => {
    setSelectedFiles(initialSelections)
  }, [initialSelections])

  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery(
    [businessId, 'files', { search, filters }],
    ({ pageParam = 1 }) => {
      return fetchFiles(
        { businessId },
        { search: search ? search : null, page: pageParam, ...filters }
      )
    },
    {
      getNextPageParam: lastPage => lastPage.next,
      getPreviousPageParam: lastPage => lastPage.prev
    }
  )

  const uploadMutation = useMutation<IFile, unknown, File>(
    file => uploadFile(file, { businessId, fileName: file.name }),
    {
      onSuccess: async function (file) {
        await queryClient.invalidateQueries([businessId, 'files'])
        setSelectedFiles(selectedFiles => {
          const newFiles = [...selectedFiles, file.id]
          return newFiles
        })
      }
    }
  )
  const uploadFileCallback = file => uploadMutation.mutateAsync(file)
  const files = collectFromPages<IFile>(data)

  const toggleSelection = (selectedId: number) => {
    const index = selectedFiles.findIndex(id => id === selectedId)
    if (index !== -1) {
      const newFiles = [...selectedFiles]
      newFiles.splice(index, 1)
      setSelectedFiles(newFiles)
    } else {
      const newFiles = [...selectedFiles, selectedId]
      setSelectedFiles(newFiles)
    }
  }

  const { renderItems, scrollRef } = useVirtualList({
    items: files,
    itemSize: 54,
    onScrollBottom: () => hasNextPage && fetchNextPage()
  })

  const handleOnSelect = () => {
    onClose(selectedFiles)
  }

  return (
    <StyledFileSelector>
      <PaddedContainer>
        <SearchInput
          placeholder={t('components.fileSelector.searchPlaceholder')}
          onSearch={searchTerm => setSearch(searchTerm)}
        />
      </PaddedContainer>

      <div>
        <FilterBar
          onFilter={filter => setFilters(filter)}
          filters={[
            {
              id: 'not-used',
              name: t('files.filters.notUsed'),
              filter: { is_used: false }
            }
          ]}
        />
      </div>

      <FileList ref={scrollRef}>
        <UploadDropzone uploadFile={uploadFileCallback} disabled={!allowUpload}>
          <AnimatedContentLoader
            isLoading={isLoading}
            isEmpty={files.length === 0}
            isEmptyDescription={t('components.fileSelector.searchNotFound')}
          >
            {renderItems((data, style) => (
              <SelectableFile
                key={`file-${data.id}`}
                style={style}
                onClick={() => toggleSelection(data.id)}
              >
                <Column width={32}>
                  <AnimatePresence mode="wait">
                    {selectedFiles.indexOf(data.id) !== -1 ? (
                      <IconWrapper key="selected">
                        <FaCheckCircle size={16} className="icon selected" />
                      </IconWrapper>
                    ) : (
                      <IconWrapper key="not-selected">
                        <FaCircle size={16} className="icon" />
                      </IconWrapper>
                    )}
                  </AnimatePresence>
                </Column>
                <Column width={50}>
                  <FileThumbnail
                    size={32}
                    filetype={data.type}
                    thumb={data.file}
                    blurhash={data.blurhash}
                  />
                </Column>
                <Column width={3}>
                  <FileName>{data.name}</FileName>
                  <FileType>{formatDate(data.created_at)}</FileType>
                </Column>
              </SelectableFile>
            ))}
          </AnimatedContentLoader>
        </UploadDropzone>
      </FileList>
      <ButtonContainer>
        <UploadFilesButton
          type="button"
          icon={<FaCloudUploadAlt />}
          uploadFile={uploadFileCallback}
        >
          {t('components.fileSelector.uploadFiles')}
        </UploadFilesButton>

        <Button
          type="button"
          icon={<FaCheckCircle />}
          intent="primary"
          onClick={handleOnSelect}
          disabled={isDisabled || uploadMutation.isLoading}
        >
          <ButtonContent>
            <ButtonText>{t('components.fileSelector.done')}</ButtonText>
            <ButtonCount>{selectedFiles.length}</ButtonCount>
          </ButtonContent>
        </Button>
      </ButtonContainer>
    </StyledFileSelector>
  )
}

const StyledFileSelector = styled.div`
  background: ${({ theme }) => theme.colors.neutralWhite};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm}rem;
`

const IconWrapper = styled(motion.div).attrs({
  initial: { scale: 0.8, opacity: 0 },
  animate: { scale: 1.0, opacity: 1 },
  exit: { scale: 0.8, opacity: 0 },
  transition: { duration: 0.2 }
})`
  display: flex;

  * {
    align-self: center;
  }
`

const FileList = styled.div`
  flex: 1;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: auto;
`

const PaddedContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

const ButtonContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.md}rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const SelectableFile = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing.xs}rem ${({ theme }) => theme.spacing.md}rem;
  cursor: pointer;
  border-radius: 1rem;

  &:hover {
    background: #f6f7f9;
  }

  & > * {
    align-self: center;
  }

  .icon {
    fill: ${({ theme }) => theme.colors.neutralGray};

    &.selected {
      fill: ${({ theme }) => theme.colors.nocfoBlue};
    }
  }
`

const Column = styled.div<{ width: number }>`
  min-width: ${({ width }) => width}px;
`

const FileName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const FileType = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  color: ${({ theme }) => theme.colors.metalGray};
`

const ButtonContent = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
`

const ButtonText = styled.span`
  align-self: center;
`

const ButtonCount = styled.span`
  background: white;
  color: ${({ theme }) => theme.colors.nocfoBlue};
  margin-left: 0.4rem;
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  font-weight: bold;
  padding: 0 0.4rem;
  border-radius: 0.6rem;
  right: 0;
  align-self: center;
`
