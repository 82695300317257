import { Input } from '@components'
import dayjs from 'dayjs'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormHeader, FormWrapper } from './ReportsPage.styled'

export const DefaultReportForm: React.FC = () => {
  const [t] = useTranslation()
  const {
    control,
    register,
    formState: { errors }
  } = useFormContext()
  const { fields } = useFieldArray({ control, name: 'columns', keyName: '__id' })

  return (
    <FormWrapper>
      {[fields[0]].map((field, index) => (
        <div key={field.__id}>
          <FormHeader>{t('reports.timespan')}</FormHeader>
          <Input
            id="basic-form-start-date"
            type="date"
            label={t('reports.startDate')}
            defaultValue={dayjs().startOf('month').format('YYYY-MM-DD')}
            placeholder={t('reports.selectDate')}
            {...register(`columns.${index}.date_from`, {
              required: t('validation.required') as string
            })}
            errors={errors.columns?.[0]?.date_from}
          />
          <Input
            id="basic-form-end-date"
            type="date"
            label={t('reports.endDate')}
            defaultValue={dayjs().endOf('month').format('YYYY-MM-DD')}
            placeholder={t('reports.selectDate')}
            {...register(`columns.${index}.date_to`, {
              required: t('validation.required') as string
            })}
            errors={errors.columns?.[0]?.date_to}
          />
        </div>
      ))}
    </FormWrapper>
  )
}
