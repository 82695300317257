import { Button, Input } from '@root/components'
import { useBusinessContext } from '@root/context'
import {
  IBusiness,
  IInvoiceNumber,
  fetchBusiness,
  fetchInvoiceNumber,
  updateBusiness,
  updateInvoiceNumber
} from '@root/query'
import { setAPIErrors } from '@root/utils'
import React from 'react'
import toast from 'react-hot-toast'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'

type FormType = IInvoiceNumber & Partial<IBusiness>

const InvoiceSettingsAdvanced: React.FC = () => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()

  const { refetch: refetchInvoiceNumber } = useQuery([businessId, 'invoice_number'], () =>
    fetchInvoiceNumber({ businessId })
  )

  const { refetch: refetchBusiness } = useQuery([businessId, 'get_business_data'], () =>
    fetchBusiness({ businessId })
  )

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { isDirty, errors }
  } = useForm<FormType>({
    defaultValues: async () => {
      const [{ data: invoiceNumberData }, { data: businessData }] = await Promise.all([
        refetchInvoiceNumber(),
        refetchBusiness()
      ])
      return {
        next_invoice_number: invoiceNumberData.next_invoice_number,
        invoicing_default_penalty_interest: businessData.invoicing_default_penalty_interest,
        invoicing_default_payment_condition_days:
          businessData.invoicing_default_payment_condition_days
      }
    }
  })

  const resetFormValues = (data: FormType) =>
    reset({
      next_invoice_number: data?.next_invoice_number,
      invoicing_default_penalty_interest: data?.invoicing_default_penalty_interest,
      invoicing_default_payment_condition_days: data?.invoicing_default_payment_condition_days
    })

  const updateDataMutation = useMutation<any, unknown, FormType>(
    async data => {
      const [
        { next_invoice_number: next_invoice_number },
        {
          invoicing_default_penalty_interest: invoicing_default_penalty_interest,
          invoicing_default_payment_condition_days: invoicing_default_payment_condition_days
        }
      ] = await Promise.all([
        updateInvoiceNumber({ businessId }, { next_invoice_number: data?.next_invoice_number }),
        updateBusiness(
          { businessId },
          {
            invoicing_default_penalty_interest: data?.invoicing_default_penalty_interest,
            invoicing_default_payment_condition_days: data?.invoicing_default_payment_condition_days
          }
        )
      ])

      return {
        next_invoice_number: next_invoice_number,
        invoicing_default_penalty_interest: invoicing_default_penalty_interest,
        invoicing_default_payment_condition_days: invoicing_default_payment_condition_days
      }
    },

    {
      onSuccess: async data => {
        resetFormValues(data)
      },
      onError: ({ status, data }) => {
        status === 400 && setAPIErrors(data, setError)
      }
    }
  )

  return (
    <MenuContainer>
      <form
        data-test="invoice-advanced-form"
        onSubmit={handleSubmit(data => {
          const promise = updateDataMutation.mutateAsync(data)
          toast.promise(promise, {
            loading: t('general.loading'),
            success: t('general.changesSaved'),
            error: t('general.error')
          })
        })}
      >
        <InputHeader>{t('settings.invoicing.invoiceNumber')}</InputHeader>
        <Input
          label={t('settings.invoicing.invoiceNumber')}
          errors={errors.next_invoice_number}
          required={true}
          type="number"
          data-test="settings-invoincing-invoice-number"
          info={t('settings.invoicing.invoiceNumberInfo')}
          {...register('next_invoice_number', {
            required: { value: true, message: t('validation.required') },
            valueAsNumber: true
          })}
        />

        <InputHeader>{t('settings.invoicing.defaultValue')}</InputHeader>
        <Input
          label={t('settings.invoicing.penaltyInterest')}
          errors={errors.invoicing_default_penalty_interest}
          required={true}
          type="number"
          step="0.1"
          info={t('settings.invoicing.penaltyInterestInfo')}
          {...register('invoicing_default_penalty_interest', {
            required: { value: true, message: t('validation.required') },
            valueAsNumber: true
          })}
        />
        <Input
          label={t('settings.invoicing.paymentTerm')}
          errors={errors.invoicing_default_payment_condition_days}
          required={true}
          type="number"
          info={t('settings.invoicing.paymentTermInfo')}
          {...register('invoicing_default_payment_condition_days', {
            required: { value: true, message: t('validation.required') },
            valueAsNumber: true
          })}
        />

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '32px' }}>
          <Button intent="success" disabled={!isDirty} showSpinner={updateDataMutation.isLoading}>
            {t('general.save')}
          </Button>
        </div>
      </form>
    </MenuContainer>
  )
}

export default InvoiceSettingsAdvanced

const MenuContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.md}rem;
`
const InputHeader = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacing.xs}rem;
  margin-top: ${({ theme }) => theme.spacing.md}rem;
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  line-height: ${({ theme }) => theme.fontSize.md}rem;
  font-weight: 600;
  letter-spacing: -0.05px;
  color: ${({ theme }) => theme.colors.neutralBlack};
`
