import nocfoSrc from '@assets/nocfo-black.svg'
import holviSrc from '@assets/Holvi_Wordmark.png'
import { AccountSelector, AnimatedContentLoader, Button, Input, Switch } from '@components'
import { useBusinessContext } from '@context'
import {
  SlideContent,
  SlideFooter,
  SlideText,
  SlideTitle,
  Spacer
} from '@pages/BankIntegrationOnboardingPage/slides/slide.styled'

import { tryFormatIBAN } from '@utils/iban'
import React from 'react'
import { Controller, get, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FaArrowLeft, FaArrowsAltH, FaCheck } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'
import {
  deleteHolviIntegration,
  fetchHolviAccounts,
  IHolviAccount,
  updateHolviAccount
} from '@query/holvi.ts'
import { AccountType } from '@constants'
import { setAPIErrors } from '@utils'
import toast from 'react-hot-toast'

interface Props {
  isEdit: boolean
  goBack: () => void
  onDone: () => void
}

const FINALIZE_FORM_ID = 'finalize-form'

export const HolviEditSlide: React.FC<Props> = ({ isEdit, onDone, goBack }) => {
  const { t } = useTranslation()
  const { businessId, data: business } = useBusinessContext()
  const queryClient = useQueryClient()

  const { isLoading, refetch } = useQuery(
    [businessId, 'holvi', 'accounts'],
    () => fetchHolviAccounts({ businessId }),
    {
      enabled: business?.holvi_bookkeeping_api_enabled
    }
  )

  const {
    handleSubmit,
    watch,
    register,
    control,
    setValue,
    setError,
    formState: { errors, isSubmitting }
  } = useForm<{ accounts: IHolviAccount[] }>({
    defaultValues: async () => {
      const { data } = await refetch()
      return { accounts: data?.results }
    }
  })
  const { fields: accountFields } = useFieldArray({
    control,
    name: 'accounts',
    keyName: '__id'
  })

  const onSubmit = handleSubmit(data => {
    Promise.all(
      data.accounts.map((account, idx) =>
        updateHolviAccount({ businessId, accountId: account.id }, account).catch(err => {
          if (err.status === 400) {
            setAPIErrors(err.data, setError, `accounts.${idx}`)
          } else {
            toast.error(t('general.error'))
          }
          throw err
        })
      )
    ).then(() => onDone())
  })

  const { mutateAsync: deleteIntegration, isLoading: isDeleting } = useMutation(
    () => deleteHolviIntegration({ businessId }),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries([businessId]),
          queryClient.invalidateQueries([businessId, 'holvi'])
        ])
      }
    }
  )

  const handleGoBack = async () => {
    if (!isEdit) {
      await deleteIntegration()
    }
    goBack()
  }

  const getAccountTitle = (account: IHolviAccount): string => {
    const accountHeader = {
      psd: t('settings.holvi.account.psd'),
      credit: t('settings.holvi.account.credit')
    }
    return account?.name || accountHeader[account.type]
  }

  const getAccountFilters = (account: IHolviAccount): Record<string, string> => {
    switch (account.type) {
      case 'psd':
        return { type: AccountType.ASS_PAY }
      case 'credit':
        return { type: AccountType.LIA_DUE }
      default:
        return {}
    }
  }

  return (
    <>
      <SlideContent>
        <StyledForm id={FINALIZE_FORM_ID} onSubmit={onSubmit}>
          <AnimatedContentLoader isLoading={isLoading}>
            {isEdit ? (
              <SlideTitle>
                {t('bankOnboarding.finalize.editTitle', { bankName: 'Holvi' })}
              </SlideTitle>
            ) : (
              <SlideTitle>
                {t('bankOnboarding.finalize.createTitle', { bankName: 'Holvi' })}
              </SlideTitle>
            )}
            <SlideText>{t('bankOnboarding.finalize.description')}</SlideText>
            <LogoWrapper>
              <LogoImg src={holviSrc} className="left" />
              <FaArrowsAltH />
              <LogoImg src={nocfoSrc} className="right" />
            </LogoWrapper>

            <Spacer />

            {accountFields.map((account, index) => {
              return (
                <StyledAccountWrapper key={`account-${account.id}`}>
                  <StyledAccountHeaderWrapper>
                    <label htmlFor={`accounts-${index}-enabled-switch`}>
                      <StyledAccountTitle>{getAccountTitle(account)}</StyledAccountTitle>
                      <StyledAccountIdentifier>
                        {tryFormatIBAN(account.iban)}
                      </StyledAccountIdentifier>
                    </label>

                    <Controller
                      control={control}
                      name={`accounts.${index}.enabled`}
                      render={({ field: { ref, value, onChange }, fieldState: { error } }) => (
                        <Switch
                          label=""
                          height={24}
                          id={`accounts-${index}-enabled-switch`}
                          ref={ref}
                          checked={value}
                          onChange={onChange}
                          error={error}
                        />
                      )}
                    />
                  </StyledAccountHeaderWrapper>

                  {watch(`accounts.${index}.enabled`) && (
                    <>
                      <Controller
                        control={control}
                        name={`accounts.${index}.account`}
                        rules={{
                          required: { value: true, message: t('validation.required') }
                        }}
                        render={({ field: { ref, value, onChange }, fieldState: { error } }) => (
                          <>
                            <AccountSelector
                              id={`accounts-${index}-account-select`}
                              label={t('bankOnboarding.finalize.accountLabel')}
                              ref={ref}
                              error={error}
                              value={value}
                              onChange={onChange}
                              required={true}
                              errors={get(errors, `accounts.${index}.account`)}
                              clearSelectedItem={() => setValue(`accounts.${index}.account`, null)}
                              queryKey={`${businessId}-holvi-accounts`}
                              accountFilter={getAccountFilters(account)}
                              allowCreate={true}
                            />
                          </>
                        )}
                      />

                      <Input
                        type="date"
                        label={t('settings.integrations.bank.minSyncDate')}
                        required={true}
                        errors={get(errors, `accounts.${index}.begin_sync_from`)}
                        {...register(`accounts.${index}.begin_sync_from`, {
                          required: { value: true, message: t('validation.required') }
                        })}
                      />
                    </>
                  )}
                </StyledAccountWrapper>
              )
            })}
            <Spacer />
          </AnimatedContentLoader>
        </StyledForm>
      </SlideContent>
      <SlideFooter>
        <Button
          type="button"
          icon={<FaArrowLeft />}
          showSpinner={isDeleting}
          onClick={() => handleGoBack()}
        >
          {t('general.back')}
        </Button>
        <Spacer />
        <Button
          type="submit"
          intent="success"
          icon={<FaCheck />}
          form={FINALIZE_FORM_ID}
          disabled={isLoading || isSubmitting}
          showSpinner={isSubmitting}
        >
          {isEdit ? t('general.save') : t('bankOnboarding.finalize.activate')}
        </Button>
      </SlideFooter>
    </>
  )
}

const StyledForm = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const StyledAccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 3px solid ${({ theme }) => theme.colors.neutralGray};
  border-radius: 1rem;
  padding: ${({ theme }) => theme.spacing.sm}rem;
  overflow: hidden;

  gap: ${({ theme }) => theme.spacing.sm}rem;
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;

  .input-wrapper {
    margin: 0;

    input {
      height: 1.6rem;
    }
  }
`

const StyledAccountHeaderWrapper = styled.div`
  display: flex;
  width: 100%;

  label {
    width: 100%;
    flex: 1;
    margin: 0;
  }

  .switch-wrapper {
    margin: 0;
  }
`

const StyledAccountTitle = styled.div`
  color: ${({ theme }) => theme.colors.metalGray};
`

const StyledAccountIdentifier = styled.div`
  color: ${({ theme }) => theme.colors.metalGray};
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
`

const LogoWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md}rem;
  justify-content: space-around;
  align-items: center;
  margin: ${({ theme }) => theme.spacing.xxl}rem 0;

  svg {
    width: 2rem;
    height: 2rem;
    min-width: 1rem;
    min-height: 1rem;
    fill: ${({ theme }) => theme.colors.neutralBlack};
  }
`

const LogoImg = styled.div<{ src: string }>`
  width: 100%;
  height: 40px;
  background-image: ${({ src }) => `url(${src})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &.right {
    height: 55px;
  }
`
