import React from 'react'
import { Controller, get, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RadioGroup, RadioItem, SlideText, SlideTitle, ValidationError } from './slide.styled'
interface FormInputProps {
  form: string
}

interface BusinessForm {
  labelKey: string
  value: string
}

const businessForms: BusinessForm[] = [
  {
    labelKey: 'constants.businessForm.FI_OY',
    value: 'FI_OY'
  },
  {
    labelKey: 'constants.businessForm.FI_TMI',
    value: 'FI_TMI'
  },
  {
    labelKey: 'constants.businessForm.FI_KY',
    value: 'FI_KY'
  },
  {
    labelKey: 'constants.businessForm.FI_AY',
    value: 'FI_AY'
  },
  {
    labelKey: 'constants.businessForm.FI_OK',
    value: 'FI_OK'
  },
  {
    labelKey: 'constants.businessForm.FI_AS_OY',
    value: 'FI_AS_OY'
  },
  {
    labelKey: 'constants.businessForm.FI_KOY',
    value: 'FI_KOY'
  },
  {
    labelKey: 'constants.businessForm.FI_YHD',
    value: 'FI_YHD'
  }
]

export const BusinessFormSlide: React.FC<FormInputProps> = () => {
  const [t] = useTranslation()
  const { control } = useFormContext()

  return (
    <>
      <SlideTitle>{t('onboarding.businessFormSlide.title')}</SlideTitle>
      <SlideText>{t('onboarding.businessFormSlide.message')}</SlideText>

      <Controller
        control={control}
        name="business.form"
        rules={{ required: { value: true, message: t('validation.required') } }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <>
            <RadioGroup>
              {businessForms.map((bf, i) => (
                <RadioItem
                  key={bf.value}
                  label={t(bf.labelKey)}
                  index={i}
                  selected={value === bf.value}
                  value={bf.value}
                  onSelect={onChange}
                />
              ))}

              {get(errors, 'business.form') && (
                <ValidationError>{get(errors, 'business.form').message}</ValidationError>
              )}
            </RadioGroup>
          </>
        )}
      />
    </>
  )
}
