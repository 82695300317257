import noAvatarImg from '@assets/no-avatar.svg'
import { TextTooltip } from '@components'
import React from 'react'
import { FaGlasses, FaShieldAlt } from 'react-icons/fa'
import styled from 'styled-components'
import { useProgressiveImage } from '@hooks/useProgressiveImage.ts'

interface Props {
  src?: string
  size?: number
  role?: string
}

const DEFAULT_SIZE = 32

export const Avatar: React.FC<Props> = ({ src, size = DEFAULT_SIZE, role }) => {
  const imageSrc = useProgressiveImage(src, noAvatarImg)

  return (
    <StyledAvatar bgSrc={imageSrc} size={size}>
      {role === 'ADMIN' && (
        <TextTooltip tooltip="Käyttäjä on pääkäyttäjä">
          <UserBadge className="admin">
            <FaShieldAlt size={10} />
          </UserBadge>
        </TextTooltip>
      )}

      {role === 'READ_ONLY' && (
        <TextTooltip tooltip="Käyttäjällä on vain lukuoikeus">
          <UserBadge className="read-only">
            <FaGlasses size={10} />
          </UserBadge>
        </TextTooltip>
      )}
    </StyledAvatar>
  )
}

const StyledAvatar = styled.div<{ bgSrc: string; size: number }>`
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  max-width: ${({ size }) => size}px;
  max-height: ${({ size }) => size}px;
  background-image: url(${({ bgSrc }) => bgSrc});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10000px;
  background-color: ${({ theme }) => theme.colors.metalGray}22;
`

const UserBadge = styled.div`
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 1.2rem;
  right: -2px;
  bottom: -2px;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;

  &.admin {
    background: ${({ theme }) => theme.colors.nocfoPurple};

    svg {
      align-self: center;
      fill: #fff !important;
    }
  }

  &.read-only {
    background: ${({ theme }) => theme.colors.nocfoBlue};

    svg {
      align-self: center;
      fill: #fff !important;
    }
  }
`
