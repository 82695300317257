import { theme } from '@styles'
import React from 'react'
import { FaCheckCircle, FaCircle } from 'react-icons/fa'
import {
  Step,
  StepContact,
  StepContent,
  StepDescription,
  StepIconContainer,
  StepTitle
} from './StepBlock.styled'

interface Props {
  isBlocked?: boolean
  isCompleted?: boolean
  title?: string
  descBlocked?: React.ReactElement | string
  descCurrent?: React.ReactElement | string
  descComplete?: React.ReactElement | string
  isLast?: boolean
}

export const StepBlock: React.FC<Props> = ({
  isBlocked,
  isCompleted,
  title,
  descBlocked,
  descCurrent,
  descComplete,
  isLast = false
}) => {
  const Icon = isBlocked ? FaCircle : isCompleted ? FaCheckCircle : FaCircle
  const description = isBlocked ? descBlocked : isCompleted ? descComplete : descCurrent

  const statusColor = isBlocked ? theme.colors.neutralGray : theme.colors.nocfoBlue
  const connectorColor = isCompleted ? theme.colors.nocfoBlue : theme.colors.neutralGray
  return (
    <Step>
      <StepIconContainer>
        <Icon color={statusColor} style={{ opacity: isCompleted ? 0.65 : 1 }} />
        {!isLast && (
          <StepContact color={connectorColor} style={{ opacity: isCompleted ? 0.65 : 1 }} />
        )}
      </StepIconContainer>
      <StepContent>
        <StepTitle>{title}</StepTitle>
        <StepDescription>{description}</StepDescription>
      </StepContent>
    </Step>
  )
}
