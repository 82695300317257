import { Input } from '@components'
import { SlideText, SlideTitle } from '@pages/OnboardingPage/slides/slide.styled'
import React from 'react'
import { get, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const FiscalPeriodSlide: React.FC = () => {
  const [t] = useTranslation()
  const {
    register,
    formState: { errors }
  } = useFormContext()

  return (
    <>
      <SlideTitle>{t('onboarding.periodSlide.title')}</SlideTitle>
      <SlideText>{t('onboarding.periodSlide.message')}</SlideText>

      <Input
        type="date"
        {...register('period.start_date', {
          required: { value: true, message: t('validation.required') }
        })}
        label={t('onboarding.periodSlide.startDate', {
          required: { value: true, message: t('validation.required') }
        })}
        errors={get(errors, 'period.start_date')}
      />
      <Input
        type="date"
        {...register('period.end_date')}
        label={t('onboarding.periodSlide.endDate')}
        errors={get(errors, 'period.end_date')}
      />
    </>
  )
}
