import imgSrc from '@assets/undraw/undraw_upgrade_re_gano.svg'
import { Button, PricingTableModal } from '@components'
import { NocfoPlan } from '@constants'
import { useBusinessContext, useFeature } from '@context'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const useShowUpgradePlanWidget = (): boolean => {
  const isBillable = useFeature('is_billable')
  const {
    data: { subscription_plan }
  } = useBusinessContext()
  return isBillable && subscription_plan === NocfoPlan.FREE
}

export const UpgradePlanWidget: React.FC = () => {
  const [t] = useTranslation()
  const [showModal, setShowModal] = useState(false)

  return (
    <StyledWidget>
      <UpgradeImg src={imgSrc} />
      <UpgradeText>{t('dashboard.upgradePlan.message')}</UpgradeText>
      <Button intent="primary" onClick={() => setShowModal(!showModal)}>
        {t('dashboard.upgradePlan.compare')}
      </Button>

      <PricingTableModal isVisible={showModal} handleOnClose={() => setShowModal(false)} />
    </StyledWidget>
  )
}

const StyledWidget = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.xl}rem;
  align-items: center;
`

const UpgradeImg = styled.img`
  max-width: 350px;
  margin-bottom: ${({ theme }) => theme.spacing.xl}rem;
`

const UpgradeText = styled.p`
  text-align: center;
  max-width: 350px;
  color: ${({ theme }) => theme.colors.neutralBlack};
`
