import { animate } from 'framer-motion'
import React, { HTMLAttributes, useEffect, useState } from 'react'
import styled from 'styled-components'

interface RollingProps extends HTMLAttributes<HTMLHeadingElement> {
  from: number
  to: number
  duration?: number
  delay?: number
  suffix?: string
  formatter?: (num: number, ...rest: any) => string
}

export const RollingNumber: React.FC<RollingProps> = ({
  from,
  to,
  duration = 1,
  delay = 0,
  suffix,
  formatter,
  ...rest
}) => {
  const [fromValue, setFromValue] = useState<number>(from)
  const [displayText, setDisplayText] = useState<string>(from.toFixed(0))

  useEffect(() => {
    const animationControl = animate(fromValue, to, {
      duration,
      delay,
      onUpdate: latest => {
        setDisplayText(formatter?.(latest) ?? latest.toFixed(0))
      },
      onComplete: () => {
        setFromValue(to)
      }
    })
    return () => animationControl.stop()
  }, [fromValue, to, duration])

  return (
    <Row>
      <Title {...rest}>{displayText}</Title>
      <Subtitle>{suffix}</Subtitle>
    </Row>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const Title = styled.h4`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSize.lg}rem;
  line-height: ${({ theme }) => theme.fontSize.lg}rem;
  color: ${({ theme }) => theme.colors.iconGrayDark};
  font-weight: 500;
`

const Subtitle = styled.span``
