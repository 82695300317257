import { NotificationUrgency } from '@root/components'
import { IDocument } from '@root/query'
import dayjs from 'dayjs'

export const selectUserNotificationIcon = (
  document: IDocument,
  showOnlyMention = false
): NotificationUrgency | null => {
  // Current user has not visited the tab after the last time they were tagged
  if (dayjs(document.audit_trail_last_mention_at).isAfter(document.audit_trail_last_seen_at)) {
    return NotificationUrgency.URGENT
  }

  if (showOnlyMention) {
    return null
  }

  // Current user has not visited the tab after the last comment was made
  if (dayjs(document.audit_trail_last_comment_at).isAfter(document.audit_trail_last_seen_at)) {
    return NotificationUrgency.NON_URGENT
  }

  // Current user has not visited the tab after the last time the document was updated.
  // Not inlcuding the user who made the update.
  if (document.audit_trail_has_unseen_actions) {
    return NotificationUrgency.NON_URGENT
  }
}
