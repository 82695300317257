import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'
import { useSteps } from './StepContext'

interface StepProps {
  index: number
  initial: string
  exit: string
  animate: string
  variants: Record<string, any>
  children: React.ReactNode
}

const StepContainer = styled(motion.div)`
  display: flex;
  flex: 1;
  flex-grow: 1;
  height: 100%;
`

export const Step: React.FC<StepProps> = ({
  index,
  children,
  initial,
  animate,
  exit,
  variants
}) => {
  const { currentStepIndex } = useSteps()

  if (index !== currentStepIndex) return null

  return (
    <StepContainer initial={initial} animate={animate} exit={exit} variants={variants} key={index}>
      {children}
    </StepContainer>
  )
}
