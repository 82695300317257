import termsImg from '@assets/undraw/undraw_accept_terms_4in8.svg'
import { BasicPage, Button, Checkbox } from '@components'
import { acceptTos, logOut, useCurrentUser } from '@query'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FaCheck } from 'react-icons/fa'
import { useMutation, useQueryClient } from 'react-query'
import styled from 'styled-components'

const emitConversionEvents = (userId: number) => {
  const _window = window as any
  const userIdentifier = `UserID:${userId.toString().padStart(12, '0')}`

  // Google Ads
  _window.gtag?.('event', 'conversion', {
    send_to: 'AW-10805139731/IGNACLXlpd4DEJOypaAo',
    transaction_id: userIdentifier
  })

  // Facebook
  _window.fbq?.('track', 'CompleteRegistration', {}, { eventID: userIdentifier })
}

export const TosPage: React.FC = () => {
  const [t] = useTranslation()
  const [isAccepted, setIsAccepted] = useState(false)
  const queryClient = useQueryClient()
  const { data: currentUser } = useCurrentUser()

  const { mutate: acceptTosMutation, isLoading } = useMutation(() => acceptTos(), {
    onSuccess: async () => {
      emitConversionEvents(currentUser.id)
      await queryClient.invalidateQueries('user')
    }
  })

  const logOutMutation = useMutation(() => logOut(), {
    onSettled: async () => {
      await queryClient.clear()
      await queryClient.invalidateQueries()
      window.location.assign('/')
    }
  })

  return (
    <BasicPage imageUrl={termsImg}>
      <h2>{t('tos.acceptTosTitle')}</h2>
      <p>{t('tos.acceptTosDescription')}</p>
      <div style={{ flex: 1 }} />
      <Checkbox
        id="tos-checkbox"
        checked={isAccepted}
        onChange={e => setIsAccepted(e.currentTarget.checked)}
        label={
          <CheckboxTextWrapper>
            <Trans
              i18nKey="tos.checkboxText"
              components={[
                <a
                  key="1"
                  target="_blank"
                  rel="noreferrer"
                  href="https://nocfo.io/service-terms/"
                />,
                <a
                  key="2"
                  target="_blank"
                  rel="noreferrer"
                  href="https://nocfo.io/privacy-policy/"
                />
              ]}
            />
          </CheckboxTextWrapper>
        }
      />

      <ButtonWrapper>
        <Button onClick={() => logOutMutation.mutate()}>{t('tos.buttonCancel')}</Button>
        <Button
          className="accept-tos"
          onClick={() => acceptTosMutation()}
          showSpinner={isLoading}
          intent="success"
          icon={<FaCheck />}
          disabled={isLoading || !isAccepted}
        >
          {t('tos.buttonText')}
        </Button>
      </ButtonWrapper>
    </BasicPage>
  )
}

const CheckboxTextWrapper = styled.div`
  margin-left: ${({ theme }) => theme.spacing.xs}rem;
`

const ButtonWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  button {
    margin-bottom: ${({ theme }) => theme.spacing.md}rem;

    &:not(:last-of-type) {
      margin-right: ${({ theme }) => theme.spacing.md}rem;
    }
  }
`
