import dayjs from 'dayjs'

import 'dayjs/locale/en'
import 'dayjs/locale/fi'

import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import { ValidLocale } from '@localisation/utils.ts'

export const initializeDayJs = () => {
  dayjs.extend(relativeTime)
  dayjs.extend(localizedFormat)
}

export const setDayJsLocale = (locale: ValidLocale) => {
  dayjs.locale(locale)
}
