import { Button, ModalV2 } from '@components'
import { IContact } from '@query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ContactForm } from './ContactForm'

interface Props {
  contact?: IContact
  isVisible: boolean
  handleOnClose: () => void
}

export const ContactFormModal: React.FC<Props> = ({ contact, isVisible, handleOnClose }) => {
  const [t] = useTranslation()
  return (
    <ModalV2
      isVisible={isVisible}
      handleOnClose={handleOnClose}
      header={contact ? t('contacts.edit') : t('contacts.newContact')}
      width={600}
      height={900}
      footer={
        <>
          <Button onClick={() => handleOnClose()}>{t('general.cancel')}</Button>
          <Button type="submit" intent="success" form="contact-form">
            {t('general.save')}
          </Button>
        </>
      }
    >
      <ContactForm contact={contact} onClose={() => handleOnClose()} />
    </ModalV2>
  )
}
