import { network } from '@utils'
import { QueryFunction } from './root'

interface HeaderPathProps {
  businessId: string
  number: number
}

interface HeaderPathResponse {
  headers: string[]
}

export const fetchHeaderPath: QueryFunction<HeaderPathProps, HeaderPathResponse> = async ({
  businessId,
  number
}) => {
  const url = `/v1/business/${businessId}/coa/path/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params: { number }
  })
  return data
}
