import { motion } from 'framer-motion'
import { FaMagic } from 'react-icons/fa'
import styled from 'styled-components'

export const DropdownWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 0 0 ${({ theme }) => theme.spacing.xs}rem 0;
`

export const StyledInvoiceCopyDropdown = styled(motion.div).attrs({
  variants: {
    hide: {
      opacity: 0,
      scale: 0.8,
      y: -25,
      x: 0,
      transition: {
        duration: 0.4,
        staggerChildren: 0.0,
        staggerDirection: -1,
        type: 'spring'
      }
    },
    show: {
      opacity: 1,
      scale: 1.0,
      y: 0,
      x: 0,
      transition: {
        duration: 0.4,
        staggerChildren: 0.0,
        staggerDirection: 1,
        type: 'spring'
      }
    }
  },
  initial: 'hide',
  animate: 'show',
  exit: 'hide'
})`
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 100000;
  border-radius: 1rem;
  width: 600px;
  overflow: auto;
  padding: ${({ theme }) => theme.spacing.md}rem;
  box-sizing: content-box;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
`

export const Header = styled.div`
  display: flex;
  font-weight: 500;
  margin: 0.6rem;
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;
`

export const HeaderIcon = styled(FaMagic)`
  align-self: center;
  margin-left: 0.4rem;
  fill: ${({ theme }) => theme.colors.iconGrayDark};
`

export const ItemContainer = styled(motion.div)`
  tabindex: 1;
  margin: ${({ theme }) => theme.spacing.md}rem 0 0 0;
`

export const Item = styled(motion.div).attrs({
  variants: {
    hidden: {
      y: 5,
      opacity: 0
    },
    show: {
      y: 0,
      opacity: 1
    }
  }
})`
  display: flex;
  padding: 0.4rem 0.6rem;
  border-radius: 0.5rem;
  cursor: pointer;

  &.keyboard-focused,
  &:hover {
    background: ${({ theme }) => theme.colors.mainBg};
  }
`

export const ItemColumn = styled.div`
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.name {
    flex: 1;
  }

  &.amount {
  }

  &.number {
    min-width: 80px;
    font-size: 0.8rem;
    margin-right: 0.8rem;
    color: ${({ theme }) => theme.colors.metalGray};
  }

  &.id {
    min-width: 60px;
    font-size: 0.8rem;
    margin-right: 0.8rem;
    color: ${({ theme }) => theme.colors.metalGray};
  }

  &.balance {
    margin-left: 0.8rem;
    text-align: right;
    font-family: 'Roboto Mono', monospace;
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.metalGray};
  }
`
