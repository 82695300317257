import { useDateBadgedList, useQueryParam, useVirtualList } from '@hooks'
import { ICombinedInvoice } from '@query'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'
import { InvoiceListItem } from './InvoiceListItem'

interface Props {
  combinedInvoices: ICombinedInvoice[]
  onScrollBottom: () => void
}

export const InvoiceList: React.FC<Props> = ({ combinedInvoices, onScrollBottom }) => {
  const [selectedId] = useQueryParam<number>('id', value => parseInt(value, 10))

  const badgedList = useDateBadgedList<ICombinedInvoice>({
    items: combinedInvoices,
    itemDateKey: 'item.invoicing_date',
    itemDateFormat: 'YYYY-MM-DD',
    dateFormat: 'MMMM YYYY'
  })

  const { scrollRef, renderItems } = useVirtualList({
    items: badgedList,
    itemSize: 72,
    onScrollBottom
  })

  return (
    <InvoiceListScrollable ref={scrollRef}>
      <AnimatePresence>
        {renderItems((item, style, index) => {
          if (item.type === 'item') {
            return (
              <motion.div key={`invoice-${index}`} layout="position" style={style}>
                <InvoiceListItem
                  key={`el-${item.type}-${item.item.item.id}`}
                  combinedInvoice={item.item}
                  isSelected={selectedId === item.item.item.id}
                  isFirst={item.extra.isFirstOfType}
                  isLast={item.extra.isLastOfType}
                />
              </motion.div>
            )
          }

          return (
            <DateBadgeWrapper key={item.item.id} style={style}>
              <DateBadge>{item.item.label}</DateBadge>
            </DateBadgeWrapper>
          )
        })}
      </AnimatePresence>
    </InvoiceListScrollable>
  )
}

const InvoiceListScrollable = styled.div`
  overflow: auto;
  flex: 1;
  height: 100%;
`

const DateBadgeWrapper = styled(motion.div).attrs({ layout: 'position' })`
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;

  & > * {
    align-self: center;
  }
`

const DateBadge = styled.div`
  text-transform: uppercase;
  padding: 0.2rem 0.4rem;
  background: ${({ theme }) => theme.colors.neutralBlack};
  color: ${({ theme }) => theme.colors.metalGray};
  border-radius: 1rem;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  font-weight: 500;
  background: ${({ theme }) => theme.colors.neutralGray};
`
