import { useTheme } from '@hooks'
import { Heading, Pane, Popover, Position } from 'evergreen-ui'
import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { FaSyncAlt } from 'react-icons/fa'

interface MenuItemProps {
  text: string
  icon: any
  primary?: boolean
  action?: () => any
  disabled?: boolean
  dropdown?: ({ close }: { close: () => void }) => void
}

interface ToolBarProps {
  items: Array<MenuItemProps>
  align?: 'left' | 'right'
}

export const ToolBar: React.FC<ToolBarProps> = ({ items, align = 'right' }) => {
  const theme = useTheme()

  const conditionalDropdown = (item: MenuItemProps, node: React.ReactElement) =>
    item.dropdown !== undefined ? (
      <Popover position={Position.BOTTOM_LEFT} content={({ close }) => item.dropdown({ close })}>
        {node}
      </Popover>
    ) : (
      node
    )

  const renderItem = (item: MenuItemProps, index: number) => {
    const [loading, setLoading] = useState(false)
    const disabled = item.disabled || loading

    return (
      <Pane key={`toolbar-item-${index}`}>
        {conditionalDropdown(
          item,
          <motion.div
            whileHover={{ scale: 1 }}
            whileTap={{ scale: disabled ? 1 : 0.9 }}
            style={{ userSelect: 'none' }}
          >
            <Pane
              display="flex"
              borderRadius={10}
              marginY={4}
              marginLeft={align === 'left' ? 0 : 8}
              marginRight={align === 'right' ? 0 : 8}
              paddingX={3}
              paddingY={2}
              background={item.primary ? theme.colors.nocfoBlue : theme.colors.mainBg}
              onClick={() => {
                if (!disabled) {
                  if (item.action) {
                    const result = item.action()
                    if (result) {
                      setLoading(true)
                      result.finally(() => setLoading(false))
                    }
                  }
                }
              }}
              opacity={disabled ? 0.7 : 1}
              cursor={disabled ? 'not-allowed' : 'pointer'}
            >
              <Pane alignSelf="center" marginX={6} marginY={4}>
                {loading ? (
                  <FaSyncAlt className="spin" color={item.primary ? '#fff' : '#252f38'} size={12} />
                ) : (
                  <item.icon color={item.primary ? '#fff' : '#252f38'} size={12} />
                )}
              </Pane>
              {item.text && (
                <Heading
                  color={item.primary ? '#fff' : '#252f38'}
                  size={200}
                  alignSelf="center"
                  marginX={8}
                  height={20}
                  fontWeight={600}
                  lineHeight={'20px'}
                  marginLeft={item.icon ? 0 : 8}
                  whiteSpace="nowrap"
                >
                  {item.text}
                </Heading>
              )}
            </Pane>
          </motion.div>
        )}
      </Pane>
    )
  }

  return (
    <Pane
      display="flex"
      flexWrap="nowrap"
      borderRadius={10}
      justifyContent={align === 'right' ? 'flex-end' : 'flex-start'}
    >
      {items.map((item: MenuItemProps, index: number) => renderItem(item, index))}
    </Pane>
  )
}
