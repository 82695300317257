import styled from 'styled-components'

export const StyledStatusTracker = styled.div`
  padding: ${({ theme }) => theme.spacing.md}rem;
  border: 3px solid ${({ theme }) => theme.colors.neutralGray};
  border-radius: 1rem;
`

export const StyledStatusTrackerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg}rem;
`

export const StyledStatusTrackerHeader = styled.h4`
  color: ${({ theme }) => theme.colors.neutralBlack};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  margin-bottom: 0;
`

export const StyledTimelineItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm}rem;
`

export const StyledTimelineItem = styled.div``

export const StyledTimelineRow = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xxs}rem;
  align-items: center;
  justify-content: space-between;
`

export const StyledDate = styled.span`
  display: flex;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.metalGray};
`

export const StyledDetailMessage = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  color: ${({ theme }) => theme.colors.neutralBlack};
`

export const StyledMoreButton = styled.a`
  color: ${({ theme }) => theme.colors.nocfoBlue};
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  font-weight: bold;
  cursor: pointer;
`

export const StyledProgressIndicator = styled.div`
  width: ${({ theme }) => theme.fontSize.xs}rem;
  height: ${({ theme }) => theme.fontSize.xs}rem;
  display: block;
  position: relative;

  &::after {
    content: '';
    box-sizing: border-box;
    width: ${({ theme }) => theme.fontSize.xs}rem;
    height: ${({ theme }) => theme.fontSize.xs}rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.nocfoRed};
    border: 2px solid ${({ theme }) => theme.colors.nocfoRed};
    position: absolute;
    left: 0;
    top: 0;
    animation: pulse 3s linear infinite;
  }

  &.completed::after {
    animation: none;
    background: ${({ theme }) => theme.colors.neutralWhite};
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.25;
    }
    100% {
      opacity: 1;
    }
  }
`

export const StyledStatusTrackerFooter = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  align-items: center;
  border-radius: 0 0 1rem 1rem;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  color: ${({ theme }) => theme.colors.metalGray};

  a {
    color: ${({ theme }) => theme.colors.metalGray};
  }

  .label {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xxs}rem;

    svg {
      margin-top: -2px;
    }
  }
`
