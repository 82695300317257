import { AnimatedContentLoader } from '@components'
import { useBusinessContext } from '@context'
import { useDateBadgedList, useQueryParam, useVirtualList } from '@hooks'
import { collectFromPages, fetchVatPeriods, IVatPeriod } from '@query'
import { motion } from 'framer-motion'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useInfiniteQuery } from 'react-query'
import styled from 'styled-components'
import { VatPeriodListItem } from './VatPeriodListItem'

const ITEM_HEIGHT = 92

export const VatPeriodList: React.FC = () => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const [selectedId] = useQueryParam<number>('id', value => parseInt(value, 10))

  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery(
    [businessId, 'vat_periods'],
    ({ pageParam = 1 }) => fetchVatPeriods({ businessId }, { page: pageParam }),
    {
      getNextPageParam: lastPage => lastPage.next,
      getPreviousPageParam: lastPage => lastPage.prev
    }
  )

  const badgedList = useDateBadgedList<IVatPeriod>({
    items: collectFromPages(data),
    itemDateKey: 'start_date',
    itemDateFormat: 'YYYY-MM-DD',
    dateFormat: 'YYYY'
  })

  const { scrollRef, renderItems } = useVirtualList({
    items: badgedList,
    itemSize: ITEM_HEIGHT,
    onScrollBottom: () => hasNextPage && fetchNextPage()
  })

  return (
    <VatPeriodListWrapper ref={scrollRef}>
      <AnimatedContentLoader
        isLoading={isLoading}
        isEmpty={badgedList.length === 0}
        isEmptyDescription={t('vatPage.noVatPeriods')}
      >
        {renderItems((item, style) => {
          if (item.type === 'item')
            return (
              <motion.div key={`vat-period-${item.item.id}`} style={style}>
                <VatPeriodListItem
                  vatPeriod={item.item}
                  isSelected={selectedId === item.item.id}
                  isFirstOfType={item.extra.isFirstOfType}
                  isLastOfType={item.extra.isLastOfType}
                  height={ITEM_HEIGHT}
                />
              </motion.div>
            )

          return (
            <DateBadgeWrapper key={item.item.id} style={style}>
              <DateBadge>
                {t('general.year')} {item.item.label}
              </DateBadge>
            </DateBadgeWrapper>
          )
        })}
      </AnimatedContentLoader>
    </VatPeriodListWrapper>
  )
}

const VatPeriodListWrapper = styled.div`
  overflow: auto;
  height: 100%;
`

const DateBadgeWrapper = styled(motion.div).attrs({ layout: 'position' })`
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;

  & > * {
    align-self: center;
  }
`

const DateBadge = styled.div`
  text-transform: uppercase;
  padding: 0.2rem 0.4rem;
  background: ${({ theme }) => theme.colors.neutralBlack};
  color: ${({ theme }) => theme.colors.metalGray};
  border-radius: 1rem;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  font-weight: 500;
  background: ${({ theme }) => theme.colors.neutralGray};
`
