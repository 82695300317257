import styled from 'styled-components'

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1px;

  & > button:not(:first-of-type) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    padding-left: ${({ theme }) => theme.spacing.sm}rem;
  }

  & > button:not(:last-of-type) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding-right: ${({ theme }) => theme.spacing.sm}rem;
  }
`
