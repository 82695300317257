import {
  getDocumentsPageUrl,
  getFilesPageUrl,
  getInvoicingPageUrl,
  getSettingsPageUrl,
  getVATPageUrl,
  NotificationType
} from '@constants'
import { useBusinessContext } from '@context'
import {
  fetchDocument,
  fetchFile,
  fetchPurchaseInvoice,
  INotification2,
  updateNotification
} from '@query'
import dayjs from 'dayjs'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCircle } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { NotificationIcon } from './NotificationIcon'
import { useNotificationColor } from './useNotificationColor'

interface NotificationProps {
  notification: INotification2
}

interface NotificationContent {
  title: string
  description: string
}

const useRelatedModelData = (notification: INotification2) => {
  const { data: file } = useQuery(
    [notification?.business.business_id, 'file', notification.related_object_id],
    () =>
      fetchFile({
        businessId: notification?.business.business_id,
        fileId: notification.related_object_id
      }),
    {
      enabled: notification.type === NotificationType.FILE_UPLOADED_VIA_EMAIL
    }
  )

  const { data: purchaseInvoice } = useQuery(
    [notification?.business.business_id, 'purchase_invoice', notification.related_object_id],
    () =>
      fetchPurchaseInvoice({
        businessId: notification?.business.business_id,
        purchaseInvoiceId: notification.related_object_id
      }),
    {
      enabled:
        notification.type === NotificationType.INVOICE_RECEIVED ||
        notification.type === NotificationType.PURCHASE_INVOICE_EMAIL_IMPORT_SUCCESS
    }
  )

  const { data: document } = useQuery(
    [notification?.business.business_id, 'document', notification.related_object_id],
    () =>
      fetchDocument({
        businessId: notification?.business.business_id,
        documentId: notification.related_object_id
      }),
    { enabled: notification.type === NotificationType.USER_MENTIONED_IN_COMMENT }
  )

  return { file, purchaseInvoice, document }
}

const useNotificationContent = (notification: INotification2, t: any): NotificationContent => {
  const related = useRelatedModelData(notification)

  return useMemo(() => {
    if (notification.type === NotificationType.CUSTOM)
      return {
        title: notification.custom_message_title,
        description: notification.custom_message_content
      }

    if (notification.type === NotificationType.FILE_UPLOADED_VIA_EMAIL) {
      return {
        title: t(`notification.${notification.type}.title`),
        description: t(`notification.${notification.type}.description`, {
          fileName: related.file?.name
        })
      }
    }

    if (notification.type === NotificationType.INVOICE_RECEIVED) {
      return {
        title: t(`notification.${notification.type}.title`),
        description: t(`notification.${notification.type}.description`, {
          senderName: related.purchaseInvoice?.sender_name
        })
      }
    }

    if (notification.type === NotificationType.USER_MENTIONED_IN_COMMENT) {
      return {
        title: t('notification.USER_MENTIONED_IN_COMMENT.title'),
        description: t('notification.USER_MENTIONED_IN_COMMENT.description', {
          documentNumber: related.document?.number
        })
      }
    }

    if (notification.type === NotificationType.PURCHASE_INVOICE_EMAIL_IMPORT_SUCCESS) {
      return {
        title: t('notification.PURCHASE_INVOICE_EMAIL_IMPORT_SUCCESS.title'),
        description: t('notification.PURCHASE_INVOICE_EMAIL_IMPORT_SUCCESS.description', {
          sender: related.purchaseInvoice?.sender_name
        })
      }
    }

    return {
      title: t(`notification.${notification.type}.title`),
      description: t(`notification.${notification.type}.description`)
    }
  }, [related.file?.id, related.purchaseInvoice?.id, related.document?.id])
}

const useNotificationLink = (notification: INotification2): string => {
  const related = useRelatedModelData(notification)

  return useMemo(() => {
    if (notification.type === NotificationType.CUSTOM) {
      return notification.custom_message_link
    }

    if (notification.type === NotificationType.VAT_PERIOD_REPORTING_DUE_1) {
      return getVATPageUrl(notification?.business.business_id, {
        id: notification.related_object_id
      })
    }

    if (notification.type === NotificationType.VAT_PERIOD_REPORTING_DUE_2) {
      return getVATPageUrl(notification?.business.business_id, {
        id: notification.related_object_id
      })
    }

    if (notification.type === NotificationType.FILE_UPLOADED_VIA_EMAIL) {
      return getFilesPageUrl(notification?.business.business_id, {
        idType: 'file',
        id: related.file?.id,
        folder: related.file?.folder_id
      })
    }

    if (notification.type === NotificationType.BANK_INTEGRATION_SUSPENDED) {
      return getSettingsPageUrl(notification?.business.business_id, {
        page: 'integrations'
      })
    }

    if (notification.type === NotificationType.BANK_INTEGRATION_GOING_TO_EXPIRE) {
      return getSettingsPageUrl(notification?.business.business_id, {
        page: 'integrations'
      })
    }

    if (notification.type === NotificationType.INVOICE_RECEIVED) {
      return getInvoicingPageUrl(notification?.business.business_id, {
        type: 'purchase',
        id: notification.related_object_id
      })
    }

    if (notification.type === NotificationType.USER_MENTIONED_IN_COMMENT) {
      return getDocumentsPageUrl(notification?.business.business_id, {
        id: notification.related_object_id,
        tab: 'audit_trail'
      })
    }

    if (notification.type === NotificationType.PURCHASE_INVOICE_EMAIL_IMPORT_SUCCESS) {
      return getInvoicingPageUrl(notification?.business.business_id, {
        type: 'purchase',
        id: notification.related_object_id
      })
    }
  }, [related.file?.id])
}

const isAbsoluteUrl = (path: string): boolean => {
  const pat = /^https?:\/\//i
  return pat.test(path)
}

export const Notification: React.FC<NotificationProps> = ({ notification }) => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const [t] = useTranslation()
  const { title, description } = useNotificationContent(notification, t)
  const to = useNotificationLink(notification)
  const color = useNotificationColor(notification)
  const { businessId } = useBusinessContext()
  const showBusinessesBadge = !businessId

  const { mutate: markAsSeen } = useMutation(
    () => updateNotification({ notificationId: notification.id }, { seen: true }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['notifications'])
      }
    }
  )

  const handleOnClick = useCallback(
    e => {
      if (!notification.seen) markAsSeen()

      // CASE: Link is not external
      // > Push the new path to the react router dom history
      if (!isAbsoluteUrl(to)) {
        history.push(to)

        // Prevent original link opening
        e.preventDefault()
        e.stopPropagation()
      }
    },
    [notification.seen, to, history]
  )

  return (
    <StyledNotification href={to || '#'} target="_blank" onClick={handleOnClick}>
      {showBusinessesBadge && (
        <StyledBusinessInfo>{notification?.business.name}</StyledBusinessInfo>
      )}
      <StyledNotificationTitle>
        {!notification.seen && <SeenIcon color={color} />} <span>{title}</span>
        <StyledTimestamp>{dayjs(notification.timestamp).fromNow()}</StyledTimestamp>
      </StyledNotificationTitle>
      <StyledNotificationContent>
        <StyledNotificationDescription>{description}</StyledNotificationDescription>
        <StyledNotificationIcon>
          <NotificationIcon notification={notification} />
        </StyledNotificationIcon>
      </StyledNotificationContent>
    </StyledNotification>
  )
}

const StyledNotification = styled.a`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.md}rem;
  gap: ${({ theme }) => theme.spacing.xxs}rem;
  transition: 0.2s;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralGray};
  text-decoration: none !important;

  &:hover {
    background: ${({ theme }) => theme.colors.defaultHover};
  }
`

const StyledNotificationIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledNotificationContent = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  gap: ${({ theme }) => theme.spacing.md}rem;
`

const StyledNotificationTitle = styled.h1`
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  margin: 0;
  color: ${({ theme }) => theme.colors.neutralBlack};

  & > span {
    flex: 1;
    white-space: pre-wrap;
  }
`

const StyledNotificationDescription = styled.p`
  flex: 1;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  margin: 0;
  color: ${({ theme }) => theme.colors.metalGray};
`

const SeenIcon = styled(FaCircle)`
  font-size: ${({ theme }) => theme.fontSize.xxs}rem;
`

const StyledTimestamp = styled.div`
  font-weight: initial;
  font-size: 0.7rem;
  color: ${({ theme }) => theme.colors.metalGray};
`

const StyledBusinessInfo = styled.span`
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  background: ${({ theme }) => theme.colors.neutralGray};
  color: ${({ theme }) => theme.colors.metalGray};
  font-weight: 500;
  border-radius: 100px;
  padding: 0.2rem 0.5rem;
  margin-bottom: 0.2rem;
  width: fit-content;
`
