import { Button, TextTooltip } from '@root/components'
import { useTranslation } from 'react-i18next'
import { FaListAlt, FaThList } from 'react-icons/fa'
import styled from 'styled-components'

interface Props {
  advancedMode: boolean
  setAdvancedMode: (value: boolean) => void
}

const InvoiceFormRowToggle: React.FC<Props> = ({ advancedMode, setAdvancedMode }) => {
  const closeDelay = 300
  const [t] = useTranslation()
  const toolTip = t('invoicing.form.advancedIvoiceRowToolTip')
  const text = advancedMode ? t('invoicing.form.basicRows') : t('invoicing.form.advancedRows')
  const Icon = advancedMode ? FaListAlt : FaThList

  return (
    <TextTooltip closeDelay={closeDelay} tooltip={toolTip}>
      <InvoiceRowToggleContainer
        onClick={e => {
          e.preventDefault() // Prevents the form from submitting
          setAdvancedMode(!advancedMode)
        }}
        data-test="invoice-row-toggle"
      >
        <Button iconRight={<Icon />} isSecondary>
          {text}
        </Button>
      </InvoiceRowToggleContainer>
    </TextTooltip>
  )
}

export default InvoiceFormRowToggle

const InvoiceRowToggleContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.sm}rem 0;
`
