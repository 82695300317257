import { Button, Input } from '@root/components'
import { useBusinessContext } from '@root/context'
import { IPurchaseInvoice, updatePurchaseInvoice } from '@root/query'
import { setAPIErrors } from '@root/utils'
import React, { useEffect } from 'react'
import { get, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import styled from 'styled-components'

interface IProps {
  purchaseInvoice: IPurchaseInvoice
  onClose: () => void
}

const PurchaseInvoiceForm: React.FC<IProps> = ({
  purchaseInvoice: oldPurchaseInvoice,
  onClose: onCloseCallBack
}) => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const queryClient = useQueryClient()

  const getDefaultValuesFromPurchaseInvoice = (purchaseInvoice: IPurchaseInvoice) => {
    return {
      invoicing_date: purchaseInvoice?.invoicing_date,
      due_date: purchaseInvoice?.due_date,
      sender_bank_account: purchaseInvoice?.sender_bank_account,
      sender_name: purchaseInvoice?.sender_name,
      amount: purchaseInvoice?.amount,
      reference: purchaseInvoice?.reference
    }
  }

  const {
    register,
    handleSubmit,
    setError,
    trigger,
    setFocus,
    watch,
    formState: { errors, defaultValues }
  } = useForm<Partial<IPurchaseInvoice>>({
    defaultValues: getDefaultValuesFromPurchaseInvoice(oldPurchaseInvoice)
  })

  const updateMutation = useMutation<IPurchaseInvoice, unknown, Partial<IPurchaseInvoice>>(
    data => updatePurchaseInvoice({ businessId, purchaseInvoiceId: oldPurchaseInvoice.id }, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([businessId, 'combined_invoices'])
        await queryClient.invalidateQueries([
          businessId,
          'purchase_invoices',
          oldPurchaseInvoice.id
        ])
        toast.success(t('general.success'))
        onCloseCallBack()
      },
      onError: ({ status, data }) => {
        if (status === 400) {
          setAPIErrors(data, setError)
        }
        toast.error(t('general.error'))
      }
    }
  )

  const onSubmit = async data => {
    updateMutation.mutate(data)
  }

  useEffect(() => {
    trigger()
  }, [defaultValues])

  // Recommended way of setting focus on the first error field:
  // https://stackoverflow.com/questions/69719627/how-to-focus-when-an-error-occurs-in-react-hook-form-controller
  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return errors[field] ? field : a
    }, null)

    if (firstError) {
      setFocus(firstError as keyof IPurchaseInvoice)
    }

    if (Object.keys(errors).length === 0 && !watch('reference')) {
      setFocus('reference')
    }
  }, [errors, setFocus])

  return (
    <StyledContainer>
      <StyledForm id="purchase-invoice-edit-form" onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="date"
          label={t('invoicing.purchaseInvoiceForm.invoicingDate.value')}
          info={t('invoicing.purchaseInvoiceForm.invoicingDate.info')}
          {...register('invoicing_date', {
            required: { value: true, message: t('validation.required') }
          })}
          errors={get(errors, 'invoicing_date')}
          required={true}
        />

        <Input
          type="date"
          label={t('invoicing.purchaseInvoiceForm.dueDate.value')}
          info={t('invoicing.purchaseInvoiceForm.dueDate.info')}
          {...register('due_date', {
            required: { value: true, message: t('validation.required') }
          })}
          errors={get(errors, 'due_date')}
          required={true}
        />

        <SectionDivider />

        <Input
          type="text"
          label={t('invoicing.purchaseInvoiceForm.senderBankAccount.value')}
          info={t('invoicing.purchaseInvoiceForm.senderBankAccount.info')}
          {...register('sender_bank_account', {
            required: { value: true, message: t('validation.required') }
          })}
          errors={get(errors, 'sender_bank_account')}
          required={true}
        />

        <Input
          type="text"
          label={t('invoicing.purchaseInvoiceForm.receiverName.value')}
          info={t('invoicing.purchaseInvoiceForm.receiverName.info')}
          {...register('sender_name', {
            required: { value: true, message: t('validation.required') }
          })}
          errors={get(errors, 'sender_name')}
          required={true}
        />
        <Input
          type="number"
          label={t('invoicing.purchaseInvoiceForm.amount.value')}
          info={t('invoicing.purchaseInvoiceForm.amount.info')}
          {...register('amount', {
            valueAsNumber: true,
            required: { value: true, message: t('validation.required') }
          })}
          errors={get(errors, 'amount')}
          step={0.01}
          required={true}
        />

        <Input
          type="text"
          label={t('invoicing.purchaseInvoiceForm.reference.value')}
          info={t('invoicing.purchaseInvoiceForm.reference.info')}
          {...register('reference')}
          errors={get(errors, 'reference')}
        />
      </StyledForm>

      <FooterButtonContainer>
        <Button onClick={() => onCloseCallBack()}>{t('general.cancel')}</Button>
        <Button
          type="submit"
          intent="success"
          form="purchase-invoice-edit-form"
          showSpinner={updateMutation.isLoading}
        >
          {t('general.save')}
        </Button>
      </FooterButtonContainer>
    </StyledContainer>
  )
}

export default PurchaseInvoiceForm

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const StyledForm = styled.form`
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 1rem;
  flex: 1;
`

const SectionDivider = styled.div`
  margin-top: ${({ theme }) => theme.spacing.lg}rem;
  width: 100%;
`

const FooterButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.spacing.lg}rem;
  gap: ${({ theme }) => theme.spacing.xs}rem;
`
