import { useBusinessContext } from '@root/context'
import { fetchAlerts } from '@root/query'
import React from 'react'
import { useQuery } from 'react-query'
import AlertCard from './AlertCard'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const Alerts: React.FC = () => {
  const { businessId } = useBusinessContext()

  const { data: businessAlerts } = useQuery([businessId, 'dashboard_alerts'], () =>
    fetchAlerts({ businessId })
  )

  return (
    <>
      {businessAlerts && businessAlerts?.length !== 0 && (
        <ScrollableContent>
          {businessAlerts.map((data, index) => (
            <AlertCard key={index} alert={data}></AlertCard>
          ))}
        </ScrollableContent>
      )}
    </>
  )
}

export default Alerts

export const ScrollableContent = styled(motion.div).attrs({
  variants: {
    hide: {
      opacity: 0,
      y: -50,
      height: 0,
      transition: {
        duration: 0.3,
        staggerChildren: 0.06,
        staggerDirection: -1,
        type: 'tween'
      }
    },
    show: {
      opacity: 1,
      y: 0,
      height: 'auto',
      transition: {
        duration: 0.3,
        staggerChildren: 0.06,
        staggerDirection: 1,
        type: 'tween'
      }
    }
  },
  initial: 'hide',
  animate: 'show',
  exit: 'hide'
})`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.md}rem;

  // hide scrollbar
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`
