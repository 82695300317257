import React from 'react'
import { useBusinessContext } from '@context'
import { useQuery } from 'react-query'
import { AnimatedContentLoader } from '@components'
import { fetchBusiness } from '@query'
import { SalariesOnboard } from '@pages/SalaryPage/SalariesOnboard.tsx'
import { SalaryIFrame } from '@pages/SalaryPage/SalaryIFrame.tsx'

const SalaryPage: React.FC = () => {
  const { businessId } = useBusinessContext()
  const { data: business, isLoading } = useQuery([businessId], () => fetchBusiness({ businessId }))

  return (
    <AnimatedContentLoader isLoading={isLoading}>
      {business?.salaxy_enabled ? <SalaryIFrame business={business} /> : <SalariesOnboard />}
    </AnimatedContentLoader>
  )
}

export default SalaryPage
