import { MonetaryAmount } from '@components'
import { useTheme } from '@hooks'
import { mapValue } from '@utils'
import React, { useCallback } from 'react'
import styled from 'styled-components'

interface Props {
  labels: [string, string]
  values: [number, number]
}

export const RatioChart: React.FC<Props> = ({ labels, values }) => {
  const theme = useTheme()

  const getColor = useCallback(
    (value: number): string => {
      if (value < 50) return theme.colors.orange
      return theme.colors.blue
    },
    [theme]
  )

  const value1 = Math.max(values[0], 0)
  const value2 = Math.max(values[1], 0)

  const mapped1 = mapValue(value1, 0, Math.max(value1, value2), 0, 1)
  const mapped2 = mapValue(value2, 0, Math.max(value1, value2), 0, 1)

  const v1 = mapValue(mapped1, 0, mapped1 + mapped2, 0, 100) || 0
  const v2 = mapValue(mapped2, 0, mapped1 + mapped2, 0, 100) || 0

  return (
    <StyledRatioChart>
      <Bars>
        <Bar>
          <BarProgress className="left" progress={v2} color={getColor(0)} />
          <Label className="left">
            <h1>{labels[1]}</h1>
            <div>{<MonetaryAmount value={values[1]} />}</div>
          </Label>
        </Bar>
        <Bar>
          <BarProgress className="right" progress={v1} color={getColor(100)} />
          <Label className="right">
            <h1>{labels[0]}</h1>
            <div>{<MonetaryAmount value={values[0]} />}</div>
          </Label>
        </Bar>
      </Bars>
    </StyledRatioChart>
  )
}

const StyledRatioChart = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  margin: 2rem auto;
`

const Bars = styled.div`
  flex: 1;
  display: flex;
  margin: 0;
  gap: 0.2rem;
  height: 1.2rem;
  width: 100%;
`

const Bar = styled.div`
  flex: 1;
  height: 100%;
  position: relative;

  background: ${({ theme }) => theme.colors.neutralGray};
  border-radius: 0.3rem;
  color: ${({ theme }) => theme.colors.metalGray};
`

const BarProgress = styled.div<{ progress: number; color: string }>`
  position: absolute;
  width: ${({ progress }) => progress}%;
  height: 100%;
  background: ${({ color }) => color};
  border-radius: 0.3rem;
  transition: 0.4s;

  &.right {
    left: 0;
  }

  &.left {
    right: 0;
  }
`

const Label = styled.div`
  position: absolute;
  top: 0;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;

  h1 {
    margin: 0;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.sm}rem;
  }

  &.right {
    right: 0;
    transform: translate(0%, -150%);
    text-align: right;
  }

  &.left {
    left: 0;
    transform: translate(0%, -150%);
    text-align: left;
  }
`
