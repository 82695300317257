import { Prompt } from '@components'
import { useBusinessContext } from '@context'
import { fetchRequisition, IBankIntegration } from '@query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaSyncAlt } from 'react-icons/fa'
import { useQuery } from 'react-query'

interface Props {
  integration: IBankIntegration
  isVisible: boolean
  onClose: () => void
}

export const RenewRequisitionPrompt: React.FC<Props> = ({ integration, isVisible, onClose }) => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()

  const { data, isFetching, isFetched } = useQuery(
    [businessId, 'requisition', integration.name, integration.type, integration.country],
    () =>
      fetchRequisition({
        businessId,
        aspspName: integration.name,
        aspspCountry: integration.country,
        psuType: integration.type
      }),
    { enabled: isVisible }
  )

  return (
    <Prompt
      isVisible={isVisible}
      onClose={onClose}
      title={t('settings.integrations.bank.integrationRenewTitle')}
      description={t('settings.integrations.bank.integrationRenewDescription')}
      buttons={[
        {
          text: t('general.cancel'),
          action: onClose
        },
        {
          icon: <FaSyncAlt />,
          text: t('settings.integrations.bank.integrationRenewButton'),
          link: isFetching ? undefined : data?.url,
          intent: 'primary',
          showSpinner: isFetching,
          disabled: !isFetched || isFetching
        }
      ]}
    />
  )
}
