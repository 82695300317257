import microsoftSvg from '@assets/login/microsoft.svg'
import nocfoIconSrc from '@assets/nocfo-icon-color.svg'
import { PublicClientApplication } from '@azure/msal-browser'
import { Alert, AnimatedContentLoader, Button } from '@components'
import { useQueryParam } from '@hooks'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google'
import { network } from '@utils'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import AutoSizer from 'react-virtualized-auto-sizer'
import styled from 'styled-components'
const oauthConfig = {
  google: {
    clientId: '60703500575-jhk6v1n2ifotr15mimfmv9kmhsah4q58.apps.googleusercontent.com'
  },
  microsoft: {
    clientId: 'fbe408c8-4ccc-4ae6-a4ef-0ac7d828217c'
  }
}

interface Props {
  isRegister?: boolean
}

interface OnSuccessProps {
  accessToken: string
  tokenId: string
  connectUrl: string
}

export const Login: React.FC<Props> = ({ isRegister }) => {
  const [t] = useTranslation()
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [next] = useQueryParam('next')

  const onSuccessMutation = useMutation<unknown, unknown, OnSuccessProps>(
    ({ accessToken, tokenId, connectUrl }) =>
      network.httpClient
        .post(
          connectUrl,
          {
            access_token: accessToken,
            code: tokenId
          },
          { headers: {} }
        )
        .then(({ data }) => {
          network.setAccessToken(data.key)
          location.replace(next ? next : '/')
        })
        .catch(err => {
          setErrorMsg(t('login.generalErrorMessage'))
          throw err
        })
  )

  const googleHandler = async (response: any) => {
    onSuccessMutation.mutate({
      accessToken: response.credential,
      tokenId: response.tokenId,
      connectUrl: '/auth/dj-rest-auth/google/connect'
    })
  }

  const microsoftHandler = (response: any) => {
    if (!response) return

    onSuccessMutation.mutate({
      accessToken: response.accessToken,
      tokenId: response.idToken,
      connectUrl: '/auth/dj-rest-auth/microsoft/connect'
    })
  }

  const msalClientApp = new PublicClientApplication({
    auth: {
      clientId: oauthConfig.microsoft.clientId,
      redirectUri: window.location.origin
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false
    }
  })

  useEffect(() => {
    msalClientApp
      .handleRedirectPromise()
      .then(response => {
        microsoftHandler(response)
      })
      .catch(err => {
        console.log(err)
        if (err.errorCode !== 'user_cancelled') {
          setErrorMsg(t('login.generalErrorMessage'))
        }
      })
  }, [])

  return (
    <AnimatedContentLoader isLoading={onSuccessMutation.isLoading || onSuccessMutation.isSuccess}>
      <Alert
        type="error"
        isVisible={Boolean(errorMsg)}
        title={isRegister ? t('login.registerErrorTitle') : t('login.loginErrorTitle')}
        description={errorMsg}
      />
      <LoginWrapper>
        <AutoSizer>
          {({ width, height }) => (
            <ButtonWrapper width={`${width}px`} height={`${height}px`}>
              <>
                <GoogleOAuthProvider clientId={oauthConfig.google.clientId}>
                  <GoogleLoginButton
                    width={`${width}px`}
                    isRegister={isRegister}
                    onSuccess={googleHandler}
                    onError={() => setErrorMsg(t('login.generalErrorMessage'))}
                  />
                </GoogleOAuthProvider>

                <LoginButton
                  src={microsoftSvg}
                  width={`${width}px`}
                  onClick={() =>
                    msalClientApp.loginRedirect({
                      scopes: ['User.Read'],
                      prompt: 'select_account'
                    })
                  }
                >
                  {isRegister ? t('login.registerWithMicrosoft') : t('login.loginWithMicrosoft')}
                </LoginButton>

                <Link
                  to={{
                    pathname: isRegister ? '/nocfo-signup' : 'nocfo-signin',
                    search: window.location.search
                  }}
                  css={`
                    width: ${width}px;
                  `}
                >
                  <LoginButton src={nocfoIconSrc} width={`${width}px`}>
                    {isRegister ? t('login.registerWithNocfo') : t('login.loginWithNocfo')}
                  </LoginButton>
                </Link>
              </>
            </ButtonWrapper>
          )}
        </AutoSizer>
      </LoginWrapper>
    </AnimatedContentLoader>
  )
}

interface LoginButtonProps extends React.HTMLProps<HTMLButtonElement> {
  src: string
  width: string
}

const GoogleLoginButton: React.FC<{
  isRegister: boolean
  onSuccess: any
  onError: any
  width: string
}> = ({ isRegister, onError, onSuccess, width }) => {
  const {
    i18n: { language: currentLanguage }
  } = useTranslation()

  return (
    <GoogleLogin
      onSuccess={onSuccess}
      onError={onError}
      text={isRegister ? 'signup_with' : 'signin_with'}
      useOneTap={true}
      size="large"
      context={isRegister ? 'signup' : 'signin'}
      locale={currentLanguage}
      width={width}
    />
  )
}

const LoginButton: React.FC<LoginButtonProps> = ({ src, width, children, onClick, disabled }) => {
  return (
    <StyledLoginButton
      width={width}
      icon={<LoginButtonIcon src={src} />}
      onClick={onClick}
      disabled={disabled}
      className="is-secondary"
    >
      {children}
    </StyledLoginButton>
  )
}

const StyledLoginButton = styled(Button)<{ width: string }>`
  width: ${({ width }) => width};
  display: flex;
  background: white;
  padding: 8px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  text-align: left;
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  font-weight: 500;
  color: #3c4043;
`

const LoginButtonIcon = styled.img`
  margin-right: 0.2rem;
  width: 1.2rem;
  height: 1.2rem;
`

const LoginWrapper = styled.div`
  min-height: 120px;
  max-width: 300px;
`

const ButtonWrapper = styled.div<{ width: string; height: string }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  gap: ${({ theme }) => theme.spacing.xs}rem;
`
