import { AnimatedContentLoader, DoubleColumnLayout, DoubleColumnLayoutColumn } from '@components'
import { useBusinessContext } from '@context'
import { useQueryParam } from '@hooks'
import { collectFromPages, fetchContacts, IContact } from '@query'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInfiniteQuery } from 'react-query'
import styled from 'styled-components'
import { ContactDetails } from './ContactDetails'
import { ContactsActionBar } from './ContactsActionBar'
import { ContactsList } from './ContactsList'

export const ContactsPage: React.FC = () => {
  const [t] = useTranslation()
  const [search, setSearch] = useState('')
  const { businessId } = useBusinessContext()
  const [contactId, setContactId] = useQueryParam('id', value => parseInt(value, 10))
  const { data, hasNextPage, fetchNextPage, isLoading } = useInfiniteQuery(
    [businessId, 'contacts', { search }],
    ({ pageParam = 1 }) => fetchContacts({ businessId }, { search, page: pageParam }),
    {
      getNextPageParam: lastPage => lastPage.next,
      getPreviousPageParam: lastPage => lastPage.prev
    }
  )

  const contacts = collectFromPages<IContact>(data)
  const onClose = () => setContactId(undefined)

  return (
    <DoubleColumnLayout
      header={t('contacts.mainTitle')}
      isRightVisible={Boolean(contactId)}
      onRightClose={onClose}
    >
      <DoubleColumnLayoutColumn>
        <PageWrapper>
          <ContactsActionBar onSearch={term => setSearch(term)} />
          <ContactListWrapper>
            <AnimatedContentLoader
              isLoading={isLoading}
              isEmpty={contacts.length === 0}
              isEmptyDescription={t('contacts.emptyDescrition')}
            >
              <ContactsList
                contacts={contacts}
                onScrollBottom={() => hasNextPage && fetchNextPage()}
              />
            </AnimatedContentLoader>
          </ContactListWrapper>
        </PageWrapper>
      </DoubleColumnLayoutColumn>

      <DoubleColumnLayoutColumn isRight={true} innerKey={`contact-${contactId}`}>
        {contactId && <ContactDetails contactId={contactId} />}
      </DoubleColumnLayoutColumn>
    </DoubleColumnLayout>
  )
}

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ContactListWrapper = styled.div`
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`
