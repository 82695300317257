import { network } from '@utils'
import { handleReturn, MutationFunction, PaginatedResponse, QueryFunction } from '../root.ts'
import { EnablebankingCashAccountType } from '@root/constants/index.ts'

export enum EnableBankingPSUType {
  PERSONAL = 'personal',
  BUSINESS = 'business'
}

interface InstitutionProps {
  businessId: string
  country: string
  psuType: EnableBankingPSUType
}

export interface IInstitution {
  name: string
  type: string
  bic: string
  logo: string
  country: string
}

export const fetchInstitutions: QueryFunction<InstitutionProps, IInstitution[]> = async ({
  businessId,
  country,
  psuType
}) => {
  const url = `/v1/integrations/banking/${businessId}/aspsps/${country}/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params: { psu_type: psuType }
  })
  return data
}

interface IRequisitionProps {
  businessId: string
  aspspCountry: string
  aspspName: string
  psuType: EnableBankingPSUType
}

export interface IRequisition {
  id: string
  url: string
}

export const fetchRequisition: QueryFunction<IRequisitionProps, IRequisition> = async ({
  businessId,
  aspspCountry,
  aspspName,
  psuType
}) => {
  const url = `/v1/integrations/banking/${businessId}/session/${aspspCountry}/${aspspName}/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params: { psu_type: psuType }
  })
  return data
}

interface IIntegrationListProps {
  businessId: string
}

export interface IBankAccount {
  id: number
  uid: string
  identifier: string
  account_id: number
  last_sync_at: string
  begin_sync_from: string
  enabled: boolean
  cash_account_type?: EnablebankingCashAccountType
  name?: string
  details?: string
}

export interface IBankIntegration {
  id: number
  name: string
  country: string
  logo: string
  bic: string
  accounts: IBankAccount[]
  access_granted_at: string
  access_granted_until: string
  max_historical_days: number
  type: EnableBankingPSUType
  enabled: boolean
  is_staled: boolean
}

export const fetchBankIntegrations: QueryFunction<
  IIntegrationListProps,
  PaginatedResponse<IBankIntegration>
> = async ({ businessId }) => {
  const url = `/v1/integrations/banking/${businessId}/integrations/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET'
  })
  return data
}

interface IntegrationInstanceProps extends IIntegrationListProps {
  integrationId: number
}

export const fetchBankIntegration: QueryFunction<
  IntegrationInstanceProps,
  IBankIntegration
> = async ({ businessId, integrationId }) => {
  const url = `/v1/integrations/banking/${businessId}/integrations/${integrationId}/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET'
  })
  return data
}

interface RequisitionStatusProps {
  businessId: string
  reference: string
}

export interface IRequisitionStatus {
  status: 'CREATED' | 'PROCESSING' | 'DONE' | 'FAILED' | 'CANCELLED'
}

export const fetchRequisitionStatus: QueryFunction<
  RequisitionStatusProps,
  IRequisitionStatus
> = async ({ businessId, reference }) => {
  const url = `/v1/integrations/nordigen/${businessId}/requisition_status/${reference}/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET'
  })
  return data
}

export interface IBankAccountInstanceProps {
  businessId: string
  bankIntegrationId: number
  bankAccountId: number
}

export const updateBankAccount: MutationFunction<IBankAccountInstanceProps, IBankAccount> = async (
  { businessId, bankIntegrationId, bankAccountId },
  requestData
) => {
  const url = `/v1/integrations/banking/${businessId}/integrations/${bankIntegrationId}/accounts/${bankAccountId}/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'PATCH',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

export const deleteBankAccount: MutationFunction<IBankAccountInstanceProps, void> = async ({
  businessId,
  bankAccountId
}) => {
  const url = `/v1/integrations/nordigen/${businessId}/bank_account/${bankAccountId}/`
  await network.httpClient.request({
    url,
    method: 'DELETE'
  })
}

export interface IIntegrationProps {
  businessId: string
  integrationId: number
}

export const updateBankIntegration: MutationFunction<IIntegrationProps, IBankIntegration> = async (
  { businessId, integrationId },
  requestData
) => {
  const url = `/v1/integrations/banking/${businessId}/integrations/${integrationId}/`
  const { data } = await network.httpClient.request({
    url,
    method: 'PATCH',
    data: requestData
  })
  return data
}

export const deleteBankIntegration: MutationFunction<IIntegrationProps, void> = async ({
  businessId,
  integrationId
}) => {
  const url = `/v1/integrations/banking/${businessId}/integrations/${integrationId}/`
  await network.httpClient.request({
    url,
    method: 'DELETE'
  })
}

export interface IBackgroundFetchProps {
  businessId: string
}

export const triggerBankIntegrationBackgroundFetch: QueryFunction<
  IBackgroundFetchProps,
  void
> = async ({ businessId }) => {
  const url = `/v1/integrations/banking/${businessId}/trigger_background_fetch/`
  await network.httpClient.request({
    url,
    method: 'GET'
  })
}

interface IIntegrationRenewProps {
  businessId: string
}

interface IIntegrationRenewInfo {
  renew_pending: boolean
  renew_pending_soon: boolean
}

export const fetchIntegrationRenewInfo: QueryFunction<
  IIntegrationRenewProps,
  IIntegrationRenewInfo
> = async ({ businessId }) => {
  const url = `/v1/integrations/banking/${businessId}/has_integrations_to_renew/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET'
  })
  return data
}
