import { downloadFile } from '@root/query'
import toast from 'react-hot-toast'

export const downloadFileToBeSaved = (
  businessId: string,
  fileId: number,
  filename: string,
  errorMsg: string
) => {
  downloadFile({ businessId, fileId })
    // Source: https://stackoverflow.com/questions/41938718/how-to-download-files-using-axios
    .then(response => {
      const link = document.createElement('a')
      const href = window.URL.createObjectURL(response)

      // Creating the link for browser and starting the download
      link.href = href
      link.download = filename
      link.click()

      // Clean up
      link.remove()
      URL.revokeObjectURL(href)
    })
    .catch(() => {
      toast.error(errorMsg)
    })
}
