import { AnimatedContentLoader, Tab, Tabs } from '@components'
import { DocumentBankDetails } from '@containers/DocumentBankDetails'
import { useBusinessContext } from '@context'
import { fetchDocument, IImportData } from '@query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { DocumentAttachmentPreview } from './DocumentAttachmentPreview'
interface Props {
  documentId?: number
  importData?: IImportData
  attachmentIds: number[]
}

export const DocumentFormExtraDetailView: React.FC<Props> = ({
  documentId,
  importData,
  attachmentIds
}) => {
  const { businessId } = useBusinessContext()
  const { t } = useTranslation()

  const { data, isLoading } = useQuery(
    [businessId, 'documents', documentId],
    () => fetchDocument({ businessId, documentId }),
    {
      enabled: !!documentId && !importData
    }
  )

  return (
    <AnimatedContentLoader isLoading={isLoading}>
      <Tabs queryKey="extra">
        <Tab key="attachments" name={t('document.formExtra.tabAttachments')}>
          <DocumentAttachmentPreview attachmentIds={attachmentIds} />
        </Tab>

        {importData ? (
          <Tab key="import-data" name={t('document.formExtra.tabImportData')}>
            <DocumentBankDetails import_data={importData || data.import_data} />
          </Tab>
        ) : (
          data &&
          data.import_data && (
            <Tab key="import-data" name={t('document.formExtra.tabImportData')}>
              <DocumentBankDetails import_data={importData || data.import_data} />
            </Tab>
          )
        )}
      </Tabs>
    </AnimatedContentLoader>
  )
}
