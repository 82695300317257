import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

export const MenuContainer = styled(motion.div).attrs({
  variants: {
    hidden: {
      opacity: 0,
      scale: 0.8,
      pointerEvents: 'none'
    },
    open: {
      opacity: 1,
      scale: 1,
      pointerEvents: 'all'
    }
  },
  transition: {
    type: 'spring',
    stiffness: 200,
    damping: 15,
    staggerChildren: 0.05,
    delayChildren: 0.2
  }
})<{ grow: 'left' | 'right' }>(
  ({ theme, grow }) => css`
    width: max-content; // not supported by IE
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 3rem;
    z-index: 20;
    background-color: ${theme.colors.neutralWhite};
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
    border-radius: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 70vh;

    ${grow}: 0;

    ${grow === 'right' &&
    `
    transform-origin: top right;
    `}

    ${grow === 'left' &&
    `
    transform-origin: top left;
    `}

    hr {
      width: 100%;
      border: none;
      border-top: 1px solid ${theme.colors.neutralGray};
      margin: 0;
    }
  `
)

export const MenuWrapper = styled.div`
  position: relative;
`

export const MenuItem = styled(motion.div).attrs({
  variants: {
    hidden: {
      opacity: 0,
      x: 10
    },
    open: {
      opacity: 1,
      x: 0
    }
  }
})`
  flex-direction: column;
  cursor: pointer;
  display: flex;
  padding: ${({ theme }) => theme.spacing.md}rem ${({ theme }) => theme.spacing.md}rem;
  color: ${({ theme }) => theme.colors.metalGray};
  min-width: 245px;

  &.disabled {
    color: ${({ theme }) => theme.colors.metalGray}77;
    cursor: not-allowed;
  }

  &:hover:not(.disabled) {
    background-color: ${({ theme }) => theme.colors.neutralGray};
  }
`

export const ItemRow = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  * {
    align-self: center;
  }

  & > .icon {
    margin-right: 1rem;
    display: flex;

    & > * {
      align-self: center;
    }
  }
`
