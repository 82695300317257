import { generateError, InputWrapper } from './Input'
import { forwardRef, useState } from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { useBusinessContext } from '@root/context'
import { fetchPeople, IUsershipPerson } from '@root/query'
import { createFullName } from '@root/utils'
import { MentionsInput, Mention } from 'react-mentions'
import { autoUpdate, useDismiss, useFloating } from '@floating-ui/react-dom-interactions'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

interface Props {
  error: any
  onBlur: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  onChangeController: (value: string) => void
  actionElement: React.ReactNode
  name: string
  value: string
}

const createPersonDisplayName = (usership: IUsershipPerson): string => {
  if (usership?.first_name && usership?.last_name) {
    return createFullName(usership)
  } else {
    return usership.email
  }
}

const InputSend = forwardRef<any, Props>(
  ({ error, onBlur, onChangeController, actionElement, name, value }, ref) => {
    const [isFocused, setFocused] = useState(false)
    const _error = generateError(error)
    const [isOpen, setIsOpen] = useState(false)
    const [t] = useTranslation()

    const { businessId } = useBusinessContext()

    const { data } = useQuery([businessId, 'people'], () =>
      fetchPeople({ businessId }, { page_size: 5000 })
    )

    const users =
      data?.results.map(usership => {
        return {
          display: createPersonDisplayName(usership),
          id: usership.user_id,
          email: usership.email
        }
      }) || []

    const { context, x, y, reference, strategy } = useFloating({
      open: isOpen,
      whileElementsMounted: autoUpdate,
      onOpenChange: value => setIsOpen(value),
      placement: 'bottom-start'
    })

    useDismiss(context)

    return (
      <>
        <InputWrapper
          label=""
          inputId={`input-send-${name}`}
          actionComponent={actionElement}
          errorMsg={_error}
          isFocused={isFocused}
        >
          <MentionsInputStyled
            id={`input-send-${name}`}
            placeholder={t('components.inputSend.placeholder')}
            name={name}
            ref={el => {
              if (typeof ref === 'function') {
                ref(el)
              } else {
                ref.current = el
              }
              reference(el)
            }}
            value={value}
            onChange={e => {
              onChangeController?.(e.target.value)
            }}
            onFocus={() => {
              setFocused(true)
            }}
            onBlur={e => {
              setFocused(false)
              onBlur?.(e)
            }}
            allowSuggestionsAboveCursor={true}
            customSuggestionsContainer={children => {
              return (
                <StyledContainer
                  style={{
                    top: y || 0,
                    left: x || 0,
                    position: strategy
                  }}
                >
                  {children}
                </StyledContainer>
              )
            }}
            allowSpaceInQuery={true}
          >
            <Mention
              trigger="@"
              data={(search: string) => {
                const searchLower = search.toLowerCase()
                return users.filter(user => {
                  return (
                    user.display.toLowerCase().includes(searchLower) ||
                    user.email.toLowerCase().includes(searchLower)
                  )
                })
              }}
              displayTransform={(_, display) => `@${display}`}
              renderSuggestion={(suggestion, _search, _highlightedDisplay, _index, focused) => {
                return (
                  <StyledSelection className={focused && 'keyboard-focused'}>
                    {suggestion.display}
                    <StyledEmail>{suggestion.email}</StyledEmail>
                  </StyledSelection>
                )
              }}
              appendSpaceOnAdd={true}
            />
          </MentionsInputStyled>
        </InputWrapper>
      </>
    )
  }
)

export default InputSend

const MentionsInputStyled = styled(MentionsInput)`
  flex: 1;
  border: none;
  white-space: pre-wrap;
  word-break: break-word;

  & > * > textarea {
    border: none;
  }

  & > * > textarea:focus {
    outline: none;
  }
`

const StyledContainer = styled(motion.div).attrs({
  initial: { y: 10, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: 0, opacity: 0 }
})`
  display: flex;
  max-height: 200px;
  max-width: 250px;
  overflow: auto;
  flex-direction: row;
  background: white;
  border-radius: 1rem;
  padding: ${({ theme }) => theme.spacing.sm}rem;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  margin-top: ${({ theme }) => theme.spacing.xs}rem;
`

const StyledSelection = styled.div`
  padding: ${({ theme }) => theme.spacing.xs}rem;
  border-radius: ${({ theme }) => theme.spacing.xs}rem;
  width: max-content;

  &.keyboard-focused,
  &:hover {
    background: ${({ theme }) => theme.colors.neutralGray};
  }
`
const StyledEmail = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  color: ${({ theme }) => theme.colors.metalGray};
`
