import { Button, ButtonProps } from '@components'
import { AnimatePresence, motion } from 'framer-motion'
import React, { HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { FaArrowLeft, FaArrowRight, FaCheck, FaExclamationCircle, FaSpinner } from 'react-icons/fa'
import styled, { css, keyframes } from 'styled-components'

export const colors = {
  valid: '#39d273',
  invalid: '#f50d05',
  active: '#1b98f5',
  grey: '#abb1a3'
}

export const Label = styled.label`
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  margin-left: 0.25rem;
  color: ${colors.grey};
`

export const Input = styled.input<{ isValid?: boolean; isInvalid?: boolean }>`
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  border: 3px solid;
  box-shadow: none;
  border-color: transparent;
  transition: border-color 300ms, box-shadow 300ms;

  &:focus {
    outline: none;
    box-shadow: 0px 0px 7px ${colors.active};
  }

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      border-color: ${colors.invalid};
      &:focus {
        outline: none;
        box-shadow: none;
      }
    `}

  ${({ isValid }) =>
    isValid &&
    css`
      border-color: ${colors.valid};

      &:focus {
        outline: none;
        box-shadow: none;
      }
    `}
`

export const StatusText = styled(motion.span)<{ color: string }>`
  margin-top: 0.25rem;
  margin-left: 0.25rem;
  color: ${({ color }) => color};
  font-size: 0.875rem;
`

export const Row = styled(motion.div)<{ selected: boolean }>`
  cursor: pointer;
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  color: white;
  border: 1px solid;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
  border-color: ${({ selected }) => (selected ? colors.active : colors.grey)};
  transition: transform 200ms, border-color 200ms;
`

export const NextButton: React.FC<ButtonProps> = ({ ...rest }) => {
  const [t] = useTranslation()

  return (
    <Button type="button" iconRight={<FaArrowRight />} intent="primary" {...rest}>
      <span>{t('general.next')}</span>
    </Button>
  )
}

export const BackButton: React.FC<ButtonProps> = ({ ...rest }) => {
  const [t] = useTranslation()

  return (
    <Button type="button" icon={<FaArrowLeft />} intent="default" {...rest}>
      {t('general.back')}
    </Button>
  )
}

export const CreateButton: React.FC<ButtonProps> = ({ ...rest }) => {
  const [t] = useTranslation()

  return (
    <Button type="submit" intent="success" {...rest}>
      {t('onboarding.periodSlide.createBusiness')}
    </Button>
  )
}

export const RadioGroup: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <AnimatePresence>
      <RadioList role="radio-group" aria-required>
        {children}
      </RadioList>
    </AnimatePresence>
  )
}

export const RadioList = styled(motion.div).attrs({
  initial: 'hide',
  animate: 'show',
  exit: 'hide',
  variants: {
    show: {
      overflow: 'auto',
      transition: {
        staggerChildren: 0.05,
        delayChildren: 0.05
      }
    },
    hide: {
      overflow: 'hidden',
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
  },
  transition: {
    staggerChildren: 0.1,
    delayChildren: 0.1
  }
})`
  display: flex;
  flex-direction: column;
  overflow: visible;

  gap: ${({ theme }) => theme.spacing.sm}rem;
  padding: ${({ theme }) => theme.spacing.xl}rem ${({ theme }) => theme.spacing.md}rem;
`

const RadioButtonText = styled.div`
  flex: 1;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  font-weight: bold;
`

const RadioButtonBody = styled.div`
  color: ${colors.grey};
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  margin: 0;
  margin-right: 2em;
  margin-top: 0.5rem;
`

type RadioValue = any

interface RadioItemProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSelect'> {
  index?: number
  selected: boolean
  label: string
  onSelect: (value: RadioValue) => void
  body?: string
  value: RadioValue
}

export const RadioItem: React.FC<RadioItemProps> = ({
  selected,
  onSelect,
  label,
  value,
  body,
  index
}) => {
  return (
    <StyledRadioItem
      custom={index}
      isSelected={selected}
      role="radio"
      aria-checked={selected}
      tabIndex={0}
      data-value={value}
      onClick={e => {
        e.preventDefault()
        onSelect(value)
      }}
    >
      <StyledRadioItemRow>
        <RadioButtonText>{label}</RadioButtonText>
        <AnimatePresence>
          <CheckMarkWrapper>
            {selected && (
              <AnimatedRadioIcon>
                <FaCheck />
              </AnimatedRadioIcon>
            )}
          </CheckMarkWrapper>
        </AnimatePresence>
      </StyledRadioItemRow>
      {body && (
        <StyledRadioItemRow>
          <RadioButtonBody>{body}</RadioButtonBody>
        </StyledRadioItemRow>
      )}
    </StyledRadioItem>
  )
}

const StyledRadioItem = styled(motion.div).attrs({
  whileHover: { scale: 1 },
  whileTap: { scale: 0.97 },
  variants: {
    show: {
      y: 0,
      opacity: 1
    },
    hide: {
      y: -20,
      opacity: 0
    }
  }
})<{ isSelected: boolean }>`
  background: ${({ theme }) => theme.colors.neutralGray};
  padding: ${({ theme }) => theme.spacing.md}rem ${({ theme }) => theme.spacing.md}rem;
  border-radius: 1rem;
  color: ${({ theme }) => theme.colors.iconGray};
  cursor: pointer;

  ${({ isSelected, theme }) =>
    isSelected &&
    `
    color: ${theme.colors.neutralBlack};
  `}
`

const StyledRadioItemRow = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  align-items: center;
`

const CheckMarkWrapper = styled.div`
  width: 1.2rem;
  height: 1.2rem;
`

const AnimatedRadioIcon = styled(motion.div).attrs({
  initial: { opacity: 0, scale: 0.3 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.3 }
})`
  svg {
    width: 1.2rem;
    height: 1.2rem;
    fill: ${({ theme }) => theme.colors.nocfoGreen};
  }
`

export const AnimationContainer = styled(motion.div)`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`

const Spin = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`

export const CheckboxLabel = styled(Label)`
  cursor: pointer;
  color: #fefefe;
  margin-bottom: 0;
  margin-right: 1rem;
`

const CheckboxInput = styled.input`
  width: 1.2rem;
  height: 1.2rem;
`

export const LoadingSpinner = styled(FaSpinner)`
  animation: ${Spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
`

export const Checkbox: React.FC<Omit<HTMLAttributes<HTMLInputElement>, 'type'>> = props => {
  return <CheckboxInput type="checkbox" {...props} />
}

export const SlideTitle = styled.h1`
  width: 100%;
  color: ${({ theme }) => theme.colors.neutralBlack};
  margin-top: ${({ theme }) => theme.spacing.sm}rem;
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;
  font-size: ${({ theme }) => theme.fontSize.xl}rem;
  font-weight: bold;
`

export const SlideText = styled.p`
  width: 100%;
  color: ${({ theme }) => theme.colors.metalGray};
  font-size: ${({ theme }) => theme.fontSize.md}rem;
`

export const ValidationError: React.FC<{ children: string }> = ({ children }) => (
  <StyledValidationError>
    <FaExclamationCircle />
    <div>{children}</div>
  </StyledValidationError>
)
export const StyledValidationError = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  padding: ${({ theme }) => theme.spacing.xs}rem;

  color: ${({ theme }) => theme.colors.nocfoRed};
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  font-weight: 500;
`

export const SlideContent = styled(motion.div).attrs({
  initial: { y: 20, opacity: 0 },
  animate: { y: 0, opacity: 1 }
})`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm}rem;
`

export const SlideFooter = styled.div`
  display: flex;
  margin-top: 1rem;
`

export const Spacer = styled.div`
  flex: 1;
`

export const SlideImg = styled.img`
  width: 100%;
  max-width: 360px;
  max-height: 25vh;
  margin: auto;
  padding: 1rem 1rem;
`
