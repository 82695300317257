import React, { useState } from 'react'
import {
  IInvoice,
  IKraviaInvoiceHistory,
  IKraviaInvoiceDetails,
  IKraviaTranslated,
  fetchKraviaInvoiceDetails
} from '@query'
import { AnimatedContentLoader, Button, ModalV2 } from '@components'
import { FaCirclePause } from 'react-icons/fa6'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useBusinessContext } from '@context'
import { formatDate } from '@utils'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { FaCashRegister } from 'react-icons/fa6'

import cn from 'classnames'
import { MisdirectPaymentForm } from './MisdirectPaymentForm.tsx'
import { StopDebtCollectionForm } from './StopDebtCollectionForm.tsx'
import { KraviaUserInvitationForm } from './KraviaUserInvitationForm.tsx'
import { KraviaDeepLinkForm } from './KraviaDeepLinkForm.tsx'
import { DebtCollectionStatusBadge } from './DebtCollectionStatusBadge.tsx'

import {
  StyledStatusTrackerFooter,
  StyledDetailMessage,
  StyledMoreButton,
  StyledProgressIndicator,
  StyledTimelineRow,
  StyledDate,
  StyledStatusTrackerHeader,
  StyledStatusTracker,
  StyledStatusTrackerContent,
  StyledTimelineItem,
  StyledTimelineItems
} from './DebtCollectionStatusMessage.styled.tsx'
import { KraviaTerminalInvoiceStatuses } from '@constants'

interface Props {
  invoice: IInvoice
}

export const DebtCollectionStatusMessage: React.FC<Props> = ({ invoice }) => {
  const {
    t,
    i18n: { language: currentLang }
  } = useTranslation()

  const { businessId } = useBusinessContext()
  const [showAll, setShowAll] = useState(false)

  // Modal states
  const [showMisdirectPaymentModal, setShowMisdirectPaymentModal] = useState(false)
  const [showStopDebtCollectionModal, setShowStopDebtCollectionModal] = useState(false)
  const [showKraviaInvoiceDetailsModal, setShowKraviaInvoiceDetailsModal] = useState(false)
  const [showKraviaInvitationModal, setShowKraviaInvitationModal] = useState(false)

  const { data, isLoading } = useQuery([businessId, 'kravia_invoice_details', invoice?.id], () =>
    fetchKraviaInvoiceDetails({ businessId, invoiceId: invoice?.id })
  )

  const getDetailMessage = (details: IKraviaTranslated) => details?.[currentLang] || details?.en
  const getStatusDetail = (data: IKraviaInvoiceDetails) =>
    data?.invoice?.status_detail[currentLang] || data?.invoice?.status_detail.en
  const debtCollectionCompleted = (data: IKraviaInvoiceDetails) =>
    KraviaTerminalInvoiceStatuses.includes(data?.invoice?.status)

  const getItemDate = (item: IKraviaInvoiceHistory) =>
    item?.completed_at || item?.approved_at || item?.created_at

  const itemsToShow = (showAll ? data?.history : [data?.history?.[0]]) || []

  return (
    <>
      <StyledStatusTracker>
        <AnimatedContentLoader isLoading={isLoading}>
          <StyledStatusTrackerContent>
            <StyledStatusTrackerHeader>
              {
                <StyledProgressIndicator
                  className={cn({ completed: debtCollectionCompleted(data) })}
                />
              }
              {debtCollectionCompleted(data)
                ? t('invoicing.debtCollection.status.completed')
                : t('invoicing.debtCollection.status.inProgress', {
                    status: getStatusDetail(data)
                  })}
            </StyledStatusTrackerHeader>

            <StyledTimelineItems>
              {itemsToShow.map((item, index) => (
                <>
                  <StyledTimelineItem key={`item-${index}`}>
                    <StyledTimelineRow>
                      <StyledDate>
                        <span>{formatDate(getItemDate(item))}</span>
                      </StyledDate>
                      <DebtCollectionStatusBadge item={item} />
                    </StyledTimelineRow>
                    <StyledDetailMessage>
                      {getDetailMessage(item?.activity_type_detail)}
                    </StyledDetailMessage>
                  </StyledTimelineItem>
                  {!showAll && index + 1 === itemsToShow.length && (
                    <StyledMoreButton
                      href="#"
                      onClick={e => {
                        e.preventDefault()
                        setShowAll(value => !value)
                      }}
                    >
                      {t('invoicing.debtCollection.status.showMore')}
                    </StyledMoreButton>
                  )}
                </>
              ))}
            </StyledTimelineItems>

            {!debtCollectionCompleted(data) && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  icon={<FaCirclePause />}
                  onClick={() => setShowStopDebtCollectionModal(true)}
                  intent="primary"
                >
                  {t('general.cancel')}
                </Button>
                <Button
                  icon={<FaCashRegister />}
                  onClick={() => setShowMisdirectPaymentModal(true)}
                >
                  {t('invoicing.debtCollection.status.misdirectPayment')}
                </Button>
              </div>
            )}

            <StyledStatusTrackerFooter>
              <FaExternalLinkAlt />
              <a
                href="#"
                onClick={e => {
                  e.preventDefault()
                  setShowKraviaInvoiceDetailsModal(true)
                }}
              >
                {t('invoicing.debtCollection.status.openInKravia')}
              </a>
            </StyledStatusTrackerFooter>
          </StyledStatusTrackerContent>
        </AnimatedContentLoader>
      </StyledStatusTracker>

      {/* MODALS */}
      <ModalV2
        header={t('invoicing.debtCollection.misdirectModal.title')}
        isVisible={showMisdirectPaymentModal}
        handleOnClose={() => setShowMisdirectPaymentModal(false)}
        height={400}
      >
        <MisdirectPaymentForm
          invoice={invoice}
          handleOnClose={() => setShowMisdirectPaymentModal(false)}
        />
      </ModalV2>

      <ModalV2
        header={t('invoicing.debtCollection.stopCollectionModal.title')}
        isVisible={showStopDebtCollectionModal}
        handleOnClose={() => setShowStopDebtCollectionModal(false)}
        height={600}
      >
        <StopDebtCollectionForm
          invoice={invoice}
          handleOnClose={() => setShowStopDebtCollectionModal(false)}
        />
      </ModalV2>

      <ModalV2
        header={t('invoicing.debtCollection.openInKraviaModal.title')}
        isVisible={showKraviaInvoiceDetailsModal}
        handleOnClose={() => setShowKraviaInvoiceDetailsModal(false)}
        height={450}
      >
        <KraviaDeepLinkForm
          linkSrc={data?.invoice_url}
          handleOnClose={() => setShowKraviaInvoiceDetailsModal(false)}
          handleOnRegister={() => {
            setShowKraviaInvoiceDetailsModal(false)
            setShowKraviaInvitationModal(true)
          }}
        />
      </ModalV2>

      <ModalV2
        header={t('invoicing.debtCollection.registerToKraviaModal.title')}
        isVisible={showKraviaInvitationModal}
        handleOnClose={() => setShowKraviaInvitationModal(false)}
        height={450}
      >
        <KraviaUserInvitationForm handleOnClose={() => setShowKraviaInvitationModal(false)} />
      </ModalV2>
    </>
  )
}
