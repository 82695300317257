import nyNocfoImg from '@assets/ny-nocfo.svg'
import { BasicPage, Button, Input } from '@components'
import { createNYBusiness, CreateNYBusinessProps, IBusiness } from '@query'
import { setAPIErrors } from '@utils'
import React from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

export const NYLandingPage: React.FC = () => {
  const [t] = useTranslation()
  const history = useHistory()
  const queryClient = useQueryClient()

  const {
    reset,
    register,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm<CreateNYBusinessProps>()
  const onboardMutation = useMutation<Partial<IBusiness>, unknown, CreateNYBusinessProps>(
    data => createNYBusiness(null, data),
    {
      onSuccess: async data => {
        await queryClient.invalidateQueries('user')
        await queryClient.invalidateQueries(['businesses'])

        history.push(`/${data.business_id}/`)
      },
      onError: ({ status, data }) => {
        if (status == 400) {
          setAPIErrors(data, setError)
        } else {
          reset()
        }
      }
    }
  )

  const onSubmit = handleSubmit(async data => {
    const promise = onboardMutation.mutateAsync(data)

    toast.promise(promise, {
      loading: t('nyOnboard.creating'),
      success: t('nyOnboard.createSuccess'),
      error: t('general.error')
    })

    return promise
  })

  return (
    <BasicPage title={t('nyOnboard.mainTitle')} imageUrl={nyNocfoImg}>
      <PageContent>
        <h2>{t('nyOnboard.instructionHeader')}</h2>
        <p>{t('nyOnboard.instructionDescription')}</p>

        <StyledForm id="ny-form" onSubmit={onSubmit}>
          <Input
            label={t('nyOnboard.businessName')}
            {...register('name', { required: { value: true, message: t('validation.required') } })}
            errors={errors.name}
          />
        </StyledForm>

        <ButtonWrapper>
          <Button
            type="submit"
            intent="success"
            form="ny-form"
            showSpinner={onboardMutation.isLoading}
            disabled={onboardMutation.isLoading}
          >
            {t('nyOnboard.startBtn')}
          </Button>
        </ButtonWrapper>
      </PageContent>
    </BasicPage>
  )
}

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const StyledForm = styled.form`
  margin-top: ${({ theme }) => theme.spacing.md}rem;
`

const ButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  * {
    align-self: flex-end;
  }
`
