import styled from 'styled-components'

export const StyledHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 0;

  & > * {
    align-self: center;
  }
`

export const HeaderTitleWrapper = styled.div`
  flex: 1;
  display: flex;

  & > * {
    align-self: center;
  }
`

export const RightMenuWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  padding: ${({ theme }) => theme.spacing.xs}rem;
`

export const HamburgerButton = styled.button`
  border: none;
  padding: 24px;
  color: ${({ theme }) => theme.colors.metalGray};
  background: none;
`

export const HelpButton = styled.button`
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  text-transform: uppercase;
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.metalGray};
  transition: color 0.2s;
  padding: 0;

  &:hover {
    color: ${({ theme }) => theme.colors.nocfoBlue};
  }

  span {
    margin-right: 0.4rem;
  }

  * {
    align-self: center;
  }
`
