import { AnimatedContentLoader, AuditTrailStep } from '@root/components'
import UserCommentInput from '@root/components/UserCommentInput/UserCommentInput'
import { useBusinessContext } from '@root/context'
import { fetchPeople, IDocument } from '@root/query'
import {
  createAuditTrailDocumentComment,
  documentAuditTrailVisited,
  fetchDocumentAuditTrail,
  IAuditTrailComment
} from '@root/query/auditTrail'
import { theme } from '@root/styles'
import { setAPIErrors } from '@root/utils'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'

interface IDocumentAuditTrailProps {
  document: IDocument
}

export const DocumentAuditTrail: React.FC<IDocumentAuditTrailProps> = ({ document }) => {
  const { businessId } = useBusinessContext()
  const [t] = useTranslation()

  interface IFormValues {
    text: string
  }

  const methods = useForm<IFormValues>({
    defaultValues: {
      text: ''
    }
  })

  const { reset, setError } = methods

  const queryClient = useQueryClient()

  const createMutation = useMutation<IAuditTrailComment, unknown, Partial<IAuditTrailComment>>(
    data => createAuditTrailDocumentComment({ businessId, documentId: document.id }, data),
    {
      onError: ({ status, data }) => {
        if (status === 400) {
          setAPIErrors(data, setError)
        } else {
          toast.error(t('general.error'))
        }
      },
      onSuccess: () => {
        toast.success(t('components.auditTrail.commentAdded'))
        queryClient.invalidateQueries(queryKeyForAuditTrail(businessId, document.id))
        queryClient.invalidateQueries({ queryKey: [businessId, 'documents'] })
        auditTrailVisitedMutation.mutate()
        reset({ text: '' })
      }
    }
  )

  const {
    data,
    isLoading: isAuditTrailLoading,
    isSuccess: isAuditTrailDataSuccess,
    isError
  } = useQuery(queryKeyForAuditTrail(businessId, document.id), () =>
    fetchDocumentAuditTrail({ businessId, documentId: document.id })
  )

  const {
    data: users,
    isLoading: isUsersLoading,
    isSuccess: isPeopleDataSuccess
  } = useQuery([businessId, 'people'], () => fetchPeople({ businessId }, { count: 1000 }))

  const auditTrailVisitedMutation = useMutation(
    () => documentAuditTrailVisited({ businessId, documentId: document.id }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [businessId, 'documents'] })
      }
    }
  )

  useEffect(() => {
    if (isAuditTrailDataSuccess && isPeopleDataSuccess) {
      auditTrailVisitedMutation.mutate()
    }
  }, [isAuditTrailDataSuccess, isPeopleDataSuccess])

  const isLoading = isAuditTrailLoading || isUsersLoading

  const lastAudit = data?.length - 1

  return (
    <AnimatedContentLoader
      isLoading={isLoading}
      isEmpty={isError}
      isEmptyDescription={t('error.oho')}
    >
      <FormProvider {...methods}>
        <UserCommentInput createMutation={createMutation} />
      </FormProvider>

      <AuditTrailWrapper style={{ marginTop: `${theme.spacing.xl}rem` }}>
        {data?.map((audit, index) => (
          <AuditTrailStep
            key={`${audit.time}-${index}-audit-trail-step`}
            isLast={lastAudit === index}
            auditRecord={audit}
            users={users?.results}
            document={document}
          ></AuditTrailStep>
        ))}
      </AuditTrailWrapper>
    </AnimatedContentLoader>
  )
}

export const queryKeyForAuditTrail = (businessId: string, documentId: number) => [
  businessId,
  'document_audit_trail',
  { documentId }
]

const AuditTrailWrapper = styled.div``
