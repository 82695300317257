import { Header } from '@containers/Header'
import React from 'react'
import { DanknessContainer, StyledColumnLayoutWrapper } from './DoubleColumnLayout.styled'

interface Props {
  header: string
  children: React.ReactNode
}

export const SingleColumnLayout: React.FC<Props> = ({ header, children }) => {
  return (
    <DanknessContainer>
      <StyledColumnLayoutWrapper key={header}>
        <Header label={header} />
        <div style={{ flex: 1, margin: '2rem', marginTop: 0, overflow: 'hidden' }}>{children}</div>
      </StyledColumnLayoutWrapper>
    </DanknessContainer>
  )
}
