import { Button, Input, Modal, ModalDialog, ModalOpenButton, Select } from '@components'
import { useBusinessContext } from '@context'
import { createInvitation, IInvitation } from '@query'
import { setAPIErrors } from '@utils'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FaPaperPlane } from 'react-icons/fa'
import { useMutation, useQueryClient } from 'react-query'
import styled from 'styled-components'

interface Props {
  onInvitationSent?: () => void
  children: React.ReactNode
}

interface InvitationFormInputs {
  email: string
  role: string
}

export const InvitationModal: React.FC<Props> = ({ children, onInvitationSent }) => {
  const { businessId } = useBusinessContext()
  const queryClient = useQueryClient()
  const [t] = useTranslation()
  const {
    reset,
    register,
    setError,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm<InvitationFormInputs>({ defaultValues: {} })

  const createInvitationMutation = useMutation<IInvitation, unknown, Partial<IInvitation>>(
    data => createInvitation({ businessId }, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([businessId, 'invitations'])
        onInvitationSent && onInvitationSent()
        reset()
      },
      onError: ({ status, data }) => {
        if (status === 400) {
          setAPIErrors(data, setError)
        }
      }
    }
  )

  return (
    <Modal>
      {({ hideModal }) => (
        <>
          <ModalOpenButton>{children}</ModalOpenButton>
          <ModalDialog>
            <StyledModalContent>
              <ModalHeader>{t('settings.people.sendInvitation')}</ModalHeader>
              <form
                id="invitation-form"
                onSubmit={handleSubmit(data =>
                  createInvitationMutation.mutateAsync(data).then(() => hideModal())
                )}
              >
                <Input
                  label={t('settings.people.email')}
                  id="email"
                  errors={errors.email}
                  required={true}
                  {...register('email', {
                    required: { value: true, message: t('validation.required') },
                    pattern: { value: /^\S+@\S+$/i, message: t('validation.invalidEmail') }
                  })}
                />

                <Select
                  label={t('settings.people.role')}
                  id="email"
                  {...register('role', {
                    required: { value: true, message: t('validation.required') }
                  })}
                >
                  <option value="ADMIN">{t('settings.people.admin')}</option>
                  <option value="EDITOR">{t('settings.people.editor')}</option>
                  <option value="READ_ONLY">{t('settings.people.readOnly')}</option>
                </Select>
              </form>
              <div style={{ flex: 1 }} />
              <Footer>
                <Button type="button" intent="default" onClick={() => hideModal()}>
                  {t('general.close')}
                </Button>
                <div style={{ flex: 1 }} />
                <Button
                  type="submit"
                  intent="success"
                  form="invitation-form"
                  icon={<FaPaperPlane />}
                  showSpinner={isSubmitting}
                  disabled={isSubmitting}
                >
                  {t('settings.people.sendInvitation')}
                </Button>
              </Footer>
            </StyledModalContent>
          </ModalDialog>
        </>
      )}
    </Modal>
  )
}

const Footer = styled.div`
  display: flex;
`

const ModalHeader = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.md}rem;
`

const StyledModalContent = styled.div`
  background: ${({ theme }) => theme.colors.neutralWhite};
  padding: ${({ theme }) => theme.spacing.lg}rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`
