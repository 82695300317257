import { useCannyIdentify } from '@containers/CannyIdentify'
import { useQueryParam } from '@hooks'
import { callAll } from '@utils'
import { AnimatePresence, motion } from 'framer-motion'
import React, { cloneElement, isValidElement, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { FaChevronRight, FaTimes } from 'react-icons/fa'
import styled from 'styled-components'

export const HelpCenter: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const rootRef = useRef<HTMLElement>(document.getElementById('modal-root'))
  const [isOpen, setOpen] = useQueryParam('help', value => value === 'open')
  const [t] = useTranslation()
  useCannyIdentify()

  if (!isValidElement(children)) {
    throw new Error('PopupMenuButton requires a valid child element')
  }

  return (
    <>
      {cloneElement<any>(children, {
        onClick: callAll(() => {
          setOpen('open')
        }, children.props.onClick)
      })}

      {createPortal(
        <AnimatePresence initial={false}>
          {isOpen && (
            <>
              <Backdrop onClick={() => setOpen(undefined)} />
              <HelpCenterTabWrapper>
                <HelpCenterTab>
                  <HelpCenterHeader>
                    <MainTitle>{t('helpCenter.help')}</MainTitle>
                    <HelpCloseButton onClick={() => setOpen(undefined)}>
                      <FaTimes />
                    </HelpCloseButton>
                  </HelpCenterHeader>

                  <HelpItem
                    title={t('helpCenter.knowledgeTitle')}
                    detail={t('helpCenter.knowledgeDescription')}
                    to={t('link.docs.main')}
                  />
                  <HelpItem
                    title={t('helpCenter.contactTitle')}
                    detail={t('helpCenter.contactDescription')}
                    to={t('link.contact')}
                  />
                  <HelpItem
                    title={t('helpCenter.feedbackTitle')}
                    detail={t('helpCenter.feedbackDescription')}
                    to={t('link.feedback')}
                  />
                  <HelpItem
                    title={t('helpCenter.wishlist')}
                    detail={t('helpCenter.wishlistDescription')}
                    to={t('link.canny')}
                  />
                </HelpCenterTab>
              </HelpCenterTabWrapper>
            </>
          )}
        </AnimatePresence>,
        rootRef.current
      )}
    </>
  )
}

interface StyledHelpItemProps {
  title: string
  detail: string
  to: string
}

const HelpItem: React.FC<StyledHelpItemProps> = ({ title, detail, to }) => {
  return (
    <a href={to} style={{ textDecoration: 'none !important' }} target="_blank" rel="noreferrer">
      <StyledHelpItem>
        <StyledHelpItemContent>
          <StyledHelpItemTitle>{title}</StyledHelpItemTitle>
          <StyledHelpItemText>{detail}</StyledHelpItemText>
        </StyledHelpItemContent>
        <FaChevronRight />
      </StyledHelpItem>
    </a>
  )
}

const HelpCenterTabWrapper = styled(motion.div).attrs({
  initial: 'hide',
  animate: 'show',
  exit: 'hide',
  variants: {
    hide: { x: '50%', opacity: 0, transition: { type: 'linear' } },
    show: { x: 0, opacity: 1 }
  },
  transition: { staggerChildren: 0.12, delayChildren: 0.2 }
})`
  position: fixed;
  width: 400px;
  max-width: 100vw;
  height: 100vh;
  right: 0;
  top: 0;
  z-index: 1000;
  padding: 1rem;
`

const Backdrop = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background: ${({ theme }) => theme.colors.backdrop};
`

const HelpCenterTab = styled(motion.div)`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.nocfoBlue};
  padding: ${({ theme }) => theme.spacing.lg}rem;
  border-radius: 1rem;
  box-shadow: 0px 8px 22px rgba(27, 31, 35, 0.07);
`

const HelpCenterHeader = styled.div`
  margin-bottom: 1rem;
  display: flex;

  & > * {
    self-align: center;
  }
`

const HelpCloseButton = styled.div`
  cursor: pointer;
  svg {
    fill: white;
  }
`

const MainTitle = styled.h4`
  color: ${({ theme }) => theme.colors.neutralWhite};
  letter-spacing: 2px;
  font-weight: 500;
  flex: 1;
  text-transform: uppercase;
`

const StyledHelpItem = styled(motion.div).attrs({
  whileHover: { scale: 1.03 },
  whileTap: { scale: 0.96 },
  variants: {
    hide: { opacity: 0, x: 40 },
    show: { opacity: 1, x: 0 }
  }
})`
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0.6rem;
  padding: 1rem;
  margin-bottom: 0.6rem;
  color: ${({ theme }) => theme.colors.neutralWhite};
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  display: flex;
  cursor: pointer;

  * {
    align-self: center;
  }

  svg {
    margin: 0.4rem;
  }
`

const StyledHelpItemContent = styled.div`
  flex: 1;
`

const StyledHelpItemTitle = styled.div`
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
`

const StyledHelpItemText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  opacity: 0.75;
`
