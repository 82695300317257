import { motion } from 'framer-motion'
import { FaTimes } from 'react-icons/fa'
import styled from 'styled-components'

export const Backdrop = styled(motion.div).attrs({
  variants: {
    init: { opacity: 0 },
    show: { opacity: 1 },
    exit: { opacity: 0 }
  },
  initial: 'init',
  animate: 'show',
  exit: 'exit'
})`
  top: 0;
  left: 0;
  position: fixed;
  background: ${({ theme }) => theme.colors.backdrop};
  width: 100%;
  height: 100%;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PromptWrapper = styled(motion.div).attrs({
  variants: {
    init: { scale: 1.3, y: -70 },
    show: { scale: 1, y: -100 },
    exit: { scale: 0.95, y: -100 }
  }
})`
  padding: ${({ theme }) => theme.spacing.md}rem;
  position: absolute;
  width: 450px;
  max-width: 100vw;
  max-height: 100vh;
`

export const PromptModal = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  background: white;
  box-shadow: 0px 5px 20px 2px rgba(0, 0, 0, 0.15);
`

export const PromptHeader = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.md}rem;
  display: flex;
  border-bottom: 1px solid #eee;

  & > * {
    align-self: center;
  }
`

export const PromptContent = styled.div`
  min-height: 120px;
  padding: ${({ theme }) => theme.spacing.md}rem;
`

export const PromptFooter = styled.div`
  padding: ${({ theme }) => theme.spacing.md}rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > * {
    margin-left: ${({ theme }) => theme.spacing.sm}rem;
  }
`

export const PromptTitle = styled.h3`
  margin: 0;
  flex: 1;
`

export const PromptClose = styled(FaTimes)`
  width: ${({ theme }) => theme.iconSize.md}rem;
  cursor: pointer;
`
