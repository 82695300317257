import {
  autoUpdate,
  flip,
  offset,
  size,
  useDismiss,
  useFloating
} from '@floating-ui/react-dom-interactions'
import { AnimatePresence } from 'framer-motion'
import React, { useState } from 'react'
import { NotificationBell } from './NotificationBell'
import { NotificationFeed } from './NotificationFeed'

export const NotificationFeedPopup: React.FC = () => {
  const [isOpen, setOpen] = useState(false)

  const { context, x, y, reference, floating, strategy } = useFloating({
    open: isOpen,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-end',
    middleware: [
      offset(20),
      flip(),
      size({
        apply: ({ availableWidth, availableHeight, elements }) => {
          const wPx = Math.min(availableWidth, 400)
          const hPx = Math.min(availableHeight, 600)
          Object.assign(elements.floating.style, {
            width: `${wPx}px`,
            maxHeight: `${hPx}px`
          })
        }
      })
    ]
  })

  useDismiss(context)

  return (
    <>
      <div ref={reference} onClick={() => setOpen(!isOpen)}>
        <NotificationBell />
      </div>
      <AnimatePresence>
        {isOpen && (
          <NotificationFeed
            ref={floating}
            style={{
              top: y || 0,
              left: x || 0,
              position: strategy
            }}
          />
        )}
      </AnimatePresence>
    </>
  )
}
