import { HeaderBlock, Tab, Tabs, TeaserView } from '@components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import InvoiceSettingsAdvanced from './InvoiceSettingsAdvanced'
import InvoiceSettingsGeneral from './InvoiceSettingsGeneral'
import { FeatureWrapper } from '@root/context'

export const InvoicingSettings: React.FC = () => {
  const [t] = useTranslation()

  return (
    <StyledWrapper>
      <HeaderBlock header={t('settings.sections.invoicing')} subHeader="" />

      <FeatureWrapper feature="feature_invoicing" reverse>
        <StyledContentWrapper>
          <TeaserView
            header={t('teaser.invoicing.header')}
            subHeader={t('teaser.invoicing.subHeader')}
            features={[
              t('teaser.feature.1'),
              t('teaser.feature.2'),
              t('teaser.feature.3'),
              t('teaser.feature.4'),
              t('teaser.feature.5')
            ]}
          />
        </StyledContentWrapper>
      </FeatureWrapper>

      <FeatureWrapper feature="feature_invoicing">
        <StyledContentWrapper>
          <Tabs>
            <Tab key="general" name={t('settings.invoicing.sections.generalSettings')}>
              <InvoiceSettingsGeneral />
            </Tab>

            <Tab key="advanced" name={t('settings.invoicing.sections.advancedSettings')}>
              <InvoiceSettingsAdvanced />
            </Tab>
          </Tabs>
        </StyledContentWrapper>
      </FeatureWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  background: white;
  height: 100%;
  overflow: auto;
`
const StyledContentWrapper = styled.div`
  margin-left: ${({ theme }) => theme.spacing.md}rem;
  margin-right: ${({ theme }) => theme.spacing.md}rem;
`
