import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

export const normalizeDateString = (dateStr: string): [string, boolean] => {
  const supportedFormats = ['YYYY-MM-DD', 'YYYY.MM.DD', 'DD.MM.YYYY', 'D.M.YYYY']

  while (supportedFormats.length !== 0) {
    const format = supportedFormats.shift()
    if (dayjs(dateStr, format).isValid()) {
      return [dayjs(dateStr, format).format('YYYY-MM-DD'), false]
    }
  }

  return [undefined, true]
}

export const normalizeNumberString = (numberStr: string): [string, boolean] => {
  numberStr = numberStr.replace(',', '.').replace('−', '-').replace(/\s/g, '')

  if (Number.isNaN(Number(numberStr))) {
    return [undefined, true]
  } else {
    return [numberStr, false]
  }
}
