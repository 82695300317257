import { BlueprintType } from '@constants'
import { IBlueprint, IBlueprintEntry } from '@query'
import { isNil } from 'lodash'

const cleanEntries = (entries?: IBlueprintEntry[]): IBlueprintEntry[] | undefined =>
  entries?.filter(entry => !isNil(entry.amount) && !isNil(entry.account_id))

export const cleanBlueprint = (
  blueprint_type: BlueprintType,
  blueprint: IBlueprint
): Partial<IBlueprint> => {
  if (blueprint_type === BlueprintType.PURCHASE) {
    return {
      credit_account_id: blueprint.credit_account_id,
      debet_entries: cleanEntries(blueprint.debet_entries)
    }
  }

  if (blueprint_type === BlueprintType.SALES) {
    return {
      debet_account_id: blueprint.debet_account_id,
      credit_entries: cleanEntries(blueprint.credit_entries),
      expense_entries: cleanEntries(blueprint.expense_entries)
    }
  }

  return {
    debet_entries: cleanEntries(blueprint.debet_entries),
    credit_entries: cleanEntries(blueprint.credit_entries)
  }
}
