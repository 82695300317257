import { useQueryParam } from '@hooks'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'
import { NormalAccountPage, TabularAccountPage } from './sections'

export const AccountsPage: React.FC = () => {
  const [editMode] = useQueryParam<string>('editMode')

  return (
    <AnimatePresence mode="wait" initial={false}>
      {editMode === 'tabular' && (
        <AnimatedWrapper key="tabular">
          <TabularAccountPage />
        </AnimatedWrapper>
      )}
      {editMode !== 'tabular' && (
        <AnimatedWrapper key="normal">
          <NormalAccountPage />
        </AnimatedWrapper>
      )}
    </AnimatePresence>
  )
}

const AnimatedWrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  height: 100%;
`
