import { Empty } from '@components'
import { Spinner } from 'evergreen-ui'
import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

interface AnimatedContentLoaderProps {
  isLoading: boolean
  isEmpty?: boolean
  isEmptyDescription?: string
  isEmptyContent?: React.ReactNode
  contentDelay?: number
  spinnerSize?: number
  disableContentAnimation?: boolean
  children: React.ReactNode
}

export const AnimatedContentLoader: React.FC<AnimatedContentLoaderProps> = ({
  isLoading,
  children,
  isEmpty = false,
  isEmptyContent = null,
  isEmptyDescription = '',
  spinnerSize = 24
}) => {
  const showIsEmpty = !isLoading && isEmpty
  const showContent = !isLoading && !showIsEmpty

  return (
    <>
      {isLoading && (
        <AnimatedElement key="spinner" className="spinner-wrapper">
          <SpinnerWrapper>
            <Spinner margin={32} alignSelf="center" size={spinnerSize} />
          </SpinnerWrapper>
        </AnimatedElement>
      )}

      {showIsEmpty && (
        <AnimatedElement key="empty">
          {isEmptyContent ? isEmptyContent : <Empty text={isEmptyDescription} />}
        </AnimatedElement>
      )}

      {showContent && <AnimatedElement key="loaded-content">{children}</AnimatedElement>}
    </>
  )
}

const AnimatedElement = styled(motion.div).attrs({
  initial: { opacity: 0, y: 0 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 0 },
  transition: { type: 'tween' }
})`
  overflow: visible;
  width: 100%;
  height: 100%;
`

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
`
