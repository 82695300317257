import imgSrc from '@assets/undraw/undraw_stripe_payments_re_chlm.svg'
import { Alert, AnimatedContentLoader, BasicPage } from '@components'
import { useQueryParam } from '@hooks'
import { completeCheckoutSession } from '@query'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'

export const StripeConfirmationPage: React.FC = () => {
  const [t] = useTranslation()
  const [stripeSessionId] = useQueryParam<string>('session_id')
  const history = useHistory()
  const queryClient = useQueryClient()
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const { isLoading, isSuccess } = useQuery(
    ['stripe_session_complete', stripeSessionId],
    () => completeCheckoutSession({ sessionId: stripeSessionId }),
    {
      onSuccess: async data => {
        if (!showSuccess) {
          setShowSuccess(true)
          await queryClient.invalidateQueries()

          toast.success(t('stripeConfirmation.success'), { icon: '🎉', duration: 10000 })
          history.push(`/${data.business_id}/`)
        }
      },
      onError: () => setShowError(true),
      enabled: !showError
    }
  )

  return (
    <BasicPage title={t('stripeConfirmation.mainTitle')} imageUrl={imgSrc}>
      <h3>{t('stripeConfirmation.secondaryTitle')}</h3>
      <AnimatedContentLoader isLoading={isLoading || isSuccess}>
        {showError && (
          <Alert
            type="error"
            title={t('stripeConfirmation.errorTitle')}
            description={
              <Trans i18nKey="stripeConfirmation.errorDescription">
                <a href={t('link.email')} rel="noreferrer" target="_blank" />
              </Trans>
            }
          />
        )}
      </AnimatedContentLoader>
    </BasicPage>
  )
}
