import React, { useState } from 'react'
import { StyledWaitingSpinnerWrapper } from '@pages/InvoicingPage/DebtCollection/trackerMessages/Onboarding/Onboarding.styled.tsx'
import successImgSrc from '@assets/undraw/undraw_confirmed_re_sef7.svg'
import { Button } from '@components'
import { FaMoneyBillTrendUp } from 'react-icons/fa6'
import { useTranslation } from 'react-i18next'

interface Props {
  onRegisterInvoice: () => Promise<any>
}

export const SuccessSlide: React.FC<Props> = ({ onRegisterInvoice }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  const handleClick = async () => {
    try {
      setIsLoading(true)
      await onRegisterInvoice()
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <StyledWaitingSpinnerWrapper>
      <img className="success-img" src={successImgSrc} />
      <div>
        <h3>{t('invoicing.debtCollection.onboard.signatureDoneTitle')}</h3>
        <p>{t('invoicing.debtCollection.onboard.signatureDoneDescription')}</p>
      </div>
      <Button
        onClick={handleClick}
        showSpinner={isLoading}
        icon={<FaMoneyBillTrendUp />}
        intent="primary"
      >
        {t('invoicing.actions.sendToDebtCollection')}
      </Button>
    </StyledWaitingSpinnerWrapper>
  )
}
