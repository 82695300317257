import dayjs from 'dayjs'
import { ValidLocale } from '@localisation'

export type DayJSInput = Date | dayjs.Dayjs | string | undefined

const _toDayJs = (date: DayJSInput): dayjs.Dayjs => {
  if (!date) return dayjs()
  if (typeof date === 'string') return dayjs(date)
  if (date instanceof Date) return dayjs(date)
  return date
}

const getProbableLocale = (): string => {
  const locale = dayjs().locale()

  switch (locale) {
    // Most of the English speakers are located in
    // Finland (or other EU country) so there is no
    // point formatting English locales US way.
    // Default to Finnish date format if user is
    // speaking English as it is easy to understand
    // for Europeans
    case ValidLocale.EN:
      return ValidLocale.FI
    default:
      return locale
  }
}

export const formatDate = (date: DayJSInput): string =>
  _toDayJs(date).locale(getProbableLocale()).format('L')

export const formatRelativeTime = (date: DayJSInput): string => _toDayJs(date).fromNow()
