import React, { useCallback, useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { fetchSalaxySession, verifySalaxy } from '@query/salaxy'
import { IBusiness } from '@query'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { SalaryMenu } from '@pages/SalaryPage/SalaryMenu'
import { buildSalaxyLink } from '@pages/SalaryPage/utils'
import { Header } from '@containers/Header'
import { toast } from 'react-hot-toast'
import { getDashboardPageUrl } from '@constants'
import { useHistory } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { Spinner } from 'evergreen-ui'
import { useQueryParam } from '@hooks'
import { DEFAULT_QUERY_CLIENT_OPTIONS } from '@root/index'
import { set, cloneDeep } from 'lodash'
import { useBusinessContext } from '@context'

interface Props {
  business: IBusiness
}

export const SalaryIFrame: React.FC<Props> = ({ business }) => {
  const history = useHistory()
  const [activePage, setActivePage] = useQueryParam<string>('p')
  const { businessId } = useBusinessContext()

  const [isSalaxyReady, setSalaxyReady] = useState(false)
  const iframeRef = React.useRef<HTMLIFrameElement>(null)
  const queryClient = useQueryClient()

  const {
    t,
    i18n: { language: currentLanguage }
  } = useTranslation()

  useEffect(() => {
    // Disable refetchOnWindowFocus when iframe is visible
    // to prevent unnecessary refetches and renders when
    // navigating between page and iframe
    queryClient.setDefaultOptions(
      set(cloneDeep(DEFAULT_QUERY_CLIENT_OPTIONS), 'queries.refetchOnWindowFocus', false)
    )

    // Reset the default options when the component is unmounted
    return () => queryClient.setDefaultOptions(DEFAULT_QUERY_CLIENT_OPTIONS)
  }, [])

  const { data: session, refetch } = useQuery(
    [business.business_id, 'salaxy_session'],
    () => fetchSalaxySession({ businessId: business.business_id }),
    {
      enabled: business?.salaxy_enabled,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: 1000 * 60 * 60 * 4, // refetch every 4 hours
      refetchIntervalInBackground: true
    }
  )

  const { mutate: verifySalaxyMutation } = useMutation(() => verifySalaxy({ businessId }), {
    onSuccess: ({ is_verified }) => {
      if (is_verified && !session?.is_verified) {
        queryClient.invalidateQueries([businessId, 'salaxy_session'])
      }
    }
  })

  const receiveMessage = useCallback((event: any) => {
    if (event?.data?.salaxySessionEvent) {
      switch (event.data.salaxySessionEvent) {
        case 'success':
          // CASE: Salaxy customer is not yet verified
          // > Check for verification
          if (!session?.is_verified) verifySalaxyMutation()
          setSalaxyReady(true)
          return
        case 'failure':
          toast.error(t('general.error'))
          history.push(getDashboardPageUrl(business.business_id))
          return
        case 'expired':
          setSalaxyReady(false)
          toast.promise(refetch(), {
            loading: t('salaries.ssoExpired.loading'),
            success: t('salaries.ssoExpired.success'),
            error: t('salaries.ssoExpired.error')
          })
          return
        case 'logout':
          history.push(getDashboardPageUrl(business.business_id))
          return
      }
    }
  }, [])

  // Start listening messages from the Palkkaus.fi window in iframe
  useEffect(() => {
    window.addEventListener('message', receiveMessage, false)
    return () => window.removeEventListener('message', receiveMessage)
  }, [receiveMessage])

  return (
    <IFrameWrapper>
      <SalaryMenu
        business={business}
        activePage={activePage}
        hideMenuItems={!session?.is_verified}
        setActivePage={(value: string) => setActivePage(value)}
      />

      <ContentWrapper>
        <Header label={t('salaries.title')} />

        {session?.access_token && (
          <StyledIframe
            ref={iframeRef}
            id="salaxy-iframe"
            name="salaxy-iframe"
            src={buildSalaxyLink({
              page: activePage,
              token: session.access_token,
              isInitialLoad: !isSalaxyReady,
              currentLanguage
            })}
          />
        )}

        <BlockingLoader isLoading={!isSalaxyReady} />
      </ContentWrapper>
    </IFrameWrapper>
  )
}

const BlockingLoader: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  return (
    <AnimatePresence mode="wait" initial={false}>
      {isLoading && (
        <StyledLoaderWrapper>
          <Spinner />
        </StyledLoaderWrapper>
      )}
    </AnimatePresence>
  )
}

const IFrameWrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
`

const ContentWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const StyledIframe = styled.iframe`
  flex: 1;
  outline: none;
  border: none;
`

const StyledLoaderWrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0, transition: { delay: 0.2 } }
})`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.neutralGray};
`
