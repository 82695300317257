import { AnimatedList, Checkbox, Input, ToolBar } from '@components'
import dayjs from 'dayjs'
import { motion } from 'framer-motion'
import React from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FaPlus, FaTimes } from 'react-icons/fa'
import { DeleteButtonWrapper, FormHeader, FormRowWrapper, FormWrapper } from './ReportsPage.styled'

export const BalanceSheetForm: React.FC = () => {
  const [t] = useTranslation()
  const {
    control,
    register,
    formState: { errors }
  } = useFormContext()
  const { fields, append, remove } = useFieldArray({ control, name: 'columns', keyName: '__id' })
  const isDeleteDisabled = fields.length <= 1

  return (
    <FormWrapper layout>
      <motion.div layout>
        <FormHeader>{t('reports.comparisonTitle')}</FormHeader>
      </motion.div>

      <AnimatedList
        items={fields.map((field, index) => (
          <FormRowWrapper key={field.__id}>
            <Input
              id={`balance-sheet-date-${index}`}
              type="date"
              placeholder="YYYY-MM-DD"
              label={t('reports.date')}
              defaultValue={dayjs().startOf('month').format('YYYY-MM-DD') as string}
              {...register(`columns.${index}.date_at`, {
                required: t('validation.required') as string
              })}
              errors={errors.columns?.[index]?.date_at}
            />
            <Input
              id={`balance-sheet-name-${index}`}
              label={t('reports.columnTitle')}
              {...register(`columns.${index}.name`)}
              errors={errors.columns?.[index]?.name}
            />
            <DeleteButtonWrapper
              data-is-disabled={isDeleteDisabled}
              onClick={() => !isDeleteDisabled && remove(index)}
            >
              <FaTimes />
            </DeleteButtonWrapper>
          </FormRowWrapper>
        ))}
        footer={
          <ToolBar
            items={[
              {
                icon: FaPlus,
                text: t('reports.addComparison'),
                primary: false,
                disabled: fields.length >= 4,
                action: () => append({})
              }
            ]}
          />
        }
      />
      <Controller
        name="extend_accounts"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Checkbox
            id="show-accounts-checkbox"
            checked={value}
            onChange={e => onChange(e.currentTarget.checked)}
            label={t('reports.showAccounts')}
          />
        )}
      />
    </FormWrapper>
  )
}
