import React from 'react'
import styled from 'styled-components'
import { HolviAuthorizeButton } from '@containers/HolviAuthorizeButton/HolviAuthorizeButton.tsx'
import holviImg from '@assets/Holvi_Wordmark.png'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { FeatureWrapper, useBusinessContext } from '@context'
import { deleteHolviIntegration, fetchHolviAccounts } from '@query/holvi.ts'
import { AnimatedContentLoader, HeaderBlock, TeaserView } from '@components'
import { HolviAccountMapper } from '@pages/SettingsPage/sections/HolviIntegrationSettings/HolviAccountMapper.tsx'
import { useTranslation } from 'react-i18next'
import toaster from 'react-hot-toast'
import { FaTrash } from 'react-icons/fa'

export const HolviIntegrationSettings: React.FC = () => {
  const { t } = useTranslation()
  const { businessId, data: business } = useBusinessContext()
  const queryClient = useQueryClient()

  const { data, isLoading: isAccountsLoading } = useQuery(
    [businessId, 'holvi', 'accounts'],
    () => fetchHolviAccounts({ businessId }),
    {
      enabled: business?.holvi_bookkeeping_api_enabled
    }
  )
  const accounts = data?.results

  const deleteMutation = useMutation(() => deleteHolviIntegration({ businessId }), {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries([businessId]),
        queryClient.invalidateQueries([businessId, 'holvi'])
      ])
      toaster.success(t('general.success'))
    }
  })

  return (
    <StyledWrapper>
      <HeaderBlock
        header={
          <Header>
            <img src={holviImg} />
            <span>{'-' + t('settings.holvi.logoTitle')}</span>
          </Header>
        }
        subHeader={null}
        menuItems={[
          {
            Icon: FaTrash,
            intent: 'danger',
            name: t('general.delete'),
            onClick: () => deleteMutation.mutate()
          }
        ]}
      />

      <ContentWrapper>
        <FeatureWrapper feature="feature_bank_integrations">
          {business?.holvi_bookkeeping_api_enabled ? (
            <AnimatedContentLoader isLoading={isAccountsLoading}>
              {accounts && <HolviAccountMapper accounts={accounts} />}
            </AnimatedContentLoader>
          ) : (
            <>
              <p>{t('settings.holvi.description')}</p>
              <HolviAuthorizeButton />
            </>
          )}
        </FeatureWrapper>

        <FeatureWrapper feature="feature_bank_integrations" reverse>
          <TeaserView
            header={t('teaser.holviIntegration.header')}
            subHeader={t('teaser.holviIntegration.subHeader')}
            features={[
              t('teaser.feature.5'),
              t('teaser.feature.8'),
              t('teaser.feature.1'),
              t('teaser.feature.2'),
              t('teaser.feature.7')
            ]}
          />
        </FeatureWrapper>
      </ContentWrapper>
    </StyledWrapper>
  )
}

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs}rem;

  img {
    height: ${({ theme }) => theme.fontSize.xl}rem;
  }
`

const StyledWrapper = styled.div`
  background: white;
  height: 100%;
  overflow: auto;
`

const ContentWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.md}rem;
`
