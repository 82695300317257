import { AnimatedContentLoader, Button, ModalV2 } from '@components'
import { useBusinessContext } from '@context'
import { fetchPricingTableKeys, useCurrentUser } from '@query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import styled from 'styled-components'

export const PricingTable: React.FC = () => {
  const { businessId } = useBusinessContext()
  const { isLoading: isUserLoading, data: user } = useCurrentUser()
  const { isSuccess: isKeysSuccess, data: keys } = useQuery('pricing-table-keys', () =>
    fetchPricingTableKeys()
  )
  const isLoading = isUserLoading || !isKeysSuccess

  const customerEmail = user?.email
  const clientReferenceId = businessId

  return (
    <AnimatedContentLoader isLoading={isLoading}>
      {isKeysSuccess && (
        <div
          dangerouslySetInnerHTML={{
            __html: `
          <stripe-pricing-table
            pricing-table-id="${keys?.pricing_table_id}"
            customer-email="${customerEmail}"
            publishable-key="${keys?.pricing_table_publishable_key}"
            client-reference-id="${clientReferenceId}"
          ></stripe-pricing-table>`
          }}
        />
      )}
    </AnimatedContentLoader>
  )
}

export const PricingTableModal: React.FC<{ isVisible: boolean; handleOnClose: () => void }> = ({
  isVisible,
  handleOnClose
}) => {
  const [t] = useTranslation()
  return (
    <ModalV2
      header={t('components.pricingTable.subscribe')}
      isVisible={isVisible}
      handleOnClose={() => handleOnClose()}
      width={1200}
      height={1000}
      footer={
        <StyledFooter>
          <Button onClick={() => handleOnClose()}>{t('general.close')}</Button>
        </StyledFooter>
      }
    >
      <PricingTable />
    </ModalV2>
  )
}

const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`
