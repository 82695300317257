import { Select, TextArea } from '@components'
import styled from 'styled-components'
import { IKYCData } from '@query'
import { RegisterOptions, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const MIN_ANSWER_LENGTH = 50

interface Props {
  formId: string
  onSubmit: (data: IKYCData) => Promise<any>
}

export const KYCForm: React.FC<Props> = ({ formId, onSubmit }) => {
  const { t } = useTranslation()
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IKYCData>()

  const [does_foreign_invoicing, has_pep_connections, is_under_financial_sanctions] = watch([
    'does_foreign_invoicing',
    'has_pep_connections',
    'is_under_financial_sanctions'
  ])

  const selectOptions: RegisterOptions = {
    setValueAs: (value: string): boolean | null =>
      value === 't' ? true : value === 'f' ? false : null,
    validate: value => {
      if (value === true || value === false) {
        return true
      }
      return t('validation.required')
    }
  }

  const textValidation: RegisterOptions['validate'] = value => {
    if (typeof value === 'string' && value.length < MIN_ANSWER_LENGTH) {
      return t('validation.minTextLength', {
        currentLength: value.length,
        minLength: MIN_ANSWER_LENGTH
      })
    }
    return true
  }

  return (
    <StyledForm id={formId} onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <label htmlFor="type_of_business_description">
          {t('invoicing.debtCollection.onboard.businessDescriptionLabel')} <RequiredMarker />
        </label>
        <DetailText>{t('invoicing.debtCollection.onboard.businessDescriptionInfo')}</DetailText>
        <TextArea
          id="type_of_business_description"
          {...register('type_of_business_description', {
            required: { value: true, message: t('validation.required') },
            validate: textValidation
          })}
          errors={errors?.type_of_business_description}
        />
      </fieldset>

      <fieldset>
        <label>
          {t('invoicing.debtCollection.onboard.foreignInvoicing')} <RequiredMarker />
        </label>
        <Select
          {...register('does_foreign_invoicing', selectOptions)}
          errors={errors?.does_foreign_invoicing}
        >
          <option selected disabled value={null}></option>
          <option value={'t'}>{t('general.yes')}</option>
          <option value={'f'}>{t('general.no')}</option>
        </Select>
        {does_foreign_invoicing && (
          <TextArea
            placeholder={t('invoicing.debtCollection.onboard.descriptionLabel')}
            {...register('does_foreign_invoicing_description', {
              required: { value: true, message: t('validation.required') },
              validate: textValidation
            })}
            errors={errors?.does_foreign_invoicing_description}
          />
        )}
      </fieldset>

      <fieldset>
        <label>{t('invoicing.debtCollection.onboard.pepConnections')}</label> <RequiredMarker />
        <Select
          {...register('has_pep_connections', selectOptions)}
          errors={errors?.has_pep_connections}
        >
          <option selected disabled value={null}></option>
          <option value={'t'}>{t('general.yes')}</option>
          <option value={'f'}>{t('general.no')}</option>
        </Select>
        {has_pep_connections && (
          <TextArea
            placeholder={t('invoicing.debtCollection.onboard.descriptionLabel')}
            {...register('has_pep_connections_description', {
              required: { value: true, message: t('validation.required') },
              validate: textValidation
            })}
            errors={errors?.has_pep_connections_description}
          />
        )}
      </fieldset>

      <fieldset>
        <label>{t('invoicing.debtCollection.onboard.financialSanctions')}</label> <RequiredMarker />
        <Select
          {...register('is_under_financial_sanctions', selectOptions)}
          errors={errors?.is_under_financial_sanctions}
        >
          <option selected disabled value={null}></option>
          <option value={'t'}>{t('general.yes')}</option>
          <option value={'f'}>{t('general.no')}</option>
        </Select>
        {is_under_financial_sanctions && (
          <TextArea
            placeholder={t('invoicing.debtCollection.onboard.descriptionLabel')}
            {...register('is_under_financial_sanctions_description', {
              required: {
                value: true,
                message: t('validation.required')
              }
            })}
            errors={errors?.is_under_financial_sanctions_description}
          />
        )}
      </fieldset>
    </StyledForm>
  )
}

const StyledForm = styled.form`
  color: ${({ theme }) => theme.colors.neutralBlack};

  label {
    font-weight: bold;
    margin-bottom: ${({ theme }) => theme.spacing.xxs}rem;
  }

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md}rem;
`

const RequiredMarker = styled.span`
  &:after {
    content: '*';
    color: ${({ theme }) => theme.colors.nocfoRed};
    font-weight: bold;
  }
`

const DetailText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  color: ${({ theme }) => theme.colors.metalGray};
  margin-bottom: ${({ theme }) => theme.spacing.xs}rem;
`
