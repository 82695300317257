import React, { useCallback } from 'react'
import { Controller, get } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RadioGroup, RadioItem, SlideText, SlideTitle, ValidationError } from './slide.styled'

export const FirstFiscalPeriodSlide: React.FC = () => {
  const [t] = useTranslation()

  const firstFiscalPeriodOptions = [
    { label: t('general.yes'), value: false },
    { label: t('general.no'), value: true }
  ]

  const validate = useCallback((value: any) => {
    if (value === true || value === false) return true
    return t('validation.required')
  }, [])

  return (
    <>
      <SlideTitle>{t('onboarding.hasHistorySlide.title')}</SlideTitle>
      <SlideText>{t('onboarding.hasHistorySlide.message')}</SlideText>

      <Controller
        name="business.has_history_before_nocfo"
        rules={{ validate }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <RadioGroup>
            {firstFiscalPeriodOptions.map((opt, i) => (
              <RadioItem
                key={i}
                label={opt.label}
                index={i}
                selected={value === opt.value}
                value={opt.value}
                onSelect={onChange}
              />
            ))}

            {get(errors, 'business.has_history_before_nocfo') && (
              <ValidationError>
                {get(errors, 'business.has_history_before_nocfo').message}
              </ValidationError>
            )}
          </RadioGroup>
        )}
      />
    </>
  )
}
