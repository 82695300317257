import { Input, Select } from '@components'
import {
  VatCode,
  VAT_CODES_FOR_SALES,
  VAT_CODES_IGNORE_VAT_IN_SALES,
  VAT_CODE_OPTIONS
} from '@constants'
import { useBusinessContext } from '@context'
import { createProduct, IProduct, updateProduct } from '@query'
import { setAPIErrors } from '@utils'
import { Pane, Switch, Text } from 'evergreen-ui'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { getVatOptions } from '@utils/getVatOptions.tsx'

interface Props {
  product?: IProduct
  onSubmit?: () => void
  onCreate?: (data: Partial<IProduct>) => void
}

export const ProductForm: React.FC<Props> = ({ product, onSubmit: onSubmitCallback, onCreate }) => {
  const [t] = useTranslation()
  const { businessId, data: business } = useBusinessContext()
  const queryClient = useQueryClient()

  const defaultValues = React.useMemo(
    () => ({
      vat_code: VatCode.DOMESTIC_SALES_TAX,
      vat_rate: getVatOptions(business.country_config)?.[0].rate,
      ...product
    }),
    [product]
  )

  const {
    register,
    handleSubmit,
    watch,
    setError,
    control,
    setValue,
    formState: { errors }
  } = useForm<Partial<IProduct>>({
    defaultValues
  })

  const commonOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries([businessId, 'products'])
    },
    onError: ({ status, data }) => {
      if (status === 400) {
        setAPIErrors(data, setError)
      } else {
        toast.error(t('general.error'))
      }
    }
  }

  const { mutateAsync: updateMutation } = useMutation<unknown, unknown, Partial<IProduct>>(
    data => updateProduct({ businessId, productId: product?.id }, data),
    commonOptions
  )

  const { mutateAsync: createMutation } = useMutation<unknown, unknown, Partial<IProduct>>(
    data => createProduct({ businessId }, data),
    commonOptions
  )

  const { unit, vat_code: vatCode } = watch()
  const onSubmit = handleSubmit(async data => {
    const promise = await (product?.id ? updateMutation(data) : createMutation(data))
    onSubmitCallback && onSubmitCallback()
    onCreate && onCreate(await promise)
  })

  useEffect(() => {
    if (VAT_CODES_IGNORE_VAT_IN_SALES.indexOf(vatCode) !== -1) {
      setValue('vat_rate', 0)
    } else {
      setValue('vat_rate', defaultValues?.vat_rate)
    }
  }, [vatCode])

  return (
    <div onSubmit={event => event.stopPropagation()}>
      <form id="product-form" onSubmit={onSubmit}>
        <Input
          label={t('products.form.name')}
          errors={errors?.name}
          info={t('products.form.nameInfo')}
          required={true}
          {...register('name', {
            required: {
              value: true,
              message: t('validation.required')
            }
          })}
        />

        <Input
          label={t('products.form.code')}
          info={t('products.form.codeInfo')}
          errors={errors?.code}
          {...register('code')}
        />

        <br />

        <Input
          label={t('products.form.unit')}
          errors={errors?.unit}
          info={t('products.form.unitInfo')}
          required={true}
          {...register('unit', {
            required: {
              value: true,
              message: t('validation.required')
            }
          })}
        />

        <Input
          label={t('products.form.unitAmount') + ' € / ' + (unit || '-')}
          type="number"
          step=".001"
          info={t('products.form.unitAmountInfo')}
          required={true}
          errors={errors?.amount}
          {...register('amount', {
            valueAsNumber: true,
            required: {
              value: true,
              message: t('validation.required')
            }
          })}
        />

        <Controller
          name="is_vat_inclusive"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Pane marginTop={16} marginBottom={16} padding={8}>
                <label htmlFor="is_vat_inclusive">
                  <Text>{t('products.form.isVatInclusive')}</Text>
                </label>
                <Switch
                  id="is_vat_inclusive"
                  height={24}
                  onChange={e => onChange(e.currentTarget.checked)}
                  checked={value}
                />
              </Pane>
            )
          }}
        />

        <br />

        <Select
          label={t('products.form.vatCode')}
          type="number"
          info={t('products.form.vatCodeInfo')}
          errors={errors?.vat_code}
          required={true}
          {...register('vat_code', {
            valueAsNumber: true,
            required: {
              value: true,
              message: t('validation.required')
            }
          })}
        >
          {VAT_CODE_OPTIONS.filter(({ value }) => VAT_CODES_FOR_SALES.indexOf(value) !== -1).map(
            ({ label, value }) => (
              <option key={`option-${value}`} value={value}>
                {t(label)}
              </option>
            )
          )}
        </Select>

        {VAT_CODES_IGNORE_VAT_IN_SALES.indexOf(vatCode) === -1 && (
          <Select
            label={t('products.form.vatRate')}
            type="number"
            info={t('products.form.vatRateInfo')}
            errors={errors?.vat_rate}
            required={true}
            {...register('vat_rate', {
              valueAsNumber: true,
              shouldUnregister: true,
              required: {
                value: true,
                message: t('validation.required')
              }
            })}
          >
            {getVatOptions(business.country_config).map(option => (
              <option key={option.rate} value={option.rate}>{`${option.rate} %`}</option>
            ))}
          </Select>
        )}
      </form>
    </div>
  )
}
