import React from 'react'
import styled from 'styled-components'

interface Props {
  src?: string
  text?: string
  children?: React.ReactNode
}

export const Empty: React.FC<Props> = ({ src, text, children }) => {
  return (
    <StyledEmptyContainer>
      {src && <img src={src} />}
      {children ? children : <p>{text}</p>}
    </StyledEmptyContainer>
  )
}

const StyledEmptyContainer = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  img {
    width: 50%;
    max-width: 400px;
    margin-bottom: 2rem;
  }

  & > * {
    align-self: center;
  }

  p {
    color: ${({ theme }) => theme.colors.metalGray};
    font-size: ${({ theme }) => theme.fontSize.sm}rem;
    margin: 0;
  }
`
