import { isFunction } from '@utils'
import React, { createContext, useContext, useMemo, useState } from 'react'

interface StepContextValue {
  stepCount: number
  setStepCount: React.Dispatch<React.SetStateAction<number>>
  currentStepIndex: number
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
  nextStep: () => void
  previousStep: () => void
  isFirstStep: boolean
  isLastStep: boolean
}

const StepContext = createContext<StepContextValue>(undefined)

export const useSteps = (): StepContextValue => {
  const state = useContext(StepContext)
  if (!state) throw new Error('cannot use step context outside of provider')

  return state
}

interface WrapperProps {
  children: React.ReactNode | ((ctx: StepContextValue) => React.ReactNode)
}

export const StepperWrapper: React.FC<WrapperProps> = ({ children }) => {
  const [stepCount, setStepCount] = useState<number>(0)
  const [currentStepIndex, setCurrentStep] = useState<number>(0)

  const nextStep = () => {
    if (currentStepIndex < stepCount) {
      setCurrentStep(currentStepIndex + 1)
    }
  }

  const previousStep = () => {
    if (currentStepIndex !== 0) {
      setCurrentStep(currentStepIndex - 1)
    }
  }

  const contextValue = useMemo(
    () => ({
      stepCount,
      setStepCount,
      currentStepIndex,
      setCurrentStep,
      nextStep,
      previousStep,
      isFirstStep: currentStepIndex === 0,
      isLastStep: currentStepIndex === stepCount - 1
    }),
    [stepCount, currentStepIndex]
  )

  if (isFunction(children)) {
    return (
      <StepContext.Provider value={contextValue}>{children(contextValue)}</StepContext.Provider>
    )
  }
  return <StepContext.Provider value={contextValue}>{children}</StepContext.Provider>
}
