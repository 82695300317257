import { motion } from 'framer-motion'
import styled from 'styled-components'

export const StyledProductDetails = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.neutralWhite};
  display: flex;
  flex-direction: column;
`

export const DetailRow = styled(motion.div).attrs({ layout: 'position' })`
  flex: 1;
  margin-bottom: ${({ theme }) => theme.spacing.lg}rem;
`

export const StyledHeader = styled.p`
  color: ${({ theme }) => theme.colors.metalGray};
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  margin-bottom: ${({ theme }) => theme.spacing.xxs}rem;
  align-self: flex-start;
`

export const StyledValue = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  color: ${({ theme }) => theme.colors.neutralBlack};
  margin: 0;
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  align-items: center;
`

export const ScrollableContent = styled.div`
  flex: 1;
  overflow: auto;
  padding: ${({ theme }) => theme.spacing.md}rem;
`

export const ContentFooter = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.spacing.md}rem;
  gap: ${({ theme }) => theme.spacing.md}rem;
`

export const StyledValueContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.xs}rem;

  // Need to add so lines up with the date better
  & > span {
    margin-bottom: ${({ theme }) => theme.spacing.xxs}rem;
  }
`
