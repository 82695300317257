import styled from 'styled-components'
import { theme } from '@styles'
import { motion } from 'framer-motion'

interface Props {
  imgSrc: string
  children: React.ReactNode | string
  fillBackground?: boolean
  childrenStyle?: React.CSSProperties
}

export const ImageMessageBox: React.FC<Props> = ({
  imgSrc,
  children,
  fillBackground = false,
  childrenStyle
}) => (
  <StyledImageMessageBox fillBackground={fillBackground}>
    <StyledImg src={imgSrc} />
    <StyledContent style={childrenStyle}>{children}</StyledContent>
  </StyledImageMessageBox>
)

const StyledImageMessageBox = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 }
})<{ fillBackground: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.xl}rem;
  padding: ${({ theme }) => theme.spacing.xl}rem;
  border-radius: 1rem;
  color: ${({ theme }) => theme.colors.neutralBlack};
  margin: ${({ theme }) => theme.spacing.md}rem 0;

  ${({ fillBackground, theme }) =>
    fillBackground
      ? `background: ${theme.colors.neutralGray};`
      : `border: 3px solid ${theme.colors.neutralGray};`}

  .switch-wrapper > * {
    margin-bottom: 0;
  }
`

const StyledContent = styled.div`
  flex: 2;
  color: ${theme.colors.neutralBlack};
`

const StyledImg = styled.img`
  flex: 1;
  max-width: 160px;
  min-width: 140px;
`
