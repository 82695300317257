import React from 'react'
import { StartDebtCollectionMessage } from './trackerMessages/StartDebtCollectionMessage.tsx'
import { IInvoice } from '@query'
import { DebtCollectionStatusMessage } from './trackerMessages/DebtCollectionStatusMessage.tsx'
import { isOverdue } from '@root/utils/invoicingHelpers.ts'

interface Props {
  invoice: IInvoice
}

export const showDebtCollectionTracker = (data: IInvoice): boolean =>
  data?.is_registered_to_kravia || isOverdue(data)

export const DebtCollectionTracker: React.FC<Props> = ({ invoice }) => {
  const showStartMessage = !invoice?.is_registered_to_kravia && isOverdue(invoice)
  const showDebtCollectionStatus = invoice?.is_registered_to_kravia

  return (
    <>
      {showStartMessage && <StartDebtCollectionMessage invoice={invoice} />}
      {showDebtCollectionStatus && <DebtCollectionStatusMessage invoice={invoice} />}
    </>
  )
}
