import { Badge, DocumentWidget, MonetaryAmount, Tab, Tabs } from '@components'
import { ACCOUNT_TYPES, VAT_CODE_OPTIONS } from '@constants'
import { FeatureWrapper } from '@context'
import { IAccount } from '@query'
import { formatCurrency } from '@utils'
import { motion } from 'framer-motion'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaInfoCircle, FaStream } from 'react-icons/fa'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'

interface Props {
  account: IAccount
}

export const AccountOverview: React.FC<Props> = ({ account }) => {
  const [t] = useTranslation()

  const accountType = ACCOUNT_TYPES.find(option => option.value === account.type)
  const vatOption = VAT_CODE_OPTIONS.find(option => option.value === account.default_vat_code)

  return (
    <div key={'account-' + account.id}>
      <Tabs>
        <Tab key="info" icon={<FaInfoCircle />} name="Tiedot">
          <div>
            <DetailRow>
              <StyledHeader>{t('accounts.overview.name')}</StyledHeader>
              <StyledValue>{account.name || '-'}</StyledValue>
            </DetailRow>

            {account.description && (
              <DetailRow>
                <StyledHeader>{t('accounts.overview.description')}</StyledHeader>
                <AccountDecription>
                  <ReactMarkdown linkTarget="_blank">{account.description}</ReactMarkdown>
                </AccountDecription>
              </DetailRow>
            )}
          </div>

          <DetailRow>
            <StyledHeader>{t('accounts.overview.type')}</StyledHeader>
            <StyledValue>{t(accountType.labelKey)}</StyledValue>
          </DetailRow>

          {account.default_vat_code !== 3 && (
            <FeatureWrapper feature="vat_obliged">
              <DetailRow>
                <StyledHeader>{t('accounts.overview.vatCode')}</StyledHeader>
                <StyledValue>
                  <Badge type="success">{t(vatOption.label)}</Badge>
                </StyledValue>
              </DetailRow>

              <DetailRow>
                <StyledHeader>{t('accounts.overview.vatRate')}</StyledHeader>
                <StyledValue>
                  <Badge type="info">
                    {t('accounts.overview.vatValue', { vatRate: account.default_vat_rate })}
                  </Badge>
                </StyledValue>
              </DetailRow>
            </FeatureWrapper>
          )}

          <DetailRow>
            <StyledHeader>{t('accounts.overview.balance')}</StyledHeader>
            <StyledValue>
              <MonetaryAmount value={account.balance} showColor={true} />
            </StyledValue>
          </DetailRow>

          <FeatureWrapper feature="has_history">
            <DetailRow>
              <StyledHeader>{t('accounts.overview.openingBalance')}</StyledHeader>
              <StyledValue>{formatCurrency(account.opening_balance)}</StyledValue>
            </DetailRow>
          </FeatureWrapper>
        </Tab>

        <Tab key="docs" icon={<FaStream />} name="Tapahtumat">
          <DocumentWidget
            header={t('accounts.documentsTitle')}
            noData={t('accounts.documentsNoData')}
            accountId={account.id}
            height={400}
          />
        </Tab>
      </Tabs>
    </div>
  )
}

const DetailRow = styled(motion.div).attrs({ layout: 'position' })`
  flex: 1;
  margin-bottom: ${({ theme }) => theme.spacing.sm}rem;
`

const StyledHeader = styled.p`
  color: ${({ theme }) => theme.colors.metalGray};
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  margin-bottom: ${({ theme }) => theme.spacing.xxs}rem;
  align-self: flex-start;
`

const StyledValue = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  color: ${({ theme }) => theme.colors.neutralBlack};
  margin: 0;
`

const AccountDecription = styled.div`
  color: ${({ theme }) => theme.colors.neutralBlack};
  width: 100%;
`
