import React, { CSSProperties, ReactNode, RefObject, useEffect, useRef, useState } from 'react'

interface RenderItemFunc<T> {
  (item: T, style: CSSProperties, index: number): React.ReactNode
}

const SCROLL_BOTTOM_TRESHOLD = 150

interface PropsIn<T> {
  items: T[]
  itemSize: number
  overshootCount?: number
  onScrollBottom?: () => void
}

interface PropsOut<T> {
  scrollRef: RefObject<any>
  renderItems: (renderItem: RenderItemFunc<T>) => ReactNode
  scrollToIndex?: (number) => void
}

export const useVirtualList = <T,>({
  items,
  itemSize,
  overshootCount = 3,
  onScrollBottom
}: PropsIn<T>): PropsOut<T> => {
  const scrollRef = useRef(null)

  // Is scrolled to bottom flag
  const [isScrollBottom, setIsScrollBottom] = useState(false)

  const itemCount = items.length
  const [indexes, setIndexes] = useState({
    firstVisibleIndex: null,
    lastVisibleIndex: null
  })

  const onScroll = () => {
    if (!scrollRef.current) return
    const scrollHeight = scrollRef.current.clientHeight
    const scrollAmount = scrollRef.current.scrollTop
    const targetHeight = scrollRef.current.getBoundingClientRect().height

    const firstVisibleIndex = Math.max(Math.round(scrollAmount / itemSize) - overshootCount, 0)
    const lastVisibleIndex = Math.min(
      Math.round((scrollAmount + targetHeight) / itemSize) + overshootCount,
      itemCount
    )

    setIndexes({ firstVisibleIndex, lastVisibleIndex })
    setIsScrollBottom(
      scrollRef.current.scrollHeight - scrollRef.current.scrollTop - SCROLL_BOTTOM_TRESHOLD <=
        scrollHeight
    )
  }

  useEffect(() => {
    if (isScrollBottom && onScrollBottom) {
      onScrollBottom()
    }
  }, [isScrollBottom])

  useEffect(() => {
    if (!scrollRef.current) return

    onScroll()
    scrollRef.current.addEventListener('scroll', onScroll)
    return () => scrollRef.current?.removeEventListener('scroll', onScroll)
  }, [scrollRef.current, itemCount])

  const scrollToIndex = index => {
    if (!scrollRef.current) return
    const scrollTop = index * itemSize
    const targetHeight = scrollRef.current.getBoundingClientRect().height
    const minScroll = scrollRef.current.scrollTop
    const maxScroll = minScroll + targetHeight - itemSize

    if (scrollTop > maxScroll) {
      scrollRef.current.scrollTop = scrollTop - targetHeight + itemSize
      return
    }

    if (scrollTop < minScroll) {
      scrollRef.current.scrollTop = scrollTop
      return
    }
  }

  const renderItems = renderItem => {
    const headItems = items.slice(0, indexes.firstVisibleIndex)
    const seenItems = items.slice(indexes.firstVisibleIndex, indexes.lastVisibleIndex)
    const tailItems = items.slice(indexes.lastVisibleIndex)

    const style = { height: itemSize }
    return (
      <>
        <div key="head-items" style={{ height: headItems.length * itemSize }} />
        {seenItems.map((item, index) => renderItem(item, style, headItems.length + index))}
        <div key="tail-items" style={{ height: tailItems.length * itemSize }} />
      </>
    )
  }

  return {
    scrollRef,
    renderItems,
    scrollToIndex
  }
}
