import { useTheme } from '@hooks'
import { theme } from '@styles'
import { Heading, Pane } from 'evergreen-ui'
import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

interface Props {
  options: Option[]
  value: any
  onUpdate: (value: any) => void
}

export const SegmentedControl: React.FC<Props> = props => {
  const theme = useTheme()
  return (
    <StyledPane
      width="100%"
      borderRadius={10}
      background={theme.colors.mainBg}
      overflow="hidden"
      padding={4}
      marginBottom={4}
      tabIndex={0}
      outline="none"
      onKeyDown={(ev: any) => {
        const _getCurrentIndex = () => {
          return props.options.findIndex((el: any) => {
            return el.value === props.value
          })
        }

        const _addToIndex = (index: number, value: number) => {
          index += value
          return Math.max(Math.min(index, props.options.length - 1), 0)
        }

        if (ev.key === 'ArrowRight') {
          const index = _getCurrentIndex()
          const newIndex = _addToIndex(index, 1)
          props.onUpdate(props.options[newIndex].value)
        }

        if (ev.key === 'ArrowLeft') {
          const index = _getCurrentIndex()
          const newIndex = _addToIndex(index, -1)
          props.onUpdate(props.options[newIndex].value)
        }
      }}
    >
      <Pane display="flex" position="relative">
        {props.options.map(({ label, value }) => {
          const isSelected = props.value === value
          return (
            <Pane
              flex="1"
              key={`option-${label}`}
              textAlign="center"
              position="relative"
              cursor={isSelected ? 'initial' : 'pointer'}
              height={40}
              onClick={() => {
                if (!isSelected) {
                  props.onUpdate(value)
                }
              }}
            >
              {isSelected && <StyledBackground className="knob" />}
              <StyledText animate={{ opacity: isSelected ? 1 : 0.7 }}>
                <Heading size={300}>{label}</Heading>
              </StyledText>
            </Pane>
          )
        })}
        {/* No value selected... */}
        {props.value === undefined && <StyledBackground className="knob" />}
      </Pane>
    </StyledPane>
  )
}

interface Option {
  label: string
  value: any
}

const spring = {
  type: 'spring',
  stiffness: 400,
  damping: 30
}

const StyledPane = styled(Pane)`
  border: 1px solid transparent;
  transition: 0.2s;

  .knob {
    transition: background-color 0.2s;
  }

  &:hover,
  &:focus {
    background: white;
    border: 1px solid ${theme.colors.mainBg};

    .knob {
      background-color: ${theme.colors.mainBg};
    }
  }
`

const StyledText = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`

const StyledBackground = styled(motion.div).attrs({
  layoutId: 'segmented-bg',
  initial: false,
  transition: spring
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 10px;
  z-index: 1;
`
