import { motion } from 'framer-motion'
import styled from 'styled-components'

export const ModalDialog = styled(motion.div).attrs({
  variants: {
    init: { scale: 1.1, y: 0 },
    show: { scale: 1, y: 0 },
    exit: { scale: 0.95, y: 0 }
  },
  initial: 'init',
  animate: 'show',
  exit: 'exit'
})<{ width: number; height: number }>`
  width: 100vw;
  height: 100vh;
  z-index: 50;
  border-radius: 1rem;
  width: ${({ width }) => width || 375}px;
  max-height: ${({ height }) => height || 735}px;
  box-shadow: 0px 8px 22px rgba(27, 31, 35, 0.07);
  overflow: hidden;

  & > div {
    border-radius: 1rem;
  }
  @media only screen and (max-width: 376px) {
    max-height: 100vh;
  }
`

export const ModalOverlay = styled(motion.div).attrs({
  // NOTE: About pointer events. Sometimes framer-motion animates exit
  // animation but does not remove the element from the DOM. This causes
  // the modal to be still clickable but not visible. To prevent this
  // we set pointer events to none when the modal is not visible.
  variants: {
    initial: { opacity: 0, pointerEvents: 'none' },
    isOpen: { opacity: 1, pointerEvents: 'all' },
    exit: { opacity: 0, transition: { duration: 0.2 }, pointerEvents: 'none' }
  },
  initial: 'initial',
  animate: 'isOpen',
  exit: 'exit'
})`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9000;
  overflow-y: auto;
  background: ${({ theme }) => theme.colors.backdrop};
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 376px) {
    overflow: hidden;
  }
`
