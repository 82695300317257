import React, { memo } from 'react'
import nocfoSrc from '@assets/nocfo-white.svg'
import styled from 'styled-components'
import {
  FaCaretRight,
  FaCog,
  FaHistory,
  FaHome,
  FaListAlt,
  FaPaperPlane,
  FaThLarge,
  FaUsers
} from 'react-icons/fa'
import { motion } from 'framer-motion'
import { useQueryParam } from '@hooks'
import { useScreen } from '@utils'
import { BasicSideSheet } from '@components'
import { Position } from 'evergreen-ui'
import { BusinessMenu } from '@containers/BusinessMenu'
import { IBusiness } from '@query'
import { getDashboardPageUrl } from '@constants'
import { useHistory } from 'react-router-dom'
import { FaWallet } from 'react-icons/fa6'
import { useTranslation } from 'react-i18next'

interface Props {
  business: IBusiness
  activePage: string
  setActivePage: (value: string) => void
  hideMenuItems: boolean
}

export const SalaryMenu: React.FC<Props> = ({
  business,
  setActivePage,
  activePage,
  hideMenuItems
}) => {
  const history = useHistory()
  const { isMobile } = useScreen()
  const [isMenuOpen, setMenuOpen] = useQueryParam<string>('menu')
  const { t } = useTranslation()
  const MenuContent = memo(() => (
    <Menu>
      <BusinessMenu>
        <BusinessMenuWrapper>
          <FaThLarge />
          <span>{business?.name}</span>
        </BusinessMenuWrapper>
      </BusinessMenu>

      <BreadCrumbs>
        <Crumb onClick={() => history.push(getDashboardPageUrl(business.business_id))}>
          <FaHome /> <span>{'NOCFO'}</span>
        </Crumb>
        <FaCaretRight />
        <Crumb disabled>{t('salaries.menu.salaries')}</Crumb>
      </BreadCrumbs>
      <br />

      <MenuItems>
        {!hideMenuItems && (
          <>
            <MenuItem onClick={() => setActivePage('/home')}>
              <MenuItemContent>
                {activePage === '/home' && <SelectionBackground />}
                <FaHome />
                <span>{t('salaries.menu.home')}</span>
              </MenuItemContent>
            </MenuItem>

            <MenuItem onClick={() => setActivePage('/calc/draft')}>
              <MenuItemContent>
                {activePage === '/calc/draft' && <SelectionBackground />}
                <FaWallet />
                <span>{t('salaries.menu.pay')}</span>
              </MenuItemContent>
            </MenuItem>

            <MenuItem onClick={() => setActivePage('/workers')}>
              <MenuItemContent>
                {activePage === '/workers' && <SelectionBackground />}
                <FaUsers />
                <span>{t('salaries.menu.workers')}</span>
              </MenuItemContent>
            </MenuItem>

            <MenuItem onClick={() => setActivePage('/payroll')}>
              <MenuItemContent>
                {activePage === '/payroll' && <SelectionBackground />}
                <FaListAlt />
                <span>{t('salaries.menu.payroll')}</span>
              </MenuItemContent>
            </MenuItem>

            <MenuGroupHeader>{t('salaries.menu.reporting')}</MenuGroupHeader>

            <MenuItem onClick={() => setActivePage('/calc/paid')}>
              <MenuItemContent>
                {activePage === '/calc/paid' && <SelectionBackground />}
                <FaHistory />
                <span>{t('salaries.menu.paid')}</span>
              </MenuItemContent>
            </MenuItem>

            <MenuItem onClick={() => setActivePage('/reports')}>
              <MenuItemContent>
                {activePage === '/reports' && <SelectionBackground />}
                <FaPaperPlane />
                <span>{t('salaries.menu.reports')}</span>
              </MenuItemContent>
            </MenuItem>

            <MenuGroupHeader>{t('salaries.menu.settings')}</MenuGroupHeader>

            <MenuItem onClick={() => setActivePage('/settings')}>
              <MenuItemContent>
                {activePage === '/settings' && <SelectionBackground />}
                <FaCog />
                <span>{t('salaries.menu.settings')}</span>
              </MenuItemContent>
            </MenuItem>
          </>
        )}
      </MenuItems>

      <MenuFooter>
        <FooterImg src={nocfoSrc} />
        <FooterText>{t('salaries.featureName')}</FooterText>
      </MenuFooter>
    </Menu>
  ))

  if (isMobile)
    return (
      <BasicSideSheet
        heading="Menu"
        position={Position.LEFT}
        isShown={isMenuOpen === 'true'}
        onClose={() => setMenuOpen(null)}
      >
        <MenuContent />
      </BasicSideSheet>
    )

  return (
    <div style={{ width: 300, minWidth: 300, maxWidth: 300 }}>
      <MenuContent />
    </div>
  )
}

const Menu = styled.div`
  height: 100%;
  padding: ${({ theme }) => theme.spacing.md}rem;
  background: ${({ theme }) => theme.colors.menuBg};
  display: flex;
  flex-direction: column;
  position: relative;
  white-space: nowrap;
  overflow: auto;
  width: 100%;

  // hide scrollbar
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .menu-toggle {
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:hover {
    .menu-toggle {
      opacity: 1;
    }
  }
`

const MenuItem = styled.button.attrs({
  type: 'button'
})`
  background: none;
  outline: none !important;
  border: none;
  position: relative;
  display: flex;
  border-radius: 1rem;
  color: white;
  height: 3rem;
  width: 100%;
`

const MenuItems = styled.div`
  flex: 1;
`

const MenuItemContent = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.sm}rem ${({ theme }) => theme.spacing.md}rem;
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  color: ${({ theme }) => theme.colors.neutralWhite};
  gap: ${({ theme }) => theme.spacing.sm}rem;

  & > * {
    align-self: center;
  }

  svg {
    color: ${({ theme }) => theme.colors.neutralGray};
    width: ${({ theme }) => theme.fontSize.md}rem;
    height: ${({ theme }) => theme.fontSize.md}rem;
    min-width: ${({ theme }) => theme.fontSize.md}rem;
    min-height: ${({ theme }) => theme.fontSize.md}rem;
  }

  &:hover {
    opacity: 0.8;
  }
`

const SelectionBackground = styled(motion.div).attrs({
  layoutId: 'salaxy-menu-selection-background',
  transition: { type: 'spring', duration: 0.6 }
})`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #00000040;
  border-radius: 1rem;
`

const MenuFooter = styled.div`
  padding: ${({ theme }) => theme.spacing.sm}rem ${({ theme }) => theme.spacing.md}rem;
`

const FooterImg = styled(motion.img)`
  align-self: flex-end;
  width: 120px;
  max-height: 30px;
  margin-left: -2px;
  margin-bottom: -2px;
`

const FooterText = styled.div`
  margin-left: 5px;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutralGray};
  letter-spacing: 1px;
`

const BusinessMenuWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;
  padding: ${({ theme }) => theme.spacing.sm}rem ${({ theme }) => theme.spacing.md}rem;
  color: ${({ theme }) => theme.colors.neutralGray};
  width: 100%;
  display: flex;
  align-items: flex-start;
  font-weight: 500;
  cursor: pointer;
  gap: 1rem;

  svg {
    color: ${({ theme }) => theme.colors.neutralGray};
    width: ${({ theme }) => theme.fontSize.md}rem;
    height: ${({ theme }) => theme.fontSize.md}rem;
    min-width: ${({ theme }) => theme.fontSize.md}rem;
    min-height: ${({ theme }) => theme.fontSize.md}rem;
  }
`

const MenuGroupHeader = styled.h4`
  height: 1rem;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  font-weight: 500;
  letter-spacing: 1px;
  margin-top: ${({ theme }) => theme.spacing.md}rem;
  padding: 0 ${({ theme }) => theme.spacing.md}rem;
  color: ${({ theme }) => theme.colors.metalGray};
`

const BreadCrumbs = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xxs}rem;
  padding: ${({ theme }) => theme.spacing.xs}rem;
  border-radius: 1rem;
  color: ${({ theme }) => theme.colors.neutralGray};
  align-items: center;
  background: ${({ theme }) => theme.colors.metalGray}88;
`

const Crumb = styled.button`
  background: none;
  border: none;
  outline: none;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.neutralGray};
  border-radius: 0.4rem;
  padding: ${({ theme }) => theme.spacing.xxs}rem ${({ theme }) => theme.spacing.xs}rem;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  display: flex;

  &:disabled {
    opacity: 0.6;
  }
`
