import welcomeImg from '@assets/undraw/undraw_qa_engineers_dg-5-p.svg'
import { BasicPage, Button, Checkbox, TextTooltip } from '@components'
import { createDemoBusiness, IBusiness } from '@query'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FaQuestionCircle } from 'react-icons/fa'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

export const DemoLandingPage: React.FC = () => {
  const [t] = useTranslation()
  const history = useHistory()
  const queryClient = useQueryClient()
  const [useDemoData, setUseDemoData] = useState(true)

  const mutationOptions = {
    onSuccess: async data => {
      await queryClient.invalidateQueries('user')
      await queryClient.invalidateQueries(['businesses'])

      history.push(`/${data.business_id}/`)
    }
  }

  const onboardMutation = useMutation<Partial<IBusiness>, unknown, boolean>(
    useMockData => createDemoBusiness({ useMockData }),
    mutationOptions
  )

  return (
    <BasicPage title={t('demo.welcome')} imageUrl={welcomeImg}>
      <PageContent>
        <h2>{t('demo.start')}</h2>
        <p>{t('demo.description1')}</p>
        <p>{t('demo.description2')}</p>
        <ActionWrapper>
          <Checkbox
            id="show-accounts-checkbox"
            checked={useDemoData}
            onChange={e => setUseDemoData(e.target.checked)}
            label={
              <CheckboxTooltipWrapper>
                {t('demo.checkbox.label')}
                <TextTooltip tooltip={t('demo.checkbox.tooltip')}>
                  <FaQuestionCircle />
                </TextTooltip>
              </CheckboxTooltipWrapper>
            }
          ></Checkbox>

          <ButtonWrapper>
            <Button
              onClick={async () => {
                const promise = onboardMutation.mutateAsync(useDemoData)

                toast.promise(promise, {
                  loading: t('demo.promise.loading'),
                  success: t('demo.promise.success'),
                  error: t('demo.promise.error')
                })
              }}
              showSpinner={onboardMutation.isLoading}
              disabled={onboardMutation.isLoading}
              type="submit"
              intent="success"
              form="demo-form"
            >
              {t('demo.buttonText')}
            </Button>
          </ButtonWrapper>
        </ActionWrapper>
      </PageContent>
    </BasicPage>
  )
}

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing.md}rem;
  flex-direction: column;
  text-align: left;

  * {
    align-self: flex-start;
  }
`
const CheckboxTooltipWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  align-items: center;
`

const ActionWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.sm}rem;
`
