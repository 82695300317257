import { Badge } from '@components'
import { useBusinessContext } from '@context'
import { useQueryParam, useVirtualList } from '@hooks'
import { IContact } from '@query'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaBriefcase, FaUser } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
interface Props {
  contacts: IContact[]
  onScrollBottom: () => void
}

interface ContactItem {
  type: 'contact'
  value: IContact
  isFirstOfType: boolean
  isLastOfType: boolean
}

interface BadgeItem {
  type: 'badge'
  value: string
}

type ContactsAndBadges = (BadgeItem | ContactItem)[]

const insertAplhabets = (contacts: IContact[]): ContactsAndBadges => {
  const contactsAndBadges = []
  let previousLetter = ''

  contacts.forEach((contact, index) => {
    const lastContact = contacts[index - 1]
    const nextContact = contacts[index + 1]

    const thisLetter = (contact.name?.charAt(0) || '').toUpperCase()
    const lastLetter = (lastContact?.name?.charAt(0) || '').toUpperCase()
    const nextLetter = (nextContact?.name?.charAt(0) || '').toUpperCase()

    const isFirstOfType = thisLetter !== lastLetter
    const isLastOfType = thisLetter !== nextLetter

    if (thisLetter !== previousLetter) {
      contactsAndBadges.push({
        type: 'badge',
        value: thisLetter
      })
      previousLetter = thisLetter
    }

    contactsAndBadges.push({
      type: 'contact',
      value: contact,
      isFirstOfType,
      isLastOfType
    })
  })

  return contactsAndBadges
}

export const ContactsList: React.FC<Props> = ({ contacts, onScrollBottom }) => {
  const [documentId] = useQueryParam('id', value => parseInt(value, 10))
  const items = insertAplhabets(contacts)

  const { scrollRef, renderItems } = useVirtualList({
    items,
    itemSize: 70,
    onScrollBottom
  })

  return (
    <ContactListWrapper ref={scrollRef}>
      <AnimatePresence>
        {renderItems((item, style, index) =>
          item.type === 'contact' ? (
            <motion.div key={item.value.id} layout="position">
              <ContactItem
                contact={item.value}
                style={style}
                isFirst={(item as ContactItem).isFirstOfType}
                isLast={(item as ContactItem).isLastOfType}
                isSelected={documentId === item.value.id}
              />
            </motion.div>
          ) : (
            <AlphabetBadge key={`${item.value}-${index}`} style={style}>
              <div>{item.value}</div>
            </AlphabetBadge>
          )
        )}
      </AnimatePresence>
    </ContactListWrapper>
  )
}

interface ContactItemProps {
  contact: IContact
  style: React.CSSProperties
  isFirst: boolean
  isLast: boolean
  isSelected: boolean
}

const ContactItem: React.FC<ContactItemProps> = ({
  contact,
  style,
  isFirst,
  isLast,
  isSelected
}) => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  return (
    <ListItem
      to={`/${businessId}/contacts?id=${contact.id}`}
      style={style}
      className={classNames({
        isFirst,
        isLast,
        isSelected
      })}
    >
      <div>{contact.name}</div>
      <IconWrapper>
        {contact.type === 'UNSET' && <Badge type="warning">{t('contacts.completeInfo')}</Badge>}
        {contact.type === 'BUSINESS' && <FaBriefcase className="business" />}
        {contact.type === 'PERSON' && <FaUser className="person" />}
      </IconWrapper>
    </ListItem>
  )
}

const ListItem = styled(Link)`
  background: white;
  padding: ${({ theme }) => theme.spacing.lg}rem;
  display: flex;
  cursor: pointer;
  text-decoration: none !important;
  color: ${({ theme }) => theme.colors.neutralBlack} !important;

  div {
    align-self: center;
  }

  div:first-of-type {
    flex: 1;
  }

  &:hover,
  &.isSelected {
    background: ${({ theme }) => theme.colors.defaultHover};
  }

  &.isFirst {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  &.isLast {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  &:not(.isLast) {
    border-bottom: 1px solid #f6f7f9;
  }
`

const IconWrapper = styled.div`
  display: flex;

  & > * {
    align-self: center;
  }

  svg {
    fill: ${({ theme }) => theme.colors.iconGray};

    &.unset {
      fill: ${({ theme }) => theme.colors.nocfoRed};
    }
  }
`

const ContactListWrapper = styled.div`
  overflow: auto;
  flex: 1;
  height: 100%;
`

const AlphabetBadge = styled(motion.div).attrs({ layout: 'position' })`
  display: flex;
  justify-content: center;

  & > div {
    align-self: center;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.metalGray};
    font-weight: 500;
  }
`
