import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type NotificationFilter = 'all' | 'new'

interface Props {
  onFilter: (value: NotificationFilter) => void
  value: NotificationFilter
}

export const NotificationFeedFilter: React.FC<Props> = ({ value, onFilter }) => {
  const [t] = useTranslation()
  const handleOnChange = e => {
    onFilter(e.currentTarget.value)
  }

  return (
    <StyledSelect onChange={handleOnChange}>
      <option value="new" selected={value === 'new'}>
        {t('notificationFeed.filter.new')}
      </option>
      <option value="all" selected={value === 'all'}>
        {t('notificationFeed.filter.all')}
      </option>
    </StyledSelect>
  )
}

const StyledSelect = styled.select`
  background: transparent;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.metalGray};
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  align-items: center;
  outline: none;
  border: none;

  &:hover {
    text-decoration: underline;
  }
`
