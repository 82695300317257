import { MutableRefObject, useCallback, useEffect } from 'react'

export const useOnClickOutside = (
  ref: MutableRefObject<any>,
  handler: (ev: MouseEvent) => void
): void => {
  const handleOnClick = useCallback(
    (event: MouseEvent) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      handler(event)
    },
    [handler, ref.current]
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleOnClick)
    document.addEventListener('touchstart', handleOnClick)

    return () => {
      document.removeEventListener('mousedown', handleOnClick)
      document.removeEventListener('touchstart', handleOnClick)
    }
  }, [handleOnClick])
}
