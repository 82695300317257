import { Button, PricingTableModal } from '@components'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCheck } from 'react-icons/fa'
import { MdOutlineRocketLaunch } from 'react-icons/md'
import styled from 'styled-components'

interface Props {
  header: string
  subHeader?: string
  features: string[]
  maxWidth?: string
}

export const TeaserView: React.FC<Props> = ({ header, subHeader, features, maxWidth = '100%' }) => {
  const [t] = useTranslation()
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <StyledTeaserView maxWidth={maxWidth}>
        <StyledIcon>
          <MdOutlineRocketLaunch />
        </StyledIcon>
        <StyledContent>
          <PlusBadge>{t('teaser.nocfoPlus')}</PlusBadge>

          <h1>{header}</h1>
          {subHeader && <p>{subHeader}</p>}

          <FeatureWrapper>
            {features.map(feature => (
              <Feature key={feature}>
                <FaCheck /> <span>{feature}</span>
              </Feature>
            ))}
            <MuchMore href={t('link.pricing')}>{t('teaser.andMuchMore')}</MuchMore>
          </FeatureWrapper>
          <StyledFooter>
            <a href={t('link.pricing')} target="_blank" rel="noreferrer">
              <Button type="button" isSecondary>
                {t('teaser.compare')}
              </Button>
            </a>
            <Button intent="primary" onClick={() => setShowModal(true)}>
              {t('teaser.startTrial')}
            </Button>
          </StyledFooter>
        </StyledContent>
      </StyledTeaserView>

      <PricingTableModal isVisible={showModal} handleOnClose={() => setShowModal(false)} />
    </>
  )
}

const PlusBadge = styled.span`
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  font-weight: bold;
  padding: 0.15rem 0.3rem;
  background: ${({ theme }) => theme.colors.nocfoBlue};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.neutralWhite};
  width: fit-content;
`

const StyledTeaserView = styled.div<{ maxWidth: string }>`
  flex-wrap: wrap;
  border: 3px solid ${({ theme }) => theme.colors.nocfoBlue};
  border-radius: 1rem;
  display: flex;
  padding: ${({ theme }) => theme.spacing.lg}rem;
  gap: ${({ theme }) => theme.spacing.lg}rem;
  overflow: visible;
  background: white;
  max-width: ${({ maxWidth }) => maxWidth};
`

const StyledIcon = styled.div`
  width: 4rem;

  svg {
    color: ${({ theme }) => theme.colors.nocfoBlue};
    width: 4rem;
    height: 4rem;
  }
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  color: ${({ theme }) => theme.colors.neutralBlack};
  gap: ${({ theme }) => theme.spacing.md}rem;
  overflow: visible;

  h1 {
    font-size: ${({ theme }) => theme.fontSize.lg}rem;
  }
`

const Feature = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs}rem;

  svg {
    fill: ${({ theme }) => theme.colors.nocfoBlue};
  }
`

const MuchMore = styled.a.attrs({
  target: '_blank',
  rel: 'noreferrer'
})`
  color: ${({ theme }) => theme.colors.neutralBlack};
  padding-left: 1.5rem;
`

const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.sm}rem;
`

const StyledFooter = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.nocfoBlue}22;
  padding-top: ${({ theme }) => theme.spacing.lg}rem;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.md}rem;
`
