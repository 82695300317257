import { motion } from 'framer-motion'
import React, { useEffect, useMemo, useState } from 'react'
import { Blurhash } from 'react-blurhash'
import { defaultStyles, FileIcon } from 'react-file-icon'
import styled from 'styled-components'

interface Props {
  thumb?: string
  filetype: string
  blurhash: string
  size?: number
}

const useProgressiveImage = src => {
  const [sourceLoaded, setSourceLoaded] = useState(null)

  useEffect(() => {
    const img = new Image()
    img.src = src
    img.onload = () => setSourceLoaded(src)
  }, [src])

  return sourceLoaded
}

export const FileThumbnail: React.FC<Props> = ({ thumb, filetype, size = 38, blurhash }) => {
  const [type, extension] = filetype.split('/')

  // Django updates S3 query token on every request. This is workaround for image
  // flickering when react-query state updates. Let's ignore token and rely on
  // using cached token.
  const cachedThumb = useMemo(() => thumb, [thumb?.split('?')[0], new Date().getMinutes()])
  const loaded = useProgressiveImage(cachedThumb)
  const isImage = type.startsWith('image')

  return isImage ? (
    <FileIconWrapper size={size}>
      {blurhash && (
        <Blurhash
          hash={blurhash}
          width={size}
          height={size}
          style={{ position: 'absolute', top: 0, left: 0, width: size, height: size }}
        />
      )}
      {loaded && <FileThumbnailContainer size={size} url={cachedThumb} />}
    </FileIconWrapper>
  ) : (
    <FileIconWrapper className="file-icon" size={size}>
      <FileIcon type={type} extension={extension} {...defaultStyles[extension]} />
    </FileIconWrapper>
  )
}

const FileIconWrapper = styled.div<{ size: number }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 20%;
  overflow: hidden;

  &.file-icon {
    height: fit-content;
  }
`

const FileThumbnailContainer = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 1 }
})<{ url: string; size: number }>`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background-image: url(${({ url }) => url});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.colors.neutralGray};
`
