import { motion } from 'framer-motion'
import { FaBook, FaSpinner } from 'react-icons/fa'
import styled from 'styled-components'

export const DropdownWrapper = styled.div`
  position: relative;
`

export const Dropdown = styled(motion.div).attrs({
  layout: 'position',
  variants: {
    hidden: { y: 10, opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
      transition: {}
    }
  },
  transition: { duration: 0.2 },
  initial: 'hidden',
  animate: 'show',
  exit: 'hidden'
})`
  position: absolute;
  width: 100%;
  max-width: 100%;
  min-width: 300px;
  background: white;
  padding: 0.8rem;
  z-index: 9;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
`

export const Header = styled.div`
  display: flex;
  font-weight: 500;
  margin: 0.6rem;
`

export const HeaderText = styled.div`
  flex: 1;
`

export const HeaderIcon = styled(FaBook)`
  align-self: center;
  margin-right: 0.4rem;
  fill: ${({ theme }) => theme.colors.iconGrayDark};
`

export const HeaderDetail = styled.div`
  font-size: 0.8rem;
  margin: 0.6rem;
  margin-top: 0;
  color: ${({ theme }) => theme.colors.metalGray};
`

export const ItemWrapper = styled.div`
  max-height: 240px;
  height: 240px;
  overflow: auto;
`

export const Item = styled.div`
  display: flex;
  padding: 0.3rem 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;

  &.keyboard-focused {
    background: ${({ theme }) => theme.colors.mainBg};
  }

  svg {
    fill: ${({ theme }) => theme.colors.nocfoGreen};
  }
`

export const ItemColumn = styled.div`
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.description {
    flex: 1;
  }

  &.number {
    min-width: 2.6rem;
    font-size: 0.8rem;
    margin-right: 0.8rem;
    color: ${({ theme }) => theme.colors.metalGray};
  }

  &.balance {
    margin-left: 0.8rem;
    text-align: right;
    font-family: 'Roboto Mono', monospace;
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.metalGray};
  }
`

export const StatusSpinnerWrapper = styled(motion.div).attrs({
  variants: {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
  transition: { duration: 0.15 }
})`
  width: 1rem;
  height: 1rem;
  align-self: center;
  display: flex;
`

export const StatusSpinner = styled(FaSpinner).attrs({ className: 'spin' })`
  width: 1rem;
  height: 1rem;
  color: ${({ theme }) => theme.colors.iconGray};
`

export const NoData = styled.div`
  color: ${({ theme }) => theme.colors.metalGray};
  padding: 0.3rem 0.5rem;
`
