import { motion } from 'framer-motion'
import styled from 'styled-components'

export const FormMainHeader = styled.h1`
  font-size: 1rem;
`

export const FormHeader = styled.h2`
  font-size: 1rem;
  margin-bottom: 0.8rem;
`

export const MainSelectWrapper = styled.div`
  margin-bottom: 1rem;
`

export const Select = styled.select`
  width: 100%;
  border-radius: 1rem;
  background: ${({ theme }) => theme.colors.neutralGray};
  border: none;
  padding: 8px;
`

export const Option = styled.option``

export const FormWrapper = styled(motion.div)`
  width: 100%;
  border: 4px solid ${({ theme }) => theme.colors.neutralGray};
  border-radius: 1rem;
  padding: 1rem;
`

export const FormRowWrapper = styled(motion.div)`
  display: flex;
  width: 100%;
  margin-bottom: 8px;

  & > * {
    flex: 1;
    margin: 0px;
    margin-right: 4px;
  }
`

export const DeleteButtonWrapper = styled.div`
  display: flex;
  max-width: 20px;
  margin: 8px;
  cursor: pointer;

  &[data-is-disabled='true'] {
    cursor: not-allowed;
    opacity: 0.6;
  }

  & > svg {
    width: 1rem;
    height: 1rem;
    align-self: center;
    fill: ${({ theme }) => theme.colors.iconGray};
  }
`

export const FormFooterWrapper = styled(motion.div).attrs({ layout: true })`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
`

export const CheckboxWrapper = styled(motion.div)`
  display: flex;
  width: 100%;

  & > * {
    align-self: center;
    margin: 0;
  }

  & > label {
    margin-left: 0.4rem;
  }
`
