import { Button } from '@components'
import { useBusinessContext } from '@context'
import { deleteInvitation, fetchInvitations } from '@query'
import React from 'react'
import { FaTrashAlt } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'

export const InvitationTable: React.FC = () => {
  const queryClient = useQueryClient()
  const { businessId } = useBusinessContext()
  const { data } = useQuery([businessId, 'invitations'], () =>
    fetchInvitations({ businessId }, { page_size: 9999 })
  )
  const invitations = data?.results || []

  const deleteMutation = useMutation<void, void, number>(
    invitationId => deleteInvitation({ businessId, invitationId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([businessId, 'invitations'])
      }
    }
  )

  return (
    <>
      {invitations.map(invitation => (
        <StyledInvitation key={`invitation-${invitation.id}`}>
          <div>{invitation.email}</div>
          <div style={{ flex: 1 }} />
          <Button
            onClick={() => deleteMutation.mutate(invitation.id)}
            disabled={deleteMutation.isLoading}
            showSpinner={deleteMutation.isLoading}
            icon={<FaTrashAlt />}
            isSecondary={true}
            intent="danger"
          ></Button>
        </StyledInvitation>
      ))}
    </>
  )
}

const StyledInvitation = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutralBlack};
`
