import { IDraggableItem } from '@components'
import { getFilesPageUrl } from '@constants'
import { useBusinessContext } from '@context'
import { IFolderMoveAction, ISubFolder, moveItemsToFolder } from '@query'
import cn from 'classnames'
import React, { Fragment } from 'react'
import { useDrop } from 'react-dnd'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { HiChevronRight } from 'react-icons/hi'
import { useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface Props {
  breadcrumbs: ISubFolder[]
  searchValue?: string
}

export const BreadCrumb: React.FC<Props> = ({ breadcrumbs, searchValue }) => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()

  if (searchValue) {
    return (
      <BreadCrumbWrapper>
        <StyledCrumb to="#">
          {t('files.browser.breadcrumb.searchHint', { searchValue })}
        </StyledCrumb>
      </BreadCrumbWrapper>
    )
  }

  return (
    <BreadCrumbWrapper>
      <Crumb
        id={undefined}
        name={t('files.browser.breadcrumb.home')}
        to={getFilesPageUrl(businessId)}
        key="root"
      />

      {breadcrumbs.map(({ id, name }) => (
        <Fragment key={`crumb-${id}`}>
          <HiChevronRight size={16} style={{ minWidth: 16, minHeight: 16 }} />
          <Crumb
            id={id}
            name={name}
            to={getFilesPageUrl(businessId, { folder: id, id: null, idType: null })}
            key={id}
          />
        </Fragment>
      ))}
    </BreadCrumbWrapper>
  )
}

const Crumb: React.FC<{ id?: number; name: string; to: string }> = ({ id, name, to }) => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const queryClient = useQueryClient()

  const moveMutation = useMutation<unknown, unknown, IFolderMoveAction>(
    data => moveItemsToFolder({ businessId }, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([businessId, 'files'])
        await queryClient.invalidateQueries([businessId, 'folders'])
      }
    }
  )

  const [{ isOver }, dropRef] = useDrop(
    () => ({
      accept: 'file-item',
      collect: monitor => ({
        isOver: monitor.isOver()
      }),
      drop: (items: IDraggableItem[]) => {
        const promise = moveMutation.mutateAsync({
          target_folder_id: id,
          items: items.map(item => ({
            id: item.item.id,
            type: item.type
          }))
        })

        toast.promise(promise, {
          loading: t('files.browser.moving'),
          success: t('files.browser.moveSuccess'),
          error: t('errors.oho')
        })
      }
    }),
    []
  )

  return (
    <StyledCrumb ref={dropRef} to={to} className={cn({ 'is-over': isOver })}>
      {name}
    </StyledCrumb>
  )
}

const BreadCrumbWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  padding: ${({ theme }) => theme.spacing.sm}rem;
  align-items: center;
  color: ${({ theme }) => theme.colors.metalGray};
  height: 2rem;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledCrumb = styled(Link)`
  color: ${({ theme }) => theme.colors.metalGray};
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  text-decoration: none !important;
  border: 2px solid transparent;
  border-radius: 0.2rem;

  &.is-over {
    border: 2px solid ${({ theme }) => theme.colors.nocfoBlue};
  }
`
