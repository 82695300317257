import { BlueprintType } from '@constants'
import { useFormContext, useWatch } from 'react-hook-form'

export const useBlueprintAccountEffect = (forAccountId?: number): number => {
  const { control } = useFormContext()
  const blueprint = useWatch({ control, name: 'blueprint' })
  const blueprintType = useWatch({ control, name: 'blueprint_type' })

  const totalExp =
    blueprint?.expense_entries
      ?.filter(({ account_id }) => !account_id || account_id !== forAccountId)
      .reduce((acc, { amount }) => acc + (amount ? Math.round(amount * 100) : 0), 0) || 0

  const totalDeb =
    blueprint?.debet_entries
      ?.filter(({ account_id }) => !account_id || account_id !== forAccountId)
      .reduce((acc, { amount }) => acc + (amount ? Math.round(amount * 100) : 0), 0) || 0

  const totalCre =
    blueprint?.credit_entries
      ?.filter(({ account_id }) => !account_id || account_id !== forAccountId)
      .reduce((acc, { amount }) => acc + (amount ? Math.round(amount * 100) : 0), 0) || 0

  if (blueprintType === BlueprintType.SALES) {
    return (totalCre - totalExp) / 100
  }

  if (blueprintType === BlueprintType.PURCHASE) {
    return -totalDeb / 100
  }

  return (totalCre - totalDeb) / 100
}
