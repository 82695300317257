import {
  AnimatedContentLoader,
  DoubleColumnLayout,
  DoubleColumnLayoutColumn,
  TeaserView
} from '@components'
import { getProductPageUrl } from '@constants'
import { FeatureWrapper, useBusinessContext, useFeature } from '@context'
import { useQueryParam } from '@hooks'
import { collectFromPages, fetchProducts, IProduct } from '@query'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInfiniteQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ProductActionBar } from './ProductActionBar'
import { ProductDetails } from './ProductDetails'
import { ProductList } from './ProductList'

export const ProductPage: React.FC = () => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const [selectedId] = useQueryParam<number>('id', value => parseInt(value, 10))
  const history = useHistory()
  const [search, setSearch] = useState('')
  const showTeaserView = !useFeature('feature_invoicing')

  const onClose = () => {
    const url = getProductPageUrl(businessId)
    history.push(url)
  }

  const { data, isLoading } = useInfiniteQuery(
    [businessId, 'products', { search }],
    () => fetchProducts({ businessId }, { search }),
    {
      enabled: !showTeaserView
    }
  )
  const products = collectFromPages<IProduct>(data)

  return (
    <DoubleColumnLayout
      header={t('products.mainTitle')}
      isRightVisible={Boolean(selectedId)}
      onRightClose={onClose}
    >
      <FeatureWrapper feature="feature_invoicing" reverse>
        <DoubleColumnLayoutColumn>
          <TeaserView
            header={t('teaser.invoicing.header')}
            subHeader={t('teaser.invoicing.subHeader')}
            features={[
              t('teaser.feature.1'),
              t('teaser.feature.2'),
              t('teaser.feature.4'),
              t('teaser.feature.5')
            ]}
            maxWidth="800px"
          />
        </DoubleColumnLayoutColumn>
      </FeatureWrapper>

      <FeatureWrapper feature="feature_invoicing">
        <DoubleColumnLayoutColumn>
          <PageContentWrapper>
            <ProductActionBar onSearch={value => setSearch(value)} />

            <ProductListWrapper>
              <AnimatedContentLoader
                isLoading={isLoading}
                isEmpty={products.length === 0}
                isEmptyDescription={t('products.isEmptyDescription')}
              >
                {products && <ProductList products={products} />}
              </AnimatedContentLoader>
            </ProductListWrapper>
          </PageContentWrapper>
        </DoubleColumnLayoutColumn>
        <DoubleColumnLayoutColumn innerKey={selectedId} isRight={true}>
          {selectedId && (
            <ProductDetails key={selectedId} productId={selectedId} onClose={onClose} />
          )}
        </DoubleColumnLayoutColumn>
      </FeatureWrapper>
    </DoubleColumnLayout>
  )
}
const PageContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ProductListWrapper = styled.div`
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`
