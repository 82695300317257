import { breakpointNumberValues } from '@styles'
import { useCallback, useEffect, useState } from 'react'

interface Props {
  width: number
  height: number
  xxs: boolean
  xs: boolean
  sm: boolean
  md: boolean
  lg: boolean
  xl: boolean
  mobile: boolean
  isMobile: boolean
  isDesktop: boolean
  breakPoints: typeof breakpointNumberValues
}

export const useScreen = (): Props => {
  // Set initial values
  const [size, setSize] = useState({
    width: document.body.clientWidth,
    height: document.body.clientHeight
  })

  const listener = useCallback(
    () =>
      setSize({
        width: document.body.clientWidth,
        height: document.body.clientHeight
      }),
    []
  )

  // Listen future resize events
  useEffect(() => {
    window.addEventListener('resize', listener, false)
    return () => window.removeEventListener('resize', listener, false)
  }, [])

  return {
    width: size.width,
    height: size.height,
    xxs: size.width < breakpointNumberValues.xs,
    xs: size.width < breakpointNumberValues.sm,
    sm: size.width < breakpointNumberValues.md,
    md: size.width < breakpointNumberValues.lg,
    lg: size.width < breakpointNumberValues.xl,
    xl: size.width >= breakpointNumberValues.xxl,
    mobile: size.width < breakpointNumberValues.md,
    isMobile: size.width < breakpointNumberValues.md,
    isDesktop: size.width > breakpointNumberValues.md,
    breakPoints: breakpointNumberValues
  }
}

export default useScreen
