import { useBusinessContext } from '@context'
import { autoUpdate, useFloating } from '@floating-ui/react-dom-interactions'
import { useUnseenNotificationCount } from '@query'
import cn from 'classnames'
import { motion } from 'framer-motion'
import React from 'react'
import { FaBell } from 'react-icons/fa'
import styled from 'styled-components'

export const NotificationBell: React.FC = () => {
  const { data: business } = useBusinessContext()
  const unseenCount = useUnseenNotificationCount(business?.id)
  const hasNotifications = unseenCount > 0

  const { x, y, reference, floating, strategy } = useFloating({
    whileElementsMounted: autoUpdate,
    placement: 'bottom'
  })

  return (
    <>
      <StyledBellIcon ref={reference} className={cn({ hasNotifications })}>
        <AnimatedBell animate={hasNotifications ? 'alert' : 'start'}>
          <FaBell />
        </AnimatedBell>
      </StyledBellIcon>
      {hasNotifications && (
        <UnseenBadge
          ref={floating}
          style={{
            top: y || 0,
            left: x || 0,
            position: strategy
          }}
        >
          <span>{unseenCount}</span>
        </UnseenBadge>
      )}
    </>
  )
}

const _getRockingAnimationRotations = (max: number, swings = 2) => [
  0,
  ...Array(swings)
    .fill(max)
    .map((amount, index) => [amount / (index + 1), -amount / (index + 1)])
    .flat(),
  0
]

const StyledBellIcon = styled(motion.button)`
  position: relative;
  outline: none;
  border: none;
  border-radius: 100px;
  width: 2.2rem;
  height: 2.2rem;
  background: ${({ theme }) => theme.colors.nocfoBlue}00;

  svg {
    width: 1.4rem;
    height: 1.4rem;
    fill: ${({ theme }) => theme.colors.metalGray};
  }
`

const AnimatedBell = styled(motion.div).attrs({
  style: {
    originX: 0.5,
    originY: 0.2
  },
  variants: {
    alert: {
      rotate: _getRockingAnimationRotations(25, 2)
    }
  },
  transition: {
    repeat: 10,
    repeatDelay: 2,
    duration: 2
  }
})``

const UnseenBadge = styled(motion.span)`
  pointer-events: none;
  position: relative;
  background: ${({ theme }) => theme.colors.nocfoBlue};
  min-width: 1.2rem;
  padding: 0.1rem 0.3rem;
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  font-weight: bold;
  border-radius: 100px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
`
