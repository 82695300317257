import { InvoiceStatus } from '@root/constants'
import { IInvoice } from '@root/query'

export const isOverdue = (data: IInvoice): boolean => {
  return (
    data?.is_past_due &&
    data?.status !== InvoiceStatus.DRAFT &&
    data?.status !== InvoiceStatus.PAID &&
    data?.status !== InvoiceStatus.CREDIT_LOSS
  )
}
