import { Button, ModalV2 } from '@components'
import { IAccount } from '@query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AccountForm } from './AccountForm'
import { useQueryParam } from '@root/hooks'

interface Props {
  account?: IAccount
  isVisible: boolean
  handleOnClose: () => void
}

export const AccountFormModal: React.FC<Props> = ({ account, isVisible, handleOnClose }) => {
  const [t] = useTranslation()
  const [, setActiveAccount] = useQueryParam('id')

  const onCreate = (data: IAccount) => {
    setActiveAccount(data.id.toString())
  }

  return (
    <ModalV2
      isVisible={isVisible}
      handleOnClose={handleOnClose}
      header={t('accounts.newAccountHeader')}
      width={500}
      height={900}
      footer={
        <>
          <Button onClick={() => handleOnClose()}>{t('general.cancel')}</Button>
          <Button type="submit" intent="success" form="account-form">
            {t('general.save')}
          </Button>
        </>
      }
    >
      <AccountForm account={account} onClose={() => handleOnClose()} onCreate={onCreate} />
    </ModalV2>
  )
}
