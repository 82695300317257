import { Button, Input } from '@components'
import { kraviaInviteCurrentUser, useCurrentUser } from '@query'
import React from 'react'
import { FaPaperPlane } from 'react-icons/fa'
import { useMutation } from 'react-query'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useBusinessContext } from '@context'
import {
  StyledButtonContainer,
  StyledFlexContainer,
  StyledFullHeightContainer
} from './KraviaCommon.styled.tsx'

interface Props {
  handleOnClose: () => void
}

export const KraviaUserInvitationForm: React.FC<Props> = ({ handleOnClose }) => {
  const { t } = useTranslation()
  const { businessId } = useBusinessContext()
  const { data } = useCurrentUser()

  const { mutate, isLoading } = useMutation(() => kraviaInviteCurrentUser({ businessId }), {
    onSuccess: () => {
      toast.success(t('general.success'))
      handleOnClose()
    },
    onError: ({ status }) => {
      if (status === 409) {
        toast.error(t('invoicing.debtCollection.registerToKraviaModal.alreadyRegistered'))
      } else {
        toast.error(t('general.error'))
      }
    }
  })

  return (
    <StyledFlexContainer>
      <StyledFullHeightContainer>
        <p>{t('invoicing.debtCollection.registerToKraviaModal.description')}</p>
        <Input
          label={t('invoicing.debtCollection.registerToKraviaModal.emailLabel')}
          info={t('invoicing.debtCollection.registerToKraviaModal.emailInfo')}
          value={data?.email}
          disabled
        />
      </StyledFullHeightContainer>

      <StyledButtonContainer>
        <Button onClick={() => handleOnClose()}>{t('general.cancel')}</Button>
        <Button
          type="button"
          intent="primary"
          icon={<FaPaperPlane />}
          onClick={() => mutate()}
          showSpinner={isLoading}
          disabled={isLoading}
        >
          {t('invoicing.debtCollection.registerToKraviaModal.send')}
        </Button>
      </StyledButtonContainer>
    </StyledFlexContainer>
  )
}
