import React from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'

interface Props {}

export const AttachmentUploader: React.FC<Props> = () => {
  const { getRootProps, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: true
  })

  return (
    <AttachmentUploaderWrapper {...getRootProps()}>
      <input {...getInputProps()} />
    </AttachmentUploaderWrapper>
  )
}

const AttachmentUploaderWrapper = styled.div``
