import { AnimatedContentLoader } from '@components'
import { useBusinessContext } from '@context'
import { fetchEntries } from '@query'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { EntryRow } from './EntryRow'

interface Props {
  documentId: number
}

export const EntryOverview: React.FC<Props> = ({ documentId }) => {
  const { businessId } = useBusinessContext()
  const [t] = useTranslation()
  const { data, isLoading } = useQuery([businessId, 'documents', documentId, 'entries'], () =>
    fetchEntries({ businessId, documentId }, { page_size: 1000 })
  )
  const entries = data?.results || []
  const isEmpty = entries.length === 0

  const sortedEntries = useMemo(
    () => entries.sort((el1, el2) => el1.account - el2.account),
    [entries]
  )

  return (
    <AnimatedContentLoader
      isLoading={isLoading}
      isEmpty={isEmpty}
      isEmptyDescription={t('document.noEntries')}
    >
      {sortedEntries.map(entry => (
        <EntryRow key={entry.id} entry={entry} />
      ))}
    </AnimatedContentLoader>
  )
}
