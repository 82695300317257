import styled from 'styled-components'

export const AccountingDetailsWrapper = styled.div`
  height: 100%;
  background: white;
  overflow: auto;
`

export const DetailContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.lg}rem;
  margin-bottom: ${({ theme }) => theme.spacing.lg}rem;
`

export const DateBlock = styled.h3`
  color: ${({ theme }) => theme.colors.metalGray};
  margin: 0;
`

export const StepperHeader = styled.h3`
  margin-bottom: ${({ theme }) => theme.spacing.xs}rem;
`

export const StepperInfo = styled.p``
