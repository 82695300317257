import styled from 'styled-components'

export const Step = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.neutralWhite};
`

export const StepIconContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.neutralGray};
  & > svg {
    width: ${({ theme }) => theme.spacing.md}rem;
    min-width: ${({ theme }) => theme.spacing.md}rem;
    height: ${({ theme }) => theme.spacing.md}rem;
    min-height: ${({ theme }) => theme.spacing.md}rem;
    align-self: center;
  }
`

export const StepContact = styled.span<{ dashed: boolean }>`
  height: 100%;
  background: ${({ theme }) => theme.colors.neutralGray};

  background-image: ${({ dashed, theme }) =>
    dashed
      ? `repeating-linear-gradient(
          0deg,  
          ${theme.colors.neutralWhite} 0,  
          ${theme.colors.neutralWhite} 0.325rem,  
          transparent 0.325rem,  
          transparent 0.65rem  
        )`
      : 'none'};

  width: 0.125rem;
  align-self: center;
  margin-bottom: -${({ theme }) => theme.spacing.xxxs}rem;
`

export const StepContent = styled.div`
  min-height 2.6rem;
  margin-bottom: ${({ theme }) => theme.spacing.lg}rem;
  margin-left: ${({ theme }) => theme.spacing.xs}rem;
  flex: 1;
`

export const StepDescription = styled.div`
  margin: 0.2rem 0.4rem;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  color: ${({ theme }) => theme.colors.metalGray};
`

export const ContentPillWrapper = styled.div<{ isHoverable: boolean }>`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.neutralGray};
  border-radius: 1rem;
  max-width: fit-content;
  padding: ${({ theme }) => theme.spacing.sm}rem;

  ${({ isHoverable, theme }) =>
    isHoverable &&
    `
    &:hover {
      background: ${theme.colors.neutralGray};
    }`}
`

export const ContentPillInfoWrapper = styled.div`
  svg {
    width: ${({ theme }) => theme.spacing.sm}rem;
    min-width: ${({ theme }) => theme.spacing.sm}rem;
    height: ${({ theme }) => theme.spacing.sm}rem;
    min-height: ${({ theme }) => theme.spacing.sm}rem;
  }
`

export const ContentPillData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: fit-content;
  gap: ${({ theme }) => theme.spacing.xs}rem;

  & > svg {
    width: ${({ theme }) => theme.spacing.md}rem;
    min-width: ${({ theme }) => theme.spacing.md}rem;
    height: ${({ theme }) => theme.spacing.md}rem;
    min-height: ${({ theme }) => theme.spacing.md}rem;
    align-self: center;
    color: ${({ theme }) => theme.colors.neutralBlack};
  }
`

export const ContentPillText = styled.p`
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  color: ${({ theme }) => theme.colors.metalGray};
  margin: 0;

  &:hover {
    text-decoration: none;
  }
`

export const ContentSecondaryText = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  color: ${({ theme }) => theme.colors.metalGray};
  margin-bottom: ${({ theme }) => theme.fontSize.xxs}rem;
`
export const MultipleFilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;
`
