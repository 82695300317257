import { ActionBar, Button, ModalV2 } from '@components'
import { UserRole } from '@constants'
import { useFeature, usePermissionBoundary } from '@context'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaPlus } from 'react-icons/fa'
import { ContactFormModal } from './ContactFormModal'
import { ContactImportWizard } from '@containers/CSVImportWizard/ContactImportWizard.tsx'
import { FaFileCsv } from 'react-icons/fa6'

interface Props {
  onSearch: (value: string) => void
}

export const ContactsActionBar: React.FC<Props> = ({ onSearch }) => {
  const [t] = useTranslation()
  const [showCreate, setShowCreate] = useState(false)
  const [showImport, setShowImport] = useState(false)
  const isEditor = usePermissionBoundary(UserRole.EDITOR)
  const bulkInvoicing = useFeature('feature_bulk_invoicing')

  const primaryButtons = [
    <Button key="new-contact-button" icon={<FaPlus />} onClick={() => setShowCreate(true)}>
      {t('contacts.newContact')}
    </Button>,
    // Activate the import button only if the bulk invoicing feature is enabled
    ...(bulkInvoicing
      ? [
          <Button key="csv-import" icon={<FaFileCsv />} onClick={() => setShowImport(true)}>
            {t('contacts.importButton')}
          </Button>
        ]
      : [])
  ]

  return (
    <>
      <ActionBar
        searchPlaceholder={t('contacts.searchContacts')}
        onSearch={search => onSearch(search)}
        primaryButtons={isEditor ? primaryButtons : []}
      />
      <ContactFormModal isVisible={showCreate} handleOnClose={() => setShowCreate(false)} />

      <ModalV2
        isVisible={showImport}
        header={t('contacts.importTitle')}
        handleOnClose={() => setShowImport(false)}
        width={800}
        height={900}
      >
        <ContactImportWizard onComplete={() => setShowImport(false)} />
      </ModalV2>
    </>
  )
}
