import { network } from '@utils'
import { handleReturn, MutationFunction, PaginatedResponse, QueryFunction } from './root'

export interface IUsershipPerson {
  id: number
  user_id: number
  first_name: string
  last_name: string
  role: string
  email: string
  avatar_url: string
}

interface PersonListProps {
  businessId: string
}

export const fetchPeople: QueryFunction<
  PersonListProps,
  PaginatedResponse<IUsershipPerson>
> = async ({ businessId }, params = null) => {
  const url = `/v1/business/${businessId}/people/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}

interface PersonInstanceProps extends PersonListProps {
  usershipId: number
}

export const deleteUsership: MutationFunction<PersonInstanceProps, void> = async ({
  businessId,
  usershipId
}) => {
  const url = `/v1/business/${businessId}/people/${usershipId}/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'DELETE',
    validateStatus: () => true
  })

  return handleReturn({ status, data })
}

export const updateUsership: MutationFunction<PersonInstanceProps, IUsershipPerson> = async (
  { businessId, usershipId },
  requestData
) => {
  const url = `/v1/business/${businessId}/people/${usershipId}/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'PATCH',
    data: requestData,
    validateStatus: () => true
  })

  return handleReturn({ status, data })
}
