import { animate, useMotionValue, useTransform } from 'framer-motion'
import { useEffect } from 'react'

export const useRollingNumber = (to: number, duration: number) => {
  const count = useMotionValue(to)
  const rounded = useTransform(count, latest => Math.round(latest))

  useEffect(() => {
    const controls = animate(count, to, { duration })
    return controls.stop
  }, [to])

  return rounded
}
