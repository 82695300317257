import { handleReturn, MutationFunction, PaginatedResponse, QueryFunction } from '@query/root.ts'
import { network } from '@utils'

interface HolviAuthorizeUrlProps {
  businessId: string
  redirectUrl: string
}

export interface IHolviAuthorizeUrlResponse {
  url: string
}

export const fetchHolviAuthorizeUrl: QueryFunction<
  HolviAuthorizeUrlProps,
  IHolviAuthorizeUrlResponse
> = async ({ businessId, redirectUrl }) => {
  const url = `/v1/integrations/business/${businessId}/holvi/authorize/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'GET',
    params: { redirect_url: redirectUrl },
    validateStatus: () => true
  })
  return handleReturn({ data, status })
}

interface HolviAccountListProps {
  businessId: string
}

export const deleteHolviIntegration: MutationFunction<HolviAccountListProps, void> = async ({
  businessId
}) => {
  const url = `/v1/integrations/business/${businessId}/holvi/delete/`
  await network.httpClient.request({
    url,
    method: 'DELETE'
  })
}

export interface IHolviAccount {
  id: number
  account: number
  uuid: string
  name: string
  iban: string
  type: string
  pool_handle: string

  enabled: boolean
  last_sync_at: string
  begin_sync_from: string
}

export const fetchHolviAccounts: QueryFunction<
  HolviAccountListProps,
  PaginatedResponse<IHolviAccount>
> = async ({ businessId }) => {
  const url = `/v1/integrations/business/${businessId}/holvi/accounts/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'GET',
    validateStatus: () => true
  })
  return handleReturn({ data, status })
}

interface HolviAccountInstanceProps {
  businessId: string
  accountId: number
}

export const updateHolviAccount: MutationFunction<
  HolviAccountInstanceProps,
  IHolviAccount
> = async ({ businessId, accountId }, requestData) => {
  const url = `/v1/integrations/business/${businessId}/holvi/accounts/${accountId}/`
  const { data, status } = await network.httpClient.request({
    url,
    method: 'PATCH',
    data: requestData,
    validateStatus: () => true
  })
  return handleReturn({ data, status })
}
