import { AuditTrailFields, AuditTrailItemType, IAuditTrailWrapper } from '@root/query/auditTrail'
import { Step, StepContact, StepContent, StepIconContainer } from './AuditTrailStep.styled'
import { FaCircle } from 'react-icons/fa'
import { AttachmentBlock } from './ContentBlocks/AttachmentBlock'
import { DiscontinuationHeaderBlock, HeaderBlock } from './ContentBlocks/Header/HeaderBlock'
import { AuditTrailComment } from '../AuditTrailComment'
import { IDocument, IUsershipPerson } from '@root/query'

export interface IBaseBlockProps {
  businessId: string
}

interface Props {
  isLast?: boolean
  auditRecord: IAuditTrailWrapper
  users: IUsershipPerson[]
  document: IDocument
}

export const AuditTrailStep: React.FC<Props> = ({
  isLast = false,
  auditRecord,
  users,
  document
}) => {
  const Icon = FaCircle

  /*
   * Rendering logic
   */
  const isAuditAction = auditRecord.type === AuditTrailItemType.ACTIVITY
  const isComment = auditRecord.type === AuditTrailItemType.COMMENT
  const isBeginningOfHistory = auditRecord.type === AuditTrailItemType.HISTORY_BEGIN

  let blockToRender = null

  if (isAuditAction) {
    switch (auditRecord.item.changes[0].field) {
      case AuditTrailFields.ATTACHMENTS:
        blockToRender = <AttachmentBlock auditRecord={auditRecord.item} />
        break
      default:
        break
    }
  }
  if (isComment) {
    blockToRender = <AuditTrailComment comment={auditRecord.item} users={users} />
  }

  return (
    <>
      {isBeginningOfHistory ? (
        <Step>
          <StepIconContainer>
            <Icon />
            {!isLast && <StepContact dashed={true} />}
          </StepIconContainer>
          <StepContent>
            <DiscontinuationHeaderBlock date={auditRecord.time} />
          </StepContent>
        </Step>
      ) : (
        <Step>
          <StepIconContainer>
            <Icon />
            {!isLast && <StepContact dashed={false} />}
          </StepIconContainer>
          <StepContent>
            <HeaderBlock auditRecord={auditRecord} document={document} />
            {blockToRender}
          </StepContent>
        </Step>
      )}
    </>
  )
}
