import { Link } from 'react-router-dom'
import {
  ContentPillData,
  ContentPillInfoWrapper,
  ContentPillText,
  ContentPillWrapper
} from '../AuditTrailStep.styled'
import { TextTooltip } from '@root/components/InfoPopup'
import { FaQuestionCircle } from 'react-icons/fa'
import { theme } from '@root/styles'

interface IContentPill {
  Icon?: React.ReactNode
  text: string
  url?: string
  info?: string
}

export const ContentPill: React.FC<IContentPill> = ({ Icon, text, url, info }) => {
  return url ? (
    <div style={{ maxWidth: 'fit-content' }}>
      <Link to={url} style={{ textDecoration: 'none' }}>
        <ContentPillWrapper isHoverable={true}>
          <ContentPillData>
            {Icon && Icon} <ContentPillText>{text}</ContentPillText>
          </ContentPillData>
        </ContentPillWrapper>
      </Link>
    </div>
  ) : (
    <ContentPillWrapper isHoverable={false}>
      <ContentPillData>
        {Icon && Icon} <ContentPillText>{text}</ContentPillText>
        {info && (
          <ContentPillInfoWrapper>
            <TextTooltip tooltip={info}>
              <FaQuestionCircle style={{ color: theme.colors.metalGray }} />
            </TextTooltip>
          </ContentPillInfoWrapper>
        )}
      </ContentPillData>
    </ContentPillWrapper>
  )
}
