import {
  Button,
  PopupMenu,
  PopupMenuButton,
  PopupMenuContainer,
  Select,
  usePopupMenu,
  VatCodeIcons
} from '@components'
import { getVatRateLabelOptions, VAT_CODE_OPTIONS, VatCode, VatRateLabel } from '@constants'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FaCheck, FaPen } from 'react-icons/fa'
import styled from 'styled-components'
import { useBusinessContext } from '@context'
import dayjs from 'dayjs'
import { getVatRate } from '@utils/getVatOptions.tsx'
import { IUpdateTabularAccountProps } from '@pages/AccountsPage/sections'
import { IAccount } from '@query'

type UpdateData = (data: IUpdateTabularAccountProps) => Promise<void>

interface Props {
  vatRateLabelValue: VatRateLabel
  vatCodeValue: VatCode
  updateData: UpdateData
  account: IAccount
}

export const EditableVATCell: React.FC<Props> = ({
  vatRateLabelValue,
  vatCodeValue,
  account,
  updateData
}) => {
  const { data: business } = useBusinessContext()

  const _updateData = ({ default_vat_rate_label, default_vat_code }) =>
    updateData({
      accountId: account.id,
      data: { default_vat_rate_label, default_vat_code }
    })

  const Icon = VatCodeIcons[vatCodeValue]
  const isZeroValue =
    [VatCode.TAX_FREE, VatCode.DOMESTIC_ZERO_TAX_RATE].indexOf(vatCodeValue) !== -1
  const displayValue = isZeroValue ? 0 : getVatRate(business?.country_config, vatRateLabelValue)

  const handleKeyUp = e => {
    if (e.key === 'Enter') {
      // Simulate mousedown to trigger useOnClickOutside
      const clickEvent = document.createEvent('MouseEvents')
      clickEvent.initEvent('mousedown', true, true)
      e.currentTarget.dispatchEvent(clickEvent)

      // Open dropdown by click
      e.currentTarget.click()
    }
  }

  return (
    <PopupMenuContainer>
      <PopupMenuButton>
        <StyledCell tabIndex={0} onKeyUp={handleKeyUp}>
          <span className="percent-wrapper">
            <Icon /> <span className="percent">{displayValue} %</span>
          </span>
          <FaPen />
        </StyledCell>
      </PopupMenuButton>

      <PopupMenu menuGrow="right">
        <PopupContent>
          <EditForm
            initialVatCode={vatCodeValue}
            initialVatRateLabel={vatRateLabelValue}
            onUpdate={_updateData}
          />
        </PopupContent>
      </PopupMenu>
    </PopupMenuContainer>
  )
}

type OnUpdateData = (data: {
  default_vat_rate_label: VatRateLabel
  default_vat_code: VatCode
}) => Promise<void>

interface EditFormProps {
  initialVatCode: VatCode
  initialVatRateLabel: VatRateLabel
  onUpdate: OnUpdateData
}

const EditForm: React.FC<EditFormProps> = ({ initialVatCode, initialVatRateLabel, onUpdate }) => {
  const [t] = useTranslation()
  const { toggleVisibility } = usePopupMenu()
  const { data: business } = useBusinessContext()
  const methods = useForm({
    defaultValues: {
      default_vat_code: initialVatCode,
      default_vat_rate_label: initialVatRateLabel
    }
  })
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = methods

  const onSubmit = handleSubmit(async data => {
    try {
      await onUpdate(data)
      toggleVisibility(false)
    } catch {
      return null
    }
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <Select
          id="accountForm-input-vat"
          label={t('accounts.defaultVat')}
          info={t('accounts.defaultVatInfo')}
          errors={errors.default_vat_code}
          {...register('default_vat_code')}
        >
          {VAT_CODE_OPTIONS.map(({ label, value, hint }) => (
            <option key={value} value={value} title={t(hint)}>
              {t(label)}
            </option>
          ))}
        </Select>

        <Select
          info={t('accounts.defaultVatPercentageInfo')}
          label={t('accounts.defaultVatPercentage')}
          errors={errors.default_vat_rate_label}
          {...register('default_vat_rate_label')}
        >
          {getVatRateLabelOptions({
            countryConfig: business?.country_config,
            dateAt: dayjs(),
            t,
            uniqueLabels: true
          }).map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>

        <ButtonContainer>
          <Button
            icon={<FaCheck />}
            style={{ padding: 6 }}
            isSecondary={true}
            intent="success"
            showSpinner={isSubmitting}
          >
            {t('general.save')}
          </Button>
        </ButtonContainer>
      </form>
    </FormProvider>
  )
}

const StyledCell = styled.div`
  display: flex;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.sm}rem ${({ theme }) => theme.spacing.sm}rem;

  color: ${({ theme }) => theme.colors.neutralBlack};
  .percent-wrapper {
    display: flex;
    gap: ${({ theme }) => theme.spacing.sm}rem;

    & > * {
      align-self: center;
    }
  }

  & > * {
    align-self: center;
  }

  span {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > svg {
    color: ${({ theme }) => theme.colors.iconGray};
    width: ${({ theme }) => theme.iconSize.xxs}rem;
    height: ${({ theme }) => theme.iconSize.xxs}rem;
    margin-left: ${({ theme }) => theme.spacing.xs}rem;
    opacity: 0;
  }

  &:hover, &:focus {
    color: ${({ theme }) => theme.colors.nocfoBlue};

    & > svg {
      opacity: 1;
    }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const PopupContent = styled.div`
  padding: 1rem;
`
