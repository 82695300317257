import { FieldError } from 'react-hook-form'

type APIError = {
  [key: string]: Array<APIError> | Array<string> | APIError
}

interface SetError {
  (name: string, error: FieldError): void
}

export const setAPIErrors = (errors: APIError, setError: SetError, base = ''): string[] => {
  const isErrorList = list => list.every(e => typeof e === 'string')

  // Loop though all entries
  for (const [key, value] of Object.entries(errors)) {
    const newBase = base !== '' ? `${base}.${key}` : key

    if (key === 'non_field_errors' || key === 'common') {
      return
    }

    // Item is array
    if (Array.isArray(value)) {
      // CASE: List of errors
      // > Set errors
      if (isErrorList(value)) {
        setError(newBase, {
          type: 'manual',
          message: value[0] as string
        })
      }
      // CASE: List of field errors
      // > Recursively call this function for each element
      else {
        value.forEach((value, index) => {
          return setAPIErrors(value, setError, `${newBase}.${index}`)
        })
      }
    }
    // CASE: Nested object
    // > Recursively call this function
    else {
      return setAPIErrors(value, setError, newBase)
    }
  }
}
