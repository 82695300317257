import { Button } from '@components'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FaCheck, FaCheckCircle } from 'react-icons/fa'
import styled from 'styled-components'

interface CompleteToastProps {
  title: string
  description: string
}

interface Props extends CompleteToastProps {
  toastId: string
}

export const showCompleteToast = ({ title, description }: CompleteToastProps): void => {
  toast.custom(
    toast => <CompleteSlide title={title} description={description} toastId={toast.id} />,
    {
      position: 'bottom-right',
      duration: 10000
    }
  )
}

export const CompleteSlide: React.FC<Props> = ({ toastId, title, description }) => {
  const { t } = useTranslation()
  const [isVisible, setVisible] = useState(true)

  return (
    <AnimatePresence initial={false}>
      {isVisible && (
        <StyledToastContent key={`toast-${toastId}`}>
          <h3>
            <FaCheckCircle />
            {title}
          </h3>
          <p>{description}</p>
          <Button
            intent="success"
            icon={<FaCheck />}
            onClick={() => {
              setVisible(false)
              setTimeout(() => {
                toast.remove(toastId)
              }, 500)
            }}
          >
            {t('general.ok')}
          </Button>
        </StyledToastContent>
      )}
    </AnimatePresence>
  )
}

const BORDER_RADIUS = '6px'

const StyledToastContent = styled(motion.div).attrs({
  initial: false,
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 }
})`
  padding: ${({ theme }) => theme.spacing.md}rem;
  background: ${({ theme }) => theme.colors.neutralWhite};
  border-radius: ${BORDER_RADIUS};
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  max-width: 600px;
  border-top: ${BORDER_RADIUS} solid ${({ theme }) => theme.colors.nocfoGreen};

  h3 {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xs}rem;

    svg {
      color: ${({ theme }) => theme.colors.nocfoGreen};
    }
  }
`
