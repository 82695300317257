import { useCurrentUserLanguage } from '@localisation'
import BusinessesPage from '@pages/BusinessesPage'
import BusinessPage from '@pages/BusinessPage'
import DemoLandingPage from '@pages/DemoLandingPage'
import NYLandingPage from '@pages/NYLandingPage'
import OnboardingPage from '@pages/OnboardingPage'
import StripeConfirmationPage from '@pages/StripeConfirmationPage'
import { useCurrentUser } from '@query'
import { GlobalStyle, theme } from '@styles'
import { isAuthenticated } from '@utils'
import React, { Suspense, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Toaster } from 'react-hot-toast'
import { useCustomerly } from 'react-live-chat-customerly'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import './App.css'
import ErrorPage from '@pages/ErrorPage'
import SignupPage from '@pages/SignupPage'
import TosPage from '@pages/TosPage'
import * as Sentry from '@sentry/react'
import NocfoAccountSigninPage from '@pages/NocfoAccountSigninPage'
import NocfoAccountSignupPage from '@pages/NocfoAccountSignupPage'
import NocfoResetPasswordPage from '@pages/NocfoResetPasswordPage'

const LoginPage = React.lazy(() => import('@pages/LoginPage'))

window.document.title = 'NOCFO'

const App: React.FC = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <BrowserRouter>
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <AppContent />
          </ErrorBoundary>
        </BrowserRouter>

        <Toaster
          position="bottom-center"
          toastOptions={{
            className: 'toast-message',
            success: {
              duration: 4000
            }
          }}
        />
      </ThemeProvider>
    </>
  )
}

const AppContent: React.FC = () => {
  const { data: user } = useCurrentUser()
  const { load: loadCustomerly } = useCustomerly()

  useCurrentUserLanguage(user)

  useEffect(() => {
    // User accepted TOS
    // > Add user context to Sentry and Customerly
    if (user?.tos_accepted) {
      Sentry.setUser({ id: user?.id })

      loadCustomerly({
        user_id: user.id,
        email: user.email,
        name: `${user.first_name} ${user.last_name}`
      })
    }
    // User not accepted TOS
    // > Remove user from Sentry
    else {
      Sentry.setUser(null)
    }
  }, [user?.tos_accepted])

  // Ensure user authentication
  if (!isAuthenticated()) {
    return (
      <>
        <Switch>
          <Route path="/signup" component={SignupPage} />
          <Route path="/nocfo-signup" component={NocfoAccountSignupPage} />
          <Route path="/nocfo-signin" component={NocfoAccountSigninPage} />
          <Route path="/nocfo-password-reset" component={NocfoResetPasswordPage} />
          <Route component={LoginPage} />
        </Switch>
      </>
    )
  }

  if (user?.tos_accepted === false) {
    return <TosPage />
  }

  return (
    <Suspense fallback="">
      <Switch>
        <Route path="/nuori-yrittaja" component={NYLandingPage} />
        <Route path="/demo" component={DemoLandingPage} />
        <Route path="/stripe-redirect" component={StripeConfirmationPage} />
        <Route path="/onboarding" component={OnboardingPage} />

        {/* ACTUAL APPLICATION */}
        <Route
          path="/:businessId"
          render={({ match: { url } }) => <BusinessPage baseUrl={url} />}
        ></Route>

        {/* LIST ALL THE BUSINESSES */}
        <Route path="/" component={BusinessesPage} />
      </Switch>
    </Suspense>
  )
}

export default App
