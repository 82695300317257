import { AnimatedContentLoader, Button } from '@components'
import { useBusinessContext, useFeature } from '@context'
import { fetchBankIntegrations } from '@query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaPlus } from 'react-icons/fa'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { BankIntegration } from './BankIntegration'
import { BankIntegrationLander } from './BankIntegrationLander'
import { RequisitionModal } from './RequisitionModal'

export const BankIntegrations: React.FC = () => {
  const featureBankIntegrations = useFeature('feature_bank_integrations')
  const [t] = useTranslation()

  const { businessId } = useBusinessContext()

  const { data, isLoading } = useQuery(
    [businessId, 'bank_integrations'],
    () => fetchBankIntegrations({ businessId }),
    {
      enabled: featureBankIntegrations
    }
  )

  const integrations = data?.results

  if (!featureBankIntegrations || integrations?.length === 0) {
    return (
      <AnimatedContentLoader isLoading={isLoading}>
        <BankIntegrationLander />
      </AnimatedContentLoader>
    )
  }

  return (
    <>
      <BankIntegrationWrapper>
        {integrations?.map(integration => (
          <BankIntegration key={integration.id} integration={integration} />
        ))}
      </BankIntegrationWrapper>

      <ButtonWrapper>
        <RequisitionModal>
          <Link to={`/${businessId}/bank-integration-flow/aspsps`}>
            <Button icon={<FaPlus />} intent="default">
              {t('settings.integrations.bank.createButton')}
            </Button>
          </Link>
        </RequisitionModal>
      </ButtonWrapper>
    </>
  )
}

const BankIntegrationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md}rem;
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
