import imgSrc from '@assets/undraw/undraw_loading_re_5axr.svg'
import { AnimatedContentLoader } from '@components'
import { useBusinessContext } from '@context'
import {
  collectFromPages,
  fetchNotifications,
  INotification2,
  markAllAsSeen,
  useCurrentUser
} from '@query'
import { motion } from 'framer-motion'
import React, { CSSProperties, forwardRef, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaRegCheckCircle } from 'react-icons/fa'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { NotificationFeedFilter } from './NotificationFeedFilter'
import { Notification } from './Notifications'

interface Props {
  style?: CSSProperties
}

type NotificationFilter = 'new' | 'all'

export const NotificationFeed = forwardRef<HTMLDivElement, Props>(({ style }, ref) => {
  const [t] = useTranslation()
  const [filter, setFilter] = useState<NotificationFilter>('new')
  const { businessId, data: business } = useBusinessContext()
  const queryClient = useQueryClient()

  const filters = useMemo(
    () => ({
      new: filter == 'new' ? true : null
    }),
    [filter]
  )

  const { data: user } = useCurrentUser()
  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery(
    ['notifications', user?.id, businessId, filters],
    ({ pageParam = 1 }) =>
      fetchNotifications({ businessId }, { page: pageParam, page_size: 10, ...filters }),
    {
      getNextPageParam: lastPage => lastPage.next,
      getPreviousPageParam: lastPage => lastPage.prev
    }
  )
  const notifications = collectFromPages<INotification2>(data)

  const { mutate: markAsSeen, isLoading: isMarkingAsSeen } = useMutation(
    () => markAllAsSeen({ businessId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['notifications'])
      }
    }
  )

  return (
    <StyledNotificationFeed ref={ref} style={style}>
      <StyledNotificationsHeader>
        <HeaderText>
          <span>{t('notificationFeed.title')}</span>
          <NotificationFeedFilter value={filter} onFilter={value => setFilter(value)} />
        </HeaderText>
        <HeaderButton onClick={() => markAsSeen()} disabled={isMarkingAsSeen}>
          <span>{t('notificationFeed.markAsRead')}</span>
          <FaRegCheckCircle />
        </HeaderButton>
      </StyledNotificationsHeader>
      {business?.name && <StyledActiveBusinessInfo>{business?.name}</StyledActiveBusinessInfo>}
      <StyledNotificationsList>
        <AnimatedContentLoader
          isLoading={isLoading}
          isEmpty={notifications.length === 0}
          isEmptyContent={<CaughtUp />}
        >
          {notifications.map(n => (
            <Notification key={n.id} notification={n} />
          ))}

          {hasNextPage && (
            <StyledLoadMore onClick={() => fetchNextPage()}>
              <span>{t('notificationFeed.loadMore')}</span>
            </StyledLoadMore>
          )}
        </AnimatedContentLoader>
      </StyledNotificationsList>
    </StyledNotificationFeed>
  )
})

const CaughtUp: React.FC = () => {
  const [t] = useTranslation()

  return (
    <CaughtUpContainer>
      <CaughtUpImg />
      <CaughtUpTitle>{t('notificationFeed.empty')}</CaughtUpTitle>
    </CaughtUpContainer>
  )
}

const StyledNotificationFeed = styled(motion.div).attrs({
  initial: { opacity: 0, y: -30, x: 20, scale: 0.9 },
  animate: { opacity: 1, y: 0, x: -0, scale: 1 },
  exit: { opacity: 0, y: -30, x: 20, scale: 0.9 }
})`
  display: flex;
  flex-direction: column;
  z-index: 9999;
  background: white;
  border-radius: 1rem;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
  height: 500px;
`

const StyledNotificationsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.md}rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralGray};
`

const HeaderText = styled.span`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  color: ${({ theme }) => theme.colors.neutralBlack};
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
`

const HeaderButton = styled(motion.button).attrs({
  whileHover: { scale: 1.02 },
  whileTap: { scale: 0.96 }
})`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xxs}rem;
  align-items: center;
  background: none;
  color: ${({ theme }) => theme.colors.nocfoBlue};
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  border: none;
  padding: 0;
  outline: none !important;
`

const StyledNotificationsList = styled.div`
  flex: 1;
  overflow: auto;
`

const StyledActiveBusinessInfo = styled.div`
  padding: ${({ theme }) => theme.spacing.xs}rem ${({ theme }) => theme.spacing.md}rem;
  background: ${({ theme }) => theme.colors.neutralGray};
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  color: ${({ theme }) => theme.colors.metalGray};
`

const CaughtUpImg = styled.img.attrs({ src: imgSrc })`
  width: 140px;
`

const CaughtUpTitle = styled.h1`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  color: ${({ theme }) => theme.colors.metalGray};
`

const CaughtUpContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.xxl}rem;
  gap: ${({ theme }) => theme.spacing.lg}rem;
  align-items: center;
  justify-content: center;
`

const StyledLoadMore = styled.button`
  width: 100%;
  background: none;
  outline: none !importart;
  border: none;
  display: flex;
  padding: ${({ theme }) => theme.spacing.sm}rem ${({ theme }) => theme.spacing.md}rem;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  color: ${({ theme }) => theme.colors.metalGray};
`
