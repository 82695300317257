import { theme } from '@styles'
import { motion } from 'framer-motion'
import React, { useState } from 'react'
import styled from 'styled-components'

interface IFilterBarFilter {
  id: string
  name: string
  filter: any
}

interface Props {
  onFilter: (string) => void
  filters: IFilterBarFilter[]
}

export const FilterBar: React.FC<Props> = ({ onFilter, filters }) => {
  const [filter, setFilter] = useState(0)

  const toggleFilter = (id, newFilter) => {
    setFilter(filter => {
      const toggleOff = filter === id
      onFilter(toggleOff ? undefined : newFilter)
      return toggleOff ? undefined : id
    })
  }

  const getState = currFilter => {
    return currFilter === filter ? 'active' : 'default'
  }

  return (
    <FilterBadgeContainer>
      {filters.map(({ id, name, filter }) => (
        <FilterBadge
          key={`filter-${id}`}
          onClick={() => toggleFilter(id, filter)}
          initial={false}
          animate={getState(id)}
        >
          <FilterBadgeText>{name}</FilterBadgeText>
        </FilterBadge>
      ))}
    </FilterBadgeContainer>
  )
}

const FilterBadge = styled(motion.div).attrs({
  variants: {
    active: {
      color: theme.colors.neutralWhite,
      background: theme.colors.nocfoBlue
    },
    default: {
      color: theme.colors.metalGray,
      background: theme.colors.neutralGray
    }
  }
})`
  position: relative;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.xxs}rem ${({ theme }) => theme.spacing.xs}rem;
  border-radius: 0.5rem;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  font-weight: 500;
  user-select: none;
  white-space: nowrap;
`

const FilterBadgeContainer = styled.div`
  flex: 1;
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  flex-wrap: wrap;
`

const FilterBadgeText = styled.div``
