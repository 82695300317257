import React, { useRef } from 'react'
import { Input, ToggleContainer } from '@root/components'
import { FormProvider, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IInvoice } from '@root/query'

interface Props {
  invoiceReceiver: number
  toggleAutoOpen?: boolean
  isCreditNote: boolean
}

export const InvoiceFormMoreSection: React.FC<Props> = ({ toggleAutoOpen = false }) => {
  const containerRef = useRef(null)
  const {
    register,
    formState: { errors }
  } = useFormContext<Partial<IInvoice>>()

  const methods = useFormContext()

  const [t] = useTranslation()

  return (
    <FormProvider {...methods}>
      <ToggleContainer
        title={t('invoicing.form.more')}
        openByDefault={toggleAutoOpen}
        ref={containerRef}
      >
        {/* OTHER */}
        <Input
          label={t('invoicing.form.reference')}
          errors={errors?.reference}
          info={t('invoicing.form.referenceInfo')}
          {...register('reference')}
        />
        <Input
          label={t('invoicing.form.penaltyInterest')}
          type="number"
          info={t('invoicing.form.penaltyInterestInfo')}
          errors={errors?.penalty_interest}
          step="0.1"
          {...register('penalty_interest', { valueAsNumber: true })}
        />
      </ToggleContainer>
    </FormProvider>
  )
}
