import { SingleColumnLayout } from '@components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CapitalChart } from './components/CapitalChart'
import { MainChart } from './components/ComposedChart'
import { KeyFigures } from './components/KeyFigures'
import { PeriodProgress } from './components/PeriodProgress'
import { UpgradePlanWidget, useShowUpgradePlanWidget } from './components/UpgradePlanWidget'
import { Widget } from './components/Widget'
import Alerts from './components/Alerts'

export const DashboardPage: React.FC = () => {
  const [t] = useTranslation()
  const showUpgradePlanWidget = useShowUpgradePlanWidget()

  return (
    <SingleColumnLayout header={t('dashboard.welcome')}>
      <Page>
        <Content>
          <Alerts />
          <ResponsiveRow>
            <Widget
              widgetTitle={t('dashboard.keyFigures.mainTitle')}
              tooltip={t('dashboard.keyFigures.mainTooltip')}
              widget={() => (
                <Column>
                  <KeyFigures />
                  <PeriodProgressWrapper>
                    <PeriodProgress />
                  </PeriodProgressWrapper>
                </Column>
              )}
            />

            <Widget
              widgetTitle={t('dashboard.capitalChart.mainTitle')}
              tooltip={t('dashboard.capitalChart.mainTooltip')}
              widget={() => <CapitalChart />}
            />

            {showUpgradePlanWidget && (
              <Widget
                widgetTitle={t('dashboard.upgradePlan.mainTitle')}
                highlight={false}
                widget={() => (
                  <Column>
                    <UpgradePlanWidget />
                  </Column>
                )}
              />
            )}
          </ResponsiveRow>

          <ResponsiveRow>
            <Widget
              widgetTitle={t('dashboard.composedChart.mainTitle')}
              tooltip={t('dashboard.composedChart.mainTooltip')}
              widget={() => (
                <DimensionProvider width="99%" height="99%">
                  <MainChart />
                </DimensionProvider>
              )}
            />
          </ResponsiveRow>
        </Content>
      </Page>
    </SingleColumnLayout>
  )
}

const Page = styled.div(
  ({ theme }) => `
  display: flex;
  flex-wrap: wrap;
  background: ${theme.colors.neutralGray};
  flex: 1;
  max-height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`
)

const ResponsiveRow = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.md}rem;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.md}rem;
  overflow: hidden;
`

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const DimensionProvider = styled.div<{ width: string; height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  display: flex;
  align-items: center;
  height: 100%;
`

const PeriodProgressWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.md}rem;
`
