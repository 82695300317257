import { ICountryConfig, IVatConfig, IVatRateOption } from '@query'
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash'
import { VatRateLabel } from '@constants'

export const getVatOptions = (
  countryConfig: ICountryConfig,
  dateAt: dayjs.Dayjs = null
): IVatRateOption[] => {
  dateAt = dateAt || dayjs()

  const reversedConfigs: IVatConfig[] = cloneDeep(countryConfig.vat_configs).reverse()

  const config = reversedConfigs.find(vatConfig =>
    dayjs(vatConfig.effective_from, 'YYYY-MM-DD').isSameOrBefore(dateAt)
  )

  if (!config) {
    console.warn("Couldn't find VAT config for date", dateAt.format('YYYY-MM-DD'))
    return reversedConfigs[0].vat_rate_options
  }

  return config.vat_rate_options
}

export const getVatRate = (
  countryConfig: ICountryConfig,
  label: VatRateLabel,
  dateAt: dayjs.Dayjs = null
): number => {
  const options = getVatOptions(countryConfig, dateAt)
  return options.find(option => option.label === label)?.rate ?? options[0].rate
}
