import { MonetaryAmount } from '@components'
import { useBusinessContext } from '@context'
import { useVirtualList } from '@hooks'
import { collectFromPages, fetchDocuments, IDocument } from '@query'
import { formatDate } from '@utils'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { useInfiniteQuery } from 'react-query'
import { AnimatedContentLoader } from '../'
import {
  Spacer,
  StyledContent,
  StyledDocBal,
  StyledDocDate,
  StyledDocDesc,
  StyledDocNumber,
  StyledDocRow,
  StyledDocument,
  StyledHeader,
  StyledHeaderIcon,
  StyledWrapper
} from './DocumentWidget.styled'

interface Props {
  header: string
  noData: string
  contactId?: number
  accountId?: number
  fileId?: number
  pageSize?: number
  height?: number
}

const containerVariants = {
  hide: { opacity: 0 },
  show: { opacity: 1, transition: { delayChildren: 0.3, staggerChildren: 0.1 } }
}

const variants = {
  hide: { opacity: 0, x: -20 },
  show: { opacity: 1, x: 0 }
}

const ITEM_SIZE = 70

export const DocumentWidget: React.FC<Props> = ({
  header,
  noData,
  contactId,
  accountId,
  fileId,
  height = 250,
  pageSize = 10
}) => {
  const { businessId } = useBusinessContext()
  const filters = { contact: contactId, account: accountId, file: fileId }
  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery(
    [businessId, 'documents', 'widget', filters],
    ({ pageParam = 1 }) =>
      fetchDocuments({ businessId }, { page: pageParam, page_size: pageSize, ...filters }),
    {
      getNextPageParam: lastPage => lastPage.next,
      getPreviousPageParam: lastPage => lastPage.prev
    }
  )
  const documents = collectFromPages<IDocument>(data)

  const { scrollRef, renderItems } = useVirtualList({
    items: documents,
    itemSize: ITEM_SIZE,
    onScrollBottom: () => {
      hasNextPage && fetchNextPage()
    }
  })

  return (
    <AnimatePresence>
      <StyledWrapper>
        <StyledHeader>
          <StyledHeaderIcon />
          {header}
        </StyledHeader>
        <StyledContent ref={scrollRef} height={height}>
          <AnimatedContentLoader
            isLoading={isLoading}
            isEmpty={documents.length === 0}
            isEmptyDescription={noData}
          >
            <AnimatePresence>
              <motion.div initial="hide" animate="show" exit="hide" variants={containerVariants}>
                {renderItems((document, style) => (
                  <motion.div key={document.id} variants={variants} style={style}>
                    <StyledDocument to={`/${businessId}/documents?id=${document.id}`}>
                      <StyledDocRow>
                        {!document.is_draft && <StyledDocNumber>{document.number}</StyledDocNumber>}
                        <Spacer />
                        <StyledDocDate>{formatDate(document.date)}</StyledDocDate>
                      </StyledDocRow>
                      <StyledDocRow>
                        <StyledDocDesc>{document.description}</StyledDocDesc>
                        <StyledDocBal>
                          <MonetaryAmount value={document.balance} showColor />
                        </StyledDocBal>
                      </StyledDocRow>
                    </StyledDocument>
                  </motion.div>
                ))}
              </motion.div>
            </AnimatePresence>
          </AnimatedContentLoader>
        </StyledContent>
      </StyledWrapper>
    </AnimatePresence>
  )
}
