import { AccountType, AnalyticsFrequency } from '@constants'
import { useBusinessContext, useDateRangeContext } from '@context'
import { AnalyticsSerieEntry, decorateExp, fetchSeries } from '@query'
import dayjs from 'dayjs'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { eurToCents } from './formatCurrency'
export const ISODateFormat = 'YYYY-MM-DD'

export const getFreqByDates = (startDate: string, endDate: string): AnalyticsFrequency => {
  const t1 = dayjs(startDate)
  const t2 = dayjs(endDate)
  const diff = t2.diff(t1, 'month')

  if (diff <= 1) return AnalyticsFrequency.D
  if (diff <= 3) return AnalyticsFrequency.W
  return AnalyticsFrequency.M
}

const appendDataWithKey = <T extends any[]>(
  orig: T,
  newData: AnalyticsSerieEntry[] = [],
  key: string,
  valueFormatter?: (n: number) => number
) => {
  return orig.map(entry => {
    const value = newData.find(e => e.label === entry.label)?.value ?? 0
    return {
      ...entry,
      [key]: eurToCents(valueFormatter?.(value) || value)
    }
  })
}

export interface ChartData {
  label: string
  sales: number
  expenses: number
  profit: number
}

export const useChartData = (): {
  data: ChartData[]
  freq: AnalyticsFrequency
  labelMap: Record<string, string>
  isLoading: boolean
} => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const { selectedRange, isReady } = useDateRangeContext()
  const freq = getFreqByDates(selectedRange?.date_from, selectedRange?.date_to)

  const labelMap = {
    expenses: t('dashboard.composedChart.expenses'),
    sales: t('dashboard.composedChart.sales'),
    profit: t('dashboard.composedChart.profit')
  }

  const { data: sales, isLoading: lSales } = useQuery(
    ['dashboard', 'sales', businessId, selectedRange?.date_from, selectedRange?.date_to],
    () =>
      fetchSeries({
        freq,
        business_id: businessId,
        date_from: selectedRange?.date_from,
        date_to: selectedRange?.date_to,
        account_type: [decorateExp(AccountType.REV)]
      }),
    {
      initialData: [],
      enabled: isReady
    }
  )

  const { data: expenses, isLoading: lExpenses } = useQuery(
    ['dashboard', 'expenses', businessId, selectedRange?.date_from, selectedRange?.date_to],
    () =>
      fetchSeries({
        freq,
        business_id: businessId,
        date_from: selectedRange?.date_from,
        date_to: selectedRange?.date_to,
        account_type: [decorateExp(AccountType.EXP)]
      }),
    {
      initialData: [],
      enabled: isReady
    }
  )

  const { data: profit, isLoading: lProfit } = useQuery(
    ['dashboard', 'profit', businessId, selectedRange?.date_from, selectedRange?.date_to],
    () =>
      fetchSeries({
        freq,
        business_id: businessId,
        date_from: selectedRange?.date_from,
        date_to: selectedRange?.date_to,
        account_type: [decorateExp(AccountType.REV), decorateExp(AccountType.EXP)]
      }),
    {
      initialData: [],
      enabled: isReady
    }
  )

  const formatData = useCallback(() => {
    const data = [...sales, ...expenses, ...profit]
    const keys = [...new Set(data.map(e => e.label))]
    let res = keys.map(label => ({ label, expenses: 0, sales: 0, profit: 0 }))
    res = appendDataWithKey(res, expenses, 'expenses')
    res = appendDataWithKey(res, sales, 'sales')
    res = appendDataWithKey(res, profit, 'profit')

    return res
  }, [sales, expenses, profit])

  return useMemo(
    () => ({
      labelMap,
      freq,
      data: formatData(),
      isLoading: lSales && lExpenses && lProfit
    }),
    [formatData, freq, lSales, lExpenses, lProfit]
  )
}

export const formatReadableDate = (date: dayjs.Dayjs, format = 'D.M'): string => {
  return date.format(format)
}

export interface Control<T> {
  label: string
  value: T
}

export const isActivePeriod = (x: { has_ended: boolean; has_began: boolean }): boolean =>
  x.has_began && !x.has_ended
