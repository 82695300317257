import imgSrc from '@assets/undraw/undraw_file_manager_re_ms29.svg'
import { Alert, Button, Checkbox } from '@components'
import { useBusinessContext } from '@context'
import { enableEinvoicing } from '@query'
import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import styled from 'styled-components'

export const ActivateEInvoicing: React.FC = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { businessId } = useBusinessContext()
  const [tosAccepted, setTosAccepted] = useState(false)
  const [showError, setShowError] = useState(false)
  const { mutate: enableEInvoicingMutation, isLoading } = useMutation(
    () => enableEinvoicing({ businessId }),
    {
      onSuccess: async () => {
        setShowError(false)
        await Promise.all([
          queryClient.invalidateQueries(['businesses']),
          queryClient.invalidateQueries([businessId])
        ])
      },
      onError: () => {
        setShowError(true)
      }
    }
  )

  return (
    <StyledContainer>
      <StyledImg />

      <Alert
        isVisible={showError}
        type="error"
        title={t('settings.einvoicing.errorTitle')}
        description={
          <Trans i18nKey="settings.einvoicing.errorDescription">
            <a href={t('link.einvoicing')} target="_blank" rel="noreferrer" />
          </Trans>
        }
      />

      <StyledTitle>{t('settings.einvoicing.activate')}</StyledTitle>

      <StyledDescription>{t('settings.einvoicing.activateDescription1')}</StyledDescription>

      <StyledDescription>{t('settings.einvoicing.activateDescription2')}</StyledDescription>

      <StyledCheckbox>
        <Checkbox
          id="grant-einvoicing-access"
          value={tosAccepted}
          onChange={e => setTosAccepted(e.target.checked)}
          label={
            <Trans i18nKey="settings.einvoicing.tosCheckbox">
              <a href={t('link.pricing')} target="_blank" rel="noreferrer" />
              <a href={t('link.apixTos')} target="_blank" rel="noreferrer" />
            </Trans>
          }
        />
      </StyledCheckbox>

      <StyledButtonWrapper>
        <Button
          intent="primary"
          onClick={() => enableEInvoicingMutation()}
          disabled={isLoading || !tosAccepted}
          showSpinner={isLoading}
        >
          {t('settings.einvoicing.activateButton')}
        </Button>
      </StyledButtonWrapper>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md}rem;
  max-width: 400px;
  margin: auto;
`

const StyledImg = styled(motion.img).attrs({
  src: imgSrc,
  initial: { opacity: 0, scale: 0.8, y: 50, rotate: 15 },
  animate: { opacity: 1, scale: 1, y: 0, rotate: 0 },
  transition: { delay: 0.1 }
})`
  max-width: 340px;
  padding: 3rem;
  padding-top: 1rem;
`

const StyledTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.lg}rem;
  color: ${({ theme }) => theme.colors.neutralBlack};
  text-align: center;
  margin: 0;
`

const StyledDescription = styled.p`
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  color: ${({ theme }) => theme.colors.neutralBlack};
  text-align: center;
  margin: 0;
`

const StyledButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.sm}rem;
`

const StyledCheckbox = styled.div`
  margin-top: 2rem;
  color: ${({ theme }) => theme.colors.neutralBlack};

  input {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }
`
