import styled, { css } from 'styled-components'

export const KBD = styled.kbd(
  ({ theme }) => css`
    padding: 0px 6px;
    border-radius: 3px;
    background: ${theme.colors.neutralGray};
    box-shadow: inset 0 0 0 1px rgb(16 112 202 / 14%);
    background-color: rgba(16, 112, 202, 0.06);
    color: ${theme.colors.metalGray};
  `
)
