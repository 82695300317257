import { AnimatedContentLoader, FilePreview2 } from '@components'
import { useBusinessContext } from '@context'
import { fetchFile } from '@query'
import { motion } from 'framer-motion'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa'
import { useQuery } from 'react-query'
import styled from 'styled-components'
interface Props {
  attachmentIds?: number[]
}

export const DocumentAttachmentPreview: React.FC<Props> = ({ attachmentIds }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const { businessId } = useBusinessContext()
  const { t } = useTranslation()
  const index = selectedIndex % attachmentIds?.length
  const selectedId = attachmentIds?.[index]
  const showArrows = attachmentIds?.length > 1

  const goPrev = useCallback(() => {
    setSelectedIndex(index => index - 1)
  }, [])

  const goNext = useCallback(() => {
    setSelectedIndex(index => index + 1)
  }, [])

  const { data, isLoading } = useQuery(
    [businessId, 'files', selectedId],
    () => fetchFile({ businessId, fileId: selectedId }),
    { enabled: !!selectedId }
  )

  return (
    <StyledWrapper>
      {attachmentIds?.length === 0 && <NoData>{t('document.formExtra.noAttachments')}</NoData>}

      <AnimatedContentLoader isLoading={isLoading}>
        {data && <FilePreview2 file={data} showNoPreview={true} />}
      </AnimatedContentLoader>

      {showArrows && (
        <StyledPrevButton onClick={() => goPrev()}>
          <FaCaretLeft />
        </StyledPrevButton>
      )}
      {showArrows && (
        <StyledNextButton onClick={() => goNext()}>
          <FaCaretRight />
        </StyledNextButton>
      )}
    </StyledWrapper>
  )
}

const StyledWrapper = styled(motion.div).attrs({
  key: 'styled-preview-wrapper'
})`
  position: relative;

  &:hover {
    .arrow-btn {
      opacity: 0.35;
    }
  }
`

const StyledArrowButton = styled(motion.div).attrs({
  className: 'arrow-btn',
  initial: false,
  animate: {
    y: '-50%'
  },
  whileHover: {
    scale: 1.1
  },
  whileTap: {
    scale: 0.9
  }
})`
  position: absolute;
  opacity: 0;

  width: 3rem;
  height: 3rem;
  background: ${({ theme }) => theme.colors.neutralBlack};
  border-radius: 200px;

  svg {
    width: 2.2rem;
    height: 2.2rem;
    fill: ${({ theme }) => theme.colors.neutralWhite};
    position: absolute;
    top: 50%;
    left: 50%;
  }
`

const StyledNextButton = styled(StyledArrowButton)`
  top: 50%;
  right: 20px;

  svg {
    transform: translate(-43%, -50%);
  }
`

const StyledPrevButton = styled(StyledArrowButton)`
  top: 50%;
  left: 20px;

  svg {
    transform: translate(-57%, -50%);
  }
`

const NoData = styled.div`
  color: ${({ theme }) => theme.colors.metalGray};
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  text-align: center;
  padding: ${({ theme }) => theme.spacing.xxl}rem 0;
`
