export const mapValue = (
  value: number,
  sourceMin: number,
  sourceMax: number,
  targetMin: number,
  targetMax: number
): number => {
  const sourceRange = sourceMax - sourceMin
  const targetRange = targetMax - targetMin

  const sourceDifference = value - sourceMin
  const targetDifference = (sourceDifference * targetRange) / sourceRange

  return targetMin + targetDifference
}
