import { AccountSelectDropdown, Alert, Button, Select } from '@components'
import { useBusinessContext } from '@context'
import { importDocuments } from '@query'
import { normalizeDateString, normalizeNumberString, useAssistantAccounts } from '@utils'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FaArrowLeft, FaFeatherAlt } from 'react-icons/fa'
import { useMutation, useQueryClient } from 'react-query'
import { useFlexLayout, useTable } from 'react-table'
import {
  AnimatedRow,
  ButtonContent,
  ButtonCount,
  ButtonText,
  CellValue,
  Footer,
  PageContent,
  StyledHeader,
  StyledTable
} from './DocumentImportTable.styled'

type JSONObject = { [key: string]: any }

interface Props {
  data: JSONObject[]
  onClose: () => void
}

const NONE_FIELD = 'none'
const DOCUMENT_FIELD_TYPES = [
  {
    label: '-',
    value: NONE_FIELD
  },
  {
    label: 'Päivämäärä',
    value: 'date'
  },
  {
    label: 'Kuvaus',
    value: 'description'
  },
  {
    label: 'Summa',
    value: 'amount'
  },
  {
    label: 'Kontakti',
    value: 'contact_hint'
  },
  {
    label: 'Arkistointitunnus',
    value: 'identifier'
  },
  {
    label: 'Viite',
    value: 'reference_number'
  }
]

interface DocumentFieldSelectorProps {
  value: string
  onChange: (value) => void
}

const DocumentFieldSelector: React.FC<DocumentFieldSelectorProps> = ({ onChange, value }) => {
  const [t] = useTranslation()

  return (
    <Select
      label={t('document.import.fieldType')}
      onChange={e => onChange(e.currentTarget.value)}
      value={value}
    >
      {DOCUMENT_FIELD_TYPES.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </Select>
  )
}

const checkIfReady = (dataTypes: string[]) => {
  return (
    dataTypes.indexOf('date') !== -1 &&
    dataTypes.indexOf('description') !== -1 &&
    dataTypes.indexOf('amount') !== -1
  )
}

export const DocumentImportTable: React.FC<Props> = ({ data, onClose }) => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const initialColNames = Object.keys(data[0])
  const [dataTypes, setDataTypes] = useState<string[]>(initialColNames.map(() => NONE_FIELD))
  const [targetAccount, setTargetAccount] = useState()
  const queryClient = useQueryClient()
  const { bankAccountOptions } = useAssistantAccounts()

  const isReady = React.useMemo(
    () => Boolean(targetAccount) && checkIfReady(dataTypes),
    [dataTypes, targetAccount]
  )

  const formatByKey = (value, dataType): [string, boolean] => {
    switch (dataType) {
      case 'date': {
        return normalizeDateString(value)
      }

      case 'amount': {
        return normalizeNumberString(value)
      }

      case 'reference_number': {
        return value ? [value, false] : [undefined, false]
      }

      default: {
        return [value, false]
      }
    }
  }

  const RenderedValue = React.memo<{ value: any; dataType: string }>(({ value, dataType }) => {
    switch (dataType) {
      case 'date': {
        const [formatted, error] = formatByKey(value, dataType)
        return (
          <CellValue
            value={formatted}
            originalValue={value}
            error={error ? t('document.import.invalidDate') : undefined}
          />
        )
      }

      case 'amount': {
        const [formatted, error] = formatByKey(value, dataType)
        return (
          <CellValue
            value={formatted}
            originalValue={value}
            error={error ? t('document.import.invalidAmount') : undefined}
          />
        )
      }

      case 'none': {
        return <CellValue value={value} isUntouched={true} />
      }

      default: {
        return <CellValue value={value} />
      }
    }
  })

  const columns = React.useMemo(
    () =>
      initialColNames.map((name, index) => ({
        Header: () => (
          <DocumentFieldSelector
            value={dataTypes[index]}
            onChange={value =>
              setDataTypes(dataTypes => {
                const newTypes = [...dataTypes]
                newTypes[index] = value
                return newTypes
              })
            }
          />
        ),
        accessor: name || '-',
        minWidth: 200,
        maxWidth: 500,
        Cell: ({ value }) => <RenderedValue value={value} dataType={dataTypes[index]} />
      })),
    [JSON.stringify(data), dataTypes]
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data
    } as any,
    useFlexLayout
  )

  const generateFinalData = (data, columns, dataTypes) => {
    const mapper = {}
    columns.forEach(({ accessor }, index) => {
      const dataType = dataTypes[index]
      mapper[accessor] = dataType
    })

    return data.map(row => {
      const items = Object.entries(row)
      const data = {}

      items.forEach(([key, value]) => {
        const newKey = mapper[key]
        if (newKey !== NONE_FIELD) {
          const [formatted, error] = formatByKey(value, newKey)

          if (error) {
            throw 'Error'
          }

          data[mapper[key as any]] = formatted
        }
      })

      return data
    })
  }

  const { mutateAsync: importDocumentsMutation, isLoading } = useMutation<unknown, unknown, any>(
    finalData =>
      importDocuments({ businessId, accountId: (targetAccount as any).id, data: finalData }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([businessId, 'documents'])
        onClose()
      },
      onError: ({ data }) => {
        if ('period_missing' in data) {
          toast.error(t('document.import.missingPeriod'))
        }
      }
    }
  )

  const handleClick = () => {
    try {
      const finalData = generateFinalData(data, columns, dataTypes)
      toast.promise(importDocumentsMutation(finalData), {
        loading: t('document.import.loading'),
        success: t('document.import.success'),
        error: t('document.import.importError')
      })
    } catch {
      toast.error(t('document.import.invalidData'))
    }
  }

  return (
    <PageContent>
      <StyledHeader>
        <Alert
          title={t('document.import.alertTitle')}
          description={t('document.import.alertContent')}
        />
        <AccountSelectDropdown
          label={t('document.import.targetAccountLabel')}
          info={t('document.import.targetAccountHint')}
          accounts={bankAccountOptions}
          value={targetAccount}
          onSelect={value => setTargetAccount(value)}
        />
      </StyledHeader>

      <StyledTable>
        <div {...getTableProps({ style: { width: '100%' } })} className="table">
          <div className="header">
            {headerGroups.map((headerGroup, i) => (
              <div key={i} {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column, i) => (
                  <div key={i} {...column.getHeaderProps()} className="th">
                    {column.render('Header')}
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <AnimatedRow key={i} {...row.getRowProps()} className="tr">
                  {row.cells.map((cell, i) => {
                    return (
                      <div key={i} {...cell.getCellProps()} className="td">
                        {cell.render('Cell')}
                      </div>
                    )
                  })}
                </AnimatedRow>
              )
            })}
          </div>
        </div>
      </StyledTable>
      <Footer>
        <Button icon={<FaArrowLeft />} onClick={onClose} intent="default">
          {t('general.cancel')}
        </Button>

        <div style={{ flex: 1 }} />

        <Button
          icon={<FaFeatherAlt />}
          intent="primary"
          showSpinner={isLoading}
          disabled={!isReady || isLoading}
          onClick={handleClick}
        >
          <ButtonContent>
            <ButtonText>{t('document.importDocuments')}</ButtonText>
            <ButtonCount>{data.length}</ButtonCount>
          </ButtonContent>
        </Button>
      </Footer>
    </PageContent>
  )
}
