import { ActionBar, Button } from '@components'
import { useBusinessContext, usePermissionBoundary } from '@context'
import { createPeriod } from '@query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaPlus } from 'react-icons/fa'
import { useMutation, useQueryClient } from 'react-query'
import { UserRole } from '@constants'

export const PeriodActionBar: React.FC = () => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const { businessId } = useBusinessContext()
  const isEditor = usePermissionBoundary(UserRole.EDITOR)

  const { mutate, isLoading } = useMutation(() => createPeriod({ businessId }, {}), {
    onSuccess: () => {
      queryClient.invalidateQueries([businessId, 'periods'])
    }
  })

  const primaryButtons = [
    <Button
      key="create-button"
      icon={<FaPlus />}
      onClick={() => mutate()}
      showSpinner={isLoading}
      disabled={isLoading}
    >
      {t('period.createNew')}
    </Button>
  ]

  return <ActionBar primaryButtons={isEditor ? primaryButtons : []} />
}
