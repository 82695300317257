import { SideSheet } from '@components'
import { Header } from '@containers/Header'
import { useScreen } from '@utils'
import { AnimatePresence, useReducedMotion } from 'framer-motion'
import React, { useCallback, useContext, useMemo } from 'react'
import {
  DanknessContainer,
  StyledColumnLayoutColumn,
  StyledColumnLayoutColumnContent,
  StyledColumnLayoutColumnContentWrapper,
  StyledColumnLayoutColumnWrapper,
  StyledColumnLayoutWrapper
} from './DoubleColumnLayout.styled'

const CARD_MOVEMENT = 16 // px
const CARD_SHADOW_ON = '0px 5px 15px 0px rgba(0, 0, 0, 0.05)'
const CARD_SHADOW_OFF = '0px 0px 0px 0px rgba(0, 0, 0, 0.05)'

interface DoubleColumnLayoutProps {
  header: string
  isRightVisible?: boolean
  onRightClose: () => void
  children: React.ReactNode
}

const LayoutContext = React.createContext({ isVisible: false, onClose: () => undefined })

export const DoubleColumnLayout: React.FC<DoubleColumnLayoutProps> = ({
  header,
  isRightVisible = false,
  onRightClose,
  children
}) => {
  return (
    <DanknessContainer>
      <StyledColumnLayoutWrapper key={header}>
        <Header label={header} />
        <LayoutContext.Provider value={{ isVisible: isRightVisible, onClose: onRightClose }}>
          <StyledColumnLayoutColumnWrapper>{children}</StyledColumnLayoutColumnWrapper>
        </LayoutContext.Provider>
      </StyledColumnLayoutWrapper>
    </DanknessContainer>
  )
}

interface DoubleLayoutColumnProps extends React.DetailedHTMLProps<any, HTMLElement> {
  innerKey: string
  isRight?: boolean
}

export const DoubleColumnLayoutColumn = React.forwardRef<any, DoubleLayoutColumnProps>(
  ({ innerKey, isRight = false, children, ...rest }, ref) => {
    const { lg } = useScreen()
    const isMobile = lg
    const shouldReduceMotion = useReducedMotion()

    const shouldBeVisible = useCallback(
      (isVisible: boolean) => (isRight ? isVisible : true),
      [isRight]
    )
    const { isVisible, onClose } = useContext(LayoutContext)

    const animation = useMemo(() => {
      const amountOfMovement = shouldReduceMotion ? 0 : CARD_MOVEMENT
      const scaleIn = shouldReduceMotion ? 1 : 1.1
      const scaleOut = shouldReduceMotion ? 1 : 0.97

      return {
        variants: {
          initial: {
            opacity: 0,
            scale: scaleIn,
            y: amountOfMovement,
            boxShadow: CARD_SHADOW_OFF,
            zIndex: 0
          },
          animate: {
            opacity: [0, 1, 1, 1],
            scale: [scaleIn, 1, 1, 1],
            y: [amountOfMovement, amountOfMovement, 0, 0],
            boxShadow: [CARD_SHADOW_OFF, CARD_SHADOW_ON, CARD_SHADOW_ON, CARD_SHADOW_OFF],
            zIndex: 1
          },
          exit: {
            opacity: [1, 0.7, 0.7, 0],
            y: [0, -amountOfMovement, 0, 0],
            scale: [1, scaleOut, scaleOut, scaleOut],
            boxShadow: CARD_SHADOW_OFF,
            zIndex: 0
          }
        },
        initial: 'initial',
        animate: 'animate',
        exit: 'exit'
      }
    }, [])

    if (isRight && isMobile) {
      return (
        <SideSheet key={innerKey} isShown={isVisible} handleClose={() => onClose && onClose()}>
          {children}
        </SideSheet>
      )
    }

    return (
      <StyledColumnLayoutColumn fullWidth={!(isRight && isMobile)}>
        <StyledColumnLayoutColumnContentWrapper>
          <AnimatePresence>
            {shouldBeVisible(isVisible) && (
              <StyledColumnLayoutColumnContent
                key={innerKey}
                ref={ref}
                {...(isRight ? animation : {})}
                {...rest}
              >
                {children}
              </StyledColumnLayoutColumnContent>
            )}
          </AnimatePresence>
        </StyledColumnLayoutColumnContentWrapper>
      </StyledColumnLayoutColumn>
    )
  }
)
