import React, { useRef } from 'react'
import { useDragLayer } from 'react-dnd'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

const getItemStyles = (initialOffset, currentOffset) => {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none'
    }
  }
  const { x, y } = currentOffset
  const transform = `translate(${x}px, ${y}px)`
  return {
    transform,
    WebkitTransform: transform
  }
}

interface Props {
  render: (items: unknown[]) => React.ReactNode
  acceptedType: string
}

export const DragLayer: React.FC<Props> = ({ render, acceptedType }) => {
  const rootRef = useRef<HTMLElement>(document.getElementById('modal-root'))

  const { item, itemType, isDragging, initialOffset, clientOffset } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    clientOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging()
  }))

  if (itemType !== acceptedType) return null

  return createPortal(
    isDragging && (
      <StyledLayer>
        <div style={getItemStyles(initialOffset, clientOffset)}>{render(item)}</div>
      </StyledLayer>
    ),
    rootRef.current
  )
}

const StyledLayer = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`
