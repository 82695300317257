import { Button, ButtonProps } from '@components'
import { motion } from 'framer-motion'
import { FaTimes } from 'react-icons/fa'
import styled from 'styled-components'

export const EntryWrapper = styled(motion.div).attrs({
  layout: 'position',
  variants: {
    open: {
      transition: { staggerChildren: 0.02, delayChildren: 0 }
    },
    closed: {
      transition: { staggerChildren: 0.02, staggerDirection: -1 }
    }
  },
  initial: 'closed',
  animate: 'open',
  exit: 'closed'
})`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.sm}rem;
`

export const StyledButton = styled(Button)<ButtonProps>`
  padding: ${({ theme }) => theme.spacing.xxs}rem ${({ theme }) => theme.spacing.xs}rem;
  background: transparent;
`

export const StyledTotalAmount = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  padding: ${({ theme }) => theme.spacing.xxs}rem;
  color: ${({ theme }) => theme.colors.metalGray};
  transition: 0.3s;

  &.warning {
    color: ${({ theme }) => theme.colors.nocfoYellow};
  }

  & > .title {
    font-weight: bold;
  }

  & > .amount {
  }
`

export const AnimatedWarningIconWrapper = styled(motion.div).attrs({
  initial: { opacity: 0, scale: 0.4 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 1 },
  whileHover: { scale: 1.2 }
})`
  svg {
    fill: ${({ theme }) => theme.colors.nocfoYellow};
    margin-bottom: 2px;
  }
`

export const EntryCol = styled.div`
  display: flex;
  flex-direction: column;
`

export const EntryRow = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.xxs}rem;
  align-self: center;

  .account-select-wrapper {
    flex: 2;
    min-width: 200px;
  }

  .vat-select-wrapper {
    flex: 1;
    min-width: 100px;
  }

  .amount-wrapper {
    flex: 1;
    min-width: 100px;
  }

  & .input-wrapper {
    margin: 0;
  }
`

interface IconProps {
  disabled: boolean
}

export const DeleteIcon = styled(FaTimes)<IconProps>`
  align-self: center;
  width: ${({ theme }) => theme.iconSize.xs}rem;
  height: ${({ theme }) => theme.iconSize.xs}rem;
  margin: ${({ theme }) => theme.spacing.sm}rem;
  cursor: pointer;

  ${({ disabled, theme }) => `
    fill: ${disabled ? theme.colors.neutralGray : theme.colors.iconGray};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `};
`

export const TotalAmount = styled(motion.code)`
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.6;
`

export const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  // background: ${({ theme }) => theme.colors.neutralGray};

  & > * {
    align-self: flex-end;
  }

  h4 {
    color: ${({ theme }) => theme.colors.metalGray};
    margin: 0;
  }
`

export const ExpenseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const AddExpenseButton = styled.button`
  display: flex;
  color: ${({ theme }) => theme.colors.metalGray};
  cursor: pointer;
  background: none;
  padding: none;
  border: none;
  margin: 0;
  padding: 0;
  align-self: flex-end;
  outline: none !important;

  &:hover {
    color: ${({ theme }) => theme.colors.nocfoBlue};
  }

  svg {
    margin-right: ${({ theme }) => theme.spacing.xxs}rem;
  }
`

export const Header = styled(motion.h4).attrs({
  layout: 'position'
})`
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.spacing.xs}rem;
`

export const PaymentMethodWrapper = styled(motion.div).attrs({
  layout: 'position'
})`
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;
`
