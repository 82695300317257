interface Props {
  page?: string
  token: string
  isInitialLoad: boolean
  currentLanguage: string
}

export const buildSalaxyLink = ({ page, token, isInitialLoad, currentLanguage }: Props) => {
  const tokenString = `access_token=${token}`
  const hashPart = page ? (isInitialLoad ? `${page}&${tokenString}` : page) : tokenString
  return `${import.meta.env.VITE_SALAXY_EMBED_URL}?skin=nocfo&lang=${currentLanguage}#${hashPart}`
}
