import { useQueryParam, useVirtualList } from '@hooks'
import { IAccount } from '@query'
import { useScreen } from '@utils'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useMemo } from 'react'
import { FaCaretRight } from 'react-icons/fa'
import styled from 'styled-components'
import { AccountListItem } from './AccountListItem'

interface Props {
  accounts: IAccount[]
  onScrollBottom?: () => Promise<any>
}

export const AccountList: React.FC<Props> = ({ accounts, onScrollBottom }) => {
  const [activeId] = useQueryParam('id', value => parseInt(value, 10))
  const { isMobile } = useScreen()

  const accountsAndBadges = useMemo(() => {
    const _accountsAndBadges = []

    accounts.forEach((_, index) => {
      const thisPath = accounts[index]?.header_path
      const lastPath = accounts[index - 1]?.header_path

      // Header changed, render pill
      if (JSON.stringify(thisPath) !== JSON.stringify(lastPath)) {
        _accountsAndBadges.push({
          type: 'path',
          data: thisPath
        })
      }

      _accountsAndBadges.push({
        type: 'item',
        data: accounts[index]
      })
    })

    return _accountsAndBadges
  }, [accounts])

  const { scrollRef, renderItems } = useVirtualList<IAccount>({
    items: accountsAndBadges,
    itemSize: 80,
    onScrollBottom
  })

  const renderItem = (item, style, index) => {
    switch (item.type) {
      case 'path': {
        const path = item.data as string[]
        const head = path.slice(0, path.length - 1)
        const last = path[path.length - 1]
        const key = [...path, index].join('-')

        if (isMobile) {
          return (
            <PathItem key={key} style={style}>
              <AccountMainHeader>{last}</AccountMainHeader>
            </PathItem>
          )
        }

        return (
          <PathItem key={key} style={style}>
            <AccountPathContainer>
              {head.map((i, index) => (
                <PathContainer key={i}>
                  <AccountPathItem key={i}>{i}</AccountPathItem>
                  {index < head.length - 1 && (
                    <AccountPathItem key={i + '-caret'}>
                      <FaCaretRight />
                    </AccountPathItem>
                  )}
                </PathContainer>
              ))}
            </AccountPathContainer>
            <AccountMainHeader>{last}</AccountMainHeader>
          </PathItem>
        )
      }

      case 'item': {
        const account = item.data as IAccount
        const isFirstAccount = accountsAndBadges[index - 1]?.type !== 'item'
        const isLastAccount = accountsAndBadges[index + 1]?.type !== 'item'

        return (
          <AccountItem
            key={account.id}
            style={style}
            isfirst={isFirstAccount}
            islast={isLastAccount}
          >
            <AccountListItem account={account} isActive={account.id === activeId} />
          </AccountItem>
        )
      }
    }
  }

  return (
    <>
      <div ref={scrollRef} style={{ width: '100%', height: '100%', overflow: 'auto' }}>
        <AnimatePresence>{renderItems(renderItem)}</AnimatePresence>
      </div>
    </>
  )
}

const AccountItem = styled(motion.div).attrs({ layout: 'position' })<{
  isfirst: boolean
  islast: boolean
}>`
  overflow: hidden;

  ${({ isfirst }) =>
    isfirst &&
    `
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  `}

  ${({ islast }) =>
    islast &&
    `
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  `}
`

const PathContainer = styled.div`
  display: flex;
`

const PathItem = styled(motion.div).attrs({ layout: 'position' })`
  display: grid;
  align-content: center;
`

const AccountPathContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-content: end;
  align-items: start;
  overflow: hidden;
  max-width: 100%;
`

const AccountPathItem = styled.div`
  color: ${({ theme }) => theme.colors.metalGray};
  opacity: 0.8;
  text-align: center;
  border-radius: 1rem;
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  margin: 0.2rem 0.4rem;
  display: block;
`

const AccountMainHeader = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  color: ${({ theme }) => theme.colors.metalGray};
  font-weight: 500;
  margin: 0.2rem 0.4rem;
  text-align: center;
`
