import { AnimatedContentLoader, Input, InputProps } from '@components'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContactType } from '@root/constants'
import {
  DropdownWrapper,
  Header,
  HeaderDetail,
  HeaderIcon,
  Item,
  ItemColumn,
  StyledBusinessDropdown
} from './BusinessSearchDropdown.styled'
import { useFormContext } from 'react-hook-form'
import { useDebouncedValue } from '@root/hooks'
import { useQuery } from 'react-query'
import { useBusinessContext } from '@root/context'
import { IBasicBusinessInfoFromPrh, fetchPrhNameSearch } from '@query/integrations/prh.ts'
import { createPortal } from 'react-dom'
import { AnimatePresence } from 'framer-motion'
import {
  autoUpdate,
  flip,
  offset,
  size,
  useDismiss,
  useFloating
} from '@floating-ui/react-dom-interactions'
import { TIME_CONSTANTS } from '@root/constants'

interface Props extends InputProps {
  label: string
  onCopy: (businessData: IBasicBusinessInfoFromPrh) => void
}

const BusinessSearchDropdown: React.FC<Props> = ({ label, onCopy }) => {
  const [isOpen, setIsOpen] = useState(true)
  const [prevDataLen, setPrevDataLen] = useState<number>(0)
  const [isFetchEnabled, setIsFetchEnabled] = useState(false)
  const [hasUserTyped, setHasUserTyped] = useState(false)
  const [focusIndex, setFocusIndex] = useState(-1)
  const { t } = useTranslation()
  const { businessId } = useBusinessContext()

  const {
    register,
    watch,
    formState: { errors }
  } = useFormContext()

  const rootRef = useRef<HTMLElement>(document.getElementById('dropdown-root'))

  const [selectedType, nameForSearch] = watch(['type', 'name'])

  const debouncedSearchTerm = useDebouncedValue<string>(nameForSearch, 200)

  const {
    data: results,
    isLoading,
    isError
  } = useQuery(
    [businessId, 'prh_search_business_name', { debouncedSearchTerm }],
    () => fetchPrhNameSearch({ businessName: debouncedSearchTerm }),
    {
      enabled:
        selectedType === ContactType.BUSINESS && debouncedSearchTerm?.length > 2 && isFetchEnabled,
      staleTime: TIME_CONSTANTS.SECONDS_IN_MINUTE * TIME_CONSTANTS.MS_IN_SECOND,
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    setPrevDataLen(results?.results.length)
    if (results !== undefined && results?.results.length !== 0 && hasUserTyped) {
      setIsOpen(true)
    } else if (isLoading && prevDataLen >= 1) {
      // Makes sure that the drop down doesn't flicker but shows the loading icon
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [results])

  const handleClose = () => {
    setIsOpen(false)
    setIsFetchEnabled(false)
    setHasUserTyped(false)
    setFocusIndex(-1)
  }

  const onKeyDown = e => {
    switch (e.key) {
      case 'ArrowDown': {
        if (focusIndex === results?.results.length - 1) {
          setFocusIndex(0)
          break
        }
        setFocusIndex(Math.min(focusIndex + 1, results?.results?.length - 1))
        break
      }

      case 'ArrowUp': {
        if (focusIndex === 0) {
          setFocusIndex(results?.results?.length - 1)
          break
        }
        setFocusIndex(Math.max(focusIndex - 1, 0))
        break
      }

      case 'Enter': {
        const result = results?.results[focusIndex]
        if (result) {
          onCopy(result)
        }
        setFocusIndex(-1)
        setIsOpen(false)
        setIsFetchEnabled(false)
        setHasUserTyped(false)
        e.stopPropagation()
        e.preventDefault()
        break
      }

      case 'Escape': {
        handleClose()
        break
      }
    }
  }

  const { context, x, y, reference, floating, strategy } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    middleware: [
      offset(0),
      flip(),
      size({
        apply: ({ availableWidth, availableHeight, elements }) => {
          Object.assign(elements.floating.style, {
            width: `${Math.min(500, availableWidth - 48)}px`,
            maxHeight: `${Math.min(6000, availableHeight - 48)}px`
          })
        }
      })
    ]
  })

  useDismiss(context)

  const name = register('name', { required: { value: true, message: t('validation.required') } })

  return (
    <DropdownWrapper ref={reference}>
      <Input
        autoComplete="off"
        label={label}
        onKeyDown={onKeyDown}
        required={true}
        errors={errors?.name}
        {...name}
        onChange={e => {
          setIsFetchEnabled(true)
          setHasUserTyped(true)
          name.onChange(e)
        }}
        onBlur={e => {
          name.onBlur(e)
          setHasUserTyped(false)
          setIsOpen(false)
        }}
      />

      {createPortal(
        <AnimatePresence>
          {isOpen && (
            <StyledBusinessDropdown
              ref={floating}
              style={{
                top: y || 0,
                left: x || 0,
                position: strategy
              }}
            >
              <Header>
                {t('contacts.form.businessAutoFill.copyBusiness')}
                <HeaderIcon />
              </Header>
              <HeaderDetail>{t('contacts.form.businessAutoFill.description')}</HeaderDetail>

              <AnimatedContentLoader
                isLoading={isLoading || isError}
                isEmpty={results?.results.length === 0}
                isEmptyDescription={t('contacts.form.businessAutoFill.isEmpty')}
              >
                {results?.results?.map((result, i) => (
                  <Item
                    key={result.business_id}
                    className={focusIndex === i && 'keyboard-focused'}
                    onTouchStart={() => {
                      setIsFetchEnabled(false)
                      setHasUserTyped(false)
                      onCopy(result)
                    }}
                    onMouseDown={() => {
                      setIsFetchEnabled(false)
                      setHasUserTyped(false)
                      onCopy(result)
                    }}
                  >
                    <ItemColumn className="description">{result.business_name}</ItemColumn>
                    <ItemColumn>{result.business_id}</ItemColumn>
                  </Item>
                ))}
              </AnimatedContentLoader>
            </StyledBusinessDropdown>
          )}
        </AnimatePresence>,
        rootRef.current
      )}
    </DropdownWrapper>
  )
}

export default BusinessSearchDropdown
