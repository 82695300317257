import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useBusinessContext } from '@context'
import { useTranslation } from 'react-i18next'
import { IKYCData, kraviaOnboardBusiness } from '@query'
import {
  StyledModelContainer,
  StyledModelContent,
  StyledModelFooter
} from '../Onboarding.styled.tsx'
import { KYCForm } from './KYCForm.tsx'
import { Button } from '@components'
import toast from 'react-hot-toast'

const KRAVIA_KYC_FORM_ID = 'kravia-kyc-form'

export const StartSlide: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const queryClient = useQueryClient()
  const { businessId } = useBusinessContext()
  const { t } = useTranslation()

  const { mutateAsync } = useMutation<unknown, unknown, IKYCData>(
    data => kraviaOnboardBusiness({ businessId }, data),
    {
      onError: () => {
        toast.error(t('general.error'))
      }
    }
  )

  const onSubmit = async (data: IKYCData) => {
    try {
      setIsLoading(true)
      await mutateAsync(data)
      await queryClient.invalidateQueries([businessId, 'kravia_onboard_status'])
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <StyledModelContainer>
      <StyledModelContent>
        <p>{t('invoicing.debtCollection.onboard.description1')}</p>
        <p>{t('invoicing.debtCollection.onboard.description2')}</p>
        <br />
        <KYCForm formId={KRAVIA_KYC_FORM_ID} onSubmit={onSubmit} />
      </StyledModelContent>
      <StyledModelFooter>
        <div style={{ flex: 1 }} />
        <Button
          disabled={isLoading}
          showSpinner={isLoading}
          type="submit"
          form={KRAVIA_KYC_FORM_ID}
          intent="success"
        >
          {t('general.next')}
        </Button>
      </StyledModelFooter>
    </StyledModelContainer>
  )
}
