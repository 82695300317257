import { IKraviaOnboardInfo } from '@query'
import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { StartSlide } from '@pages/InvoicingPage/DebtCollection/trackerMessages/Onboarding/onboardingSlides/StartSlide.tsx'
import { SignatureSlide } from '@pages/InvoicingPage/DebtCollection/trackerMessages/Onboarding/onboardingSlides/SignatureSlide.tsx'
import { SuccessSlide } from '@pages/InvoicingPage/DebtCollection/trackerMessages/Onboarding/onboardingSlides/SuccessSlide.tsx'

interface Props {
  data: IKraviaOnboardInfo
  onRegisterInvoice: () => Promise<any>
}

export const OnboardingView: React.FC<Props> = ({ data, onRegisterInvoice }) => {
  const showStart = data?.kravia_enabled === false
  const showWait = data?.kravia_verified === false && !showStart
  const showSuccess = data?.kravia_verified === true && !showWait && !showStart

  return (
    <AnimatePresence>
      {showStart && <StartSlide />}
      {showWait && <SignatureSlide />}
      {showSuccess && <SuccessSlide onRegisterInvoice={onRegisterInvoice} />}
    </AnimatePresence>
  )
}
