import { AnimatedContentLoader, Input } from '@components'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  DropdownWrapper,
  Header,
  HeaderDetail,
  HeaderIcon,
  Item,
  ItemColumn,
  StyledBusinessDropdown
} from './BusinessSearchDropdown.styled'
import { useFormContext } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useBusinessContext } from '@root/context'
import { IPrhSearchResults, fetchPrhSearch } from '@query/integrations/prh.ts'
import { createPortal } from 'react-dom'
import { AnimatePresence } from 'framer-motion'
import {
  autoUpdate,
  flip,
  offset,
  size,
  useDismiss,
  useFloating
} from '@floating-ui/react-dom-interactions'
import { TIME_CONSTANTS } from '@root/constants'

interface IProps {
  onCopy: (data: IPrhSearchResults) => void
  required: boolean
}

const BusinessIdDropdown: React.FC<IProps> = ({ onCopy, required }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [hasUserTyped, setHasUserTyped] = useState(false)
  const [focusIndex, setFocusIndex] = useState(-1)
  const { t } = useTranslation()
  const { businessId } = useBusinessContext()
  const businessIdRegex = new RegExp('(^\\d{7}-\\d)$')

  const {
    register,
    watch,
    formState: { errors }
  } = useFormContext()

  const rootRef = useRef<HTMLElement>(document.getElementById('dropdown-root'))

  const [contactBusinessId] = watch(['contact_business_id'])

  const {
    data: resultsFromFetch,
    isLoading,
    isError
  } = useQuery(
    [businessId, 'prh_search', { contactBusinessId }],
    () => fetchPrhSearch({ businessId: contactBusinessId }),
    {
      enabled: businessIdRegex.test(contactBusinessId),
      staleTime: TIME_CONSTANTS.SECONDS_IN_MINUTE * TIME_CONSTANTS.MS_IN_SECOND
    }
  )

  const results = resultsFromFetch ? [resultsFromFetch] : []

  const handleClose = () => {
    setIsOpen(false)
    setHasUserTyped(false)
    setFocusIndex(-1)
  }

  useEffect(() => {
    if (businessIdRegex.test(contactBusinessId) && resultsFromFetch && hasUserTyped) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [resultsFromFetch])

  const onKeyDown = e => {
    switch (e.key) {
      case 'ArrowDown': {
        if (focusIndex === results?.length - 1) {
          setFocusIndex(0)
          break
        }
        setFocusIndex(Math.min(focusIndex + 1, results?.length - 1))
        break
      }

      case 'ArrowUp': {
        if (focusIndex === 0) {
          setFocusIndex(results?.length - 1)
          break
        }
        setFocusIndex(Math.max(focusIndex - 1, 0))
        break
      }

      case 'Enter': {
        const result = results[focusIndex]
        if (result) {
          onCopy(result)
        }
        setFocusIndex(-1)
        setIsOpen(false)
        setHasUserTyped(false)
        e.stopPropagation()
        e.preventDefault()
        break
      }

      case 'Escape': {
        handleClose()
        break
      }
    }
  }

  const { context, x, y, reference, floating, strategy } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    middleware: [
      offset(0),
      flip(),
      size({
        apply: ({ availableWidth, availableHeight, elements }) => {
          Object.assign(elements.floating.style, {
            width: `${Math.min(500, availableWidth - 48)}px`,
            maxHeight: `${Math.min(6000, availableHeight - 48)}px`
          })
        }
      })
    ]
  })

  useDismiss(context)

  const registeredBusinessId = { ...register('contact_business_id', { shouldUnregister: true }) }

  return (
    <DropdownWrapper ref={reference}>
      <Input
        label={t('contacts.form.businessId')}
        onKeyDown={onKeyDown}
        errors={errors?.contact_business_id}
        {...registeredBusinessId}
        required={required}
        onChange={e => {
          registeredBusinessId.onChange(e)
          setHasUserTyped(true)
        }}
        onBlur={e => {
          registeredBusinessId.onBlur(e)
          setIsOpen(false)
          setHasUserTyped(false)
        }}
      />

      {createPortal(
        <AnimatePresence>
          {isOpen && (
            <StyledBusinessDropdown
              ref={floating}
              style={{
                top: y || 0,
                left: x || 0,
                position: strategy
              }}
            >
              <Header>
                {t('contacts.form.businessAutoFill.copyBusiness')}
                <HeaderIcon />
              </Header>
              <HeaderDetail>{t('contacts.form.businessAutoFill.description')}</HeaderDetail>

              <AnimatedContentLoader
                isLoading={isLoading && isError}
                isEmpty={results?.length === 0}
                isEmptyDescription={t('contacts.form.businessAutoFill.isEmpty')}
              >
                {results?.map((result, i) => (
                  <Item
                    key={result.business_id}
                    className={focusIndex === i && 'keyboard-focused'}
                    onTouchStart={() => {
                      onCopy(result)
                      setIsOpen(false)
                      setHasUserTyped(false)
                    }}
                    onMouseDown={() => {
                      onCopy(result)
                      setIsOpen(false)
                      setHasUserTyped(false)
                    }}
                  >
                    <ItemColumn className="description">{result.business_name}</ItemColumn>
                    <ItemColumn>{result.business_id}</ItemColumn>
                  </Item>
                ))}
              </AnimatedContentLoader>
            </StyledBusinessDropdown>
          )}
        </AnimatePresence>,
        rootRef.current
      )}
    </DropdownWrapper>
  )
}

export default BusinessIdDropdown
