import {
  useDismiss,
  autoUpdate,
  flip,
  offset,
  Placement,
  useFloating,
  useHover
} from '@floating-ui/react-dom-interactions'
import { AnimatePresence, motion } from 'framer-motion'
import React, { HTMLAttributes, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

interface Props extends HTMLAttributes<HTMLSpanElement> {
  tooltip: string
  placement?: Placement
  openDelay?: number
  closeDelay?: number
}

export const TextTooltip: React.FC<Props> = ({
  tooltip,
  placement = 'bottom-end',
  children,
  openDelay = 200,
  closeDelay = 600,
  ...rest
}) => {
  const rootRef = useRef<HTMLElement>(document.getElementById('dropdown-root'))
  const [infoOpen, setInfoOpen] = useState(false)

  const { context, x, y, reference, floating, strategy } = useFloating({
    open: infoOpen,
    onOpenChange: setInfoOpen,
    whileElementsMounted: autoUpdate,
    placement,
    middleware: [offset(10), flip()]
  })

  useHover(context, {
    mouseOnly: true,
    delay: {
      open: openDelay,
      close: closeDelay
    }
  })

  useDismiss(context, { referencePress: true })

  return (
    <Info {...rest} ref={reference}>
      {children}
      {createPortal(
        <AnimatePresence>
          {infoOpen && (
            <InfoPopup
              ref={floating}
              variants={{
                hide: { opacity: 0, scale: 0.7, x: -20, y: -20, pointerEvents: 'none' },
                open: { opacity: 1, scale: 1.0, x: 0, y: 0, pointerEvents: 'all' }
              }}
              initial="hide"
              animate="open"
              exit="hide"
              key="info-popup"
              style={{
                top: y || 0,
                left: x || 0,
                position: strategy
              }}
            >
              {tooltip}
            </InfoPopup>
          )}
        </AnimatePresence>,
        rootRef.current
      )}
    </Info>
  )
}

const Info = styled.span`
  svg {
    cursor: pointer;
  }
`

const InfoPopup = styled(motion.div).attrs({
  variants: {
    hide: { opacity: 0, scale: 0.7, x: -20, y: -20, pointerEvents: 'none' },
    open: { opacity: 1, scale: 1.0, x: 0, y: 0, pointerEvents: 'all' }
  },
  initial: 'hide',
  animate: 'open',
  exit: 'hide'
})`
  z-index: 10000;
  position: absolute;
  background: ${({ theme }) => theme.colors.menuBg};
  color: white;
  box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.14);
  border-radius: 1rem;
  padding: ${({ theme }) => theme.spacing.md}rem;
  max-width: 300px;
  width: fit-content;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-word;
  line-height: 1.2rem;
  font-weight: 500;
`
