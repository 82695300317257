import { Input, InputProps } from '@components'
import { useDebouncedValue } from '@hooks'
import React, { forwardRef, useEffect, useState } from 'react'
import { FaSearch, FaTimes } from 'react-icons/fa'
import styled from 'styled-components'

interface SearchBarProps extends InputProps {
  onSearch: (string) => void
  delay?: number
}

export const SearchInput: React.ForwardRefExoticComponent<SearchBarProps> = forwardRef(
  ({ onSearch, delay = 300, ...rest }, ref) => {
    const [searchTerm, setSearchTerm] = useState('')
    const debouncedSearchTerm = useDebouncedValue<string>(searchTerm, delay)

    useEffect(() => onSearch(searchTerm), [debouncedSearchTerm])

    const onChange = e => setSearchTerm(e.currentTarget.value)

    return (
      <SearchWrapper>
        <LookingGlassIcon size={16} />

        <Input
          ref={ref}
          onChange={onChange}
          value={searchTerm}
          {...rest}
          style={{ marginBottom: 0 }}
        />
        {searchTerm && (
          <EmptyIconWrapper onClick={() => setSearchTerm('')}>
            <FaTimes size={16} />
          </EmptyIconWrapper>
        )}
      </SearchWrapper>
    )
  }
)

const SearchWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  .input-wrapper {
    margin-bottom: 0;
    flex: 1;
    padding-left: 2rem;
  }
`

const LookingGlassIcon = styled(FaSearch)`
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-42%);
  color: ${({ theme }) => theme.colors.metalGray}99;
  pointer-events: none;
  margin-left: 0.7rem;
  padding-bottom: ${({ theme }) => theme.spacing.xxxs}rem;
`

const EmptyIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  padding: 0.8rem;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.metalGray};

  svg {
    align-self: center;
    vertical-align: center;
  }
`
