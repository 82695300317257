import { Input } from '@components'
import { network } from '@utils'
import React from 'react'
import { get, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SlideText, SlideTitle } from './slide.styled'

export const BusinessIdSlide: React.FC = () => {
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext()
  const [t] = useTranslation()

  const asyncValidation = async (businessId: string) => {
    try {
      const { data } = await network.httpClient.get(
        `/v1/business_id_lookup/?business_id=${businessId}`
      )
      setValue('business.name', data.name, {})
    } catch (e) {
      // CASE: Business ID is already reserved
      if (e?.response?.status === 409) {
        return t('onboarding.businessIdSlide.businessIdReservedShort')
      }
    }
  }

  const validateBusinessId = async (businessId: string) => {
    const businessIdRegex = /\d{7}-\d/
    if (!businessIdRegex.test(businessId)) {
      return t('validation.finnishBusinessId')
    }

    const validation = await asyncValidation(businessId)
    if (validation) {
      return validation
    }

    return true
  }

  return (
    <>
      <SlideTitle>{t('onboarding.businessIdSlide.title')}</SlideTitle>
      <SlideText>{t('onboarding.businessIdSlide.message')}</SlideText>

      <Input
        id="business_id-input"
        label={t('onboarding.businessIdSlide.inputLabel')}
        placeholder={t('onboarding.businessIdSlide.inputPlaceholder')}
        type="text"
        errors={get(errors, 'business.business_id')}
        autoFocus={true}
        {...register('business.business_id', {
          minLength: {
            value: 9,
            message: t('validation.finnishBusinessId')
          },
          maxLength: {
            value: 9,
            message: t('validation.finnishBusinessId')
          },
          validate: validateBusinessId
        })}
      />
    </>
  )
}
