import imgSrc from '@assets/undraw/undraw_secure_login_pdn4.svg'
import { BasicPage, Button, Input } from '@components'
import { useQueryParam } from '@hooks'
import { ISignUpData, nocfoAccountSignUp } from '@query'
import { setAPIErrors } from '@utils'
import React from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'

interface ILoginForm {
  email: string
  password: string
  password_repeat: string
}

export const NocfoAccountSignupPage: React.FC = () => {
  const [isMobileAppLogin] = useQueryParam('app', value => !!value)
  const history = useHistory()
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<ILoginForm>()

  const signUpMutation = useMutation<unknown, unknown, ISignUpData>(
    data => nocfoAccountSignUp(null, data),
    {
      onSuccess: () => {
        history.push('/nocfo-signin')
      },
      onError: ({ status, data }) => {
        if (status === 400) {
          setAPIErrors(data, setError)
        }
      }
    }
  )

  const onSubmit = handleSubmit(async data => {
    // Extra password validation
    if (data?.password !== data?.password_repeat) {
      setError('password_repeat', { message: t('validation.passwordsDoNotMatch') })
      return
    }

    const promise = signUpMutation.mutateAsync({ email: data.email, password: data.password })
    await toast.promise(promise, {
      loading: t('nocfoLogin.signingUpLoading'),
      success: t('nocfoLogin.signingUpSuccess'),
      error: t('general.error')
    })
  })

  return (
    <BasicPage title={t('nocfoLogin.signupTitle')} imageUrl={imgSrc}>
      <StyledForm onSubmit={onSubmit}>
        <Input
          label={t('nocfoLogin.email')}
          errors={errors.email}
          {...register('email', {
            required: { value: true, message: t('validation.required') }
          })}
        />
        <Input
          label={t('nocfoLogin.password')}
          type="password"
          {...register('password', {
            required: { value: true, message: t('validation.required') }
          })}
          errors={errors.password}
        />
        <Input
          label={t('nocfoLogin.passwordRepeat')}
          type="password"
          {...register('password_repeat', {
            required: { value: true, message: t('validation.required') }
          })}
          errors={errors.password_repeat}
        />

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            type="submit"
            disabled={signUpMutation.isLoading}
            showSpinner={signUpMutation.isLoading}
            intent="success"
          >
            {t('nocfoLogin.signupButton')}
          </Button>
        </div>

        <br />
        <br />
        {!isMobileAppLogin && (
          <StyledLink
            to={{
              pathname: '/signup',
              search: window.location.search
            }}
          >
            {t('nocfoLogin.signupOptions')}
          </StyledLink>
        )}
      </StyledForm>
    </BasicPage>
  )
}

const StyledLink = styled(Link)`
  display: block;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  color: ${({ theme }) => theme.colors.metalGray};
  margin-bottom: ${({ theme }) => theme.spacing.xxs}rem;
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`
