import { PagerBalls } from '@components'
import { COASize } from '@constants'
import { useQueryParam } from '@hooks'
import { BackButton, CreateButton, NextButton } from '@pages/OnboardingPage/slides/slide.styled'
import { createBusiness, createPeriod, IBusiness, IPeriod } from '@query'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useCallback, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
  AllDoneSlide,
  BusinessFormSlide,
  BusinessIdSlide,
  BusinessNameSlide,
  FirstFiscalPeriodSlide,
  FiscalPeriodSlide,
  VatPeriodSlide
} from './slides'

interface IBusinessOnboardingForm {
  business: Partial<IBusiness>
  period: Partial<IPeriod>
}

export const OnboardingPage: React.FC = () => {
  const history = useHistory()
  const [isMobileAppContext] = useQueryParam('app', value => !!value)
  const [mobileRedirectUrl] = useQueryParam('redirectUrl')
  const queryClient = useQueryClient()

  const [slideIndex, setSlideIndex] = useState(0)
  const methods = useForm<IBusinessOnboardingForm>({})
  const {
    trigger: triggerValidation,
    formState: { isValidating }
  } = methods

  const Slides = useMemo(
    () => [
      BusinessIdSlide,
      BusinessNameSlide,
      BusinessFormSlide,
      VatPeriodSlide,
      FirstFiscalPeriodSlide,
      FiscalPeriodSlide
    ],
    []
  )
  const Slide = useMemo(() => Slides[slideIndex], [slideIndex])

  const prevPage = useCallback(
    () => setSlideIndex(index => Math.max(index - 1, 0)),
    [Slides.length]
  )

  const nextPage = useCallback(async () => {
    const isFormValid = await triggerValidation()
    if (isFormValid) {
      setSlideIndex(index => Math.min(index + 1, Slides.length - 1))
    }
  }, [Slides.length])

  const showNext = useMemo(() => slideIndex < Slides.length - 1, [slideIndex, Slides.length])
  const showPrev = useMemo(() => slideIndex > 0, [slideIndex])
  const showSubmit = useMemo(() => slideIndex == Slides.length - 1, [slideIndex, Slides.length])

  const {
    isLoading: isBusinessLoading,
    isSuccess: isBusinessSuccess,
    isError: isBusinessError,
    mutateAsync: mutateBusiness
  } = useMutation<unknown, unknown, IBusinessOnboardingForm>(data =>
    createBusiness({ coaSize: COASize.SM }, data.business)
  )
  const {
    isLoading: isPeriodLoading,
    isSuccess: isPeriodSuccess,
    isError: isPeriodError,
    mutateAsync: mutatePeriod
  } = useMutation<unknown, unknown, IBusinessOnboardingForm>(data =>
    createPeriod({ businessId: data.business.business_id }, data.period)
  )
  const isLoading = isBusinessLoading || isPeriodLoading
  const isSuccess = isBusinessSuccess && isPeriodSuccess
  const isError = isBusinessError || isPeriodError
  const showAllDone = isLoading || isSuccess || isError

  const performMobileRedirect = (businessId?: string) => {
    location.replace(`${mobileRedirectUrl}${businessId ? `?businessId=${businessId}` : ''}`)
  }

  const handleSubmit = methods.handleSubmit(async data => {
    // Create business
    await mutateBusiness(data)

    // Create period and refetch user and businesses
    await Promise.all([
      mutatePeriod(data),
      queryClient.invalidateQueries('user'),
      queryClient.invalidateQueries(['businesses'], { exact: false })
    ])

    setTimeout(() => {
      if (isMobileAppContext) {
        performMobileRedirect(data.business.business_id)
      } else {
        history.push(`/${data.business.business_id}/bank-integration-flow/start`)
      }
    }, 4000)
  })

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit}
        onKeyDown={async e => {
          if (e.key === 'Enter') {
            e.preventDefault()
            await nextPage()
          }
        }}
      >
        <StyledPage>
          <StyledPageContent>
            {!showAllDone && <PagerBalls slideIndex={slideIndex} slideCount={Slides.length} />}

            <AnimatePresence mode="wait">
              {showAllDone ? (
                <AnimatedSlide key={`slide-all-done`}>
                  <AllDoneSlide isLoading={isLoading} isSuccess={isSuccess} />
                </AnimatedSlide>
              ) : (
                <AnimatedSlide key={`slide-${slideIndex}`}>
                  <Slide />
                </AnimatedSlide>
              )}
            </AnimatePresence>

            {!showAllDone && (
              <>
                <AnimatedButtonWrapper>
                  <AnimatePresence mode="wait">
                    {
                      <AnimatedButton key="back-btn" className="back-btn">
                        <BackButton onClick={() => (showPrev ? prevPage() : history.goBack())} />
                      </AnimatedButton>
                    }
                  </AnimatePresence>
                  <div style={{ flex: 1 }} />
                  <AnimatePresence mode="wait">
                    {showNext && (
                      <AnimatedButton key="next-btn" className="next-btn">
                        <NextButton
                          showSpinner={isValidating}
                          disabled={!showNext || isValidating}
                          onClick={nextPage}
                        />
                      </AnimatedButton>
                    )}

                    {showSubmit && (
                      <AnimatedButton key="submit-btn" className="done-btn">
                        <CreateButton disabled={!showSubmit} />
                      </AnimatedButton>
                    )}
                  </AnimatePresence>
                </AnimatedButtonWrapper>
              </>
            )}
          </StyledPageContent>
        </StyledPage>
      </form>
    </FormProvider>
  )
}

const StyledPage = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.neutralGray};
  padding: ${({ theme }) => theme.spacing.xxl}rem;

  @media (max-width: 768px) {
    padding: 0;
  }
`

const StyledPageContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 1000px;
  max-width: 600px;
  padding: ${({ theme }) => theme.spacing.md}rem;
  border-radius: 1rem;
  background: ${({ theme }) => theme.colors.neutralWhite};

  @media (max-width: 768px) {
    max-width: 100%;
    max-height: 100%;
  }
`

const AnimatedSlide = styled(motion.div).attrs({
  initial: { opacity: 0, y: -20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 },
  transition: {
    duration: 0.4
  }
})`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const AnimatedButton = styled(motion.div).attrs({
  initial: { opacity: 0, scale: 0.8 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.8 },
  transition: {
    duration: 0.4
  }
})``

const AnimatedButtonWrapper = styled(motion.div).attrs({
  layout: 'position'
})`
  display: flex;
  justify-content: flex-end;
`
