import { ModalContainer } from '@components'
import { FloatingPortal } from '@floating-ui/react'
import { useScreen } from '@utils'
import { AnimatePresence } from 'framer-motion'
import React, { useCallback, useMemo, useState } from 'react'
import {
  DoubleModalWrapper,
  ModalColumn,
  ModalOverlay,
  SecondaryModalContent
} from './DoubleModal.styled'
import { useHotkeys } from 'react-hotkeys-hook'

interface Props {
  isVisible: boolean
  mainContent: React.ReactNode
  mainHeader: string
  mainFooter?: React.ReactNode
  onClose: () => any
  secondaryContent: React.ReactNode
  oneModalWidthMin?: number
  oneModalWidthMax?: number
  height?: number
}

const MODAL_GAP_PX = 15

export const DoubleModal: React.FC<Props> = ({
  isVisible,
  mainContent,
  mainHeader,
  mainFooter,
  onClose,
  secondaryContent,
  oneModalWidthMin = 700,
  oneModalWidthMax = 1000,
  height = 900
}) => {
  const [, setMouseDown] = useState(false)
  const { width: screenWidth, height: screenHeight } = useScreen()

  const getFullModalWidth = useCallback(
    (oneModalWidth: number) => oneModalWidth * 2 + MODAL_GAP_PX * 3,
    [MODAL_GAP_PX]
  )

  useHotkeys('escape', () => onClose())

  const oneModalWidth = useMemo(() => {
    const calcWidth = (screenWidth - MODAL_GAP_PX * 3) / 2
    return Math.max(Math.min(calcWidth, oneModalWidthMax), oneModalWidthMin)
  }, [screenWidth, oneModalWidthMin, oneModalWidthMax])

  const showStack = useMemo(
    () => screenWidth < getFullModalWidth(oneModalWidth),
    [screenWidth, oneModalWidth]
  )

  const animateLeft = useMemo(
    () => ({
      width: oneModalWidth,
      height: 'auto',
      maxHeight: Math.min(height, screenHeight) - MODAL_GAP_PX * 2,
      x: showStack ? -oneModalWidth / 2 : -oneModalWidth - MODAL_GAP_PX / 2,
      y: -Math.min(height, screenHeight) / 2 + MODAL_GAP_PX,
      scale: showStack ? 0.8 : 1
    }),
    [showStack, screenHeight, screenWidth]
  )

  const animateRight = useMemo(
    () => ({
      width: Math.min(oneModalWidth, screenWidth),
      height: showStack
        ? Math.min(height, screenHeight)
        : Math.min(height, screenHeight) - MODAL_GAP_PX * 2,
      x: showStack ? -Math.min(oneModalWidth, screenWidth) / 2 : MODAL_GAP_PX / 2,
      y: showStack
        ? -Math.min(height, screenHeight) / 2
        : -Math.min(height, screenHeight) / 2 + MODAL_GAP_PX
    }),
    [showStack, screenHeight, screenWidth]
  )

  return (
    <FloatingPortal key="double-modal">
      <AnimatePresence>
        {isVisible && (
          <ModalOverlay
            key="double-modal-overlay"
            onMouseDown={() => setMouseDown(true)}
            onTouchStart={() => setMouseDown(true)}
            onClick={() =>
              setMouseDown(value => {
                value && onClose()
                return false
              })
            }
          >
            <DoubleModalWrapper
              key="double-modal-wrapper"
              onMouseDown={e => e.stopPropagation()}
              onClick={e => e.stopPropagation()}
            >
              <ModalColumn key="modal-left" initial={false} animate={animateLeft}>
                <AnimatePresence>
                  {!showStack && (
                    <SecondaryModalContent key="modal-left-content">
                      {secondaryContent}
                    </SecondaryModalContent>
                  )}
                </AnimatePresence>
              </ModalColumn>

              <ModalColumn key="modal-right" initial={false} animate={animateRight}>
                <ModalContainer header={mainHeader} footer={mainFooter} onClose={onClose}>
                  {mainContent}
                </ModalContainer>
              </ModalColumn>
            </DoubleModalWrapper>
          </ModalOverlay>
        )}
      </AnimatePresence>
    </FloatingPortal>
  )
}
