import React from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import cn from 'classnames'
import { useState } from 'react'
import { Spinner } from 'evergreen-ui'
import imgSrc from '@assets/undraw/undraw_add_files_re_v09g.svg'
import { Alert } from '@components'
import { Trans, useTranslation } from 'react-i18next'

interface Props {
  onFileUpload: (file: File) => Promise<any>
}

export const UploadCSVSlide: React.FC<Props> = ({ onFileUpload }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  const { open, getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    noDragEventsBubbling: true,
    accept: '.csv',
    onDrop: acceptedFiles => {
      setIsLoading(true)
      onFileUpload(acceptedFiles[0]).finally(() => setIsLoading(false))
    }
  })

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    )
  }

  return (
    <Wrapper>
      <Alert
        type="info"
        title={t('components.importWizard.upload.title')}
        description={
          <Trans i18nKey="components.importWizard.upload.description">
            <a href={t('link.docs.csvImport')} target="_blank" rel="noreferrer" />
          </Trans>
        }
      />
      <StyledDropzone className={cn({ isDragActive })} {...getRootProps()} onClick={() => open()}>
        <img src={imgSrc} width={100} />
        <input {...getInputProps()} />
        {isDragActive ? (
          <span>{t('components.importWizard.upload.dropActiveInfo')}</span>
        ) : (
          <span>{t('components.importWizard.upload.dropInfo')}</span>
        )}
      </StyledDropzone>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg}rem;
`

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

const StyledDropzone = styled.div`
  flex: 1;
  padding: ${({ theme }) => theme.spacing.xl}rem;
  border: 3px dashed ${({ theme }) => theme.colors.neutralGray};
  border-radius: 1rem;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg}rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.metalGray};
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  cursor: pointer;

  &.isDragActive {
    border-color: ${({ theme }) => theme.colors.nocfoBlue};
    color: ${({ theme }) => theme.colors.nocfoBlue};
  }
`
