import {
  Button,
  DocumentCopyDropdown,
  Input,
  Select,
  ContactSelector,
  InlineTeaserView
} from '@components'
import { BLUEPRINT_TYPE_OPTIONS } from '@constants'
import { AttachmentSelector } from '@containers/AttachmentSelector'
import { useBusinessContext, useFeature } from '@context'
import { BlueprintEditor } from '@pages/DocumentPage/DocumentForm/BlueprintEditor'
import { IDocument } from '@query'
import { IAccountingSuggestion } from '@query/suggestions'
import { getDocumentCopyValues } from '@utils/copyDocument'
import React, { useCallback, useEffect } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FaCheck } from 'react-icons/fa'
import styled from 'styled-components'
import { cleanBlueprint } from '@utils'
import { MdOutlineRocketLaunch } from 'react-icons/md'
import { useAllowUpload } from '@root/hooks/useAllowUpload'

export interface SuggestionFormProps {
  document: IDocument
  suggestion: IAccountingSuggestion
  onCreate: (data: IAccountingSuggestion) => Promise<any>
  onClose: () => void
  onValuesUpdate?: (values: Partial<IAccountingSuggestion>) => void
}

export const SuggestionForm: React.FC<SuggestionFormProps> = ({
  document,
  suggestion,
  onCreate,
  onClose,
  onValuesUpdate
}) => {
  const { businessId } = useBusinessContext()
  const { t } = useTranslation()

  const methods = useForm<IAccountingSuggestion>({
    defaultValues: {
      ...suggestion,
      attachment_ids: [...suggestion.attachment_ids, ...document.attachment_ids]
    }
  })
  const {
    register,
    control,
    setValue,
    getValues,
    watch,
    handleSubmit,
    reset,
    formState: { isSubmitting }
  } = methods
  const enableContactSuggestion = !watch('contact')

  const onSubmit = handleSubmit(data => {
    const newData = {
      ...data,
      blueprint: cleanBlueprint(data?.blueprint_type, data?.blueprint)
    }
    onCreate(newData).catch(() => {
      reset(newData)
    })
  })

  const isAllowedToUpload = useAllowUpload()
  const hasUnlimitedStorage = useFeature('feature_unlimited_storage', true)

  // WATCH VALUES
  // ============

  const values = watch()
  useEffect(() => {
    if (onValuesUpdate) onValuesUpdate(values)
  }, [JSON.stringify(values)])

  const showFileTeaser = !hasUnlimitedStorage && values.attachment_ids?.length === 0

  // COPY FUNCTIONALITY
  // ==================

  const onCopy = useCallback(documentToCopy => {
    reset(
      {
        ...getValues(),
        ...getDocumentCopyValues(documentToCopy, suggestion.import_data)
      },
      { keepDefaultValues: true }
    )
  }, [])

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={onSubmit}>
        <StyledFormContent>
          <Input label={t('document.date')} type="date" disabled {...register('date')} />

          {/* DESCRIPTION & COPY FUNCTIONALITY */}
          <Controller
            control={control}
            name="description"
            rules={{ required: { value: true, message: t('validation.required') } }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <DocumentCopyDropdown
                key="document-copy-dropdown"
                label={t('document.description')}
                placeholder={t('document.descriptionPlaceholder')}
                info={t('document.descriptionInfo')}
                id="document-description"
                businessId={businessId}
                importModelId={suggestion.import_data.id}
                onChange={onChange}
                onCopy={onCopy}
                value={value}
                errors={error}
                required={true}
              />
            )}
          />

          <Controller
            name="contact"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <ContactSelector
                {...field}
                errors={error}
                ref={null}
                clearSelectedItem={() => setValue('contact', null)}
                required={false}
                isSuggestionEnabled={enableContactSuggestion}
                suggestionQuery={{ query: suggestion?.contact_name }}
                fetchOnlyInvoicableContacts={false}
                placeholder={t('document.contactPlaceholder')}
                label={t('document.contact')}
                info={t('document.contactInfo')}
                noDataMessage={t('contacts.contactSelector.noDataDocumentForm')}
              ></ContactSelector>
            )}
          />

          <br />

          <Controller
            name="blueprint_type"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Select
                label={t('document.selectedType')}
                onChange={onChange}
                value={value}
                errors={error}
                required={true}
              >
                {BLUEPRINT_TYPE_OPTIONS.map(({ value, labelKey }) => (
                  <option key={value} value={value}>
                    {t(labelKey)}
                  </option>
                ))}
              </Select>
            )}
          />
          <BlueprintEditor
            expectedPaymentAccount={suggestion.import_data.payment_account_id}
            expectedPaymentAccountAmount={suggestion.import_data.amount}
          />

          <h4>{t('document.attachments')}</h4>
          {showFileTeaser ? (
            <InlineTeaserView
              icon={<MdOutlineRocketLaunch />}
              iconText={t('inlineTeaser.iconText')}
              text={t('inlineTeaser.files')}
            />
          ) : (
            <Controller
              name="attachment_ids"
              control={control}
              render={({ field: { value, onChange } }) => (
                <AttachmentSelector
                  value={value as number[]}
                  onChange={onChange}
                  showRemoveInsteadOfViewLink={true}
                  disabled={!isAllowedToUpload}
                />
              )}
            />
          )}
        </StyledFormContent>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button type="button" onClick={() => onClose()}>
            {t('general.cancel')}
          </Button>
          <Button
            intent="success"
            icon={<FaCheck />}
            disabled={isSubmitting}
            showSpinner={isSubmitting}
          >
            {t('general.save')}
          </Button>
        </div>
      </StyledForm>
    </FormProvider>
  )
}

const StyledForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const StyledFormContent = styled.div`
  flex: 1;
  overflow: auto;
`
