import countries from 'i18n-iso-countries'
import enCountries from 'i18n-iso-countries/langs/en.json'
import fiCountries from 'i18n-iso-countries/langs/fi.json'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import english from './locales/en.json'
import finnish from './locales/fi.json'
import { getCurrentLocale, ValidLocale } from '@localisation/utils.ts'

countries.registerLocale(fiCountries)
countries.registerLocale(enCountries)

enum Format {
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage'
}

const NAMESPACE = 'common_localisation'

export const translations = {
  [ValidLocale.FI]: { [NAMESPACE]: finnish },
  [ValidLocale.EN]: { [NAMESPACE]: english }
}

export const i18nInstance = i18next.createInstance()

export const setI18nLanguage = i18nInstance.changeLanguage.bind(i18nInstance)

export const initializei18n = (): void => {
  i18nInstance.use(initReactI18next).init({
    resources: translations,
    lng: getCurrentLocale(),
    supportedLngs: Object.values(ValidLocale),
    fallbackLng: ValidLocale.EN,
    ns: [NAMESPACE],
    defaultNS: NAMESPACE,
    interpolation: {
      escapeValue: false,
      format: (value, format: Format) => {
        if (format === Format.CURRENCY) {
          return new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' }).format(
            value
          )
        }
        if (format === Format.PERCENTAGE) {
          return Intl.NumberFormat('fi-FI', {
            style: 'percent',
            maximumFractionDigits: 2
          }).format(value / 100)
        }
        return value
      }
    },
    debug: false,
    react: {
      useSuspense: true
    }
  })
}
