import { CSVImportWizard, FieldToMap } from './base'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { ContactType, IContact } from '@query'
import { CONTACT_TYPE_OPTIONS, INVOICING_LANGUAGE_OPTIONS, InvoicingLanguage } from '@constants'
import { Badge } from '@components'
import { createImportJob, createImportJobDryRun, ImportJobType } from '@query/importJob.ts'
import { useBusinessContext } from '@context'

interface Props {
  onComplete: () => void
}

export const ContactImportWizard: React.FC<Props> = ({ onComplete }) => {
  const { t } = useTranslation()
  const { businessId } = useBusinessContext()

  const requiredField = yup.string().required(t('validation.required'))
  const fields: FieldToMap<keyof IContact>[] = [
    {
      name: t('contacts.form.customerId'),
      info: t('contacts.form.customerIdInfo'),
      csvField: 'Contact Type',
      apiField: 'type',
      required: true,
      isIdentifier: true,
      fieldValidator: requiredField,
      valueValidator: requiredField.test(
        'is-valid-type',
        t('validation.invalid_contact_type'),
        value => {
          return [ContactType.PERSON, ContactType.BUSINESS].includes(ContactType[value])
        }
      ),
      renderExample: value => (
        <Badge>
          {t(CONTACT_TYPE_OPTIONS.find(option => option.value === ContactType[value])?.labelKey)}
        </Badge>
      )
    },
    {
      name: t('contacts.form.type'),
      info: t('contacts.form.typeInfo'),
      csvField: 'Contact Type',
      apiField: 'type',
      required: true,
      fieldValidator: requiredField,
      valueValidator: requiredField.test(
        'is-valid-type',
        t('validation.invalid_contact_type'),
        value => {
          return [ContactType.PERSON, ContactType.BUSINESS].includes(ContactType[value])
        }
      ),
      renderExample: value => (
        <Badge>
          {t(CONTACT_TYPE_OPTIONS.find(option => option.value === ContactType[value])?.labelKey)}
        </Badge>
      )
    },
    {
      name: t('contacts.form.name'),
      csvField: 'Name',
      apiField: 'name',
      required: true,
      fieldValidator: requiredField,
      valueValidator: yup.string().optional()
    },
    {
      name: t('contacts.form.businessId'),
      info: t('contacts.form.businessIdInfo'),
      csvField: 'Business ID',
      apiField: 'contact_business_id',
      required: false,
      fieldValidator: yup.string().optional(),
      valueValidator: yup.string().optional()
    },
    {
      name: t('contacts.form.email'),
      csvField: 'Invoicing Email',
      apiField: 'invoicing_email',
      info: '',
      required: false,
      fieldValidator: yup.string().optional(),
      valueValidator: yup.string().optional()
    },
    {
      name: t('contacts.form.einvoiceAddress'),
      info: t('contacts.form.einvoiceAddressInfo'),
      csvField: 'Einvoice Address',
      apiField: 'invoicing_einvoice_address',
      required: false,
      fieldValidator: yup.string().optional(),
      valueValidator: yup.string().optional()
    },
    {
      name: t('contacts.form.einvoiceOperator'),
      info: t('contacts.form.einvoiceOperatorInfo'),
      csvField: 'Einvoice Operator',
      apiField: 'invoicing_einvoice_operator',
      required: false,
      fieldValidator: yup.string().optional(),
      valueValidator: yup.string().optional()
    },
    {
      name: t('contacts.form.taxNumber'),
      info: t('contacts.form.taxNumberInfo'),
      csvField: 'Tax Code',
      apiField: 'invoicing_tax_code',
      required: false,
      fieldValidator: yup.string().optional(),
      valueValidator: yup.string().optional()
    },
    {
      name: t('contacts.form.invoicingStreet'),
      csvField: 'Street',
      apiField: 'invoicing_street',
      required: false,
      fieldValidator: yup.string().optional(),
      valueValidator: yup.string().optional()
    },
    {
      name: t('contacts.form.invoicingCity'),
      csvField: 'City',
      apiField: 'invoicing_city',
      required: false,
      fieldValidator: yup.string().optional(),
      valueValidator: yup.string().optional()
    },
    {
      name: t('contacts.form.invoicingPostalCode'),
      csvField: 'Postal Code',
      apiField: 'invoicing_postal_code',
      required: false,
      fieldValidator: yup.string().optional(),
      valueValidator: yup.string().optional()
    },
    {
      name: t('contacts.form.invoicingCountry'),
      csvField: 'Country',
      apiField: 'invoicing_country',
      required: false,
      fieldValidator: yup.string().optional(),
      valueValidator: yup.string().optional()
    },
    {
      name: t('contacts.form.invoicingLanguage'),
      info: t('contacts.form.invoicingLanguageHint'),
      csvField: 'Invoicing Language',
      apiField: 'invoicing_language',
      required: false,
      fieldValidator: yup.string().optional(),
      valueValidator: yup.string().lowercase().optional(),
      renderExample: value =>
        t(
          INVOICING_LANGUAGE_OPTIONS.find(option => option.value === InvoicingLanguage[value])
            ?.labelKey
        )
    },
    {
      name: t('contacts.form.notes'),
      csvField: 'Notes',
      apiField: 'notes',
      required: false,
      fieldValidator: yup.string().optional(),
      valueValidator: yup.string().optional()
    }
  ]

  return (
    <CSVImportWizard
      fields={fields}
      onVerifyData={async data => {
        const { to_be_created: toBeCreated, to_be_updated: toBeUpdated } =
          await createImportJobDryRun({ businessId }, { type: ImportJobType.CONTACTS, data })
        return { toBeCreated, toBeUpdated }
      }}
      onImportData={async data => {
        const result = await createImportJob({ businessId }, { type: ImportJobType.CONTACTS, data })
        onComplete()
        return result
      }}
    />
  )
}
