import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.md}rem;
`

export const StyledForm = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 0rem;
  }
`
