import { Dropdown, Input } from '@components'
import { useVirtualList } from '@hooks'
import { InputValidationError } from '@utils/types/shared'
import React, { useEffect, useState } from 'react'
import { FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FaCheck } from 'react-icons/fa'
import {
  DropdownWrapper,
  Header,
  HeaderIcon,
  HeaderText,
  Item,
  ItemColumn,
  ItemWrapper,
  NoData
} from './AccountSelectDropdown.styled'

interface Props {
  id?: string
  accounts: QueryResult[]
  value: number
  label: string
  info?: string
  inputRef?: React.RefCallback<any>
  onSelect?: (QueryResult?) => void
  errors?: FieldError | InputValidationError
  required?: boolean
}

type QueryResult = {
  id: number
  type: string
  number: string
  padded_number: string
  name: string
  default_vat_code: number
  default_vat_rate: number
}

export const AccountSelectDropdown: React.FC<Props> = ({
  id,
  accounts,
  value,
  label,
  info,
  inputRef,
  errors,
  onSelect,
  required
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [selected, setSelected] = useState<QueryResult>(undefined)
  const [focusIndex, setFocusIndex] = useState(0)
  const [results, setResults] = useState<QueryResult[]>(accounts)

  const getLabel = (result: QueryResult): string => {
    if (result) return `${result.number} ${result.name}`
    return ''
  }

  const selectResult = (result: QueryResult, skipSave = false) => {
    setSelected(result)
    setSearchTerm(getLabel(result))
    setFocusIndex(0)
    setIsOpen(false)
    !skipSave && onSelect(result)
  }

  useEffect(() => {
    const escapeRegExp = (value: string) => value.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
    const terms = escapeRegExp(searchTerm || '')
      .split(/\s/)
      .map((t: string) => `(?=.*${t})`)
    const regex = new RegExp(terms.join('') + '.*', 'i')

    const results = [...accounts].filter(account => {
      const label = `${account.number} ${account.name}`
      return regex.test(label)
    })

    setResults(results)
  }, [searchTerm])

  const getCurrent = () => accounts.find(account => account.id === value)

  useEffect(() => {
    const selected = getCurrent()
    if (selected) {
      selectResult(selected, true)
    } else {
      setResults(accounts)
    }
  }, [value, accounts])

  const onKeyDown = e => {
    switch (e.key) {
      case 'ArrowDown': {
        setFocusIndex(Math.min(focusIndex + 1, results.length - 1))
        break
      }

      case 'ArrowUp': {
        setFocusIndex(Math.max(focusIndex - 1, 0))
        break
      }

      case 'Enter': {
        const result = results[focusIndex]
        selectResult(result)
        isOpen && e.preventDefault()
        break
      }

      case 'Escape': {
        const result = getCurrent()
        selectResult(result)
        setIsOpen(false)
        break
      }
    }
  }

  const anchorRef = React.useRef()

  return (
    <DropdownWrapper ref={anchorRef}>
      <Input
        id={id}
        ref={inputRef}
        label={label}
        value={searchTerm}
        info={info}
        autoComplete="off"
        onKeyDown={onKeyDown}
        required={required}
        onChange={e => {
          setIsOpen(true)
          setSearchTerm(e.currentTarget.value)
        }}
        errors={errors}
        onFocus={() => {
          setIsOpen(true)
        }}
        onBlur={() => {
          const result = getCurrent()
          if (result) selectResult(result, true)
          setIsOpen(false)
        }}
      />

      {/* Dropdown functionality */}
      {isOpen && (
        <Dropdown anchorRef={anchorRef}>
          <AccountDropdown
            focusIndex={focusIndex}
            accounts={results}
            onSelect={result => selectResult(result)}
            onFocus={result => setFocusIndex(result)}
            selectedId={selected?.id}
          />
        </Dropdown>
      )}
    </DropdownWrapper>
  )
}

interface AccountDropdownProps {
  accounts: QueryResult[]
  focusIndex?: number
  onSelect: (result: QueryResult) => void
  onFocus: (number: number) => void
  selectedId?: number
}

const AccountDropdown: React.FC<AccountDropdownProps> = ({
  focusIndex,
  accounts,
  onSelect,
  onFocus,
  selectedId
}) => {
  const [t] = useTranslation()
  const { scrollRef, renderItems, scrollToIndex } = useVirtualList({
    items: accounts,
    itemSize: 35
  })

  useEffect(() => scrollToIndex(focusIndex), [focusIndex])

  const onClick = e => {
    e.preventDefault()
  }

  return (
    <div onMouseDown={onClick} onTouchStart={onClick}>
      <Header>
        <HeaderIcon />
        <HeaderText>{t('document.accountSelectHeader')}</HeaderText>
      </Header>
      <ItemWrapper ref={scrollRef}>
        {accounts.length !== 0 &&
          renderItems((item, style, index) => (
            <Item
              data-test="account-select-dropdown-item"
              key={`account-${item.id}`}
              title={item.name}
              className={focusIndex === index && 'keyboard-focused'}
              onClick={() => onSelect(item)}
              onMouseEnter={() => onFocus(index)}
              style={style}
            >
              <ItemColumn className="number">
                <code>{item.number}</code>
              </ItemColumn>
              <ItemColumn className="description">{item.name}</ItemColumn>
              {selectedId === item.id && (
                <ItemColumn>
                  <FaCheck />
                </ItemColumn>
              )}
            </Item>
          ))}
      </ItemWrapper>
      {accounts.length === 0 && <NoData>{t('document.accountSelectNoData')}</NoData>}
    </div>
  )
}
