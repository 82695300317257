import { TextTooltip } from '@components'
import { AccountType } from '@constants'
import { useBusinessContext, useDateRangeContext } from '@context'
import { useTheme } from '@hooks'
import { decorateExp, fetchAccountStat } from '@query'
import { formatEurosWithOpts } from '@utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaChartLine, FaEuroSign, FaExchangeAlt } from 'react-icons/fa'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { RollingNumber } from './RollingNumber'
import { CardTitle, Row } from './styles'

export const KeyFigures: React.FC = () => {
  const [t] = useTranslation()
  const theme = useTheme()
  const { businessId } = useBusinessContext()

  const formatter = (num: number) =>
    formatEurosWithOpts(num.toFixed(0), {
      inCents: true,
      withEuroSymbol: false,
      avoidDecimals: true
    })

  const { selectedRange, isReady } = useDateRangeContext()

  const { data: gross } = useQuery(
    ['aggregate', 'gross', businessId, selectedRange?.date_from, selectedRange?.date_to],
    () =>
      fetchAccountStat({
        business_id: businessId,
        field: 'balance',
        aggregate: 'sum',
        date_from: selectedRange?.date_from,
        date_to: selectedRange?.date_to,
        account_type: [AccountType.REV_SAL]
      }),
    {
      initialData: { field: '', value: 0 },
      enabled: isReady
    }
  )

  const { data: prof } = useQuery(
    ['aggregate', 'profit', businessId, selectedRange?.date_from, selectedRange?.date_to],
    () =>
      fetchAccountStat({
        business_id: businessId,
        field: 'balance',
        aggregate: 'sum',
        date_from: selectedRange?.date_from,
        date_to: selectedRange?.date_to,
        account_type: [decorateExp(AccountType.REV), decorateExp(AccountType.EXP)]
      }),
    {
      initialData: { field: '', value: 0 },
      enabled: isReady
    }
  )

  const { data: money } = useQuery(
    ['aggregate', 'money', businessId, selectedRange?.date_to],
    () =>
      fetchAccountStat({
        business_id: businessId,
        field: 'balance',
        aggregate: 'sum',
        date: selectedRange?.date_to,
        account_type: [AccountType.ASS_PAY]
      }),
    {
      initialData: { field: '', value: 0 },
      enabled: isReady
    }
  )

  return (
    <Row>
      <Card>
        <IconContainer>
          <IconKnob color={theme.colors.orange}>
            <FaExchangeAlt size={`${theme.iconSize.xs}em`} color={theme.colors.neutralWhite} />
          </IconKnob>
        </IconContainer>
        <Title>{t('dashboard.keyFigures.revenueTitle')}</Title>
        <Separator color={theme.colors.orange} />
        <TrollingNumber from={0} to={gross.value} delay={0.1} formatter={formatter} />
        <Tooltip tooltip={t('dashboard.keyFigures.revenueTooltip')} />
      </Card>
      <Card>
        <IconContainer>
          <IconKnob color={theme.colors.blue}>
            <FaChartLine size={`${theme.iconSize.xs}em`} color={theme.colors.neutralWhite} />
          </IconKnob>
        </IconContainer>
        <Title>{t('dashboard.keyFigures.profitTitle')}</Title>
        <Separator color={theme.colors.blue} />
        <TrollingNumber from={0} to={prof.value} delay={0.2} formatter={formatter} />
        <Tooltip tooltip={t('dashboard.keyFigures.profitTooltip')} />
      </Card>
      <Card>
        <IconContainer>
          <IconKnob color={theme.colors.yellow}>
            <FaEuroSign size={`${theme.iconSize.xs}em`} color={theme.colors.neutralWhite} />
          </IconKnob>
        </IconContainer>
        <Title>{t('dashboard.keyFigures.moneyTitle')}</Title>
        <Separator color={theme.colors.yellow} />
        <TrollingNumber from={0} to={money.value} delay={0.2} formatter={formatter} />
        <Tooltip tooltip={t('dashboard.keyFigures.moneyTooltip')} />
      </Card>
    </Row>
  )
}

const Separator = styled.div<{ color: string }>`
  width: 100px;
  height: 2px;
  border-radius: 5px;
  background: ${({ color }) => color};
  margin: ${({ theme }) => theme.spacing.md}rem 0;
`

const Card = styled.div`
  position: relative;
  flex: 1;
  min-width: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid ${({ theme }) => theme.colors.neutralGray};
  border-radius: ${({ theme }) => theme.spacing.md}rem;
  margin-top: ${({ theme }) => theme.spacing.md}rem;

  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
`

const Tooltip = styled(TextTooltip)`
  position: absolute;
  right: -5px;
  top: -5px;
`

const IconContainer = styled.div`
  transform: translateY(-50%);
`

const Title = styled(CardTitle)`
  color: ${({ theme }) => theme.colors.blue};
`

const TrollingNumber = styled(RollingNumber)`
  color: ${({ theme }) => theme.colors.blue};
  font-weight: 600;
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;
`

const IconKnob = styled.div<{ color: string }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color};
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 1rem;
`
