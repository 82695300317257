import { Input, Select, TextArea } from '@components'
import { ACCOUNT_TYPES, getVatRateLabelOptions, VAT_CODE_OPTIONS, VatRateLabel } from '@constants'
import { FeatureWrapper, useBusinessContext } from '@context'
import { useLocalStorage, useTheme } from '@hooks'
import { createAccount, IAccount, updateAccount } from '@query'
import { setAPIErrors } from '@utils'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useMemo } from 'react'
import { Control, useForm, useWatch } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Trans, useTranslation } from 'react-i18next'
import { IoMdCloseCircleOutline } from 'react-icons/io'
import { useMutation, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { AccountPathVisualizer } from './AccountPathVisualizer'
import dayjs from 'dayjs'

interface Props {
  account?: IAccount
  onClose?: () => void
  onCreate?: (data: IAccount) => void
}

const getFilteredAccountTypeOptions = (accountNumber: number) => {
  if (!accountNumber) return ACCOUNT_TYPES

  const term = parseInt(accountNumber?.toString().charAt(0), 10)

  const _filter = option => {
    if (term === 1) return option.value.startsWith('ASS')
    if (term === 2) return option.value.startsWith('LIA')
    if (term === 3) return option.value.startsWith('REV')
    return option.value.startsWith('REV') || option.value.startsWith('EXP')
  }

  return ACCOUNT_TYPES.filter(_filter)
}

export const AccountForm: React.FC<Props> = ({ account, onClose, onCreate }) => {
  const {
    businessId,
    data: { country_config: countryConfig }
  } = useBusinessContext()
  const queryClient = useQueryClient()
  const isEdit = !!account?.id

  const getDefaultValues = (data?: IAccount) => ({
    number: data?.number,
    name: data?.name,
    description: data?.description,
    default_vat_rate_label: data?.default_vat_rate_label || VatRateLabel.ZERO,
    default_vat_code: data?.default_vat_code,
    type: data?.type,
    opening_balance: data?.opening_balance || 0
  })

  const [t] = useTranslation()
  const {
    register,
    handleSubmit,
    setError,
    reset,
    watch,
    control,
    formState: { errors }
  } = useForm<Partial<IAccount>>({
    defaultValues: getDefaultValues(account)
  })

  const resetForm = (data: IAccount) => reset(getDefaultValues(data))

  const accountNumber = watch('number')

  const accountTypeOptions = useMemo(() => {
    const options = getFilteredAccountTypeOptions(accountNumber)

    return options
  }, [accountNumber])

  const options = {
    onSuccess: data => {
      resetForm(data)
      toast.success(t('general.changesSaved'))
      queryClient.invalidateQueries([businessId, 'accounts'])
      queryClient.invalidateQueries([businessId, 'tabular_accounts'])
      if (account) queryClient.invalidateQueries([businessId, 'accounts', account.id])

      onCreate(data)
      onClose && onClose()
    },
    onError: ({ status, data }) => {
      if (status === 400) {
        setAPIErrors(data, setError)
      }
    }
  }

  const updateMutation = useMutation<IAccount, unknown, Partial<IAccount>>(
    data => updateAccount({ businessId, accountId: account.id }, data),
    options
  )

  const createMutation = useMutation<IAccount, unknown, Partial<IAccount>>(
    data => createAccount({ businessId }, data),
    options
  )

  const stripTags = (str = '') => str.replaceAll(/(<\/*[0-9]>)/g, '')

  const getCurrentVatOption = (currentValue: string) =>
    VAT_CODE_OPTIONS.find(vc => {
      return vc.value === +currentValue // buu conversion :poopemoji:
    })

  const handleSave = async data => {
    try {
      // https://github.com/orgs/react-hook-form/discussions/9879#discussioncomment-4883381
      await (isEdit ? updateMutation.mutateAsync(data) : createMutation.mutateAsync(data))
    } catch {
      return null
    }
  }
  // Fix for when submitting the form it doesn't send parent forms:
  // https://github.com/redux-form/redux-form/issues/3701#issuecomment-670471760
  return (
    <div onSubmit={event => event.stopPropagation()}>
      <form id="account-form" onSubmit={handleSubmit(handleSave)}>
        <AccountPathVisualizer currentAccountId={account?.id} control={control} onClose={onClose} />

        <Input
          type="number"
          label={t('accounts.number')}
          info={t('accounts.numberInfo')}
          {...register('number', {
            required: t('validation.required') as string,
            min: { value: 1, message: '> 0' },
            max: { value: 9999999, message: '< 9999999' }
          })}
          step={1}
          errors={errors.number}
          required={true}
        />

        <Input
          label={t('accounts.name')}
          info={t('accounts.nameInfo')}
          {...register('name', {
            required: t('validation.required') as string
          })}
          errors={errors.name}
          required={true}
        />

        <TextArea
          label={t('accounts.description')}
          info={t('accounts.descriptionInfo')}
          {...register('description')}
          errors={errors.description}
        />

        <FeatureWrapper feature="vat_obliged">
          <h4>{t('accounts.vatInfo')}</h4>

          <Select
            id="accountForm-input-vat"
            label={t('accounts.defaultVat')}
            info={t('accounts.defaultVatInfo')}
            errors={errors.default_vat_code}
            {...register('default_vat_code')}
          >
            {VAT_CODE_OPTIONS.map(({ label, value, hint }) => (
              <option key={value} value={value} title={stripTags(t(hint))}>
                {t(label)}
              </option>
            ))}
          </Select>

          <FieldChangeAware control={control} fieldName="default_vat_code">
            {fieldValue =>
              fieldValue && (
                <ExtraHint>
                  <label htmlFor="accountForm-input-vats">
                    <Trans i18nKey={getCurrentVatOption(fieldValue)?.hint}>
                      <a href={getCurrentVatOption(fieldValue)?.extraInfo ?? ''} />
                    </Trans>
                  </label>
                </ExtraHint>
              )
            }
          </FieldChangeAware>

          <Select
            info={t('accounts.defaultVatPercentageInfo')}
            label={t('accounts.defaultVatPercentage')}
            errors={errors.default_vat_rate_label}
            {...register('default_vat_rate_label', {
              required: true
            })}
          >
            {getVatRateLabelOptions({ countryConfig, dateAt: dayjs(), t, uniqueLabels: true }).map(
              ({ label, value }, index) => (
                <option key={`option-${index}`} value={value}>
                  {label}
                </option>
              )
            )}
          </Select>
        </FeatureWrapper>

        <h4>{t('accounts.additionalInfo')}</h4>

        <Select
          info={t('accounts.accountTypeInfo')}
          label={t('accounts.accountType')}
          errors={errors.type}
          required={true}
          {...register('type', {
            required: t('validation.required') as string
          })}
        >
          <option hidden={true} value={null}></option>
          {accountTypeOptions.map(({ labelKey, value }) => (
            <option key={`type-${value}`} value={value}>
              {t(labelKey)}
            </option>
          ))}
        </Select>

        <FeatureWrapper feature="has_history">
          <Input
            info={t('accounts.initialBalanceInfo')}
            type="number"
            label={t('accounts.initialBalance')}
            step=".01"
            errors={errors.opening_balance}
            {...register('opening_balance', { valueAsNumber: true, min: -999999999 })}
          />
        </FeatureWrapper>
      </form>
    </div>
  )
}

interface InputTooltipProps {
  control: Control<any>
  fieldName: string
  children: (fieldValue: string) => React.ReactNode
}

const FieldChangeAware: React.FC<InputTooltipProps> = ({ control, fieldName, children }) => {
  const fieldValue = useWatch({ control, name: fieldName })

  return <>{children(fieldValue)}</>
}

const ExtraHint: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isVisible, setVisibilityForever] = useLocalStorage('account-vat-tooltip-visible', true)
  const theme = useTheme()

  return (
    <AnimatePresence>
      {isVisible && (
        <HintContainer
          key="hintContainer"
          variants={containerVariants}
          initial="visible"
          exit="hidden"
          animate="visible"
        >
          {children}
          <Info onClick={() => setVisibilityForever(false)}>
            <IoMdCloseCircleOutline
              role="button"
              aria-label="piilota"
              color={theme.colors.neutralWhite}
            />
          </Info>
        </HintContainer>
      )}
    </AnimatePresence>
  )
}

const containerVariants = {
  visible: {
    opacity: 1
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.3
    }
  }
}

const HintContainer = styled(motion.div)(
  ({ theme }) => `
  background: ${theme.colors.menuBg};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.sm}rem ${theme.spacing.md}rem;
  border-radius: 1rem;
  color: ${theme.colors.neutralWhite};
  font-size: ${theme.fontSize.md}rem;
  margin-bottom: ${theme.spacing.md}rem;
  position: relative;

  & > label {
    margin-bottom: 0;

    & > a {
      color: ${theme.colors.mutedYellow};
      fontWeight: bold;

    }
  }

  &:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid ${theme.colors.menuBg};
    border-right: 10px solid transparent;
    border-top: 10px solid ${theme.colors.menuBg};
    border-bottom: 10px solid transparent;
    right: 50%;
    top: -4px;
    transform: rotate(45deg);
    }

`
)

const Info = styled.span(
  ({ theme }) => `
  align-self: flex-start;
  margin-left: ${theme.spacing.md}rem;
  margin-top: 2px;

  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    height: 1.2rem;
    width: 1.2rem;
  }

  &:hover {
    transform: scale(1.1);
    transition: transform 200ms;
  }
`
)
