import { network } from '@utils'
import { handleReturn, MutationFunction } from './root'

interface FileConversionProps {
  format: 'csv'
  base64: string
}

type JSONObject = { [key: string]: any }

export const convertToJSON: MutationFunction<FileConversionProps, JSONObject[]> = async ({
  format,
  base64
}) => {
  const url = `/v1/import_tools/${format}/to_json/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'POST',
    data: base64,
    validateStatus: () => true
  })
  return handleReturn({ status, data })
}

interface IImportDocument {
  date: string
  amount: string
  description: string
  contact: string
}

interface DocumentImportProps {
  businessId: string
  accountId: number
  data: IImportDocument[]
}

export const importDocuments: MutationFunction<DocumentImportProps, unknown> = async ({
  businessId,
  accountId,
  data: payload
}) => {
  const url = `/v1/import_tools/${businessId}/import/${accountId}/`
  const { status, data } = await network.httpClient.request({
    url,
    method: 'POST',
    data: payload,
    validateStatus: () => true,
    timeout: 60000 // timeout 60min
  })
  return handleReturn({ status, data })
}
