import { HeaderBlock, TeaserView } from '@root/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const EInvoicingDemoSettings: React.FC = () => {
  const [t] = useTranslation()
  return (
    <StyledWrapper>
      <HeaderBlock header={t('settings.einvoicing.header')} subHeader="" />
      <ContentWrapper>
        <TeaserView
          header={t('teaser.eInvoicing.header')}
          subHeader={t('teaser.eInvoicing.subHeader')}
          features={[
            t('teaser.feature.2'),
            t('teaser.feature.3'),
            t('teaser.feature.1'),
            t('teaser.feature.4')
          ]}
        />
      </ContentWrapper>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  background: white;
  height: 100%;
  overflow: auto;
`
const ContentWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing.md}rem;
  margin-top: 0;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: ${({ theme }) => theme.fontSize.md}rem;
  }
`
