import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { useSteps } from './StepContext'

interface StepProps extends HTMLAttributes<HTMLDivElement> {
  dotSize?: string
}

export const StepWizard: React.FC<StepProps> = ({ dotSize = '1rem', ...rest }) => {
  const { currentStepIndex, stepCount } = useSteps()

  return (
    <Row aria-hidden {...rest}>
      {Array.from({ length: stepCount }).map((_, index) => (
        <Dot dotSize={dotSize} active={index === currentStepIndex} key={index} />
      ))}
    </Row>
  )
}

interface DotProps {
  active: boolean
  dotSize: string
}

const Row = styled.div`
  padding: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Dot = styled.div<DotProps>`
  width: ${({ dotSize }) => dotSize};
  height: ${({ dotSize }) => dotSize};
  border-radius: ${({ dotSize }) => dotSize};
  background-color: ${({ theme }) => theme.colors.iconGray};
  margin-right: 0.5rem;
  transition: opacity 0.2s, transform 0.3s;
  opacity: 0.2;

  &:last-of-type {
    margin-right: 0;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.nocfoBlue};
      outline: none;
      opacity: 1;
      transform: scale(1.15);
    `}
`
