interface PageProps<T> {
  results: T[]
}

interface DataProps<T> {
  pages: PageProps<T>[]
}

export const collectFromPages = <T>(data?: DataProps<T>): T[] => {
  return data?.pages?.reduce((pages, page) => [...pages, ...page.results], []) || []
}
