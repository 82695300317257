import { network } from '@utils'
import { PaginatedResponse, QueryFunction } from './root'

export interface IEntry {
  id: number
  account: number
  account_name: string
  account_number: number
  amount: number
  is_debet: boolean
  description: string
  vat_code: number
  vat_rate: number
}

interface EntryListProps {
  businessId: string
  documentId: number
}

export const fetchEntries: QueryFunction<EntryListProps, PaginatedResponse<IEntry>> = async (
  { businessId, documentId },
  params = null
) => {
  const url = `/v1/business/${businessId}/document/${documentId}/entry/`
  const { data } = await network.httpClient.request({
    url,
    method: 'GET',
    params
  })
  return data
}
