import {
  autoUpdate,
  detectOverflow,
  flip,
  offset,
  RootBoundary,
  safePolygon,
  useDismiss,
  useFloating,
  useHover,
  useInteractions
} from '@floating-ui/react-dom-interactions'
import { AuditTrailChangeReasons, IAuditUser } from '@root/query/auditTrail'
import { theme } from '@root/styles'
import { motion } from 'framer-motion'
import React, { createElement, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IUserNameWithLinkProps {
  user: IAuditUser
  changeReason?: AuditTrailChangeReasons
  tag?: string
}

const UserDetails: React.FC<IUserNameWithLinkProps> = ({ user, changeReason, tag }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [t] = useTranslation()
  const exportReason = AuditTrailChangeReasons[changeReason]
  const isExported = !!exportReason
  const userEmail = user?.email
  const openDelay = 400

  /**
   * @returns Returns the string that is used to display the entity that made the change.
   * In the case of no user, returns the change reason.
   * Also checks for missing user name in which case the email is defaulted to.
   */
  const selectUserName = (
    user: IAuditUser,
    isExported: boolean,
    exportReason: Partial<AuditTrailChangeReasons>
  ): string => {
    if (!isExported) {
      return user?.name || user?.email || t('components.auditTrail.deletedUser')
    }
    switch (exportReason) {
      case AuditTrailChangeReasons.ENABLE_BANKING_IMPORTED:
        return t('components.auditTrail.bankIntegration')
      case AuditTrailChangeReasons.HOLVI_IMPORTED:
        return t('components.auditTrail.holviIntegration')
      case AuditTrailChangeReasons.IMPORT_TASK:
        return t('components.auditTrail.importTask')
      case AuditTrailChangeReasons.MOCK_SUGGESTION:
        return t('components.auditTrail.suggestion')
    }
    return null
  }

  const userDisplayName = selectUserName(user, isExported, exportReason)

  const changeOpen = (value: boolean) => {
    setIsOpen(value)
  }
  const rootRef = useRef<HTMLElement>(document.getElementById('dropdown-root'))

  const middleware = {
    name: 'middleware',
    async fn(state) {
      const overflowContainer = document.getElementById('document-tabs')
      const overflow = await detectOverflow(state, {
        rootBoundary: overflowContainer as unknown as RootBoundary
      })
      if (overflow.top > 0 || overflow.top < -overflowContainer.offsetHeight) {
        changeOpen(false)
      }
      return {}
    }
  }

  const { context, x, y, reference, floating, strategy } = useFloating({
    open: isOpen,
    whileElementsMounted: autoUpdate,
    onOpenChange: open => changeOpen(open),
    placement: 'bottom-start',
    middleware: [offset(10), flip(), middleware]
  })

  const hover = useHover(context, {
    delay: openDelay,
    handleClose: safePolygon() // User can interact with the floating element better.
  })
  useDismiss(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([hover])

  return (
    <>
      {isExported ? (
        <p>{userDisplayName}</p>
      ) : tag ? (
        createElement(
          tag,
          { ref: reference, getReferenceProps, style: { color: `${theme.colors.nocfoBlue}` } },
          userDisplayName
        )
      ) : (
        <UserNameHoverable ref={reference} {...getReferenceProps()}>
          {userDisplayName}
        </UserNameHoverable>
      )}

      {isOpen &&
        !isExported &&
        createPortal(
          <StyledContainer
            ref={floating}
            {...getFloatingProps()}
            style={{
              top: y || 0,
              left: x || 0,
              position: strategy
            }}
          >
            <ContentWrapper>
              <StyledName>{userDisplayName}</StyledName>
              <StyledEmail>{userEmail}</StyledEmail>
            </ContentWrapper>
          </StyledContainer>,
          rootRef.current
        )}
    </>
  )
}

export default UserDetails

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledEmail = styled.div`
  color: ${({ theme }) => theme.colors.metalGray};
`

const StyledName = styled.div`
  color: ${({ theme }) => theme.colors.neutralBlack};
  font-weight: bold;
`

const UserNameHoverable = styled.span`
  display: inline;
  color: ${({ theme }) => theme.colors.nocfoBlue};
`

const StyledContainer = styled(motion.div).attrs({
  initial: { y: 10, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: 0, opacity: 0 }
})`
  display: flex;
  flex-direction: row;
  background: white;
  border-radius: 1rem;
  padding: ${({ theme }) => theme.spacing.md}rem;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
`
