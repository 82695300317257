import { motion } from 'framer-motion'
import { FaStream } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StyledWrapper = styled(motion.div).attrs({})`
  margin-top: ${({ theme }) => theme.spacing.md}rem;
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;
  border: 1px solid ${({ theme }) => theme.colors.neutralGray};
  border-radius: 1rem;
  overflow: hidden;
`

export const StyledHeader = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  padding: ${({ theme }) => theme.spacing.md}rem;
  background: ${({ theme }) => theme.colors.neutralGray};
  color: ${({ theme }) => theme.colors.metalGray};
  color: ${({ theme }) => theme.colors.metalGray};
  font-weight: bold;
  display: flex;
  align-self: center;
`

export const StyledHeaderIcon = styled(FaStream)`
  fill: ${({ theme }) => theme.colors.metalGray};
  width: 1rem;
  height: 1rem;
  margin-right: ${({ theme }) => theme.spacing.sm}rem;
`

export const StyledContent = styled.div<{ height?: number }>`
  height: ${({ height }) => height || 250}px;
  background: ${({ theme }) => theme.colors.neutralGray};
  padding: 0.8rem;
  padding-top: 0;
  padding-bottom: 0.8rem;
  overflow: auto;
`

export const StyledDocument = styled(Link)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.sm}rem;
  gap: ${({ theme }) => theme.spacing.xxs}rem;
  color: ${({ theme }) => theme.colors.metalGray} !important;
  text-decoration: none !important;
  background: white;
  border-radius: 0.5rem;
  margin-bottom: 0.8rem !important;
  width: 100%;

  &:hover {
    background: ${({ theme }) => theme.colors.defaultHover};
  }

  & > * {
    white-space: nowrap;
  }
`

export const StyledDocRow = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.xs}rem;
`

export const StyledDocDate = styled.div`
  color: ${({ theme }) => theme.colors.metalGray};
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
`

export const StyledDocDesc = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.neutralBlack};
  flex: 1;
`

export const StyledDocBal = styled.div`
  color: ${({ theme }) => theme.colors.metalGray};
  text-align: right;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
`

export const StyledDocNumber = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  color: ${({ theme }) => theme.colors.metalGray};
  font-variant-numeric: tabular-nums;
  font-weight: bold;
`

export const Spacer = styled.div`
  flex: 1;
`
