import classNames from 'classnames'
import { motion } from 'framer-motion'
import React from 'react'
import { FaCheck, FaExclamationCircle } from 'react-icons/fa'
import styled from 'styled-components'

interface InvalidValueProps {
  value: string
  originalValue?: string
  error?: string
  isUntouched?: boolean
}

export const CellValue: React.FC<InvalidValueProps> = ({
  value,
  originalValue,
  error,
  isUntouched = false
}) => {
  const Icon = React.memo<{ error: string }>(({ error }) =>
    error ? <FaExclamationCircle /> : <FaCheck />
  )
  return (
    <StyledValue>
      <div className={classNames('icon-row', { error, isUntouched })} title={error}>
        {!isUntouched && <Icon error={error} />}
        <span>{error ? originalValue : value}</span>
      </div>
    </StyledValue>
  )
}

const StyledValue = styled.div`
  .icon-row {
    padding: 0.4rem 0.6rem;
    display: flex;
    align-items: center;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.isUntouched {
      opacity: 0.5;
    }

    svg {
      color: ${({ theme }) => theme.colors.nocfoGreen};
      width: 0.8rem;
      height: 0.8rem;
      min-width: 0.8rem;
      min-height: 0.8rem;
    }

    &.error {
      svg {
        color: ${({ theme }) => theme.colors.nocfoRed};
      }
    }

    svg {
      margin-right: 0.4rem;
      width: 0.8rem;
      height: 0.8rem;
    }
  }

  .error-text {
    display: flex;
    color: ${({ theme }) => theme.colors.nocfoYellow};
    font-size: ${({ theme }) => theme.fontSize.xs}rem;
    align-items: center;
  }
`

export const StyledTable = styled(motion.div).attrs({
  variants: {
    hidden: { y: 10, opacity: 0 },
    show: {
      y: 0,
      opacity: 1
    }
  },
  initial: 'hidden',
  animate: 'show',
  exit: 'hidden',
  transition: {
    staggerChildren: 0.015,
    delayChildren: 0
  }
})`
  flex: 1;
  border: 1px solid ${({ theme }) => theme.colors.neutralGray};
  border-radius: 1rem;
  overflow: auto;
  background: white;

  .td:not(:last-of-type),
  .th:not(:last-of-type) {
    border-right: 1px solid ${({ theme }) => theme.colors.neutralGray};
  }

  .header {
    border-bottom: 2px solid ${({ theme }) => theme.colors.neutralGray};
    background: ${({ theme }) => theme.colors.neutralWhite};
  }

  .tr:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutralGray};
  }

  .th {
    padding: 1rem;

    .input-wrapper {
      margin: 0;
    }
  }
`

export const AnimatedRow = styled(motion.div).attrs({
  variants: {
    hidden: {
      y: 5,
      opacity: 0
    },
    show: {
      y: 0,
      opacity: 1
    }
  }
})``

export const PageContent = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
`

export const Footer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
`

export const StyledCell = styled.div`
  padding: 0.4rem 0.6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .ignored {
    opacity: 0.5;
  }
`

export const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  justify-content: space-between;
`

export const ButtonText = styled.span`
  align-self: center;
`

export const ButtonCount = styled.span`
  background: white;
  color: ${({ theme }) => theme.colors.nocfoBlue};
  margin-left: 0.4rem;
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  font-weight: bold;
  padding: 0 0.4rem;
  border-radius: 0.6rem;
  right: 0;
  align-self: center;
`

export const StyledHeader = styled.div`
  padding: ${({ theme }) => theme.spacing.md}rem;
  background: white;
  border-radius: 1rem;
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;

  .input-wrapper {
    margin: 0;
  }
`
