import { VAT_PERIOD_OPTIONS } from '@constants'
import React from 'react'
import { Controller, get } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RadioGroup, RadioItem, SlideText, SlideTitle, ValidationError } from './slide.styled'

interface VatPeriodSlideProps {
  vatPeriod: number | null
}

export const VatPeriodSlide: React.FC<VatPeriodSlideProps> = () => {
  const [t] = useTranslation()

  return (
    <>
      <SlideTitle>{t('onboarding.vatPeriodSlide.title')}</SlideTitle>
      <SlideText>{t('onboarding.vatPeriodSlide.message')}</SlideText>

      <Controller
        name="business.default_vat_period"
        rules={{ required: { value: true, message: t('validation.required') } }}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <RadioGroup>
            {VAT_PERIOD_OPTIONS.map((vp, i) => (
              <RadioItem
                key={vp.value}
                label={t(vp.labelKey)}
                index={i}
                selected={value === vp.value}
                value={vp.value}
                onSelect={onChange}
              />
            ))}

            {get(errors, 'business.default_vat_period') && (
              <ValidationError>
                {get(errors, 'business.default_vat_period').message}
              </ValidationError>
            )}
          </RadioGroup>
        )}
      />
    </>
  )
}
