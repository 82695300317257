import styled from 'styled-components'

export const Step = styled.div`
  border-radius: 1rem;
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs}rem;
`

export const StepIconContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & > svg {
    margin: 0.2rem;
    width: 1.2rem;
    min-width: 1.2rem;
    height: 1.2rem;
    min-height: 1.2rem;
    align-self: center;
  }
`

export const StepContact = styled.span`
  height: 100%;
  background: ${props => props.color};
  width: 0.3rem;
  align-self: center;
  border-radius: 1rem;
`

export const StepContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 2.6rem;
  padding-top: ${({ theme }) => theme.spacing.xxs}rem;
  padding-bottom: ${({ theme }) => theme.spacing.md}rem;
  gap: ${({ theme }) => theme.spacing.xs}rem;
`

export const StepTitle = styled.h4`
  margin: 0;
`

export const StepDescription = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md}rem;
`
