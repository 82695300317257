import { DisableInvoiceRecurrenceProps, IInvoice, disableInvoiceRecurrence } from '@root/query'
import React from 'react'
import { FaSave } from 'react-icons/fa'
import { Prompt } from '@root/components'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useBusinessContext } from '@root/context'
import toast from 'react-hot-toast'
import ReactMarkdown from 'react-markdown'

interface Props {
  invoice: IInvoice
  handleOnClose: () => void
  showPrompt: boolean
}

const InvoiceRecurrenceEditSettings: React.FC<Props> = ({ invoice, handleOnClose, showPrompt }) => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()

  const queryClient = useQueryClient()
  const options = {
    onSuccess: () => {
      toast.success(t('general.changesSaved'))
      queryClient.invalidateQueries([businessId, 'invoices'])
      queryClient.invalidateQueries([businessId, 'invoice'])
      handleOnClose()
    }
  }

  const updateMutation = useMutation<unknown, unknown, DisableInvoiceRecurrenceProps>(
    ({ businessId, invoiceId }) => disableInvoiceRecurrence({ businessId, invoiceId: invoiceId }),
    options
  )

  const handleSendingData = async () => {
    try {
      const invoiceIdToUse = invoice?.recurrence_parent_id || invoice?.id
      await updateMutation.mutateAsync({ businessId, invoiceId: invoiceIdToUse })
    } catch {
      toast.error(t('error.genericRetry'))
      return null
    }
  }

  return (
    <Prompt
      title={t('invoicing.details.recurrence.editRecurrence.title')}
      description={
        <ReactMarkdown>{t('invoicing.details.recurrence.editRecurrence.desc')}</ReactMarkdown>
      }
      isVisible={showPrompt}
      onClose={() => handleOnClose()}
      buttons={[
        {
          text: t('general.cancel'),
          action: () => handleOnClose()
        },
        {
          text: t('invoicing.details.recurrence.editRecurrence.action'),
          action: () => handleSendingData(),
          icon: <FaSave />,
          intent: 'danger',
          disabled: updateMutation.isLoading,
          showSpinner: updateMutation.isLoading
        }
      ]}
    />
  )
}

export default InvoiceRecurrenceEditSettings
