import { useLocalStorage } from '@hooks'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaInfoCircle,
  FaMagic,
  FaTimes
} from 'react-icons/fa'
import styled from 'styled-components'

const ICONS = {
  info: FaInfoCircle,
  success: FaCheckCircle,
  error: FaExclamationCircle,
  warning: FaExclamationTriangle,
  magic: FaMagic
}

export type AlertType = 'info' | 'success' | 'error' | 'warning' | 'magic'

interface Props {
  type?: AlertType
  title?: string
  description?: string | React.ReactElement
  isVisible?: boolean
  marginBottom?: number | string
  dismissable?: boolean
  dismissableKey?: string
}

type DismissValue = 'dismissed' | undefined

export const Alert: React.FC<Props> = ({
  type = 'info',
  title,
  description,
  isVisible = true,
  marginBottom,
  dismissable,
  dismissableKey
}) => {
  if (dismissable && !dismissableKey) throw 'dismissableKey required'
  const [isDismissed, setDismissed] = useLocalStorage<DismissValue>(dismissableKey, undefined)

  const Icon = ICONS[type]
  return (
    <AnimatePresence initial={false}>
      {isVisible && isDismissed !== 'dismissed' && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <StyledAlert className={type} aria-live="assertive" marginBottom={marginBottom}>
            <Icon />
            <AlertContent>
              <AlertTitleRow>
                {title && <AlertTitle>{title}</AlertTitle>}
                {dismissable && (
                  <DismissButton onClick={() => setDismissed('dismissed')}>
                    <FaTimes />
                  </DismissButton>
                )}
              </AlertTitleRow>
              {description && <AlertText>{description}</AlertText>}
            </AlertContent>
          </StyledAlert>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const AlertContent = styled.div`
  align-self: center;
  flex: 1;
  min-width: 0;
`

const AlertTitle = styled.p`
  flex: 1;
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.spacing.xxs}rem;
`
const AlertText = styled.div`
  margin: 0;
  min-width: 0;
`

const StyledAlert = styled.div<{ marginBottom?: number | string }>`
  padding: ${({ theme }) => `${theme.spacing.md}rem ${theme.spacing.md}rem`};
  margin-bottom: ${({ marginBottom, theme }) =>
    marginBottom !== undefined ? marginBottom : `${theme.spacing.md}rem`};
  border-radius: 1rem;
  display: flex;
  color: ${({ theme }) => theme.colors.neutralBlack};
  gap: ${({ theme }) => theme.spacing.sm}rem;
  min-width: 0;

  & > svg {
    width: 1.4rem;
    height: 1.4rem;
    min-width: 1.4rem;
    min-height: 1.4rem;
    align-self: flex-start;
  }

  &.success {
    background: ${({ theme }) => theme.colors.nocfoGreen}33;

    & svg {
      fill: ${({ theme }) => theme.colors.nocfoGreen};
    }
  }

  &.info {
    background: ${({ theme }) => theme.colors.nocfoBlue}33;

    & svg {
      fill: ${({ theme }) => theme.colors.nocfoBlue};
    }
  }

  &.warning {
    background: ${({ theme }) => theme.colors.nocfoYellow}33;

    & svg {
      fill: ${({ theme }) => theme.colors.nocfoYellow};
    }
  }

  &.error {
    background: ${({ theme }) => theme.colors.nocfoRed}33;

    & svg {
      fill: ${({ theme }) => theme.colors.nocfoRed};
    }
  }

  &.magic {
    background: ${({ theme }) => theme.colors.nocfoPurple}33;
    color: ${({ theme }) => theme.colors.nocfoPurple};

    & svg {
      fill: ${({ theme }) => theme.colors.nocfoPurple};
    }
  }
`

const AlertTitleRow = styled.div`
  display: flex;
  flex: 1;
`

const DismissButton = styled.button`
  outline: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
`
