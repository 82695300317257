import { ItemRenderProps } from '@components/UniversalAPISelector'
import { useBusinessContext } from '@root/context'
import { DefaultQueryParams, IAccount, fetchAccount, fetchAutoCompleteAccounts } from '@root/query'
import { motion } from 'framer-motion'
import { Button, InputProps, ModalProps } from '@components'
import styled from 'styled-components'
import { FaBook, FaCheck, FaPen } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import UniversalAPISelector from '@root/components/UniversalAPISelector/UniversalAPISelector'
import { useScreen } from '@root/utils'
import { AccountForm } from '@pages/AccountsPage/components'

interface Props extends InputProps {
  onChange: (accountId: number) => void
  clearSelectedItem: () => void
  accountFilter?: DefaultQueryParams
  allowCreate?: boolean
  queryKey: string
}

export const AccountSelector: React.FC<Props> = ({
  accountFilter = {},
  queryKey,
  allowCreate,
  ...rest
}) => {
  const { businessId } = useBusinessContext()
  const [t] = useTranslation()
  const formId = 'account-form'
  const { isMobile } = useScreen()

  const dataToShow = (item: IAccount): string => {
    return `${item.number} ${item.name}`
  }

  const renderRow = (
    item: IAccount,
    {
      isFocused,
      isSelected,
      onKeyDown,
      handleOnItemClick,
      handleOnEditClick,
      style,
      showEditItem
    }: ItemRenderProps<IAccount>
  ) => {
    return (
      <ItemWrapper
        className={isFocused && 'keyboard-focused'}
        onKeyDown={onKeyDown}
        onClick={() => handleOnItemClick(item)}
        style={style}
        key={item.id}
      >
        <Item key={`account-${item.id}`} title={item.name}>
          <ItemColumn isMobile={isMobile} className="id">
            {item.number}
          </ItemColumn>
          <ItemColumn isMobile={isMobile}>{item.name}</ItemColumn>
          <RightItemsWrapper>
            {showEditItem && (
              <EditButtonWrapper>
                <Button
                  isSecondary={true}
                  icon={<FaPen />}
                  onClick={() => handleOnEditClick(item)}
                ></Button>
              </EditButtonWrapper>
            )}
            {isSelected && (
              <ItemColumn isMobile={isMobile}>
                <CheckMarkWrapper>
                  <FaCheck />
                </CheckMarkWrapper>
              </ItemColumn>
            )}
          </RightItemsWrapper>
        </Item>
      </ItemWrapper>
    )
  }

  const headerIcon = () => {
    return (
      <HeaderIcon>
        <FaBook />
      </HeaderIcon>
    )
  }

  const createModalProps: Partial<ModalProps> = {
    width: 600,
    height: 800
  }

  const renderCreateForm = (onCreate: (data: IAccount) => void, item: IAccount) => {
    return <AccountForm account={item} onCreate={onCreate} />
  }

  return (
    <UniversalAPISelector
      {...rest}
      fetchItem={id => {
        const data = fetchAccount({ businessId, accountId: id })
        return data
      }}
      fetchItems={({ page, search }) => {
        const options = { page, page_size: 500, search }
        const data = fetchAutoCompleteAccounts({ businessId }, { ...options, ...accountFilter })
        return data
      }}
      renderRow={renderRow}
      noDataMessage={t('document.accountSelectNoData')}
      dropDownHeader={t('document.accountSelectHeader')}
      dropdownHeaderIcon={headerIcon()}
      queryKey={`AccountUAS-${queryKey}`}
      formId={formId}
      getDataToShow={dataToShow}
      createModalProps={allowCreate && createModalProps}
      renderCreateForm={allowCreate && renderCreateForm}
    />
  )
}

const HeaderIcon = styled(FaBook)`
  align-self: center;
  margin-right: 0.4rem;
  fill: ${({ theme }) => theme.colors.iconGrayDark};
`

const Item = styled(motion.div).attrs({
  variants: {
    hidden: {
      y: 5,
      opacity: 0
    },
    show: {
      y: 0,
      opacity: 1
    }
  }
})`
  display: flex;
  width: 100%;
  padding: 0.4rem 0.6rem;

  cursor: pointer;
  height: 35px;
`

const EditButtonWrapper = styled.div`
  display: none;
  align-self: center;
`

const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: ${({ theme }) => theme.spacing.xs}rem;

  &:hover ${EditButtonWrapper} {
    display: block;
  }
  &.keyboard-focused,
  &:hover {
    background: ${({ theme }) => theme.colors.mainBg};
  }
`

const RightItemsWrapper = styled.div`
  margin-left: auto;
  display: flex;
`

const ItemColumn = styled.div<{ isMobile }>`
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.id {
    min-width: ${({ theme }) => theme.spacing.xxl}rem;
    font-size: ${({ theme }) => theme.fontSize.sm}rem;
    color: ${({ theme }) => theme.colors.metalGray};
    font-variant-numeric: tabular-nums;
    font-family: 'Roboto Mono', monospace;

    ${({ theme, isMobile }) =>
      isMobile ? `margin-right:  0;` : `margin-right:  ${theme.spacing.sm}rem;`}
  }
`
const CheckMarkWrapper = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  margin: ${({ theme }) => theme.spacing.sm}rem;

  svg {
    fill: ${({ theme }) => theme.colors.nocfoGreen};
  }
`
