import { initializeDayJs, initializei18n } from '@localisation'
import * as Sentry from '@sentry/react'
import 'react-18-image-lightbox/style.css'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ReactDOMClient from 'react-dom/client'
import { CustomerlyProvider } from 'react-live-chat-customerly'
import { DefaultOptions, QueryClient, QueryClientProvider } from 'react-query'
import App from './App'
import './index.css'
import * as serviceWorker from './serviceWorker'

initializei18n()
initializeDayJs()

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  enabled: import.meta.env.VITE_SENTRY_ENABLED === 'true',
  release: process.env.SENTRY_RELEASE // Defined in vite.config.mts
})

export const DEFAULT_QUERY_CLIENT_OPTIONS: DefaultOptions = {
  queries: {
    cacheTime: 1000 * 60 * 60 * 24 // 24 hours,
  }
}
const queryClient = new QueryClient({
  defaultOptions: DEFAULT_QUERY_CLIENT_OPTIONS
})

const container = document.getElementById('root')
const root = ReactDOMClient.createRoot(container)

root.render(
  <QueryClientProvider client={queryClient}>
    <CustomerlyProvider appId={import.meta.env.VITE_CUSTOMERLY_APP_ID}>
      <DndProvider backend={HTML5Backend}>
        <App />
      </DndProvider>
    </CustomerlyProvider>
  </QueryClientProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
