import { AnimatedContentLoader } from '@components'
import { useBusinessContext } from '@context'
import { useQueryParam, useVirtualList } from '@hooks'
import { collectFromPages, fetchPeriods, IPeriod } from '@query'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useInfiniteQuery } from 'react-query'
import styled from 'styled-components'
import { PeriodListItem } from './PeriodListItem'

const ITEM_HEIGHT = 92

export const PeriodList: React.FC = () => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const [selectedId] = useQueryParam<number>('id', value => parseInt(value, 10))

  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery(
    [businessId, 'periods'],
    ({ pageParam = 1 }) => fetchPeriods({ businessId }, { page: pageParam }),
    {
      getNextPageParam: lastPage => lastPage.next,
      getPreviousPageParam: lastPage => lastPage.prev
    }
  )
  const periods = collectFromPages<IPeriod>(data)

  const { scrollRef, renderItems } = useVirtualList({
    items: periods,
    itemSize: ITEM_HEIGHT,
    onScrollBottom: () => hasNextPage && fetchNextPage()
  })

  return (
    <PeriodListWrapper ref={scrollRef}>
      <AnimatedContentLoader
        isLoading={isLoading}
        isEmpty={periods.length === 0}
        isEmptyDescription={t('period.noPeriods')}
      >
        <AnimatePresence>
          {renderItems((item, style, index) => {
            const isFirstOfType = index === 0
            const isLastOfType = index === periods.length - 1

            return (
              <motion.div layout="position" key={`period-${item.id}`} style={style}>
                <PeriodListItem
                  period={item}
                  isSelected={selectedId === item.id}
                  isFirstOfType={isFirstOfType}
                  isLastOfType={isLastOfType}
                  height={ITEM_HEIGHT}
                />
              </motion.div>
            )
          })}
        </AnimatePresence>
      </AnimatedContentLoader>
    </PeriodListWrapper>
  )
}

const PeriodListWrapper = styled.div`
  overflow: auto;
  height: 100%;
`
