import { motion } from 'framer-motion'
import { FaMagic } from 'react-icons/fa'
import styled from 'styled-components'

export const DropdownWrapper = styled.div`
  position: relative;
`

export const StyledBusinessDropdown = styled(motion.div).attrs({
  variants: {
    hide: {
      opacity: 0,
      scale: 0.8,
      y: -25,
      x: 0,
      transition: {
        duration: 0.4,
        staggerChildren: 0.0,
        staggerDirection: -1,
        type: 'spring'
      }
    },
    show: {
      opacity: 1,
      scale: 1.0,
      y: 0,
      x: 0,
      transition: {
        duration: 0.4,
        staggerChildren: 0.0,
        staggerDirection: 1,
        type: 'spring'
      }
    }
  },
  initial: 'hide',
  animate: 'show',
  exit: 'hide'
})`
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 100000;
  border-radius: 1rem;
  width: 600px;
  overflow: auto;
  padding: ${({ theme }) => theme.spacing.md}rem;
  box-sizing: content-box;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
`

export const Dropdown = styled(motion.div).attrs({
  layout: true,
  variants: {
    hidden: { y: 10, opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.1
      }
    }
  },
  initial: 'hidden',
  animate: 'show',
  exit: 'hidden'
})`
  position: absolute;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  background: white;
  padding: 0.8rem;
  z-index: 999999;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
`

export const Header = styled.div`
  display: flex;
  font-weight: 500;
  margin: 0.6rem;
  margin-bottom: 0;
`

export const HeaderIcon = styled(FaMagic)`
  align-self: center;
  margin-left: 0.4rem;
  fill: ${({ theme }) => theme.colors.metalGray};
`

export const HeaderDetail = styled.div`
  font-size: 0.8rem;
  margin: 0.6rem;
  margin-top: 0;
`

export const Item = styled(motion.div).attrs({
  variants: {
    hidden: {
      y: 5,
      opacity: 0
    },
    show: {
      y: 0,
      opacity: 1
    }
  }
})`
  display: flex;
  padding: 0.4rem 0.6rem;
  border-radius: 0.5rem;
  cursor: pointer;

  &.keyboard-focused,
  &:hover {
    background: ${({ theme }) => theme.colors.mainBg};
  }
`

export const ItemColumn = styled.div`
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.description {
    flex: 1;
  }
`
