import { AnimatePresence, motion } from 'framer-motion'
import React, { useMemo } from 'react'
import styled from 'styled-components'

interface Props {
  slideIndex: number
  slideCount: number
}

export const PagerBalls: React.FC<Props> = ({ slideIndex, slideCount }) => {
  const pagerBallKeys = useMemo(() => {
    const activeKey = 'slide-active'
    const otherKeys = Array(slideCount - 1)
      .fill(0)
      .map((_, index) => `slide-${index}`)
    return [...otherKeys.slice(0, slideIndex), activeKey, ...otherKeys.slice(slideIndex)]
  }, [slideIndex, slideCount])

  return (
    <Balls>
      <AnimatePresence initial={false}>
        {pagerBallKeys.map((key, index) => (
          <Ball
            key={key}
            animate={key === 'slide-active' ? 'active' : 'default'}
            className={slideIndex === index && 'selected'}
          />
        ))}
      </AnimatePresence>
    </Balls>
  )
}

const Balls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: ${({ theme }) => theme.spacing.md}rem;
`

const Ball = styled(motion.div).attrs({
  layout: 'position',
  variants: {
    active: { opacity: 1, scale: 1 },
    default: { opacity: 0.2, scale: 0.8 }
  }
})`
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background: ${({ theme }) => theme.colors.nocfoBlue};
`
