import { IFile } from '@query'
import React, { useCallback, useMemo, useState } from 'react'
import Lightbox from 'react-18-image-lightbox'
import { BlurhashCanvas } from 'react-blurhash'
import { FaTimes } from 'react-icons/fa'
import styled from 'styled-components'

interface FilePreviewProps {
  file: IFile
  showRemove?: boolean
  onRemove?: () => void
  showHeader?: boolean
  showNoPreview?: boolean
}

export const FilePreview2: React.FC<FilePreviewProps> = ({
  file,
  showHeader,
  showRemove,
  onRemove,
  showNoPreview = false
}) => {
  const [lightBoxShown, setLightBoxShown] = useState(false)
  const fileSrc = useMemo(
    () => file.file,
    [file.id, file.file.split('?')[0], new Date().getHours()]
  )
  const isImage = file.type.toLowerCase().indexOf('image') !== -1
  const isPdf = file.type.toLowerCase() === 'application/pdf'

  const scale = useCallback((number, inMin, inMax, outMin, outMax) => {
    return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin
  }, [])

  const blurhashW = useMemo(
    () =>
      file.blurhash_w && file.blurhash_h
        ? Math.round(scale(file.blurhash_w, 0, Math.max(file.blurhash_w, file.blurhash_h), 0, 32))
        : undefined,
    [file.id]
  )
  const blurhashH = useMemo(
    () =>
      file.blurhash_w && file.blurhash_h
        ? Math.round(scale(file.blurhash_h, 0, Math.max(file.blurhash_w, file.blurhash_h), 0, 32))
        : undefined,
    [file.id]
  )

  if (isImage) {
    return (
      <>
        {showHeader && (
          <StyledHeader>
            {file.name}
            {showRemove && <StyledRemove onClick={() => onRemove()} />}
          </StyledHeader>
        )}
        <StyledCard>
          <ImagePreviewWrapper onClick={() => setLightBoxShown(true)}>
            {file.blurhash && (
              <ImagePreview key="blurhash">
                <BlurhashCanvas
                  hash={file.blurhash}
                  width={blurhashW}
                  height={blurhashH}
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain'
                  }}
                />
              </ImagePreview>
            )}
            <ImagePreview
              key="content"
              url={fileSrc}
              imageW={file.blurhash_w}
              imageH={file.blurhash_h}
            />
          </ImagePreviewWrapper>

          {lightBoxShown && (
            <Lightbox mainSrc={fileSrc} onCloseRequest={() => setLightBoxShown(false)} />
          )}
        </StyledCard>
      </>
    )
  }

  if (isPdf) {
    return (
      <>
        {showHeader && (
          <StyledHeader>
            {file.name}
            {showRemove && <StyledRemove onClick={() => onRemove()} />}
          </StyledHeader>
        )}
        <StyledCard>
          <StyledEmbedWrapper>
            <StyledEmbed src={file.file} type={file.type} />
          </StyledEmbedWrapper>
        </StyledCard>
      </>
    )
  }

  if (showNoPreview) {
    return <NoPreview>{`Tiedostoa ${file.name} ei voida esikatsella`}</NoPreview>
  }

  return null
}

const ImagePreviewWrapper = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 100%;
`

const ImagePreview = styled.div<{ url?: string; imageW?: number; imageH?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-image: url(${({ url }) => url});
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`

const StyledEmbedWrapper = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 70.7%;
`

const StyledEmbed = styled.embed`
  border: none;
  position: absolute;
  width: 100%;
  height: 100%;
`

const StyledCard = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.02);
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;
  border: 2px solid ${({ theme }) => theme.colors.neutralGray};
  border-radius: 1rem;
`

const StyledHeader = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.metalGray};
  margin-bottom: ${({ theme }) => theme.spacing.xs}rem;
`

const StyledRemove = styled(FaTimes)``

const NoPreview = styled.div`
  color: ${({ theme }) => theme.colors.metalGray};
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  text-align: center;
  padding: ${({ theme }) => theme.spacing.xxl}rem ${({ theme }) => theme.spacing.md}rem;
  background: ${({ theme }) => theme.colors.neutralGray};
  border-radius: 1rem;
`
