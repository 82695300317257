import { Alert, CopyValue, ToggleContainer } from '@components'
import { IImportData } from '@query'
import { formatCurrency, formatDate } from '@utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const DocumentBankDetails: React.FC<{ import_data: IImportData }> = ({ import_data }) => {
  const { t } = useTranslation()

  return (
    <>
      <Alert description={t('document.importDetails.alertDescription')} />

      <DetailRow>
        <StyledHeader>{t('document.importDetails.date')}</StyledHeader>
        <StyledValue>{formatDate(import_data.date || '-')}</StyledValue>
      </DetailRow>

      <DetailRow>
        <StyledHeader>{t('document.importDetails.description')}</StyledHeader>
        <StyledValue>{import_data.description || '-'}</StyledValue>
      </DetailRow>

      <DetailRow>
        <StyledHeader>{t('document.importDetails.amount')}</StyledHeader>
        <StyledValue>{import_data.amount ? formatCurrency(import_data.amount) : '-'}</StyledValue>
      </DetailRow>

      <DetailRow>
        <StyledHeader>{t('document.importDetails.contact')}</StyledHeader>
        <StyledValue>{import_data.contact_hint || '-'}</StyledValue>
      </DetailRow>

      <DetailRow>
        <StyledHeader>{t('document.importDetails.reference')}</StyledHeader>
        <StyledValue>{import_data.reference_number || '-'}</StyledValue>
      </DetailRow>

      <ToggleContainer title={t('document.importDetails.traceId')}>
        <DetailRow>
          <StyledHeader>{t('document.importDetails.traceIdDescription')}</StyledHeader>
          <StyledValue>
            <CopyValue value={import_data.trace_id || '-'} />
          </StyledValue>
        </DetailRow>
      </ToggleContainer>
    </>
  )
}

const DetailRow = styled.div`
  flex: 1;
  margin-bottom: ${({ theme }) => theme.spacing.lg}rem;
`

const StyledHeader = styled.p`
  color: ${({ theme }) => theme.colors.metalGray};
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  margin-bottom: ${({ theme }) => theme.spacing.xxs}rem;
  align-self: flex-start;
`

const StyledValue = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  color: ${({ theme }) => theme.colors.neutralBlack};
  margin: 0;
`
