import React from 'react'
import styled from 'styled-components'
import { Badge, Select, TextTooltip } from '@components'
import { FaArrowTurnUp, FaMapPin } from 'react-icons/fa6'
import { FaCheckCircle, FaDotCircle, FaTimesCircle } from 'react-icons/fa'
import { useTheme } from '@hooks'
import { FieldError } from 'react-hook-form'
import { isNil } from 'lodash'
import cn from 'classnames'
import { FieldToMap } from '@containers/CSVImportWizard/base/slides/components/types.ts'
import { useTranslation } from 'react-i18next'

export type ReadyStatus = 'UNSET' | 'SUCCESS' | 'ERROR'

interface MapperOption {
  label: string
  info?: string
  value: string
}

export interface ValueError {
  row: number
  message: string
}

interface Props {
  field: FieldToMap<any>
  value: string
  status: ReadyStatus
  errors?: FieldError
  onChange: (value: string) => void
  options: MapperOption[]
  disabledOptions?: string[]
}

export const FieldMapper: React.FC<Props> = ({
  field,
  value,
  onChange,
  options,
  disabledOptions = [],
  status,
  errors
}) => {
  const { t } = useTranslation()
  const hasError = status === 'ERROR'

  const isDisabledOption = (selection: string) =>
    selection !== value && !isNil(disabledOptions?.find(v => v === selection))

  return (
    <FieldMapperWrapper className={cn({ hasError })}>
      <Row>
        <SourceFieldName>
          <ReadinessIndicator status={status} />
          <span>
            {field.name} {field.required && <span className="required-indicator">*</span>}
          </span>
          {field.isIdentifier && (
            <>
              <div style={{ flex: 1 }} />
              <TextTooltip tooltip={t('components.importWizard.mapper.isIdentifierInfo')}>
                <Badge>
                  <FaMapPin />
                  <span>{t('components.importWizard.mapper.isIdentifier')}</span>
                </Badge>
              </TextTooltip>
            </>
          )}
        </SourceFieldName>
      </Row>
      {field?.info && (
        <Row>
          <FieldInfo>{field.info}</FieldInfo>
        </Row>
      )}
      <Row>
        <FaArrowTurnUp className="arrow-turn" />
        <Select
          name={field.apiField + '-select'}
          label={t('components.importWizard.mapper.label')}
          value={value}
          onChange={onChange}
        >
          <option value="">{'-'}</option>
          {options.map(({ value, label }) => (
            <option key={value} disabled={isDisabledOption(value)}>
              {label} {isDisabledOption(value)}
            </option>
          ))}
        </Select>
      </Row>
      {errors && <ErrorMessage>{errors.message}</ErrorMessage>}
    </FieldMapperWrapper>
  )
}

const ReadinessIndicator: React.FC<{ status: ReadyStatus }> = ({ status }) => {
  const theme = useTheme()

  return (
    <>
      {status === 'UNSET' && <FaDotCircle color={theme.colors.neutralGray} />}
      {status === 'SUCCESS' && <FaCheckCircle color={theme.colors.nocfoGreen} />}
      {status === 'ERROR' && <FaTimesCircle color={theme.colors.nocfoRed} />}
    </>
  )
}

const FieldMapperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.md}rem;
  padding: ${({ theme }) => theme.spacing.md}rem;
  border-radius: 1rem;
  border: 2px solid ${({ theme }) => theme.colors.neutralGray};
  transition: border-color 0.4s;

  &.hasError {
    border-color: ${({ theme }) => theme.colors.nocfoRed};
    font-size: ${({ theme }) => theme.fontSize.sm}rem;
    font-weight: 500;
  }
`

const FieldInfo = styled.div`
  color: ${({ theme }) => theme.colors.metalGray};
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  padding-left: ${({ theme }) => theme.spacing.xl}rem;
`

const ErrorMessage = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  color: ${({ theme }) => theme.colors.nocfoRed};
  padding-left: ${({ theme }) => theme.spacing.xl}rem;
`

const SourceFieldName = styled.h3`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md}rem;
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.neutralBlack};
  margin: 0;

  .required-indicator {
    color: ${({ theme }) => theme.colors.nocfoRed};
    font-weight: bold;
  }
`

const Row = styled.div`
  display: flex;
  flex: 1;
  gap: ${({ theme }) => theme.spacing.md}rem;
  align-items: center;
  justify-content: space-between;

  & > * {
    margin: 0;
  }

  .arrow-turn {
    width: ${({ theme }) => theme.fontSize.md}rem;
    height: ${({ theme }) => theme.fontSize.md}rem;
    fill: ${({ theme }) => theme.colors.metalGray};
    rotate: 90deg;
  }
`
