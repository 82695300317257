import { AnimatedContentLoader } from '@components'
import { useBusinessContext } from '@context'
import { useQueryParam } from '@hooks'
import { fetchRequisitionStatus } from '@query'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { BankIntegrations } from './BankIntegrations'
import { RequisitionLoader } from './RequisitionLoader'

export const IntegrationSettings: React.FC = () => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const { businessId } = useBusinessContext()
  const [waitForReference, setWaitForReference] = useQueryParam<string>('reference')

  useQuery(
    [businessId, 'wait_for_reference', waitForReference],
    () => fetchRequisitionStatus({ businessId, reference: waitForReference }),
    {
      onSuccess: data => {
        if (data.status === 'DONE') {
          setWaitForReference(undefined)
          toast.success(t('settings.integrations.bank.integrationCreated'))
          queryClient.invalidateQueries([businessId, 'bank_integrations'])
        } else if (data.status === 'CANCELLED') {
          setWaitForReference(undefined)
        } else if (data.status === 'FAILED') {
          setWaitForReference(undefined)
          toast.error(t('error.oho'))
        }
      },
      onError: () => {
        setWaitForReference(undefined)
        toast.error(t('error.oho'))
      },
      enabled: !!waitForReference,
      refetchInterval: 1000
    }
  )

  return (
    <StyledWrapper>
      <h2>{t('settings.sections.integrations')}</h2>

      <AnimatePresence mode="wait" initial={false}>
        {waitForReference ? (
          <AnimatedWrapper key="loader">
            <RequisitionLoader />
          </AnimatedWrapper>
        ) : (
          <AnimatedWrapper key="content">
            <AnimatedContentLoader isLoading={!!waitForReference}>
              <BankIntegrations />
            </AnimatedContentLoader>
          </AnimatedWrapper>
        )}
      </AnimatePresence>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  background: white;
  height: 100%;
  overflow: auto;
  padding: ${({ theme }) => theme.spacing.md}rem;

  h2 {
    margin-bottom: ${({ theme }) => theme.spacing.lg}rem;
  }
`

const AnimatedWrapper = styled(motion.div).attrs({
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 0 }
})``
