import { AnimatedContentLoader } from '@components'
import { getDocumentsPageUrl } from '@constants'
import { useBusinessContext } from '@context'
import { useQueryParams } from '@hooks'
import { DocumentOverview } from '@pages/DocumentPage/DocumentDetails/DocumentOverview'
import { IDocument } from '@query'
import {
  acceptAccountingSuggestion,
  fetchAccountingSuggestions,
  IAccountingSuggestion
} from '@query/suggestions'
import React, { useCallback } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { SuggestionCardStack } from './SuggestionCardStack'

export const AccountingSuggestions: React.FC<{
  document: IDocument
  setShowEdit: (value: boolean) => void
}> = ({ document, setShowEdit }) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const { businessId } = useBusinessContext()
  const [params] = useQueryParams()

  const { data, isLoading } = useQuery([businessId, 'suggestions', document.id], () =>
    fetchAccountingSuggestions({ businessId, documentId: document.id })
  )
  const suggestions = data?.results || []

  const invalidateQueries = useCallback(async () => {
    await Promise.all([
      queryClient.invalidateQueries([businessId, 'documents']),
      queryClient.invalidateQueries([businessId, 'documents', document.id]),
      queryClient.invalidateQueries([businessId, 'suggestions', document.id])
    ])
  }, [queryClient, document.id])

  interface AcceptPropsIn {
    suggestionId: number
    modifiedData?: Partial<IAccountingSuggestion>
  }

  const acceptMutation = useMutation<unknown, unknown, AcceptPropsIn>(
    ({ suggestionId, modifiedData }) => {
      return acceptAccountingSuggestion({ businessId, suggestionId, modifiedData })
    },
    {
      onSuccess: async () => {
        await invalidateQueries()
        history.push(
          getDocumentsPageUrl(businessId, { ...params, id: document.id, tab: 'overview' })
        )
      }
    }
  )

  return (
    <Wrapper>
      <AnimatedContentLoader
        isLoading={isLoading}
        isEmpty={suggestions.length === 0}
        isEmptyContent={<DocumentOverview document={document} setShowEdit={setShowEdit} />}
      >
        {suggestions.length > 0 && (
          <SuggestionCardStack
            document={document}
            suggestions={suggestions}
            onAccept={(suggestionId, modifiedData) => {
              return acceptMutation.mutateAsync({ suggestionId, modifiedData })
            }}
          />
        )}
      </AnimatedContentLoader>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
