import { Input } from '@components'
import React from 'react'
import { get, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SlideText, SlideTitle } from './slide.styled'
interface BusinessNameInputProps {
  name: string
}

export const BusinessNameSlide: React.FC<BusinessNameInputProps> = () => {
  const [t] = useTranslation()
  const {
    register,
    formState: { errors }
  } = useFormContext()

  return (
    <>
      <SlideTitle>{t('onboarding.businessNameSlide.title')}</SlideTitle>
      <SlideText>{t('onboarding.businessNameSlide.message')}</SlideText>

      <Input
        id="business_name-input"
        autoFocus={true}
        label={t('onboarding.businessNameSlide.inputLabel')}
        type="text"
        placeholder={t('onboarding.businessNameSlide.inputPlaceholder')}
        errors={get(errors, 'business.name')}
        {...register('business.name', {
          required: { value: true, message: t('validation.required') }
        })}
      />
    </>
  )
}
