import { Button, DocumentWidget, HeaderBlock, Prompt, Tab, Tabs } from '@components'
import { ContactType, CONTACT_TYPE_OPTIONS, UserRole } from '@constants'
import { PermissionBoundary, useBusinessContext, usePermissionBoundary } from '@context'
import { useQueryParam } from '@hooks'
import { collectFromPages, deleteContact, fetchContact, IContact } from '@query'
import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FaCalculator, FaInfoCircle, FaPen, FaStream, FaTrash } from 'react-icons/fa'
import ReactMarkdown from 'react-markdown'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { ContactFormModal } from '../ContactFormModal'

interface Props {
  contactId: number
}

export const ContactDetails: React.FC<Props> = ({ contactId }) => {
  const { businessId } = useBusinessContext()
  const queryClient = useQueryClient()
  const [t] = useTranslation()
  const [showDelete, setShowDelete] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [, setActiveId] = useQueryParam('id')
  const isEditor = usePermissionBoundary(UserRole.EDITOR)

  const { data } = useQuery(
    [businessId, 'contacts', contactId],
    () => fetchContact({ businessId, contactId }),
    {
      initialData: () => {
        const data = queryClient.getQueryData([businessId, 'contacts'], { exact: false })
        if (data) {
          const contacts = collectFromPages<IContact>(data as any)
          return contacts.find(c => c.id === contactId)
        }
      }
    }
  )

  const deleteMutation = useMutation(() => deleteContact({ businessId, contactId: data?.id }), {
    onSuccess: () => {
      toast.success(t('contacts.deletedSuccess'))
      setActiveId(undefined)
    },
    onError: () => {
      toast.error(t('general.error'))
    },
    onSettled: () => queryClient.invalidateQueries([businessId, 'contacts'], { exact: false })
  })

  const contantType = CONTACT_TYPE_OPTIONS.find(o => o.value === data?.type)
  const isBusiness = data?.type === ContactType.BUSINESS

  return (
    <>
      <StyledContact>
        <HeaderBlock
          header={t('contacts.detailsTitle')}
          subHeader={data?.name}
          menuItems={
            isEditor && [
              {
                name: t('contacts.delete'),
                Icon: FaTrash,
                onClick: () => setShowDelete(true),
                intent: 'danger'
              }
            ]
          }
        />
        <ScrollableContent>
          <Tabs>
            <Tab key="info" icon={<FaInfoCircle />} name={t('contacts.tabGeneral')}>
              <div>
                <DetailRow>
                  <StyledHeader>{t('contacts.form.name')}</StyledHeader>
                  <StyledValue>
                    {data?.name}

                    <div style={{ marginTop: 10 }}>
                      {data?.name_aliases.map(name => (
                        <div style={{ opacity: 0.6 }} key={name}>
                          {name}
                        </div>
                      ))}
                    </div>
                  </StyledValue>
                </DetailRow>

                <DetailRow>
                  <StyledHeader>{t('contacts.form.type')}</StyledHeader>
                  <StyledValue>{t(contantType?.labelKey)}</StyledValue>
                </DetailRow>

                {isBusiness && (
                  <DetailRow>
                    <StyledHeader>{t('contacts.form.businessId')}</StyledHeader>
                    <StyledValue>{data?.contact_business_id || '-'}</StyledValue>
                  </DetailRow>
                )}

                {data?.notes && (
                  <DetailRow>
                    <StyledHeader>{t('contacts.form.notes')}</StyledHeader>
                    <StyledNotes>
                      <ReactMarkdown linkTarget="_blank">{data?.notes}</ReactMarkdown>
                    </StyledNotes>
                  </DetailRow>
                )}
              </div>
            </Tab>

            {data?.is_invoicing_enabled && (
              <Tab key="invoicing" icon={<FaCalculator />} name={t('contacts.tabInvoicing')}>
                <DetailRow>
                  <StyledHeader>{t('contacts.form.email')}</StyledHeader>
                  <StyledValue>{data?.invoicing_email || '-'}</StyledValue>
                </DetailRow>

                {isBusiness && (
                  <>
                    <DetailRow>
                      <StyledHeader>{t('contacts.form.einvoiceAddress')}</StyledHeader>
                      <StyledValue>{data?.invoicing_einvoice_address || '-'}</StyledValue>
                    </DetailRow>

                    <DetailRow>
                      <StyledHeader>{t('contacts.form.einvoiceOperator')}</StyledHeader>
                      <StyledValue>{data?.invoicing_einvoice_operator || '-'}</StyledValue>
                    </DetailRow>

                    <DetailRow>
                      <StyledHeader>{t('contacts.form.taxNumber')}</StyledHeader>
                      <StyledValue>{data?.invoicing_tax_code || '-'}</StyledValue>
                    </DetailRow>
                  </>
                )}

                <DetailRow>
                  <StyledHeader>{t('contacts.form.invoicingStreet')}</StyledHeader>
                  <StyledValue>{data?.invoicing_street || '-'}</StyledValue>
                </DetailRow>

                <DetailRow>
                  <StyledHeader>{t('contacts.form.invoicingCity')}</StyledHeader>
                  <StyledValue>{data?.invoicing_city || '-'}</StyledValue>
                </DetailRow>

                <DetailRow>
                  <StyledHeader>{t('contacts.form.invoicingPostalCode')}</StyledHeader>
                  <StyledValue>{data?.invoicing_postal_code || '-'}</StyledValue>
                </DetailRow>

                <DetailRow>
                  <StyledHeader>{t('contacts.form.invoicingCountry')}</StyledHeader>
                  <StyledValue>{data?.invoicing_country || '-'}</StyledValue>
                </DetailRow>
              </Tab>
            )}

            <Tab key="docs" icon={<FaStream />} name={t('contacts.documents')}>
              <DocumentWidget
                header={t('contacts.documentsHeader')}
                noData={t('contacts.documentsNoData')}
                contactId={contactId}
                height={400}
              />
            </Tab>
          </Tabs>
        </ScrollableContent>

        <PermissionBoundary requireRole={UserRole.EDITOR}>
          <StyledFooter>
            <Button icon={<FaPen />} onClick={() => setShowEdit(true)}>
              {t('general.edit')}
            </Button>
          </StyledFooter>
        </PermissionBoundary>
      </StyledContact>

      <ContactFormModal
        isVisible={showEdit}
        handleOnClose={() => setShowEdit(false)}
        contact={data}
      />

      <Prompt
        title={t('contacts.delete')}
        description={t('contacts.deleteConfirm')}
        isVisible={showDelete}
        onClose={() => setShowDelete(false)}
        buttons={[
          {
            text: t('general.cancel'),
            action: () => setShowDelete(false)
          },
          {
            text: t('general.delete'),
            intent: 'danger',
            action: () => {
              setShowDelete(false)
              deleteMutation.mutate()
            }
          }
        ]}
      />
    </>
  )
}

const StyledContact = styled.div`
  height: 100%;
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
`

const ScrollableContent = styled.div`
  flex: 1;
  overflow: auto;
  padding: ${({ theme }) => theme.spacing.md}rem;
  padding-top: 0;
`

const StyledFooter = styled.div`
  padding: ${({ theme }) => theme.spacing.lg}rem;
`

const DetailRow = styled(motion.div).attrs({ layout: 'position' })`
  flex: 1;
  margin-bottom: ${({ theme }) => theme.spacing.sm}rem;
`

const StyledHeader = styled.p`
  color: ${({ theme }) => theme.colors.metalGray};
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  margin-bottom: ${({ theme }) => theme.spacing.xxs}rem;
  align-self: flex-start;
`

const StyledValue = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  color: ${({ theme }) => theme.colors.neutralBlack};
  margin: 0;
`

const StyledNotes = styled.div`
  color: ${({ theme }) => theme.colors.neutralBlack};
  width: 100%;
`
