import { Button } from '@components'
import { FaDoorOpen, FaKey } from 'react-icons/fa6'
import { FaExternalLinkAlt } from 'react-icons/fa'
import {
  StyledFlexContainer,
  StyledFullHeightContainer,
  StyledStackedButtonContainer
} from './KraviaCommon.styled.tsx'
import { useTranslation } from 'react-i18next'

interface Props {
  linkSrc: string
  handleOnRegister: () => void
  handleOnClose: () => void
}

export const KraviaDeepLinkForm: React.FC<Props> = ({
  linkSrc,
  handleOnRegister,
  handleOnClose
}) => {
  const { t } = useTranslation()

  return (
    <StyledFlexContainer>
      <StyledFullHeightContainer>
        {t('invoicing.debtCollection.openInKraviaModal.description')}
      </StyledFullHeightContainer>

      <StyledStackedButtonContainer>
        <a href={linkSrc} onClick={() => handleOnClose()} target="_blank" rel="noreferrer">
          <Button intent="primary" icon={<FaExternalLinkAlt />}>
            {t('invoicing.debtCollection.openInKraviaModal.toKraviaButton')}
          </Button>
        </a>
        <Button icon={<FaKey />} onClick={() => handleOnRegister()}>
          {t('invoicing.debtCollection.openInKraviaModal.registerButton')}
        </Button>
        <Button icon={<FaDoorOpen />} onClick={() => handleOnClose()}>
          {t('general.cancel')}
        </Button>
      </StyledStackedButtonContainer>
    </StyledFlexContainer>
  )
}
