import React from 'react'
import styled from 'styled-components'

interface Field {
  label: string
  value?: string | number | React.ReactNode
}

interface Props {
  fields: Field[]
}

export const ExampleCard: React.FC<Props> = ({ fields }) => {
  return (
    <StyledCardBase>
      {fields.map(({ label, value }, index) => (
        <ExampleCardRow key={`card-row-${index}`}>
          <div className="title">{label}</div>
          <div className="value">{value || '-'}</div>
        </ExampleCardRow>
      ))}
    </StyledCardBase>
  )
}

export const StyledCardBase = styled.div`
  display: grid;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.neutralGray};
  border-radius: 0.5rem;
  padding: ${({ theme }) => theme.spacing.md}rem;
  background: ${({ theme }) => theme.colors.neutralGray};
  gap: ${({ theme }) => theme.spacing.md}rem;
  padding-bottom: 6rem;

  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

export const ExampleCardRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xs}rem;

  .title {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.metalGray};
    font-size: ${({ theme }) => theme.fontSize.sm}rem;
  }

  .value {
    font-size: ${({ theme }) => theme.fontSize.md}rem;
    color: ${({ theme }) => theme.colors.neutralBlack};
  }
`
