import styled from 'styled-components'

export const Row = styled.div<{ spacing?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.spacing.md}rem;
  gap: ${({ theme }) => theme.spacing.md}rem;
  align-items: center;
  width: 100%;
  justify-content: space-around;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const Card = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding: ${theme.spacing.md}rem;
  box-shadow: inset 0 0 0 1px ${theme.colors.neutralGray};
  border-radius: ${theme.spacing.sm}rem;
  min-height: 7rem;
  min-width: 12rem;
  margin-left: ${theme.spacing.md}rem;
  margin-bottom: ${theme.spacing.md}rem;

  &:nth-of-type(1) {
    margin-left: 0;
  }
`
)

export const CardTitle = styled.h4`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSize.md}rem;
  line-height: ${({ theme }) => theme.fontSize.md}rem;
  color: ${({ theme }) => theme.colors.iconGrayDark};
  font-weight: 500;
`

export const DimensionProvider = styled.div<{ width: string; height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  display: flex;
  align-items: center;
  height: 100%;
  overflow: visible;
`
