import { Alert, AnimatedContentLoader, Input, Select, TextArea } from '@root/components'
import { InvoiceDeliveryMethod } from '@root/constants'
import { useBusinessContext } from '@root/context'
import { fetchContact, fetchContactDeliveryMethods, IInvoice, SendInvoiceProps } from '@root/query'
import React, { useEffect } from 'react'
import { get, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import styled from 'styled-components'

interface Props {
  invoiceReceiver: number
  emailContentName: 'data.email_content' | 'recurrence_email_content'
  emailSubjectName: 'data.email_subject' | 'recurrence_email_subject'
  changeDisabled?: (disabled: boolean) => void
}

const SendInvoiceFormBase: React.FC<Props> = ({
  invoiceReceiver: contactId,
  emailContentName,
  emailSubjectName,
  changeDisabled
}) => {
  const { businessId } = useBusinessContext()
  const [t] = useTranslation()
  const {
    register,
    watch,
    setValue,
    formState: { errors, isSubmitted }
  } = useFormContext<Partial<IInvoice> | SendInvoiceProps>()

  const {
    data: receiver,
    isLoading: isReceiverLoading,
    isError: isErrorReceiver
  } = useQuery([businessId, 'contact', contactId], () => fetchContact({ businessId, contactId }), {
    enabled: contactId !== null && contactId !== undefined
  })

  const {
    data: deliveryMethodOptions,
    isLoading: isDeliveryMethodsLoading,
    isError: isErrorDeliveryMethods
  } = useQuery(
    [businessId, 'invoice', contactId, 'delivery_methods'],
    () => fetchContactDeliveryMethods({ businessId, contactId: contactId }),
    {
      enabled: contactId !== null && contactId !== undefined,
      onSuccess: deliveryMethods => {
        // Set einvoice as default if delivery method allowed
        if (
          deliveryMethods.find(
            ({ method, errors }) => method === InvoiceDeliveryMethod.EINVOICE && errors.length === 0
          )
        ) {
          setValue('delivery_method', InvoiceDeliveryMethod.EINVOICE)
        } else {
          setValue('delivery_method', InvoiceDeliveryMethod.EMAIL)
        }
      }
    }
  )

  const isFormDataLoading = isReceiverLoading || isDeliveryMethodsLoading
  const isError = isErrorDeliveryMethods || isErrorReceiver

  const deliveryMethod = watch('delivery_method')
  const deliveryMethodOption = deliveryMethodOptions?.find(
    ({ method }) => method === deliveryMethod
  )

  useEffect(() => {
    const disabled =
      isFormDataLoading || isError || isSubmitted || contactId === undefined || contactId === null
    changeDisabled?.(disabled)
  }, [isFormDataLoading, isError, isSubmitted])

  return (
    <>
      {isError ? (
        <AlertWrapper>
          <Alert
            type="error"
            description={t('invoicing.form.repetition.errorFetchingSubject')}
          ></Alert>
        </AlertWrapper>
      ) : contactId ? (
        <AnimatedContentLoader isLoading={isFormDataLoading}>
          <Select
            label={t('invoicing.form.repetition.header')}
            {...register('delivery_method', { shouldUnregister: true })}
          >
            {deliveryMethodOptions?.map(({ method }) => (
              <option key={method} value={method}>
                {t(`invoicing.deliveryMethod.${method}`)}
              </option>
            ))}
          </Select>

          {deliveryMethodOption?.errors?.map((error, index) => (
            <Alert key={`alert-${index}`} type="warning" description={error} />
          ))}

          {deliveryMethod === InvoiceDeliveryMethod.EINVOICE && (
            <>
              <Input
                label={t('invoicing.send.einvoiceAddress')}
                value={receiver?.invoicing_einvoice_address}
                disabled={true}
              />
              <Input
                label={t('invoicing.send.einvoiceOperator')}
                value={receiver?.invoicing_einvoice_operator}
                disabled={true}
              />
            </>
          )}

          {deliveryMethod === InvoiceDeliveryMethod.EMAIL && (
            <>
              <Input
                label={t('invoicing.send.receiver')}
                value={receiver?.invoicing_email}
                disabled={true}
              />
              <Input
                label={t('invoicing.send.emailSubject')}
                required={true}
                {...register(emailSubjectName, {
                  required: {
                    value: true,
                    message: t('validation.required')
                  },
                  shouldUnregister: true
                })}
                errors={get(errors, emailSubjectName)}
              />
              <TextArea
                label={t('invoicing.send.emailContent')}
                {...register(emailContentName, {
                  shouldUnregister: true
                })}
                errors={get(errors, emailContentName)}
              />
            </>
          )}
        </AnimatedContentLoader>
      ) : (
        <AlertWrapper>
          <Alert type={'warning'} description={t('invoicing.form.repetition.noSubject')}></Alert>
        </AlertWrapper>
      )}
    </>
  )
}

export default SendInvoiceFormBase

const AlertWrapper = styled.div`
  flex: 1 0 100%;
`
