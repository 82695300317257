import { Button, DoubleColumnLayout, DoubleColumnLayoutColumn, Select } from '@components'
import { ReportType, REPORT_TYPE_OPTIONS } from '@constants'
import { useBusinessContext, useDateRangeContext, useFeatureContext } from '@context'
import { generateReport } from '@utils/generateReport'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useCallback, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FaCloudDownloadAlt } from 'react-icons/fa'
import styled from 'styled-components'
import { BalanceSheetForm } from './components/BalanceSheetForm'
import { DefaultReportForm } from './components/DefaultReportForm'
import { IncomeStatementForm } from './components/IncomeStatementForm'
import { FormFooterWrapper, MainSelectWrapper, Option } from './components/ReportsPage.styled'

export const ReportsPage: React.FC = () => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const { vat_obliged: vatObliged } = useFeatureContext()
  const { selectedRange } = useDateRangeContext()

  const getDefaultColumnValues = useCallback(
    () => [
      {
        date_at: selectedRange?.date_to,
        date_from: selectedRange?.date_from,
        date_to: selectedRange?.date_to
      }
    ],
    [selectedRange]
  )

  const methods = useForm({
    defaultValues: {
      business_id: businessId,
      type: ReportType.LEDGER,
      extend_accounts: true,
      columns: getDefaultColumnValues()
    },
    shouldUnregister: true
  })

  const {
    reset,
    formState: { isSubmitting }
  } = methods

  useEffect(() => {
    reset(
      { columns: getDefaultColumnValues() },
      { keepDirty: true, keepTouched: true, keepDefaultValues: true }
    )
  }, [selectedRange])

  const selectedType = methods.watch('type')

  const getForm = useCallback(selectedType => {
    switch (selectedType) {
      case ReportType.BALANCE_SHEET:
        return <BalanceSheetForm />

      case ReportType.INCOME_STATEMENT:
        return <IncomeStatementForm />

      default:
        return <DefaultReportForm />
    }
  }, [])

  const cleanForm = data => {
    if (data.type === ReportType.BALANCE_SHEET) {
      data.columns = data.columns.map(({ name, date_at }) => ({
        name,
        date_at
      }))
    } else if (data.type === ReportType.INCOME_STATEMENT) {
      data.columns = data.columns.map(({ name, date_from, date_to }) => ({
        name,
        date_from,
        date_to
      }))
    } else {
      data.columns = [data.columns[0]]
    }

    return data
  }

  // Filter report options. E.g. Hide VAT report is not VAT obliged business.
  const reportTypeOptions = REPORT_TYPE_OPTIONS.filter(({ value }) => {
    if (value == ReportType.VAT_REPORT) {
      return vatObliged
    } else {
      return true
    }
  })

  return (
    <DoubleColumnLayout
      header={t('reports.title')}
      isRightVisible={false}
      onRightClose={() => null}
    >
      <DoubleColumnLayoutColumn>
        <FormProvider {...methods}>
          <StyledForm
            onSubmit={methods.handleSubmit(data => {
              try {
                generateReport(cleanForm(data))
              } catch {
                // https://github.com/orgs/react-hook-form/discussions/9879#discussioncomment-4883381
                return null
              }
            })}
          >
            <input type="hidden" {...methods.register('business_id')} />

            <MainSelectWrapper>
              <Select label={t('reports.reportType')} {...methods.register('type')}>
                {reportTypeOptions.map(({ labelKey, value }) => (
                  <Option key={labelKey} value={value}>
                    {t(labelKey)}
                  </Option>
                ))}
              </Select>
            </MainSelectWrapper>

            <AnimatePresence mode="wait">
              <motion.div
                key={selectedType}
                variants={formAnimationVariants}
                animate="animate"
                initial="initial"
                exit="exit"
              >
                {getForm(selectedType)}
              </motion.div>
            </AnimatePresence>

            <FormFooterWrapper>
              <Button icon={<FaCloudDownloadAlt />} intent="primary" showSpinner={isSubmitting}>
                {t('reports.downloadBtn')}
              </Button>
            </FormFooterWrapper>
          </StyledForm>
        </FormProvider>
      </DoubleColumnLayoutColumn>
      <DoubleColumnLayoutColumn isRight={true} />
    </DoubleColumnLayout>
  )
}

const StyledForm = styled.form`
  background: white;
  height: 100%;
  padding: ${({ theme }) => theme.spacing.lg}rem;
  overflow: auto;
`

const formAnimationVariants = {
  initial: {
    opacity: 0,
    y: 10
  },
  animate: {
    opacity: 1,
    y: 0
  },
  exit: {
    opacity: 0
  }
}
