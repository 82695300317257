import { AnimatedContentLoader, DateProgressItem } from '@components'
import { useBusinessContext, useFeatureContext } from '@context'
import { useTheme } from '@hooks'
import { fetchPeriod, fetchVatPeriod } from '@query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

export const PeriodProgress: React.FC = () => {
  const [t] = useTranslation()
  const { is_ny, vat_obliged } = useFeatureContext()
  const theme = useTheme()
  const { businessId, periodId, vatPeriodId } = useBusinessContext()

  const showPeriodProgress = !is_ny && Boolean(periodId)
  const showVATProgress = !is_ny && vat_obliged && Boolean(vatPeriodId)

  const { data, isLoading } = useQuery(
    [businessId, 'period', 'active'],
    () => fetchPeriod({ businessId, periodId }),
    { enabled: showPeriodProgress }
  )

  const { data: vatPeriod, isLoading: loadingVat } = useQuery(
    [businessId, 'vatPeriod', 'active'],
    () => fetchVatPeriod({ businessId, vatPeriodId }),
    { enabled: showVATProgress }
  )

  // Do not render if NY
  if (is_ny) return null

  if (showVATProgress) {
    return (
      <div style={{ flex: 1 }}>
        <AnimatedContentLoader isLoading={isLoading || loadingVat}>
          {data && (
            <DateProgressItem
              dateFrom={new Date(data?.start_date)}
              dateTo={new Date(data?.end_date)}
              titleFrom={t('dashboard.periodProgress.periodStart')}
              titleTo={t('dashboard.periodProgress.periodEnd')}
              status={status}
              progressColor={() => theme.colors.blue}
            />
          )}

          {vatPeriod && (
            <DateProgressItem
              dateFrom={new Date(vatPeriod?.start_date)}
              dateTo={new Date(vatPeriod?.end_date)}
              titleFrom={t('dashboard.periodProgress.vatPeriodStart')}
              titleTo={t('dashboard.periodProgress.vatPeriodEnd')}
              status={status}
              progressColor={() => theme.colors.orange}
            />
          )}
        </AnimatedContentLoader>
      </div>
    )
  }

  return (
    <div style={{ flex: 1 }}>
      <AnimatedContentLoader isLoading={isLoading}>
        {data && (
          <DateProgressItem
            dateFrom={new Date(data?.start_date)}
            dateTo={new Date(data?.end_date)}
            titleFrom={t('dashboard.periodProgress.periodStart')}
            titleTo={t('dashboard.periodProgress.periodEnd')}
            status={status}
            progressColor={() => theme.colors.blue}
          />
        )}
      </AnimatedContentLoader>
    </div>
  )
}
