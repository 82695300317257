import img from '@assets/undraw/undraw_add_document_re_mbjx.svg'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useCustomerly } from 'react-live-chat-customerly'
import styled from 'styled-components'

export const DocumentPageOnboarding: React.FC = () => {
  const [t] = useTranslation()
  const { open } = useCustomerly()

  return (
    <StyledWrapper>
      <img src={img} />

      <h1>{t('document.onboarding.title')}</h1>

      <p>
        <Trans i18nKey="document.onboarding.description1">
          <a target="_blank" rel="noreferrer" href={t('link.docs.exampleDocuments')} />
        </Trans>
      </p>

      <p>
        <Trans i18nKey="document.onboarding.description2">
          <a href="#" onClick={() => open()} />
        </Trans>
      </p>
    </StyledWrapper>
  )
}

const CONTENT_WIDTH_PX = 450

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 1rem;
  padding: ${({ theme }) => theme.spacing.xxl}rem ${({ theme }) => theme.spacing.md}rem;
  color: ${({ theme }) => theme.colors.neutralBlack};

  img {
    width: 40%;
    max-width: 250px;
    min-width: 200px;
    padding: ${({ theme }) => theme.spacing.lg}rem;
  }

  h1 {
    text-align: center;
    font-size: ${({ theme }) => theme.fontSize.lg}rem;
    margin-top: ${({ theme }) => theme.spacing.md}rem;
    margin-bottom: ${({ theme }) => theme.spacing.md}rem;
    max-width: ${CONTENT_WIDTH_PX}px;
  }

  p {
    text-align: center;
    font-size: ${({ theme }) => theme.fontSize.md}rem;
    max-width: ${CONTENT_WIDTH_PX}px;
  }
`
