import styled from 'styled-components'
import { motion } from 'framer-motion'

export const StyledFlexContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm}rem;
`

export const StyledForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm}rem;
`

export const StyledFullHeightContainer = styled.div`
  flex: 1;
`

export const StyledStackedButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm}rem;

  a > * {
    width: 100%;
  }
`

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.sm}rem;
`

export const StyledRadioGroup = styled.div.attrs({ role: 'radiogroup' })`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm}rem;
`

export const StyledRadio = styled(motion.div).attrs({
  role: 'radio',
  whileHover: { scale: 1.02 },
  whileTap: { scale: 0.96 }
})<{ color: string }>`
  cursor: pointer;
  border: 3px solid ${({ theme }) => theme.colors.neutralGray};
  border-radius: 1rem;
  padding: ${({ theme }) => theme.spacing.md}rem;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  transition: 0.2s;

  &.selected {
    border: 3px solid ${({ color }) => color};
    background: ${({ color }) => color}11;
  }

  svg {
    fill: ${({ color }) => color};
  }
`
export const StyledRadioHeader = styled.strong`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  align-items: center;
`

export const StyledRadioDescription = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  color: ${({ theme }) => theme.colors.metalGray};
`
