import { IDraggableItem, Prompt } from '@components'
import { UserRole } from '@constants'
import { useBusinessContext, usePermissionBoundary } from '@context'
import { useQueryParam } from '@hooks'
import { IDocument, massDeleteDocuments } from '@query'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import { useDrop } from 'react-dnd'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { FaTrash } from 'react-icons/fa'
import { useMutation, useQueryClient } from 'react-query'
import styled from 'styled-components'

export const DocumentTrash: React.FC = () => {
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const [documents, setDocuments] = useState<IDocument[]>([])
  const queryClient = useQueryClient()
  const isEditor = usePermissionBoundary(UserRole.EDITOR)
  const [openDocumentId, setOpenDocumentId] = useQueryParam<number>('id', value =>
    value ? parseInt(value, 10) : undefined
  )

  const [{ isOver, isActiveTarget }, dropRef] = useDrop(() => ({
    accept: ['document'],
    collect: monitor => ({
      isOver: monitor.isOver(),
      isActiveTarget: monitor.canDrop()
    }),
    drop: (items: IDraggableItem[]) => {
      const docs = items.map(item => item.item) as IDocument[]
      setDocuments(docs)
    }
  }))

  const { mutate: massDeleteMutation, isLoading: isDeleting } = useMutation(
    () =>
      massDeleteDocuments(
        { businessId },
        documents.map(d => ({
          id: d.id
        }))
      ),
    {
      onSuccess: async data => {
        if (documents.find(d => d.id === openDocumentId)) {
          setOpenDocumentId(undefined)
        }

        queryClient.invalidateQueries([businessId, 'documents'])

        setDocuments([])

        const deleteCount = data.delete
        const lockedCount = data.locked

        if (lockedCount) {
          deleteCount
            ? toast(t('document.massDelete.successPartial', { deleteCount, lockedCount }), {
                icon: '⚠️'
              })
            : toast(t('document.massDelete.successOnlyLocked'), { icon: '⚠️' })
        } else if (deleteCount) {
          toast.success(t('document.massDelete.success', { deleteCount }))
        }
      }
    }
  )

  const animateState = isOver ? 'over' : isActiveTarget ? 'show' : 'hide'

  if (!isEditor) return null
  return (
    <>
      <AnimatePresence initial={false}>
        <StyledTrash ref={dropRef} animate={animateState}>
          <FaTrash />
        </StyledTrash>
      </AnimatePresence>

      <Prompt
        isVisible={documents.length > 0}
        title={t('document.massDelete.deleteTitle')}
        description={t('document.massDelete.deleteDescription', { deleteCount: documents.length })}
        onClose={() => setDocuments([])}
        buttons={[
          {
            text: t('general.cancel'),
            action: () => !isDeleting && setDocuments([])
          },
          {
            text: t('general.delete'),
            icon: <FaTrash />,
            intent: 'danger',
            action: () => massDeleteMutation(),
            showSpinner: isDeleting,
            disabled: isDeleting
          }
        ]}
      />
    </>
  )
}

const StyledTrash = styled(motion.div).attrs({
  variants: {
    hide: {
      y: 100,
      opacity: 0,
      pointerEvents: 'none'
    },
    show: {
      y: 0,
      opacity: 1,
      pointerEvents: 'all'
    },
    over: {
      y: 0,
      opacity: 1,
      scale: 1.2,
      pointerEvents: 'all'
    }
  }
})`
  position: absolute;
  width: 3.5rem;
  height: 3.5rem;
  background: ${({ theme }) => theme.colors.nocfoRed};
  right: ${({ theme }) => theme.spacing.md}rem;
  bottom: ${({ theme }) => theme.spacing.md}rem;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.2);

  svg {
    width: ${({ theme }) => theme.iconSize.sm}rem;
    height: ${({ theme }) => theme.iconSize.sm}rem;
    fill: ${({ theme }) => theme.colors.neutralWhite};
  }
`
