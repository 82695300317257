import {
  deleteAuditTrailDocumentComment,
  IAuditTrailComment,
  IAuditUser
} from '@root/query/auditTrail'
import React, { createElement, ReactNode, useState } from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import { useBusinessContext, usePermissionBoundary } from '@root/context'
import { UserRole } from '@root/constants'
import { IUsershipPerson, useCurrentUser } from '@root/query'
import { Prompt } from '../Prompt'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { queryKeyForAuditTrail } from '@root/pages/DocumentPage/DocumentDetails/DocumentAuditTrail'
import toast from 'react-hot-toast'
import UserDetails from '../AuditTrailStep/ContentBlocks/Header/UserDetails'
import { visitParents } from 'unist-util-visit-parents'

interface Props {
  comment: IAuditTrailComment
  users: IUsershipPerson[]
}

export const AuditTrailComment: React.FC<Props> = ({ comment, users }) => {
  const isAdmin = usePermissionBoundary(UserRole.ADMIN)
  const { data: currentUser } = useCurrentUser()
  const canRemoveUpdateComment = isAdmin || currentUser?.id === comment.created_by.id
  const [t] = useTranslation()
  const { businessId } = useBusinessContext()
  const [showDelete, setShowDelete] = useState(false)
  const queryClient = useQueryClient()

  const deleteMutation = useMutation(
    () => deleteAuditTrailDocumentComment({ businessId, commentId: comment.id }),
    {
      onSuccess: async () => {
        setShowDelete(false)
        await queryClient.invalidateQueries(queryKeyForAuditTrail(businessId, comment.document))
        toast.success(t('components.auditTrail.commentDeleted'))
      },
      onError: async () => {
        setShowDelete(false)
        await queryClient.invalidateQueries(queryKeyForAuditTrail(businessId, comment.document))
        toast.error(t('components.auditTrail.commentDeletedError'))
      }
    }
  )

  /**
   * Find all mentions in the text and replace them with UserDetails component.
   * Return an array of strings and UserDetails components.
   */
  const findUserMentions = (text): Array<string | ReactNode> => {
    const mentionRegex = /\((userId:\d+)\)/g
    const parts = text.split(mentionRegex)

    return parts.map((part, index) => {
      if (part === '') {
        return null
      }
      if (index % 2 === 1) {
        const userId = part.match(/\d+/)[0]

        const user = users?.find(usershipPerson => usershipPerson.user_id === parseInt(userId))

        return (
          <UserDetails
            key={`user-item-${index}`}
            user={
              {
                id: user?.id,
                name: user?.first_name ? `${user?.first_name} ${user?.last_name}` : null,
                email: user?.email
              } as IAuditUser
            }
          ></UserDetails>
        )
      }
      return part
    })
  }

  const parseChildrenForUserMention = (children, node, inline = true) => {
    const components = []
    children.forEach((child, index) => {
      if (typeof child !== 'string') {
        components.push(child)
        return
      }
      const component = findUserMentions(child)
      const element = createElement(
        node.tagName,
        {
          key: `user-comment-element-${node.tagName}-${index}`,
          style: inline ? { display: 'inline' } : {}
        },
        component
      )
      components.push(element)
    })
    return components
  }

  const userMentionMatch = () => {
    return {
      'has-user-mention': ({ children, node }) => {
        return parseChildrenForUserMention(children, node)
      },
      visited: ({ children }) => {
        return children
      }
    }
  }

  function rehypeWrapText() {
    return function wrapTextTransform(tree) {
      visitParents(tree, 'text', (node, ancestors) => {
        if (
          ancestors.at(-1).tagName !== 'visited' &&
          ancestors.at(-1).tagName !== 'has-user-mention'
        ) {
          const mentionRegex = /\((userId:\d+)\)/g
          const containsMention = mentionRegex.test(node.value)

          node.type = 'element'
          node.tagName = containsMention ? 'has-user-mention' : 'visited'
          node.children = [{ type: 'text', value: node.value }]
        }
      })
    }
  }

  return (
    <>
      <CommentWrapper>
        <CommentTextContainer>
          <ReactMarkdown
            rehypePlugins={[rehypeWrapText]}
            linkTarget="_blank"
            components={userMentionMatch() as Partial<import('react-markdown').Components>}
          >
            {comment.text}
          </ReactMarkdown>
        </CommentTextContainer>
        {canRemoveUpdateComment && (
          <Actions>
            <button
              onClick={e => {
                e.preventDefault()
                setShowDelete(true)
              }}
            >
              {t('general.delete')}
            </button>
          </Actions>
        )}
      </CommentWrapper>

      {/* DELETE PROMPT */}
      <Prompt
        isVisible={showDelete}
        title={t('general.areYouSure')}
        description={t('components.auditTrail.removeCommentCheck')}
        onClose={() => setShowDelete(false)}
        buttons={[
          {
            text: t('general.cancel'),
            action: () => setShowDelete(false)
          },
          {
            text: t('components.auditTrail.deleteComment'),
            intent: 'danger',
            action: () => deleteMutation.mutate(),
            showSpinner: deleteMutation.isLoading,
            disabled: deleteMutation.isLoading
          }
        ]}
      />
    </>
  )
}

const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid ${({ theme }) => theme.colors.neutralGray};
  border-radius: 1rem;
  max-width: fit-content;
  padding: ${({ theme }) => theme.spacing.sm}rem;

  color: ${({ theme }) => theme.colors.metalGray};
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
`

const CommentTextContainer = styled.div`
  & > *:last-child {
    margin: 0;
  }
`

const Actions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.spacing.sm}rem;

  & > button {
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    color: ${({ theme }) => theme.colors.metalGray};
    font-weight: bold;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`
