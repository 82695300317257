import { Alert, Switch, TextEditor } from '@components'
import React from 'react'
import { Controller, useWatch } from 'react-hook-form'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'

interface Props {
  sectionTitle: string
  sectionBadge?: string
  sectionInfo?: string
  showKeyName: string
  textKeyName: string
  control?: any
}

export const FinancialStatementSection: React.FC<Props> = ({
  sectionTitle,
  sectionBadge,
  sectionInfo,
  showKeyName,
  textKeyName,
  control
}) => {
  const isVisible = useWatch({ control, name: showKeyName })

  return (
    <FinancialStatementSectionWrapper>
      <StyledTogglabbleHeader>
        <StyledHeader htmlFor={showKeyName}>
          {sectionBadge && (
            <SectionBadge>
              <span>{sectionBadge}</span>
            </SectionBadge>
          )}
          <span>{sectionTitle}</span>
        </StyledHeader>
        <div>
          <Controller
            control={control}
            name={showKeyName}
            render={({ field: { ref, value, onChange }, fieldState: { error } }) => (
              <Switch
                ref={ref}
                id={showKeyName}
                checked={value}
                onChange={onChange}
                error={error}
              />
            )}
          />
        </div>
      </StyledTogglabbleHeader>

      {isVisible && (
        <StyledTogglabbleContent key={`togglable-container`}>
          <Alert
            isVisible={!!sectionInfo}
            description={<ReactMarkdown>{sectionInfo}</ReactMarkdown>}
          />
          <Controller
            control={control}
            name={textKeyName}
            render={({ field: { value, onChange } }) => (
              <TextEditor value={value} onChange={onChange} />
            )}
          />
        </StyledTogglabbleContent>
      )}
    </FinancialStatementSectionWrapper>
  )
}

const SectionBadge = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  font-size: ${({ theme }) => theme.fontSize.xs}rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.orange};
  letter-spacing: 1px;
  white-space: nowrap;

  svg {
    fill: ${({ theme }) => theme.colors.orange};
  }
`

const StyledTogglabbleHeader = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.spacing.md}rem ${({ theme }) => theme.spacing.sm}rem;
  gap: ${({ theme }) => theme.spacing.md}rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .input-wrapper {
    margin: 0;
  }
`

const StyledHeader = styled.label`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${({ theme }) => theme.spacing.xs}rem;

  .info-knob {
    width: 1rem;
    height: 1rem;
  }
`

const StyledTogglabbleContent = styled.div`
  padding: ${({ theme }) => theme.spacing.md}rem ${({ theme }) => theme.spacing.sm}rem;
  padding-top: 0;
  border-radius: 1rem;

  h1 {
    font-size: ${({ theme }) => theme.fontSize.md}rem;
  }
`

const FinancialStatementSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.lg}rem;
  border: 1px solid red;
  border-radius: 1rem;
  border: 3px solid ${({ theme }) => theme.colors.neutralGray};
  background: ${({ theme }) => theme.colors.neutralGray};
`
