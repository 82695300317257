import { Input } from '@components'
import {
  autoUpdate,
  FloatingFocusManager,
  offset,
  Placement,
  size,
  useDismiss,
  useFloating
} from '@floating-ui/react-dom-interactions'
import { useQueryParams } from '@hooks'
import cn from 'classnames'
import dayjs from 'dayjs'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { FaCalendarAlt, FaCaretDown } from 'react-icons/fa'
import styled from 'styled-components'

interface HistoryOption {
  key: string
  dates: [string, string]
}

interface OnFilterProps {
  date_from: string
  date_to: string
}

interface DateRangeFilterProps {
  onFilter: (dates: OnFilterProps, key: string) => void
  options?: HistoryOption[]
  defaultOption?: string
  placement?: Placement
}

const MAX_DROPDOWN_WIDTH = 600

export const DateRangeFilter: React.FC<DateRangeFilterProps> = ({
  onFilter,
  options,
  defaultOption,
  placement = 'bottom-start'
}) => {
  const [t] = useTranslation()
  const historyOptions = useMemo<HistoryOption[]>(
    () =>
      options || [
        {
          key: 'all',
          dates: [null, null]
        },
        {
          key: 'month-this',
          dates: [
            dayjs().startOf('month').format('YYYY-MM-DD'),
            dayjs().endOf('month').format('YYYY-MM-DD')
          ]
        },
        {
          key: 'month-prev',
          dates: [
            dayjs().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
            dayjs().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
          ]
        },
        {
          key: 'year-this',
          dates: [
            dayjs().startOf('year').format('YYYY-MM-DD'),
            dayjs().endOf('year').format('YYYY-MM-DD')
          ]
        },
        {
          key: 'year-prev',
          dates: [
            dayjs().subtract(1, 'years').startOf('year').format('YYYY-MM-DD'),
            dayjs().subtract(1, 'years').endOf('year').format('YYYY-MM-DD')
          ]
        },
        {
          key: 'custom',
          dates: [
            dayjs().startOf('month').format('YYYY-MM-DD'),
            dayjs().endOf('month').format('YYYY-MM-DD')
          ]
        }
      ],
    [dayjs().format('YYYY-MM-DD'), options]
  )
  const [params, setParams] = useQueryParams()
  const selected = useMemo(() => {
    return (
      historyOptions.find(item => item.key === params.t) ||
      historyOptions.find(item => item.key === defaultOption) ||
      historyOptions[0]
    )
  }, [params.t])
  const [menuOpen, setMenuOpen] = useState(false)

  const { context, x, y, reference, floating, strategy } = useFloating({
    open: menuOpen,
    onOpenChange: setMenuOpen,
    placement,
    middleware: [
      offset(10),
      size({
        apply: ({ availableWidth, elements }) => {
          const maxWidth = availableWidth - 10
          const width = MAX_DROPDOWN_WIDTH > maxWidth ? maxWidth : MAX_DROPDOWN_WIDTH
          Object.assign(elements.floating.style, {
            maxWidth: `${width}px`
          })
        }
      })
    ],
    whileElementsMounted: autoUpdate
  })

  useDismiss(context)

  useEffect(() => {
    if (selected.key === 'custom') {
      onFilter(
        {
          date_from: params.df as string,
          date_to: params.dt as string
        },
        selected.key
      )
    } else {
      onFilter(
        {
          date_from: selected.dates[0],
          date_to: selected.dates[1]
        },
        selected.key
      )
    }
  }, [selected.key, params.df, params.dt])

  useEffect(() => {
    const dates: { df?: string; dt?: string } = {}

    if (selected.key === 'custom' && !params.df) {
      dates.df = dayjs().startOf('year').format('YYYY-MM-DD')
    }

    if (selected.key === 'custom' && !params.df) {
      dates.dt = dayjs().endOf('year').format('YYYY-MM-DD')
    }

    setParams(dates)
  }, [selected.key === 'custom'])

  return (
    <>
      <StyledFilterButton
        ref={reference}
        onClick={() => {
          setMenuOpen(!menuOpen)
        }}
      >
        <FaCalendarAlt />
        <span>{t(`components.dateRangeFilter.${selected.key}`)}</span>
        <FaCaretDown />
      </StyledFilterButton>

      <DateRangeMenu
        ref={floating}
        context={context}
        isVisible={menuOpen}
        options={historyOptions}
        selected={selected.key}
        onSelect={({ key }) =>
          setParams({
            t: key,
            dt: key === 'custom' ? params.dt : null,
            df: key === 'custom' ? params.df : null
          })
        }
        customDates={[params.df as string, params.dt as string]}
        setCustomDates={dates => {
          setParams({
            df: dates[0],
            dt: dates[1]
          })
        }}
        x={x}
        y={y}
        strategy={strategy}
      />
    </>
  )
}

interface DateRangeMenuProps {
  context: any
  isVisible: boolean
  options: HistoryOption[]
  selected: string
  onSelect: (selection: HistoryOption) => void
  customDates: [string, string]
  setCustomDates: (dates: [string, string]) => void
  x?: number
  y?: number
  strategy?: any
}

const DateRangeMenu = React.forwardRef<HTMLElement, DateRangeMenuProps>(
  (
    {
      isVisible,
      context,
      options,
      selected,
      customDates,
      setCustomDates,
      onSelect,
      x = 0,
      y = 0,
      strategy
    },
    ref
  ) => {
    const [t] = useTranslation()
    const rootRef = useRef<HTMLElement>(document.getElementById('dropdown-root'))
    const isCustom = selected === 'custom'

    return createPortal(
      <AnimatePresence>
        {isVisible && (
          <FloatingFocusManager context={context}>
            <StyledMenu
              ref={ref as any}
              style={{
                top: y || 0,
                left: x || 0,
                position: strategy,
                width: 'max-content'
              }}
            >
              {options.map(option => (
                <StyledOption
                  key={option.key}
                  className={cn({ isSelected: selected === option.key })}
                  onClick={() => onSelect(option)}
                >
                  {t(`components.dateRangeFilter.${option.key}`)}
                </StyledOption>
              ))}

              <AnimatePresence>
                {isCustom && (
                  <CustomDatesRow>
                    <Input
                      label={t(`components.dateRangeFilter.startDate`)}
                      type="date"
                      value={customDates[0]}
                      onChange={e => {
                        setCustomDates([e.currentTarget.value, customDates[1]])
                      }}
                    />
                    <Input
                      label={t(`components.dateRangeFilter.endDate`)}
                      type="date"
                      value={customDates[1]}
                      onChange={e => {
                        setCustomDates([customDates[0], e.currentTarget.value])
                      }}
                    />
                  </CustomDatesRow>
                )}
              </AnimatePresence>
            </StyledMenu>
          </FloatingFocusManager>
        )}
      </AnimatePresence>,
      rootRef.current
    )
  }
)

const StyledFilterButton = styled.button`
  outline: none;
  border: none;
  padding: ${({ theme }) => theme.spacing.xxs}rem;
  gap: ${({ theme }) => theme.spacing.xxs}rem;
  display: flex;
  white-space: nowrap;
  background: ${({ theme }) => theme.colors.neutralGray};
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.metalGray};
  border-radius: 0.3rem;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.xs}rem;
`

const StyledMenu = styled(motion.div).attrs({
  variants: {
    hide: {
      opacity: 0,
      scale: 0.98,
      y: 10,
      transition: {
        duration: 0.2,
        staggerChildren: 0.06,
        staggerDirection: -1,
        type: 'tween'
      }
    },
    show: {
      opacity: 1,
      scale: 1.0,
      y: 0,
      transition: {
        duration: 0.15,
        staggerChildren: 0.06,
        staggerDirection: 1,
        type: 'tween'
      }
    }
  },
  initial: 'hide',
  animate: 'show',
  exit: 'hide'
})`
  display: flex;
  flex-wrap: wrap;
  max-width: ${MAX_DROPDOWN_WIDTH}px;
  background: ${({ theme }) => theme.colors.neutralWhite};
  padding: ${({ theme }) => theme.spacing.sm}rem;
  gap: ${({ theme }) => theme.spacing.xs}rem;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
`

const StyledOption = styled(motion.div).attrs({
  variants: {
    hide: {
      opacity: 0,
      y: 20,
      transition: {
        duration: 0.05
      }
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.05
      }
    }
  }
})`
  font-size: ${({ theme }) => theme.fontSize.sm}rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.metalGray};
  white-space: nowrap;
  background: ${({ theme }) => theme.colors.neutralGray};
  border-radius: 100rem;
  padding: ${({ theme }) => theme.spacing.xs}rem ${({ theme }) => theme.spacing.xs}rem;
  transition: 0.2s;
  cursor: pointer;

  &.isSelected {
    background: ${({ theme }) => theme.colors.nocfoBlue};
    color: white;
  }
`

const CustomDatesRow = styled(motion.div).attrs({
  initial: { opacity: 0, y: -10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -10 }
})`
  display: flex;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.sm}rem;
  padding-top: ${({ theme }) => theme.spacing.xs}rem;
  border-top: 1px solid ${({ theme }) => theme.colors.neutralGray};

  .input-wrapper {
    margin: 0;
  }
`
